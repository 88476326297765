import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

export default function TransitionAlerts(props: any) {
	return (
		<Collapse in={true}>
			<Alert
				severity={props.severity}
				sx={{ p: { xs: 0.5, sm: "6px 16px" }, pl: { xs: 1.5 } }}
			>
				{props.content}
			</Alert>
		</Collapse>
	);
}

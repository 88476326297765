import {
	CardHeader,
	Card,
	Grow,
	Table,
	TableBody,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	Divider,
	Typography,
} from "@mui/material";
import moment from "moment";
import ButtonLoader from "../../../utils/ButtonLoader";
import { formatCurrency } from "../../../utils/FormatCurrency";
import { classes } from "../styles";

const Renewals = (props: any) => {
	const { data } = props;
	let sortedPayments: any = data?.scheduledPayments?.sort(function (
		a: any,
		b: any
	) {
		return a.dueDate.localeCompare(b.dueDate);
	});
	return (
		<div style={{ marginBottom: 20 }}>
			<Grow in={true} timeout={0}>
				<Card raised>
					<CardHeader sx={classes.cardHeader} title={"Upcoming Payments"} />
					<Divider light />
					<TableContainer sx={{ maxHeight: 198 }}>
						<Table stickyHeader aria-label="simple table">
							<TableHead>
								<TableRow sx={classes.tableRow}>
									<TableCell
										padding="none"
										sx={[
											{
												pl: 2,
												py: 1,
												minWidth: { xs: 80, sm: 200 },
											},
											classes.tableRow,
										]}
									>
										Item
									</TableCell>
									<TableCell
										padding="none"
										sx={[classes.tableRow, { minWidth: { xs: 45, sm: 50 } }]}
										align="right"
									>
										Remaining
									</TableCell>
									<TableCell
										padding="none"
										sx={[classes.tableRow, { minWidth: { xs: 60, sm: 70 } }]}
										align="right"
									>
										Amount
									</TableCell>
									<TableCell
										padding="none"
										sx={[
											{
												pr: { xs: 2 },
												minWidth: { sm: 75 },
												maxWidth: 100,
											},
											classes.tableRow,
										]}
										align="right"
									>
										Due Date
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data?.scheduledPayments?.length > 0 &&
									props.successful &&
									sortedPayments.map((row: any, index: any) => (
										<TableRow
											key={index}
											sx={[
												{
													"&:last-child td, &:last-child th": { border: 0 },
												},
												classes.table,
											]}
										>
											<TableCell
												component="th"
												scope="row"
												padding="none"
												sx={{
													pl: 2,
													// whiteSpace: "nowrap",
													// textOverflow: "ellipsis",
													// overflow: "hidden",
													maxWidth: 120,
												}}
											>
												{row.productName}
											</TableCell>
											<TableCell align="right" padding="none">
												{row.remainingPayments === "2147483647"
													? "Recurring"
													: row.remainingPayments}
											</TableCell>
											<TableCell align="right" padding="none">
												{formatCurrency(row.amountDue, props.clubData)}
											</TableCell>
											<TableCell
												align="right"
												padding="none"
												sx={{ pr: { xs: 2 }, py: 2 }}
											>
												{moment(row.dueDate?.slice(0, 10)).format("M/DD/YY")}
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
						{!data?.scheduledPayments && props.successful || data?.scheduledPayments?.length == 0 && props.successful ?(
							<Typography
								align="center"
								variant="subtitle2"
								sx={{
									p: 2,
									backgroundColor: "secondary.light",
								}}
							>
								There are no upcoming payments
							</Typography>
						): null}
						{props.requesting && (
							<div
								style={{
									backgroundColor: "#E5F6FD",
									overflowY: "hidden",
									height: 50,
									paddingTop: 10,
									paddingBottom: 10,
								}}
							>
								<ButtonLoader />
							</div>
						)}
					</TableContainer>
				</Card>
			</Grow>
		</div>
	);
};

export default Renewals;

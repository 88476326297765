import { Typography, Slider, Grid } from "@mui/material";
import { formatCurrency } from "../../utils/FormatCurrency";

const classes: any = {
	root: {
		marginLeft: "auto",
		marginRight: "auto",
	},
};

export default function RangeSlider(props: any) {
	const { clubData } = props;
	const handleChange = (event: any, newValue: number | number[]) => {
		props.setPrice(newValue as number[]);
	};
	function valuetext(value: any) {
		return formatCurrency(value, clubData);
	}

	return (
		<div style={classes.root}>
			<Grid
				container
				spacing={2}
				style={{ width: "100%", paddingTop: 0 }}
				justifyContent="center"
			>
				<Grid item xs={12} sx={{ pb: 0, ml: 2.5 }}>
					<Typography align="right" variant="caption" id="range-slider">
						Price range:
					</Typography>
				</Grid>
				<Grid item xs={2} style={{ paddingTop: 0 }}>
					<Typography
						align="right"
						style={{
							paddingRight: 5,
							marginLeft: clubData?.currencyPostfix ? -20 : 0,
						}}
					>
						{clubData?.currencyPrefix + 0 + clubData?.currencyPostfix}
					</Typography>
				</Grid>
				<Grid item xs={6} style={{ paddingTop: 0 }}>
					<Slider
						value={props.price}
						onChange={handleChange}
						valueLabelDisplay="auto"
						aria-labelledby="range-slider"
						getAriaValueText={valuetext}
						step={50}
						style={{ minWidth: 80, paddingTop: 0 }}
						defaultValue={1000}
						max={1000}
						size="small"
					/>
				</Grid>
				<Grid item xs={3} style={{ paddingTop: 0 }}>
					<Typography>
						{clubData?.currencyPrefix + 1000 + clubData?.currencyPostfix + "+"}
					</Typography>
				</Grid>
			</Grid>
		</div>
	);
}

import React from "react";
import { Link } from "react-router-dom";
import {
	BottomNavigationAction,
	BottomNavigation,
	AppBar,
	Icon,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import { connect } from "react-redux";

const classes: any = {
	bottomNav: {
		backgroundColor: "text.disabled",
		color: "white",
	},
};

function Footer(props: any) {
	const { userConfig, user } = props;
	let path: any =
		userConfig?.businessAlias + "/" + window.location.pathname.split("/")[2];
	const [value, setValue] = React.useState<any>("");
	const theme: Theme = useTheme();
	React.useEffect(() => {
		setValue("/" + path);
	}, [path]);
	const items = [
		{
			icon: "dashboard_icon",
			text: "Dashboard",
			link: `/${userConfig?.businessAlias}/dashboard`,
		},
		{
			icon: "person",
			text: "Profile",
			link: `/${userConfig?.businessAlias}/profile`,
		},
		{
			icon: "event",
			text: "Schedule",
			link: `/${userConfig?.businessAlias}/schedule`,
		},
		{
			icon: "post_add_icon",
			text: "Enroll",
			link: `/${userConfig?.businessAlias}/enroll`,
		},
		{
			icon: "shopping_cart",
			text: "Marketplace",
			link: `/${userConfig?.businessAlias}/marketplace`,
		},
	];
	return (
		<div>
			{user ? (
				<AppBar
					sx={{
						bottom: 0,
						top: "auto",
						height: "50px",
						borderTop: `solid 1px ${theme.palette.secondary.main}`,
						display: "flex",
						[theme.breakpoints.up("md")]: {
							display: "none",
						},
					}}
					position="fixed"
				>
					<BottomNavigation
						value={value}
						onChange={(event, newValue) => {
							setValue(newValue);
						}}
						showLabels
						sx={classes.bottomNav}
					>
						{items.map((item, index) => {
							return (
								<BottomNavigationAction
									style={{ minWidth: 0 }}
									component={Link}
									key={index}
									to={item.link}
									value={item.link}
									icon={<Icon>{item.icon}</Icon>}
									label={<div style={{ fontSize: 12 }}>{item.text}</div>}
								/>
							);
						})}
					</BottomNavigation>
				</AppBar>
			) : (
				""
			)}
		</div>
	);
}
const mapStateToProps = (state: any) => {
	return {
		user: state.auth.currentUser,
		errors: state.auth.errors,
		userConfig: state.auth.userConfig,
	};
};

export default connect(mapStateToProps)(Footer);

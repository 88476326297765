import { Typography, Grid } from "@mui/material/";
import AmericanExpress from "../../../media/AmericanExpressLogo.svg";
import Visa from "../../../media/VisaLogo.svg";
import Discover from "../../../media/DiscoverLogo.svg";
import MasterCard from "../../../media/MasterCardLogo.svg";

export default function DefaultPayment(props: any) {
	const getCardLogo = (cardType: any) => {
		switch (cardType) {
			case "American Express":
				return AmericanExpress;
			case "Visa":
				return Visa;
			case "Master Card":
				return MasterCard;
			case "Discover":
				return Discover;
			default:
				return cardType;
		}
	};
	return (
		<Grid container justifyContent="center" direction="column">
			<Grid item>
				<Typography variant="subtitle1" align="center">
					<strong>We Accept:</strong>
				</Typography>
			</Grid>
			<Grid container justifyContent="center" spacing={1}>
				{props.acceptedCards?.map((card: any) => (
					<Grid key={card} item>
						<img src={getCardLogo(card)} height={40} alt="card" />
					</Grid>
				))}
			</Grid>
		</Grid>
	);
}

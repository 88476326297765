import { Typography, Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";

const expiredToken = () => {
	const params: any = window.location.pathname.split("/")[1];
	return (
		<Grid container direction="row" justifyContent="center" sx={{ pt: 1 }}>
			<Grid item>
				<Alert severity="error">
					<Typography>Token has expired</Typography>
				</Alert>
				<Typography sx={{ mt: 1 }}>
					Please follow the{" "}
					<Link
						style={{ fontSize: "16px" }}
						to={`/${params}/forgot/password`}
						color="primary"
					>
						Forgot Password
					</Link>{" "}
					link and try again
				</Typography>
			</Grid>
		</Grid>
	);
};
export default expiredToken;

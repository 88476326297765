import { Grid, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { validateStep1 } from "../SignUp/Validation";
import { addUserValidate } from "../Profile/validate";
import { LocalizationProvider } from "@mui/x-date-pickers";

const CustomDatePicker = (props: any) => {
	const { errors, setErrors, handleDateChange, selectedDate, addUser } = props;
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Grid container justifyContent="space-around">
				<DatePicker
					openTo="year"
					label="Date of birth"
					views={["year", "month", "day"]}
					value={dayjs(selectedDate)}
					maxDate={new Date()}
					minDate={new Date("1900/01/01")}
					showToolbar={true}
					toolbarPlaceholder="Select Date"
					toolbarFormat="MMM D, YYYY"
					DialogProps={{
						sx: {
							"& .MuiPickersToolbar-penIconButton": {
								display: "none !important",
							},
						},
					}}
					onChange={(newValue: any) => {
						const isValid: any = addUser
							? addUserValidate({ dob: newValue ? newValue.$d : newValue })
							: validateStep1({
									dob: newValue ? newValue.$d : newValue,
							  });
						handleDateChange(newValue);
						setErrors({
							...errors,
							dob: isValid.dob,
						});
					}}
					renderInput={(params: any) => (
						<TextField
							{...params}
							required
							helperText={errors.dob}
							error={Boolean(errors.dob)}
							color="secondary"
							onBlur={() => {
								const isValid: any = addUser
									? addUserValidate({
											dob: selectedDate?.$d ? selectedDate?.$d : selectedDate,
									  })
									: validateStep1({
											dob: selectedDate?.$d ? selectedDate?.$d : selectedDate,
									  });
								setErrors({
									...errors,
									dob: isValid.dob,
								});
							}}
							margin={props.margin}
							InputLabelProps={{ shrink: true }}
							fullWidth
							variant={props.variant}
						/>
					)}
				/>
			</Grid>
		</LocalizationProvider>
	);
};

export default CustomDatePicker;

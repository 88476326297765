export const validateQuestionnaires = (
	values: any,
	setErrors: any,
	answers: any,
	sigImage: any
) => {
	let temp: any = [];
	let answer: any = "";
	let i: any;
	for (i = 0; answers > i; i++) {
		if (values[i] === null) {
			answer = "";
		} else {
			answer = values[i.toString()] ? "" : "Answer is required";
		}
		temp.push(answer);
	}
	if (sigImage === null) {
		temp.push("Signature is required");
	}
	setErrors([...temp]);
	return temp.every((x: any) => x === "");
};

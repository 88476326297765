import ReactHtmlParser from "react-html-parser";
import SignatureCanvas from "react-signature-canvas";
import { FormHelperText, Grid, Typography } from "@mui/material";

export default function Contract(props: any) {
	const { membershipTerms, clubData, refs, errors } = props;
	let membershipInfo: any = unescapeHTML(membershipTerms.membershipInfo);
	let memberInfo: any = unescapeHTML(membershipTerms.memberInfo);
	let paymentInfo: any = unescapeHTML(membershipTerms.paymentInfo);
	let cancelTerms: any = unescapeHTML(membershipTerms.cancellationTerms);
	let legalTerms: any = unescapeHTML(membershipTerms.legalTerms);

	function unescapeHTML(escapedHTML: any) {
		let html = escapedHTML
			.replace(/&lt;br\/&gt;/g, "")
			.replace(/(\r\n|\n|\r)/gm, "<br/>");
		return ReactHtmlParser(html);
	}
	const sigPad: any = {
		width: "100%",
		height: "200px",
		border: "1px solid black",
	};
	return (
		<div>
			<Grid container justifyContent="space-between">
				<Grid item>
					{clubData.logoPath && (
						<img src={clubData?.logoPath} width={120} alt="logo" />
					)}
				</Grid>
				<Grid item>
					<Typography align="right" variant="subtitle2">
						{clubData?.clubName}
						<br />
						{clubData?.address}
						<br />
						{clubData?.city} {clubData?.state}, {clubData?.country}
					</Typography>
				</Grid>
			</Grid>
			<br />
			{memberInfo}
			{membershipInfo}
			{paymentInfo}
			Member Signature:
			<SignatureCanvas
				ref={refs.sigCanvas}
				penColor="black"
				canvasProps={{
					style: sigPad,
				}}
			/>
			{errors.sig1 ? (
				<FormHelperText style={{ color: "red" }}>{errors.sig1}</FormHelperText>
			) : null}
			<br />
			<br />
			{cancelTerms}
			Member Signature:
			<SignatureCanvas
				ref={props.refs.sigCanvas2}
				penColor="black"
				canvasProps={{
					style: sigPad,
				}}
			/>
			{errors.sig2 ? (
				<FormHelperText style={{ color: "red" }}>{errors.sig2}</FormHelperText>
			) : null}
			<br />
			<br />
			{legalTerms}
			Member Signature:
			<SignatureCanvas
				ref={props.refs.sigCanvas3}
				penColor="black"
				canvasProps={{
					style: sigPad,
				}}
			/>
			{errors.sig3 ? (
				<FormHelperText style={{ color: "red" }}>{errors.sig3}</FormHelperText>
			) : null}
			<br />
			<br />
		</div>
	);
}

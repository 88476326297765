const states = [
	{
		label: "",
		value: "",
	},
	{
		label: "Alabama",
		value: "AL",
		country: "US",
		timezone: "America/Chicago",
	},
	{
		label: "Alaska",
		value: "AK",
		country: "US",
		timezone: "America/Anchorage",
	},
	{
		label: "Arizona",
		value: "AZ",
		country: "US",
		timezone: "America/Phoenix",
	},
	{
		label: "Arkansas",
		value: "AR",
		country: "US",
		timezone: "America/Chicago",
	},
	{
		label: "California",
		value: "CA",
		country: "US",
		timezone: "America/Los_Angeles",
	},
	{
		label: "Colorado",
		value: "CO",
		country: "US",
		timezone: "America/Denver",
	},
	{
		label: "Connecticut",
		value: "CT",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "Delaware",
		value: "DE",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "Florida",
		value: "FL",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "Georgia",
		value: "GA",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "Hawaii",
		value: "HI",
		country: "US",
		timezone: "Pacific/Honolulu",
	},
	{
		label: "Idaho",
		value: "ID",
		country: "US",
		timezone: "America/Boise",
	},
	{
		label: "Illinois",
		value: "IL",
		country: "US",
		timezone: "America/Chicago",
	},
	{
		label: "Indiana",
		value: "IN",
		country: "US",
		timezone: "America/Indiana/Indianapolis",
	},
	{
		label: "Iowa",
		value: "IA",
		country: "US",
		timezone: "America/Chicago",
	},
	{
		label: "Kansas",
		value: "KS",
		country: "US",
		timezone: "America/Chicago",
	},
	{
		label: "Kentucky",
		value: "KY",
		country: "US",
		timezone: "America/Chicago",
	},
	{
		label: "Louisiana",
		value: "LA",
		country: "US",
		timezone: "America/Chicago",
	},
	{
		label: "Maine",
		value: "ME",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "Maryland",
		value: "MD",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "Massachusetts",
		value: "MA",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "Michigan",
		value: "MI",
		country: "US",
		timezone: "America/Detroit",
	},
	{
		label: "Minnesota",
		value: "MN",
		country: "US",
		timezone: "America/Chicago",
	},
	{
		label: "Mississippi",
		value: "MS",
		country: "US",
		timezone: "America/Chicago",
	},
	{
		label: "Missouri",
		value: "MO",
		country: "US",
		timezone: "America/Chicago",
	},
	{
		label: "Montana",
		value: "MT",
		country: "US",
		timezone: "America/Denver",
	},
	{
		label: "Nebraska",
		value: "NE",
		country: "US",
		timezone: "America/Chicago",
	},
	{
		label: "Nevada",
		value: "NV",
		country: "US",
		timezone: "America/Los_Angeles",
	},
	{
		label: "New Hampshire",
		value: "NH",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "New Jersey",
		value: "NJ",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "New Mexico",
		value: "NM",
		country: "US",
		timezone: "America/Denver",
	},
	{
		label: "New York",
		value: "NY",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "North Carolina",
		value: "NC",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "North Dakota",
		value: "ND",
		country: "US",
		timezone: "America/Chicago",
	},
	{
		label: "Ohio",
		value: "OH",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "Oklahoma",
		value: "OK",
		country: "US",
		timezone: "America/Chicago",
	},
	{
		label: "Oregon",
		value: "OR",
		country: "US",
		timezone: "America/Los_Angeles",
	},
	{
		label: "Pennsylvania",
		value: "PA",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "Rhode Island",
		value: "RI",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "South Carolina",
		value: "SC",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "South Dakota",
		value: "SD",
		country: "US",
		timezone: "America/Denver",
	},
	{
		label: "Tennessee",
		value: "TN",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "Texas",
		value: "TX",
		country: "US",
		timezone: "America/Chicago",
	},
	{
		label: "Utah",
		value: "UT",
		country: "US",
		timezone: "America/Denver",
	},
	{
		label: "Vermont",
		value: "VT",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "Virginia",
		value: "VA",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "Washington",
		value: "WA",
		country: "US",
		timezone: "America/Los_Angeles",
	},
	{
		label: "West Virginia",
		value: "WV",
		country: "US",
		timezone: "America/New_York",
	},
	{
		label: "Wisconsin",
		value: "WI",
		country: "US",
		timezone: "America/Chicago",
	},
	{
		label: "Wyoming",
		value: "WY",
		country: "US",
		timezone: "America/Denver",
	},
];

export default states;

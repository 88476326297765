import React from "react";
import { Typography, DialogContent, DialogContentText } from "@mui/material";
import CompletedQuestionnaire from "./CompletedQuestionnaires";
import PendingQuestionnaire from "./PendingQuestionnaires";
import Notifications from "../../AlertMessage/Notifications";
import Loader from "../../../utils/Loader";
import Title from "../Dialogs/Title";

export default function ManageQuestionnaires(props: any) {
	const { matches } = props;
	const questionnaireData = props.data;
	const config = {
		headers: {
			Authorization: `Bearer ${
				props.userConfig ? props.userConfig.token : null
			}`,
		},
		partnerApiKey: props.userConfig ? props.userConfig.partnerApiKey : null,
		businessAlias: props.userConfig ? props.userConfig.businessAlias : null,
		id: props.userConfig ? props.userConfig.clientId : null,
	};
	React.useEffect(() => {
		props.getQuestionnaires(config);
	}, []);

	React.useEffect(() => {
		if (
			props.messages === "Successfully submitted Questionnaire" ||
			props.messages === "Successfully updated Questionnaire"
		) {
			props.getQuestionnaires(config);
		}
	}, [props.messages]);

	const renderQuestionnaires = () => {
		let i: any = 0;
		let questionnaires: any = [];
		let questionnaire: any;
		let sortedAs: any = props.data?.assignments.sort(function (a: any, b: any) {
			return b.id - a.id;
		});

		for (i = 0; i < props.data.assignments.length; i++) {
			let questions: any = props.data?.questionnaires.filter((qs: any) => {
				return qs.id === sortedAs[i].questionnaireId;
			});
			questions = questions[0];
			questionnaire =
				sortedAs[i]?.answers?.length > 0 ? (
					<React.Fragment key={sortedAs[i].questionnaireId}>
						<CompletedQuestionnaire
							questions={questions.questions}
							name={questions.name}
							matches={matches}
							assignments={sortedAs[i]}
							onUpdate={props.updateQuestionnaires}
							userConfig={props.userConfig}
							status={props.data.status}
							signature={sortedAs[i].signature}
							getSignatureImage={props.getSignatureImage}
							signatureImage={props.signatureImage}
							onClose={props.onClose}
							completed={
								sortedAs[i]?.answers?.length > 0 ? "Completed" : "Pending"
							}
						/>
						<Typography align="center" variant="subtitle2">
							{sortedAs[i]?.answers?.length > 0 ? "Completed" : "Pending"}
						</Typography>
					</React.Fragment>
				) : (
					<React.Fragment key={sortedAs[i].questionnaireId}>
						<PendingQuestionnaire
							questions={questions?.questions}
							matches={matches}
							name={questions?.name}
							assignments={sortedAs[i]}
							questionnaireId={questions.id}
							updateQuestionnaires={props.updateQuestionnaires}
							onClose={props.onClose}
							userConfig={props.userConfig}
						/>
						<Typography align="center" variant="subtitle2">
							{sortedAs[i]?.answers?.length > 0 ? "Completed" : "Pending"}
						</Typography>
					</React.Fragment>
				);
			questionnaires.push(questionnaire);
		}
		return questionnaires;
	};

	return (
		<>
			<Title title="Questionnaires" onClose={props.onClose} />
			{props.data ? (
				<>
					<DialogContent sx={{ p: 0, pb: 2 }}>
						<DialogContentText sx={{ mt: 2, p: 0 }}>
							{questionnaireData.questionnaires.length === 0 ||
							!questionnaireData.questionnaires ? (
								<Typography align="center" sx={{ p: 2 }}>
									There are no questionnaires
								</Typography>
							) : (
								<>
									{renderQuestionnaires()}

									{props.errors || props.messages ? (
										<Notifications
											messages={props.messages}
											errors={props.errors}
										/>
									) : null}
								</>
							)}
						</DialogContentText>
					</DialogContent>
				</>
			) : (
				<Loader />
			)}
		</>
	);
}

import { connect } from "react-redux";
import Profile from "../components/Profile/Profile";

const mapStateToProps = (state: any) => {
	return {
		user: state.auth.currentUser,
		errors: state.auth.errors,
		clientId: state.auth.clientId,
		clubData: state.billingProfile.clubData,
		data: state.client.data,
		submitted: state.auth.submitted,
		userMessages: state.auth.messages,
		userConfig: state.auth.userConfig,
		requesting: state.client.requesting,
		successful: state.client.successful,
		clientErrors: state.client.errors,
		profileImage: state.client.profileImage,
		profileImageMessage: state.client.profileImageMessage,
		successfulUpdate: state.client.successfulUpdate,
		requestingUpdate: state.client.requestingUpdate,
		userInfo: state.client.user,
		messages: state.client.messages,
		currentUser: state.auth.currentUser,
		beltRank: state.client.beltRank,
		recurring: state.dashboard.data,
		billingProfiles: state.billingProfile.billingProfiles,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getCredits: (config: any) =>
			dispatch({ type: "GET_CREDITS_REQUEST", value: config }),
		getBeltRank: (config: any) =>
			dispatch({ type: "BELT_RANK_REQUEST", value: config }),
		getProfileInfo: (config: any) =>
			dispatch({ type: "PROFILE_INFO_REQUEST", value: config }),
		updateProfileInfo: (config: any) =>
			dispatch({ type: "PROFILE_UPDATE_REQUEST", value: config }),
		updateNotifications: (config: any) =>
			dispatch({ type: "UPDATE_NOTIFICATIONS_REQUEST", value: config }),
		getProfileImage: (config: any) =>
			dispatch({ type: "GET_PROFILE_IMAGE_REQUEST", value: config }),
		deleteProfileImage: (config: any) =>
			dispatch({ type: "DELETE_PROFILE_IMAGE_REQUEST", value: config }),
		uploadProfileImage: (config: any) =>
			dispatch({ type: "PROFILE_IMAGE_UPLOAD_REQUEST", value: config }),
		clearErrors: () => dispatch({ type: "CLEAR_ERRORS" }),
		getQuestionnaires: (config: any) =>
			dispatch({ type: "GET_QUESTIONNAIRE_REQUEST", value: config }),
		getBillingProfile: (config: any) =>
			dispatch({ type: "BILLING_PROFILE_REQUEST", value: config }),
		updateRecurringProfile: (config: any) =>
			dispatch({ type: "UPDATE_RECURRING_REQUEST", value: config }),
		getDashboardData: (config: any) =>
			dispatch({ type: "GET_DASHBOARD_DATA_REQUEST", value: config }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);

import axios from "axios";
import * as URL from "../config";

export function requestGetMemberships(action: any) {
	const getMembershipsUrl: any = `${URL.MP_MEMBER}/clients/memberships?ClientId=${action.id}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}`;
	return axios
		.get(getMembershipsUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestPayMembership(action: any) {
	const payMembershipUrl: any = `${URL.MP_BILLING}/memberships/pay`;
	return axios
		.post(payMembershipUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			return error.response;
		});
}
export function requestContractPdf(action: any) {
	const getContractPdfUrl = `${URL.MP_MEMBER}/media?MediaPath=${action.mediaPath}&PartnerApiKey=${action.partnerApiKey}&BusinessAlias=${action.businessAlias}`;
	return axios
		.get(getContractPdfUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

import { call, put, takeEvery } from "redux-saga/effects";
import * as constant from "../constants/users.constants";
import {
	requestForgotUsername,
	requestResetPassword,
	requestForgotPassword,
	requestChangePassword,
	requestEmail,
	requestValidPassword,
	requestSetPassword,
	requestCheckToken,
} from "../services/user.service";
import { push } from "react-router-redux";
import { ResponseGenerator } from "../types";

export function* forgotPassword(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestForgotPassword, config);
		if (response.status === 200) {
			yield put({ type: constant.FORGOT_PASSWORD_SUCCESS });
		}
	} catch (error) {
		yield put({ type: constant.FORGOT_PASSWORD_FAILURE, error });
	}
}

export function* forgotUsername(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestForgotUsername, config);
		if (response) {
			yield put({ type: constant.FORGOT_USERNAME_SUCCESS });
		}
	} catch (error) {
		yield put({ type: constant.FORGOT_USERNAME_FAILURE, error });
	}
}
export function* checkToken(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestCheckToken, config);
		if (response.status === 200) {
			yield put({ type: constant.CHECK_TOKEN_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constant.CHECK_TOKEN_FAILURE, error });
	}
}
export function* resetPassword(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestResetPassword, config);
		if (response.status === 200) {
			yield put({ type: constant.RESET_PASSWORD_SUCCESS });
			yield put(push(`/${config.value.BusinessAlias}/signin`));
		}
	} catch (error) {
		yield put({ type: constant.RESET_PASSWORD_FAILURE, error });
	}
}
export function* setPassword(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestSetPassword, config);
		if (response.status === 200) {
			yield put({ type: constant.SET_PASSWORD_SUCCESS });
			yield put(push(`/${config.value.BusinessAlias}/signin`));
		}
	} catch (error) {
		yield put({ type: constant.SET_PASSWORD_FAILURE, error });
	}
}
export function* changePassword(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestChangePassword, config);
		if (response.status === 200) {
			yield put({ type: constant.CHANGE_PASSWORD_SUCCESS });
			yield put(push(`/${config.value.BusinessAlias}/signin`));
		}
	} catch (error) {
		yield put({ type: constant.CHANGE_PASSWORD_FAILURE, error });
	}
}

export function* requestResendEmail(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestEmail, config);
		if (response.status === 200) {
			yield put({ type: constant.RESEND_EMAIL_SUCCESS });
		}
	} catch (error) {
		yield put({ type: constant.RESEND_EMAIL_FAILURE, error });
	}
}
export function* validPassword(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestValidPassword, config);
		if (response.status === 200) {
			yield put({ type: constant.VALID_PASSWORD_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constant.VALID_PASSWORD_FAILURE, error });
	}
}
export function* watchUserRequest() {
	yield takeEvery(constant.RESET_PASSWORD_REQUEST, resetPassword);
	yield takeEvery(constant.VALID_PASSWORD_REQUEST, validPassword);
	yield takeEvery(constant.RESEND_EMAIL_REQUEST, requestResendEmail);
	yield takeEvery(constant.CHANGE_PASSWORD_REQUEST, changePassword);
	yield takeEvery(constant.SET_PASSWORD_REQUEST, setPassword);
	yield takeEvery(constant.FORGOT_USERNAME_REQUEST, forgotUsername);
	yield takeEvery(constant.FORGOT_PASSWORD_REQUEST, forgotPassword);
	yield takeEvery(constant.CHECK_TOKEN_REQUEST, checkToken);
}

import React from "react";
import {
	Grid,
	Typography,
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
} from "@mui/material";
import { pdfjs, Document, Page } from "react-pdf";
import PrintIcon from "@mui/icons-material/Print";
import GetAppIcon from "@mui/icons-material/GetApp";
import Title from "../Dialogs/Title";
import Loader from "../../../utils/Loader";

const classes: any = {
	iframe: {
		width: "100%",
		overflowY: "auto",
	},
	document: {
		width: "100% !important",
		height: "auto !important",
	},
	page: {
		marginBottom: 0,
		width: "100%",
	},
};

export default function ContractPdf(props: any) {
	const { userConfig, url, id, contractPdf, onClose, contractPdfSuccessful } =
		props;
	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	const [pdfImage, setPdfImage] = React.useState<any>("");
	const [numPages, setNumPages] = React.useState<any>(0);
	const [initialLoad, setInitialLoad] = React.useState<boolean>(false);
	React.useEffect(() => {
		if (!initialLoad) {
			if (url && url.includes("memberportal-2")) {
				config = { ...config, mediaPath: url };
				config.invoiceId = id;
				props.getContractPdf(config);
			}
			setPdfImage(null);
			setInitialLoad(true);
		}
	}, [initialLoad]);
	React.useEffect(() => {
		setPdfImage(contractPdf);
	}, [contractPdf]);

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
	}
	const openPdf = (basePdf: any) => {
		let byteCharacters = atob(basePdf);
		let byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		let byteArray = new Uint8Array(byteNumbers);
		let file = new Blob([byteArray], { type: "application/pdf;base64" });
		let fileURL = URL.createObjectURL(file);
		window.open(fileURL);
	};
	let config: any = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		id: userConfig ? userConfig.clientId : null,
		invoiceId: id,
	};
	return (
		<>
			<Title title="View Contract" onClose={onClose} />
			{url ? (
				!url?.includes("memberportal-2") ? (
					<DialogContent sx={{ p: 0, m: 0 }}>
						<DialogContentText>
							<iframe
								allowFullScreen={true}
								className={classes.iframe}
								src={props.url}
								style={{
									width: props.mobile ? 500 : 900,
									minHeight: props.mobile ? 750 : 650,
								}}
							/>
						</DialogContentText>
					</DialogContent>
				) : (
					<>
						<DialogActions>
							<Grid container justifyContent="center" padding="none">
								<Button
									variant="contained"
									color="primary"
									startIcon={<GetAppIcon />}
									sx={{ mr: 1 }}
								>
									<a
										download={`Invoice ${props.id}`}
										href={`data:application/pdf;base64, ${pdfImage}`}
										style={{
											textDecoration: "none",
											color: "white",
										}}
									>
										Download
									</a>
								</Button>
								<Button
									variant="contained"
									color="primary"
									startIcon={<PrintIcon />}
									onClick={() => openPdf(pdfImage)}
									sx={{ ml: 1 }}
								>
									print
								</Button>
							</Grid>
						</DialogActions>
						<DialogContent sx={{ p: 0, m: 0, width: "100%" }}>
							<DialogContentText>
								{pdfImage && contractPdfSuccessful ? (
									<>
										<Document
											className={classes.document}
											file={`data:application/pdf;base64,${pdfImage}`}
											onLoadSuccess={onDocumentLoadSuccess}
											onLoadError={(error: any) => console.log(error)}
											options={{ workerSrc: "/pdf.worker.js" }}
											loading={<Loader />}
										>
											{Array.from(new Array(numPages), (el, index) => (
												<React.Fragment key={index}>
													<Page
														className={classes.page}
														width={props.mobile ? 350 : 900}
														key={`page_${index + 1}`}
														pageNumber={index + 1}
													/>

													<Typography
														style={{ fontSize: 10 }}
														variant="subtitle2"
														align="center"
													>
														Page {index + 1} of {numPages}
													</Typography>
												</React.Fragment>
											))}
										</Document>
									</>
								) : null}
							</DialogContentText>
						</DialogContent>
					</>
				)
			) : (
				<div style={{ padding: 50 }}>
					<Typography align="center">
						There is no contract generated at this time
					</Typography>
				</div>
			)}
		</>
	);
}

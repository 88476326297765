export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const SIGNOUT = "SIGNOUT";

export const CLUB_LOGO_REQUEST = "CLUB_LOGO_REQUEST";
export const CLUB_LOGO_SUCCESS = "CLUB_LOGO_SUCCESS";
export const CLUB_LOGO_ERROR = "CLUB_LOGO_ERROR";

export const CHECK_ALIAS_REQUEST = "CHECK_ALIAS_REQUEST";
export const CHECK_ALIAS_SUCCESS = "CHECK_ALIAS_SUCCESS";
export const CHECK_ALIAS_ERROR = "CHECK_ALIAS_ERROR";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";

export const REQUEST_QUESTIONNAIRES = "REQUEST_QUESTIONNAIRES";
export const SUBMIT_QUESTIONNAIRES = "SUBMIT_QUESTIONNAIRES";

export const UPDATE_QUESTIONNAIRE_REQUEST = "UPDATE_QUESTIONNAIRE_REQUEST";
export const UPDATE_QUESTIONNAIRE_SUCCESS = "UPDATE_QUESTIONNAIRE_SUCCESS";

export const MARK_QUESTIONNAIRE = "MARK_QUESTIONNAIRE";
export const MARK_QUESTIONNAIRE_SUCCESS = "MARK_QUESTIONNAIRE_SUCCESS";

export const REQUEST_QUESTIONNAIRE_SUCCESS = "REQUEST_QUESTIONNAIRE_SUCCESS";
export const SUBMIT_QUESTIONNAIRE_SUCCESS = "SUBMIT_QUESTIONNAIRE_SUCCESS";
export const QUESTION_FAILURE = "QUESTION_FAILURE";

export const CHECK_USERNAME = "CHECK_USERNAME";
export const USERNAME_ERROR = "USERNAME_ERROR";
export const USERNAME_TAKEN = "USERNAME_TAKEN";

export const SENDING_REQUEST = "SENDING_REQUEST";
export const REQUEST_ERROR = "REQUEST_ERROR";

export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const CLEAR_USER_MESSAGE = "CLEAR_USER_MESSAGE";
export const CLEAR_USERNAME_ERROR = "CLEAR_USERNAME_ERROR";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const FORGOT_USERNAME_REQUEST = "FORGOT_USERNAME_REQUEST";
export const FORGOT_USERNAME_SUCCESS = "FORGOT_USERNAME_SUCCESS";
export const FORGOT_USERNAME_FAILURE = "FORGOT_USERNAME_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const CHECK_TOKEN_REQUEST = "CHECK_TOKEN_REQUEST";
export const CHECK_TOKEN_SUCCESS = "CHECK_TOKEN_SUCCESS";
export const CHECK_TOKEN_FAILURE = "CHECK_TOKEN_FAILURE";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILURE = "SET_PASSWORD_FAILURE";

export const VALID_PASSWORD_REQUEST = "VALID_PASSWORD_REQUEST";
export const VALID_PASSWORD_SUCCESS = "VALID_PASSWORD_SUCCESS";
export const VALID_PASSWORD_FAILURE = "VALID_PASSWORD_FAILURE";

export const RESEND_EMAIL_REQUEST = "RESEND_EMAIL_REQUEST";
export const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";
export const RESEND_EMAIL_FAILURE = "RESEND_EMAIL_FAILURE";

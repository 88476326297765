import React, { useRef } from "react";
import {
	FormControlLabel,
	Grid,
	FormHelperText,
	Typography,
	Button,
	Box,
	Radio,
	FormControl,
	TextField,
	Dialog,
	Backdrop,
} from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import SignatureCanvas from "react-signature-canvas";
import { validateQs } from "./Validation";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const classes: any = {
	popover: {
		height: "100%",
	},
	header: {
		width: "100%",
		margin: 0,
		padding: "20px",
		backgroundColor: "primary.main",
		color: "white",
	},
	body: {
		margin: "20px",
	},
	sigPad: {
		width: "100%",
		minHeight: "150px",
		border: "1px solid black",
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginLeft: "auto",
		marginRight: "auto",
		maxWidth: "800px",
	},
};
export default function Questionnaire(props: any) {
	const [values, setValue] = React.useState<any[]>([]);
	const [errors, setErrors] = React.useState<any>([]);
	const sigCanvas: any = useRef({});
	const [open, setOpen] = React.useState(false);
	let sigImage: any;
	const handleOpen = () => {
		props.setErrors({ ["questionnaires"]: "" });
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let { name, value } = event.target;
		setValue({ ...values, [name]: value });
	};

	const validate = (config: any) => {
		let signature;
		if (!sigCanvas.current.isEmpty()) {
			signature = "signature";
		} else {
			signature = null;
		}
		let answerz: any = [];
		config.Answers.map((answer: any) => {
			return answerz.push(answer.Answer);
		});
		let answerQuantity = answerz.length;
		return validateQs(answerz, setErrors, answerQuantity, signature);
	};

	const submitAnswers = () => {
		let getAnswers = props.questions.map((question: any, index: any) => ({
			Question: question.question,
			Answer: question.questionType === "text_block" ? null : values[index],
			Order: question.order,
			QuestionType: question.questionType,
		}));
		return getAnswers;
	};
	const clear = () => {
		sigCanvas.current.clear();
	};
	const submitSig = () => {
		if (!sigCanvas.current.isEmpty()) {
			sigImage = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
		} else {
			sigImage = "";
		}
	};
	const handleSubmit = (e: any) => {
		e.preventDefault();
		submitSig();
		let answers = submitAnswers();
		const config = {
			BusinessAlias: window.location.pathname.split("/")[1],
			PartnerApiKey: "mm",
			SystemName: "MP2",
			QuestionnaireId: props.id.toString(),
			QuestionnaireName: props.name,
			ClientId: "0000",
			Accepted: "Yes",
			AssignmentDate: new Date().toISOString(),
			SubmitDate: new Date().toISOString(),
			Base64SignatureImage: sigImage.substring(22),
			SignatureImageExtension: "png",
			Answers: answers,
		};

		let valid;
		valid = validate(config);
		if (valid) {
			props.handleQuestionnaire(config);
			props.markQs(config.QuestionnaireId);
			handleClose();
		}
	};
	return (
		<Box sx={classes.popover}>
			{props.data ? (
				<Grid container justifyContent="center">
					<Button
						style={{ marginTop: 10 }}
						onClick={handleOpen}
						variant="contained"
						color="primary"
						disabled={props.submitted.includes(props.id)}
					>
						{props.submitted.includes(props.id) ? (
							<CheckCircleOutlineIcon style={{ marginRight: 5 }} />
						) : null}{" "}
						{props.name} Questionnaire
					</Button>
				</Grid>
			) : null}
			<Dialog
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				sx={{ ...classes.modal }}
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Box sx={classes.header}>
					<Typography variant="h5">{props.name} Questionnaire</Typography>
				</Box>
				{props.questions.map((question: any, index: any) => (
					<Box sx={classes.body} key={question.id}>
						{question.questionType === "text_block" ||
						question.questionType === "text_question" ? (
							<Box>{ReactHtmlParser(question.question)}</Box>
						) : (
							<Box>{question.question}</Box>
						)}
						<FormControl fullWidth component="fieldset">
							{question.questionType === "boolean_question" ? (
								<>
									<Box style={{ display: "flex" }}>
										<FormControlLabel
											value="yes"
											name={index.toString()}
											control={
												<Radio
													checked={values[index] === "yes"}
													onChange={handleChange}
												/>
											}
											label="Yes"
										/>
										<FormControlLabel
											value="no"
											name={index.toString()}
											control={
												<Radio
													checked={values[index] === "no"}
													onChange={handleChange}
												/>
											}
											label="No"
										/>
									</Box>
									<Box>
										<FormHelperText
											style={{ paddingTop: 10, width: "100%" }}
											error={errors[index] ? true : false}
										>
											{errors[index]}
										</FormHelperText>
									</Box>
								</>
							) : null}

							{question.questionType === "text_question" ? (
								<TextField
									onChange={handleChange}
									fullWidth
									variant="outlined"
									name={index.toString()}
									error={errors[index] ? true : false}
									helperText={errors[index]}
								/>
							) : null}
						</FormControl>
					</Box>
				))}
				<Box sx={classes.body}>
					<strong>Member Signature:</strong>
					<Box style={{ width: "100%", minHeight: 100 }}>
						<SignatureCanvas
							ref={sigCanvas}
							penColor="black"
							canvasProps={{
								style: classes.sigPad,
							}}
						/>
						<Button onClick={clear}>Clear</Button>

						<FormHelperText
							style={{ paddingTop: 10, paddingLeft: 15, width: "100%" }}
							error={
								errors[errors.length - 1] === "Signature is required"
									? true
									: false
							}
						>
							{errors[errors.length - 1] === "Signature is required"
								? errors[errors.length - 1]
								: null}
						</FormHelperText>
					</Box>
					<Grid style={{ padding: 10 }} container justifyContent="center">
						<Button
							fullWidth
							style={{ marginBottom: 10 }}
							variant="contained"
							color="primary"
							onClick={handleSubmit}
						>
							Submit Questionnaire
						</Button>
						<Button fullWidth onClick={handleClose} variant="outlined">
							Back to Sign Up
						</Button>
					</Grid>
				</Box>
			</Dialog>
		</Box>
	);
}

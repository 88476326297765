export const countries = [
	{ name: "", value: "" },
	{ name: "United States", value: "US" },
	{ name: "Canada", value: "CA" },
	{ name: "Afghanistan", value: "Afghanistan" },
	{ name: "Albania", value: "Albania" },
	{ name: "Algeria", value: "Algeria" },
	{ name: "American Samoa", value: "American Samoa" },
	{ name: "Andorra", value: "Andorra" },
	{ name: "Angola", value: "Angolda" },
	{ name: "Anguilla", value: "Anguilla" },
	{ name: "Antarctica", value: "Antarctica" },
	{ name: "Antigua and Barbuda", value: "Antigua and Barbuda" },
	{ name: "Argentina", value: "Argentina" },
	{ name: "Armenia", value: "Armenia" },
	{ name: "Aruba", value: "Aruba" },
	{ name: "Australia", value: "Australia" },
	{ name: "Austria", value: "Austria" },
	{ name: "Azerbaijan", value: "Azerbaijan" },
	{ name: "Bahamas", value: "Bahamas" },
	{ name: "Bahrain", value: "Bahrain" },
	{ name: "Bangladesh", value: "Bangladesh" },
	{ name: "Barbados", value: "Barbados" },
	{ name: "Belarus", value: "Belarus" },
	{ name: "Belgium", value: "Belgium" },
	{ name: "Belize", value: "Belize" },
	{ name: "Benin", value: "Benin" },
	{ name: "Bermuda", value: "Bermuda" },
	{ name: "Bhutan", value: "Bhutan" },
	{ name: "Bolivia", value: "Bolivia" },
	{ name: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
	{ name: "Botswana", value: "Botswana" },
	{ name: "Bouvet Island", value: "Bouvet Island" },
	{ name: "Brazil", value: "Brazil" },
	{
		name: "British Indian Ocean Territory",
		value: "British Indian Ocean Territory",
	},
	{ name: "British Virgin Islands", value: "British Virgin Islands" },
	{ name: "Brunei", value: "Brunei" },
	{ name: "Bulgaria", value: "Bulgaria" },
	{ name: "Burkina Faso", value: "Burkina Faso" },
	{ name: "Burundi", value: "Burundi" },
	{ name: "Cambodia", value: "Cambodia" },
	{ name: "Cameroon", value: "Cameroon" },
	{ name: "Cape Verde", value: "Cape Verde" },
	{ name: "Cayman Islands", value: "Cayman Islands" },
	{ name: "Central African Republic", value: "Central African Republic" },
	{ name: "Chad", value: "Chad" },
	{ name: "Chile", value: "Chile" },
	{ name: "China", value: "China" },
	{ name: "Christmas Island", value: "Christmas Island" },
	{ name: "Cocos Islands", value: "Cocos Islands" },
	{ name: "Colombia", value: "Colombia" },
	{ name: "Comoros", value: "Comoros" },
	{ name: "Congo - Brazzaville", value: "Congo - Brazzaville" },
	{ name: "Congo - Kinshasa", value: "Congo - Kinshasa" },
	{ name: "Cook Islands", value: "Cook Islands" },
	{ name: "Costa Rica", value: "Costa Rica" },
	{ name: "Cote D'Ivoire", value: "Cote D'Ivoire" },
	{ name: "Croatia", value: "Croatia" },
	{ name: "Cuba", value: "Cuba" },
	{ name: "Cyprus", value: "Cyprus" },
	{ name: "Czech Republic", value: "Czech Republic" },
	{ name: "Denmark", value: "Denmark" },
	{ name: "Djibouti", value: "Djibouti" },
	{ name: "Dominica", value: "Dominica" },
	{ name: "Dominican Republic", value: "Dominican Republic" },
	{ name: "Ecuador", value: "Ecuador" },
	{ name: "Egypt", value: "Egypt" },
	{ name: "El Salvador", value: "El Salvador" },
	{ name: "Equatorial Guinea", value: "Equatorial Guinea" },
	{ name: "Eritrea", value: "Eritrea" },
	{ name: "Estonia", value: "Estonia" },
	{ name: "Ethiopia", value: "Ethiopia" },
	{ name: "Falkland Islands", value: "Falkland Islands" },
	{ name: "Faroe Islands", value: "Faroe Islands" },
	{ name: "Fiji", value: "Fiji" },
	{ name: "Finland", value: "Finland" },
	{ name: "France", value: "France" },
	{ name: "French Guiana", value: "French Guiana" },
	{ name: "French Polynesia", value: "French Polynesia" },
	{ name: "French Southern Territories", value: "French Southern Territories" },
	{ name: "Gabon", value: "Gabon" },
	{ name: "Gambia", value: "Gambia" },
	{ name: "Georgia", value: "Georgia" },
	{ name: "Germany", value: "Germany" },
	{ name: "Ghana", value: "Ghana" },
	{ name: "Gibraltar", value: "Gibraltar" },
	{ name: "Greece", value: "Greece" },
	{ name: "Greenland", value: "Greenland" },
	{ name: "Grenada", value: "Grenada" },
	{ name: "Guadeloupe", value: "Guadeloupe" },
	{ name: "Guam", value: "Guam" },
	{ name: "Guatemala", value: "Guatemala" },
	{ name: "Guernsey", value: "Guernsey" },
	{ name: "Guinea", value: "Guinea" },
	{ name: "Guinea-Bissau", value: "Guinea-Bissau" },
	{ name: "Guyana", value: "Guyana" },
	{ name: "Haiti", value: "Haiti" },
	{
		name: "Heard Island and Mcdonald Islands",
		value: "Heard Island and Mcdonald Islands",
	},
	{
		name: "Holy See (Vatican City State)",
		value: "Holy See (Vatican City State)",
	},
	{ name: "Honduras", value: "Honduras" },
	{ name: "Hong Kong", value: "Hong Kong" },
	{ name: "Hungary", value: "Hungary" },
	{ name: "Iceland", value: "Iceland" },
	{ name: "India", value: "India" },
	{ name: "Indonesia", value: "Indonesia" },
	{ name: "Iran", value: "Iran" },
	{ name: "Iraq", value: "Iraq" },
	{ name: "Ireland", value: "Ireland" },
	{ name: "Isle of Man", value: "Isle of Man" },
	{ name: "Israel", value: "Israel" },
	{ name: "Italy", value: "Italy" },
	{ name: "Jamaica", value: "Jamaica" },
	{ name: "Japan", value: "Japan" },
	{ name: "Jersey", value: "Jersey" },
	{ name: "Jordan", value: "Jordan" },
	{ name: "Kazakhstan", value: "Kazakhstan" },
	{ name: "Kenya", value: "Kenya" },
	{ name: "Kiribati", value: "Kiribati" },
	{ name: "Kuwait", value: "Kuwait" },
	{ name: "Kyrgyzstan", value: "Kyrgyzstan" },
	{ name: "Laos", value: "Laos" },
	{ name: "Latvia", value: "Latvia" },
	{ name: "Lebanon", value: "Lebanon" },
	{ name: "Lesotho", value: "Lesotho" },
	{ name: "Liberia", value: "Liberia" },
	{ name: "Libya", value: "Libya" },
	{ name: "Liechtenstein", value: "Liechtenstein" },
	{ name: "Lithuania", value: "Lithuania" },
	{ name: "Luxembourg", value: "Luxembourg" },
	{ name: "Macao", value: "Macao" },
	{ name: "Macedonia", value: "Macedonia" },
	{ name: "Madagascar", value: "Madagascar" },
	{ name: "Malawi", value: "Malawi" },
	{ name: "Malaysia", value: "Malaysia" },
	{ name: "Maldives", value: "Maldives" },
	{ name: "Mali", value: "Mali" },
	{ name: "Malta", value: "Malta" },
	{ name: "Marshall Islands", value: "Marshall Islands" },
	{ name: "Martinique", value: "Martinique" },
	{ name: "Mauritania", value: "Mauritania" },
	{ name: "Mauritius", value: "Mauritius" },
	{ name: "Mayotte", value: "Mayotte" },
	{ name: "Mexico", value: "Mexico" },
	{ name: "Micronesia", value: "Micronesia" },
	{ name: "Moldova", value: "Moldova" },
	{ name: "Monaco", value: "Monaco" },
	{ name: "Mongolia", value: "Mongolia" },
	{ name: "Montserrat", value: "Montserrat" },
	{ name: "Morocco", value: "Morocco" },
	{ name: "Mozambique", value: "Mozambique" },
	{ name: "Myanmar", value: "Myanmar" },
	{ name: "Namibia", value: "Namibia" },
	{ name: "Nauru", value: "Nauru" },
	{ name: "Nepal", value: "Nepal" },
	{ name: "Netherlands", value: "Netherlands" },
	{ name: "Netherlands Antilles", value: "Netherlands Antilles" },
	{ name: "New Caledonia", value: "New Caledonia" },
	{ name: "New Zealand", value: "New Zealand" },
	{ name: "Nicaragua", value: "Nicaragua" },
	{ name: "Niger", value: "Niger" },
	{ name: "Nigeria", value: "Nigeria" },
	{ name: "Niue", value: "Niue" },
	{ name: "Norfolk Island", value: "Norfolk Island" },
	{ name: "Northern Mariana Islands", value: "Northern Mariana Islands" },
	{ name: "North Korea", value: "North Korea" },
	{ name: "Norway", value: "Norway" },
	{ name: "Oman", value: "Oman" },
	{ name: "Pakistan", value: "Pakistan" },
	{ name: "Palau", value: "Palau" },
	{ name: "Palestinian Territories", value: "Palestinian Territories" },
	{ name: "Panama", value: "Panama" },
	{ name: "Papua New Guinea", value: "Papua New Guinea" },
	{ name: "Paraguay", value: "Paraguay" },
	{ name: "Peru", value: "Peru" },
	{ name: "Philippines", value: "Philippines" },
	{ name: "Pitcairn", value: "Pitcairn" },
	{ name: "Poland", value: "Poland" },
	{ name: "Portugal", value: "Portugal" },
	{ name: "Puerto Rico", value: "Puerto Rico" },
	{ name: "Qatar", value: "Qatar" },
	{ name: "Reunion", value: "RE" },
	{ name: "Romania", value: "Romania" },
	{ name: "Russia", value: "Russia" },
	{ name: "Rwanda", value: "Rwanda" },
	{ name: "Saint Helena", value: "Saint Helena" },
	{ name: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
	{ name: "Saint Lucia", value: "Saint Lucia" },
	{ name: "Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
	{
		name: "Saint Vincent and the Grenadines",
		value: "Saint Vincent and the Grenadines",
	},
	{ name: "Samoa", value: "Samoa" },
	{ name: "San Marino", value: "San Marino" },
	{ name: "Sao Tome and Principe", value: "Sao Tome and Principe" },
	{ name: "Saudi Arabia", value: "Saudi Arabia" },
	{ name: "Senegal", value: "Senegal" },
	{ name: "Serbia", value: "Serbia" },
	{ name: "Seychelles", value: "Seychelles" },
	{ name: "Sierra Leone", value: "Sierra Leone" },
	{ name: "Singapore", value: "Singapore" },
	{ name: "Slovakia", value: "Slovakia" },
	{ name: "Slovenia", value: "Slovenia" },
	{ name: "Solomon Islands", value: "Solomon Islands" },
	{ name: "Somalia", value: "Somalia" },
	{ name: "South Africa", value: "South Africa" },
	{
		name: "South Georgia and the South Sandwich Islands",
		value: "South Georgia and the South Sandwich Islands",
	},
	{ name: "South Korea", value: "South Korea" },
	{ name: "Spain", value: "Spain" },
	{ name: "Sri Lanka", value: "Sri Lanka" },
	{ name: "Sudan", value: "Sudan" },
	{ name: "Suriname", value: "Suriname" },
	{ name: "Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
	{ name: "Swaziland", value: "Swaziland" },
	{ name: "Sweden", value: "Sweden" },
	{ name: "Switzerland", value: "Switzerland" },
	{ name: "Syria", value: "Syria" },
	{ name: "Taiwan", value: "Taiwan" },
	{ name: "Tajikistan", value: "Tajikistan" },
	{ name: "Tanzania", value: "Tanzania" },
	{ name: "Thailand", value: "Thailand" },
	{ name: "Timor-Leste", value: "Timor-Leste" },
	{ name: "Togo", value: "Togo" },
	{ name: "Tokelau", value: "Tokelau" },
	{ name: "Tonga", value: "Tonga" },
	{ name: "Trinidad and Tobago", value: "Trinidad and Tobago" },
	{ name: "Tunisia", value: "Tunisia" },
	{ name: "Turkey", value: "Turkey" },
	{ name: "Turkmenistan", value: "Turkmenistan" },
	{ name: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
	{ name: "Tuvalu", value: "Tuvalu" },
	{ name: "Uganda", value: "Uganda" },
	{ name: "Ukraine", value: "Ukraine" },
	{ name: "United Arab Emirates", value: "United Arab Emirates" },
	{ name: "United Kingdom", value: "United Kingdom" },
	{ name: "U.S. Minor Outlying Islands", value: "U.S. Minor Outlying Islands" },
	{ name: "U.S. Virgin Islands", value: "U.S. Virgin Islands" },
	{ name: "Uruguay", value: "Uruguay" },
	{ name: "Uzbekistan", value: "Uzbekistan" },
	{ name: "Vanuatu", value: "Vanuatu" },
	{ name: "Venezuela", value: "Venezuela" },
	{ name: "Vietnam", value: "Vietnam" },
	{ name: "Wallis and Futuna", value: "Wallis and Futuna" },
	{ name: "Western Sahara", value: "Western Sahara" },
	{ name: "Yemen", value: "Yemen" },
	{ name: "Zambia", value: "Zambia" },
	{ name: "Zimbabwe", value: "Zimbabwe" },
];

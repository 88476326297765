const forgotUser = (values: any, setErrors: any) => {
	let temp: any = {};
	temp.firstName = values.firstName ? "" : "First name is required";
	temp.lastName = values.lastName ? "" : "Last name is required";
	temp.email = values.email ? "" : "Email is required";
	setErrors({ ...temp });
	return Object.values(temp).every((x) => x === "");
};
const forgotPassword = (values: any, setErrors: any) => {
	let temp: any = {};
	temp.userName = values.userName ? "" : "Username is required";
	temp.email = values.email ? "" : "Email is required";
	setErrors({ ...temp });
	return Object.values(temp).every((x) => x === "");
};

const signInValidate = (values: any, setErrors: any) => {
	let temp: any = {};
	temp.userName = values.userName ? "" : "Username is required";
	temp.password = values.password ? "" : "Password is required";
	setErrors({ ...temp });
	return Object.values(temp).every((x) => x === "");
};
export { forgotUser, forgotPassword, signInValidate };

import AmericanExpress from "../media/AmericanExpressLogo.svg";
import Visa from "../media/VisaLogo.svg";
import Discover from "../media/DiscoverLogo.svg";
import MasterCard from "../media/MasterCardLogo.svg";

export const getCardLogo = (cardType: any) => {
	switch (cardType) {
		case "American Express":
			return AmericanExpress;
		case "Visa":
			return Visa;
		case "Master Card":
			return MasterCard;
		case "Discover":
			return Discover;
		default:
			return cardType;
	}
};

import React from "react";
import {
	Typography,
	Button,
	Card,
	CardHeader,
	Grid,
	ListItem,
	Popover,
} from "@mui/material";
import { formatCurrency } from "../../../utils/FormatCurrency";
import moment from "moment";

const classes: any = {
	invoiceHeader: {
		backgroundColor: "text.disabled",
		color: "white",
		margin: 0,
		height: 10,
		maxWidth: "100%",
	},
	tableRowRoot: {
		"&:nth-of-type(odd)": {
			backgroundColor: "#DCDCDC",
		},
	},
};

export const Membership = (props: any) => {
	const [popoverOpen, setPopoverOpen] = React.useState("");
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const { membership, clubData } = props;
	const handlePopoverClick = (
		id: any,
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		setAnchorEl(event.currentTarget);
		setPopoverOpen(id);
	};
	const closePopover = () => {
		setAnchorEl(null);
		setPopoverOpen("0");
	};

	const id = popoverOpen !== "0" ? "simple-popover" : undefined;
	let fontStyle = {
		fontFamily: "Roboto Condensed, sans-serif",
		fontSize: 16,
	};
	return (
		<Card style={{ width: "100%", borderRadius: 0 }}>
			<CardHeader
				sx={classes.invoiceHeader}
				title={
					<Grid container justifyContent="flex-start">
						<Grid item>
							<Typography
								variant="subtitle2"
								sx={{
									fontFamily: "Roboto Condensed, sans-serif",
									fontSize: 18,
									lineHeight: 1,
								}}
							>
								{membership.contractName.length > 35
									? membership.contractName.slice(0, 35) + "..."
									: membership.contractName}
							</Typography>
						</Grid>
					</Grid>
				}
			/>
			<ListItem sx={props.index % 2 === 0 ? classes.tableRowRoot : ""}>
				<Grid container direction="row" justifyContent="space-between">
					<Grid
						container
						direction="column"
						sx={{ p: 1, pt: 0 }}
						justifyContent="flex-start"
					>
						<Typography align="left" variant="subtitle2" sx={fontStyle}>
							<strong>Purchase For:</strong> {membership.purchasedFor}
						</Typography>
						<Typography align="left" variant="subtitle2" sx={fontStyle}>
							<strong>Amount:</strong>{" "}
							{formatCurrency(membership.dueAmount, clubData)}
						</Typography>
						<Typography align="left" variant="subtitle2" sx={fontStyle}>
							<strong>Due:</strong>{" "}
							{moment(membership.contractEndingDate.slice(0, 10)).format(
								"MM/DD/YYYY"
							)}
						</Typography>
						<Grid
							container
							direction="row"
							spacing={2}
							justifyContent="center"
							sx={{ pb: 0, pt: 0.8 }}
						>
							<Grid item xs={6}>
								<Button
									onClick={(event: any) =>
										handlePopoverClick(membership.id, event)
									}
									fullWidth
									variant="outlined"
									sx={{ bgcolor: "#FAFAFA" }}
								>
									View
								</Button>
								<Popover
									id={id}
									key={membership.id}
									open={popoverOpen === membership.id}
									anchorEl={anchorEl}
									onClose={closePopover}
									anchorOrigin={{
										vertical: "top",
										horizontal: "center",
									}}
									transformOrigin={{
										vertical: "bottom",
										horizontal: "center",
									}}
								>
									<Button
										onClick={() => props.setOpen(`contract ${membership.id}`)}
										fullWidth
										variant="outlined"
									>
										View Contract
									</Button>
									<Button
										onClick={() => props.setOpen(`invoice ${membership.id}`)}
										fullWidth
										variant="outlined"
									>
										View Invoices
									</Button>
								</Popover>
							</Grid>
							<Grid item xs={6}>
								<Button
									onClick={props.handlePayMembership}
									fullWidth
									variant="contained"
									color="primary"
								>
									Pay
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ListItem>
		</Card>
	);
};

import { Typography, IconButton, ButtonBase } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link } from "react-router-dom";
import MsLogo from "../media/msi-logo-fullcolor.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from "@mui/icons-material/Close";

const classes: any = {
	submit: {
		mt: 1,
		mx: 3,
		textDecoration: "none",
	},
};
let path: any = window.location.pathname.split("/")[1];

export function BackToSignInButton() {
	return (
		<Link to={`/${path}/signin`} style={classes.submit}>
			<div style={{ display: "flex", margin: 10 }}>
				<ChevronLeftIcon color="secondary" />
				<Typography color="secondary" variant="button">
					BACK TO SIGN IN
				</Typography>
			</div>
		</Link>
	);
}
export function ForgotUsernameButton() {
	return (
		<Link to={`/${path}/forgot/username`} style={classes.submit}>
			<div style={{ display: "flex", margin: 10 }}>
				<Typography color="secondary" variant="button">
					Forgot Username
				</Typography>
			</div>
		</Link>
	);
}

export function ForgotPasswordButton() {
	return (
		<Link to={`/${path}/forgot/password`} style={classes.submit}>
			<div style={{ display: "flex", margin: 10 }}>
				<Typography color="secondary" variant="button">
					FORGOT PASSWORD
				</Typography>
			</div>
		</Link>
	);
}

export function MemberSolutionsButton() {
	return (
		<>
			<a
				href="https://www.membersolutions.com"
				style={{ textDecoration: "none", width: "100%" }}
				target="_blank"
				rel="noreferrer"
			>
				<div
					style={{
						display: "flex",
						margin: 10,
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography
						variant="subtitle2"
						style={{ marginRight: 5, color: "grey", opacity: 0.4 }}
					>
						Powered by{" "}
					</Typography>
					<img
						src={MsLogo}
						height={45}
						alt="member solutions"
						style={{ opacity: 0.4 }}
					/>
				</div>
			</a>
		</>
	);
}

export function CloseButton(props: any) {
	return (
		<ButtonBase centerRipple={true} focusRipple={true}>
			<IconButton onClick={props.onClose} size="large">
				{props.style === "open" ? (
					<CloseIcon
						sx={{
							color: props.color || "white",
						}}
					/>
				) : (
					<HighlightOffIcon sx={{ color: props.color || "white" }} />
				)}
			</IconButton>
		</ButtonBase>
	);
}

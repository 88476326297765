import React from "react";
import { AppBar, Tabs, Tab, Box, Paper, Container } from "@mui/material";
import { MySchedule } from "./MySchedule";
import { GymSchedule } from "./GymSchedule";

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}
function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && <Paper>{children}</Paper>}
		</div>
	);
}
function a11yProps(index: number) {
	return {
		id: `scrollable-auto-tab-${index}`,
		"aria-controls": `scrollable-auto-tabpanel-${index}`,
	};
}
const classes: any = {
	root: {
		flexGrow: 1,
		width: { xs: "100%", md: "80%" },
		marginBottom: "20px",
		marginTop: "120px",
		marginLeft: "auto",
		marginRight: "auto",
		padding: 0,
	},
	appBar: {
		top: 55,
		zIndex: 50,
		left: { xs: 0, md: 40 },
		height: 55,
		pt: "7px",
	},
	body: {
		ml: { xs: 0, md: "30px" },
		width: "100%",
	},
};
export default function Schedule(props: any) {
	const [value, setValue] = React.useState(0);
	const [defaultScreen, setDefaultScreen] = React.useState(false);
	const path: any = window.location.pathname.split("/")[3];
	React.useEffect(() => {
		if (!defaultScreen && path) {
			setValue(1);
			setDefaultScreen(true);
		}
	}, []);
	const handleChange = (event: any, newValue: any) => {
		setValue(newValue);
	};
	return (
		<Box sx={classes.root}>
			{props.userConfig ? (
				<Box sx={classes.body}>
					<AppBar position="fixed" color="default" sx={classes.appBar}>
						<Tabs
							value={value}
							onChange={handleChange}
							indicatorColor="primary"
							textColor="primary"
							aria-label="scrollable auto tabs example"
							centered
						>
							<Tab label={"My Schedule"} {...a11yProps(0)} />
							<Tab label={"Schedule"} {...a11yProps(1)} />
						</Tabs>
					</AppBar>
					<Container maxWidth="lg">
						<TabPanel value={value} index={0}>
							<MySchedule {...props} />
						</TabPanel>
						<TabPanel value={value} index={1}>
							<GymSchedule {...props} />
						</TabPanel>
					</Container>
				</Box>
			) : null}
		</Box>
	);
}

import React from "react";
import { useState, useEffect } from "react";
import {
	Button,
	Typography,
	Divider,
	FormControl,
	InputLabel,
	Select,
	Grid,
	Checkbox,
	InputAdornment,
	OutlinedInput,
	IconButton,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { SelectChangeEvent } from "@mui/material/Select";
import PaymentMethods from "../../containers/PaymentMethods";
import OneTimeCardForm from "../Profile/Dialogs/OneTimePayForm";
import { formatCurrency } from "../../utils/FormatCurrency";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import ButtonLoader from "../../utils/ButtonLoader";
import styles from "../../styles";
import { renderPaymentMethods } from "../Forms/PaymentMethods";
import getLegends from "../../helpers/getLegends";

const classes: any = {
	header: {
		backgroundColor: "primary.main",
		color: "white",
		borderTopLeftRadius: "5px",
		width: "100%",
		padding: "20px",
	},
	closeButton: {
		justifyContent: "flex-end",
	},
	promo: {
		width: "100%",
		alignItems: "center",
	},
};

export default function CompletePurchase(props: any) {
	const {
		userInfo,
		userConfig,
		billingProfiles,
		getCredits,
		clubData,
		credits,
	} = props;
	const [client, setClient] = useState<string>(clubData?.clubType);
	const [collapse, setCollapse] = useState<boolean>(false);
	const [oneTimePayment, setOneTimePayment] = useState<boolean>(true);
	const [checked, setChecked] = useState<boolean>(false);
	const [paymentMethod, setPaymentMethod] = useState<string>("");
	const [oneTimePaymentType, setOneTimePaymentType] =
		useState<string>("CreditCard");
	const [purchase, setPurchase] = useState<boolean>(false);
	const [open, setOpen] = useState("0");
	const [oneTime, setOneTime] = useState<boolean>(false);
	const [promoCode, setPromoCode] = useState<string>(
		props.promo ? "Promo Applied" : ""
	);
	const [discountStatus, setDiscountStatus] = useState<boolean>(false);
	const [alert, setAlert] = useState<string>("");
	const [values, setValues] = useState<any>({
		amountType: "all",
		credit: props?.credit,
		amount: null,
		paymentMethodType: paymentMethod,
		billingProfileId: null,
		accountNumber: "",
		routing: "",
		accountTypeFull: "",
		creditcard: null,
		nameOnCard: null,
		expiryMonth: null,
		expiryYear: null,
		securityCode: null,
		bankName: "",
		firstName: userInfo?.firstName,
		lastName: userInfo?.lastName,
	});
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	useEffect(() => {
		setCollapse(true);
	}, [collapse]);
	useEffect(() => {
		setPaymentProfile();
		getCredits(props.config);
		if (props.billingProfiles.length === 0) {
			props.getBillingProfile(props.config);
		}
	}, []);
	useEffect(() => {
		if (props.promo === "OK") {
			setDiscountStatus(true);
			setAlert("Promo successfully applied");
		} else if (props.promo === "InvalidCode") {
			setDiscountStatus(false);
			setAlert("Invalid Promo Code");
		} else if (props.promo === "InvalidTimePeriod") {
			setDiscountStatus(false);
			setAlert("Expired Promo Code");
		} else {
			setDiscountStatus(false);
			setAlert("");
		}
	}, [props.promo]);
	useEffect(() => {
		setPaymentProfile();
	}, [billingProfiles]);
	useEffect(() => {
		if (props.purchaseSuccess && purchase) {
			props.getCart(props.config);
			props.onClose();
			props.closeCart();
		}
	}, [props.purchaseSuccess, purchase]);
	useEffect(() => {
		props.items.forEach((item: any) => {
			if (item.productLegend.includes("Automatic Renewal")) {
				setOneTimePayment(false);
			}
		});
	}, [props.items]);

	const handleSetChecked = (event: any) => {
		setChecked(event.target.checked);
	};
	const setPaymentProfile = () => {
		let isDefault: any = false;
		sortedBillingProfiles?.forEach((profile: any) => {
			if (profile.isDefaultProfile && !oneTime) {
				setPaymentMethod(profile.id);
				isDefault = true;
			} else {
				if (sortedBillingProfiles.length > 0) {
					setPaymentMethod(sortedBillingProfiles[0].id);
				}
			}
		});
	};
	const handlePaymentChange = (event: SelectChangeEvent) => {
		setPaymentMethod(event.target.value as string);
	};
	const usePromoCode = () => {
		let config: any = props.config;
		config.promoCode = promoCode;
		if (promoCode?.length > 0) {
			props.applyPromoCode(config);
		}
	};
	const clearAll = () => {
		setAlert("");
	};
	const openOneTimePayment = () => {
		setOpen("2");
	};
	const openAddPayment = () => {
		setOpen("1");
	};
	const handleClose = () => {
		setOpen("0");
	};
	let sortedBillingProfiles: any = billingProfiles.sort(function (
		a: any,
		b: any
	) {
		return b.isDefaultProfile - a.isDefaultProfile;
	});
	let paymentMethods: any = renderPaymentMethods(
		sortedBillingProfiles,
		oneTimePaymentType,
		values,
		oneTime
	);

	let oneTimePaymentModal = (
		<Dialog open={open === "2"} onClose={handleClose} sx={styles.modal}>
			<OneTimeCardForm
				{...props}
				values={values}
				acceptedCards={props.clubData.validCCTypes}
				setValues={setValues}
				setPaymentMethod={setPaymentMethod}
				oneTimePaymentType={oneTimePaymentType}
				setOneTimePaymentType={setOneTimePaymentType}
				setPaymentProfile={setPaymentProfile}
				oneTime={oneTime}
				setOneTime={setOneTime}
				onClose={handleClose}
			/>
		</Dialog>
	);
	const getSubtotal = () => {
		let subtotal: any = 0;
		props.items.forEach((item: any) => {
			subtotal = subtotal + item.totalAmount;
		});
		return subtotal;
	};

	const getTax = () => {
		let tax: any = 0;
		props.items.forEach((item: any) => {
			tax = tax + item.tax;
		});
		return tax;
	};
	const getDiscount = () => {
		let tax: any = 0;
		props.items.forEach((item: any) => {
			tax = tax + item.discount;
		});
		return tax;
	};
	let subtotal = getSubtotal();
	let discount = getDiscount();
	let tax = getTax();
	let total: any =
		Number(getTax()) + Number(getSubtotal()) - Number(getDiscount());
	let creditAmount: any;
	if (checked && total < props.credits / 100) {
		creditAmount = total * 100;
	} else {
		creditAmount = credits;
	}
	const completePurchase = (event: any) => {
		event.preventDefault();
		const payConfig: any = {
			headers: {
				Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
			},
			partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
			businessAlias: userConfig ? userConfig.businessAlias : null,
			clientId: userConfig ? userConfig.clientId : null,
			billingProfileId:
				oneTime || (checked && total < credits / 100) ? "0" : paymentMethod,
			credits: checked ? Number(creditAmount.toFixed(2)) : 0,
			amount: Number(total.toFixed(2)),
			promoCode: promoCode,
			creditCard:
				oneTimePaymentType === "CreditCard" && oneTime
					? {
							cardNumber: values.creditcard,
							nameOnCard: values.nameOnCard,
							expiryMonth: values.expiryMonth,
							expiryYear: values.expiryYear,
							securityCode: values.securityCode,
					  }
					: null,
			eft:
				oneTimePaymentType === "Eft" && oneTime
					? {
							AccountNumber: values.accountNumber,
							TransitNumber: values.transitNumber,
							InstitutionNumber: values.institutionNumber,
							BankAccountType: values.accountTypeFull,
							NameOnAccount: values.firstName + " " + values.lastName,
					  }
					: null,
			ach:
				oneTimePaymentType === "Ach" && oneTime
					? {
							AccountNumber: values.accountNumber,
							RoutingNumber: values.routing,
							BankAccountType: values.accountTypeFull,
							NameOnAccount: values.firstName + " " + values.lastName,
					  }
					: null,
		};
		props.completePurchase(payConfig);
		setPurchase(true);
	};
	const removePromoCode = () => {
		props.removePromo();
		setDiscountStatus(false);
		setPromoCode("");
		props.getCart(props.config);
		setAlert("");
	};
	const handleBack = () => {
		removePromoCode();
		props.onClose();
	};
	return (
		<>
			<Typography variant="h5" align="center" sx={classes.header}>
				Complete Purchase
			</Typography>
			<DialogContent>
				<DialogContentText>
					<Grid
						container
						justifyContent="center"
						style={{
							paddingBottom: 10,
							marginBottom: 10,
							borderBottom: "1px solid lightgrey",
						}}
					>
						<Grid item xs={3}>
							<Typography align="left">Quantity</Typography>
						</Grid>
						<Grid item xs={3}>
							<Typography align="left">Item</Typography>
						</Grid>
						<Grid item xs={3}>
							<Typography align="center">Purchase For</Typography>
						</Grid>
						<Grid item xs={3}>
							<Typography align="right">Amount</Typography>
						</Grid>
					</Grid>
					<>
						{props.items.map((item: any, index: any) => (
							<Grid
								key={index}
								container
								justifyContent="center"
								style={{ padding: "0px 0px 20px 20px" }}
							>
								<Grid item xs={1}>
									<Typography>{item.cartQuantity}</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography align="center">{item.name}</Typography>
								</Grid>
								<Grid item xs={5}>
									<Typography align="center">
										{item.isPackage || item.isService
											? item.purchaseForName
											: null}
									</Typography>
								</Grid>
								<Grid item xs={2}>
									<Typography align="right">
										{formatCurrency(item.totalAmount, props.clubData)}
										{item.productLegend ? getLegends(item.productLegend) : null}
									</Typography>
								</Grid>
							</Grid>
						))}
						<Divider />
						<Grid container justifyContent="flex-end">
							<Grid
								container
								justifyContent="space-between"
								style={{ maxWidth: 250, width: "100%", paddingTop: 10 }}
							>
								<Grid item xs={6}>
									<Typography>Subtotal:</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography align="right">
										{formatCurrency(subtotal, clubData)}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography>Discount:</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography align="right">
										{discount ? "-" : null}
										{formatCurrency(discount, clubData)}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography>Tax:</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography align="right">
										{formatCurrency(tax, clubData)}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Divider />
									<Typography gutterBottom variant="h6">
										Total:
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Divider />
									<Typography align="right" variant="h6">
										{formatCurrency(total, clubData)}
									</Typography>
								</Grid>
								<Grid container justifyContent="center">
									{Number(total.toFixed(2)) < 0 && (
										<Typography
											align="right"
											sx={{ color: "red" }}
											variant="caption"
										>
											Total cannot be less than zero
										</Typography>
									)}
								</Grid>
								{checked && total > 0 && (
									<>
										<Grid container justifyContent="space-between">
											<Typography variant="subtitle1">
												Credits Applied:
											</Typography>
											<Typography variant="subtitle1" sx={{ mr: -0.6 }}>
												(
												{Number(credits) / 100 - total > 0
													? formatCurrency(total, clubData)
													: formatCurrency(Number(credits) / 100, clubData)}
												)
											</Typography>
										</Grid>
										{Number(props.credits) / 100 - total < 0 && (
											<Grid container justifyContent="space-between">
												<Typography variant="subtitle1">
													Amount Remaining:
												</Typography>
												<Typography variant="subtitle1">
													{formatCurrency(
														Number(total - Number(credits) / 100),
														clubData
													)}
												</Typography>
											</Grid>
										)}
									</>
								)}
							</Grid>
						</Grid>
						{credits && total > 0 ? (
							<Grid
								container
								alignItems="center"
								justifyContent="flex-start"
								sx={{ p: 0, pb: 1, mt: 6 }}
							>
								<Grid item>
									<Checkbox
										checked={checked}
										sx={{ p: 0, pr: 0.5 }}
										onChange={(event: any) => handleSetChecked(event)}
										disabled={client === "FSB ONLY" && credits / 100 < total}
										// disabled={credits / 100 < total}
									/>
								</Grid>
								<Grid item>
									<Typography>
										Available Credit (
										{formatCurrency(props.credits / 100, props.clubData)})
										{credits / 100 < total && client === "FSB ONLY" ? (
											<Typography variant="caption">
												{" "}
												*Not enough credits
											</Typography>
										) : null}
									</Typography>
								</Grid>
							</Grid>
						) : null}
						<Grid container direction="column" justifyContent="flex-end">
							<FormControl variant="outlined">
								<OutlinedInput
									placeholder="Enter Promo Code"
									style={{ padding: 0 }}
									disabled={discountStatus && props.promo === "OK"}
									endAdornment={
										<InputAdornment style={{ height: "100%" }} position="end">
											{discountStatus && props.promo === "OK" ? (
												<Button
													style={{ height: 56 }}
													variant="contained"
													color="primary"
													onClick={removePromoCode}
												>
													Remove
												</Button>
											) : (
												<Button
													style={{
														height: 55,
														borderTopLeftRadius: 0,
														borderBottomLeftRadius: 0,
													}}
													variant="contained"
													color="primary"
													onClick={usePromoCode}
												>
													apply
												</Button>
											)}
										</InputAdornment>
									}
									value={promoCode || ""}
									onChange={(event: any) => setPromoCode(event.target.value)}
								/>
								{alert && (
									<Alert
										severity={discountStatus ? "success" : "error"}
										action={
											<IconButton
												sx={classes.closeButton}
												onClick={() => {
													clearAll();
												}}
												size="large"
											>
												<CloseIcon />
											</IconButton>
										}
										sx={classes.promo}
									>
										<Typography>{alert}</Typography>
									</Alert>
								)}
							</FormControl>
							<FormControl margin="normal" variant="outlined">
								<InputLabel shrink={true} htmlFor="outlined-age-native-simple">
									Payment Method
								</InputLabel>
								<Select
									label="Payment Method"
									native
									value={paymentMethod}
									onChange={handlePaymentChange}
									disabled={
										(checked && client === "FSB ONLY") ||
										(checked && client === "SSB" && credits / 100 > total) ||
										(checked && credits / 100 > total)
									}
									inputProps={{
										name: "paymentMethod",
										id: "outlined-age-native-simple",
										padding: 5,
									}}
								>
									{paymentMethods}
								</Select>
							</FormControl>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<Button
										fullWidth
										onClick={openAddPayment}
										variant="outlined"
										color="primary"
									>
										Payment Methods
									</Button>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Button
										fullWidth
										onClick={openOneTimePayment}
										variant="outlined"
										color="primary"
										disableRipple
										disabled={!oneTimePayment || client === "FSB ONLY"}
									>
										One Time Payment
									</Button>
								</Grid>
							</Grid>
							{oneTimePaymentModal}
						</Grid>
					</>
					<Dialog open={open === "1"} onClose={handleClose} sx={styles.modal}>
						<PaymentMethods
							{...props}
							handleClose={handleClose}
							setIsDefault={props.setIsDefault}
							open={true}
						/>
					</Dialog>
					{/* {!oneTimePayment && (
						<Typography variant="caption">
							There is an item that requires recurring payments in your cart. If
							have not selected a default payment profile, the selected payment
							will become the default. Changing the default profile will effect
							existing recurring payments.
						</Typography>
					)} */}
					{/* <Button onClick={() => checkDefault(paymentMethod)}>default</Button> */}
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ p: 2 }}>
				<Button
					color="primary"
					onClick={handleBack}
					variant="outlined"
					fullWidth
					sx={{ mr: 1 }}
				>
					Back to Cart
				</Button>

				<Button
					color="primary"
					variant="contained"
					fullWidth
					onClick={completePurchase}
					disabled={
						props.purchaseRequesting ||
						(props.billingProfiles.length < 1 && !oneTime) ||
						Number(total.toFixed(2)) < 0
					}
					sx={{ ml: 1 }}
				>
					{props.purchaseRequesting ? (
						<ButtonLoader />
					) : matches ? (
						"Purchase"
					) : (
						"Complete Purchase"
					)}
				</Button>
			</DialogActions>
		</>
	);
}

import React from "react";
import Calendar from "./Calendar";
import Notifications from "../../components/AlertMessage/Notifications";
import {
	Typography,
	Grid,
	MenuItem,
	Select,
	FormControl,
	Box,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import moment from "moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { MetroSpinner } from "react-spinners-kit";
import { blue } from "../../theme";
import "moment-timezone";

const classes: any = {
	root: {
		marginTop: { xs: "70px", sm: "130px" },
	},
	header: {
		backgroundColor: "primary.main",
		color: "white",
		height: "70px",
		borderTopRightRadius: "5px",
		borderTopLeftRadius: "5px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		marginBottom: "5px",
	},

	schedule: {
		width: { md: "220px" },
		color: "white",
		marginRight: "10px",
		borderRadius: "5px",
		justifyContent: "flex-end",
	},
	select: {
		"& .MuiOutlinedInput-input": {
			backgroundColor: "white",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
			backgroundColor: "white",
			borderRadius: "5px",
		},
		minWidth: "150px",
	},
};
export const MySchedule = (props: any) => {
	const { userConfig, relatedUsers, clubData } = props;
	const [user, setUser] = React.useState(userConfig?.clientId);
	const [initialLoad, setInitialLoad] = React.useState<boolean>(false);
	const [startDate] = React.useState<any>(
		moment()
			.tz(clubData?.timezone || null)
			.format("YYYY-MM-DD")
	);
	const config: any = {
		businessAlias: userConfig?.businessAlias,
		clientId: userConfig?.clientId,
		startDate: startDate,
		endDate: moment(startDate).add(30, "days").format("YYYY-MM-DD"),
		scheduleType: "Class",
	};
	React.useEffect(() => {
		if (!initialLoad) {
			props.getUserSchedule(config);
			setInitialLoad(true);
		}
	}, []);
	React.useEffect(() => {
		if (initialLoad) {
			config.clientId = user;
			props.getUserSchedule(config);
		}
	}, [user]);
	React.useEffect(() => {
		if (
			props.messages === "Class was successfully cancelled" ||
			props.messages == "Class was successfully booked" ||
			props.messages == "Successfully removed from waitlist"
		) {
			config.clientId = user;
			props.getUserSchedule(config);
		}
	}, [props.messages]);
	React.useEffect(() => {
		if (!relatedUsers) {
			let config2 = {
				headers: { Authorization: `Bearer ${userConfig.token}` },
				partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
				businessAlias: userConfig ? userConfig.businessAlias : null,
				id: userConfig ? userConfig.clientId : null,
			};
			props.getRelatedUsers(config2);
		}
	}, [relatedUsers]);
	const handleUserChange = (event: SelectChangeEvent) => {
		setUser(event.target.value as string);
	};
	return (
		<Box sx={classes.root}>
			{props.messages || props.errors ? (
				<Notifications messages={props.messages} errors={props.errors} />
			) : null}
			<Box sx={classes.header}>
				<Grid container justifyContent="space-between">
					<Grid
						item
						sx={{ display: { xs: "none", sm: "block" } }}
						sm={4}
					></Grid>
					<Grid item xs={6} sm={4}>
						<Typography style={{ marginTop: 10 }} align="center" variant="h5">
							My Schedule
						</Typography>
					</Grid>
					<Grid item xs={6} sm={4}>
						{props.purchaseForUsers && (
							<Grid container justifyContent={"flex-end"}>
								<FormControl
									sx={classes.schedule}
									margin="dense"
									variant="outlined"
									color="primary"
								>
									<Select
										sx={classes.select}
										size="small"
										inputProps={{
											name: "schedule",
											id: "outlined-age-native-simple",
											padding: 5,
											background: "white",
										}}
										value={user}
										onChange={handleUserChange}
									>
										{props.purchaseForUsers?.map((user: any) => (
											<MenuItem key={user.id} value={user.id}>
												{user.fullName}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Box>
			{!props.requesting && props.successful && (
				<Calendar
					type="my"
					{...props}
					appointments={props.mySchedule}
					user={user}
				/>
			)}
			{props.requesting && (
				<Grid container justifyContent="center" sx={{ p: 10 }}>
					<MetroSpinner size={50} color={blue} />
				</Grid>
			)}
			{!props.requesting && !props.successful && (
				<Typography sx={{ pt: 5, pb: 5 }} align="center">
					There was an error loading schedule
				</Typography>
			)}
			{!props.requesting && props.successful && (
				<Grid container justifyContent="center">
					<FiberManualRecordIcon
						sx={{ color: "#3788D8", fontSize: "small", mt: 0.7, mr: 0.5 }}
					/>
					<Typography sx={{ mr: 0.5 }}> booked</Typography>
					<FiberManualRecordIcon
						sx={{ color: "red", fontSize: "small", mt: 0.7 }}
					/>
					<Typography sx={{ ml: 0.5 }}>waitlisted</Typography>
				</Grid>
			)}
		</Box>
	);
};

import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
const SignUpError = (props: any) => {
	return (
		<React.Fragment>
			<div
				style={{
					display: "flex",
				}}
			>
				<Grid container justifyContent="center" sx={{ margin: "auto auto" }}>
					<Paper sx={{ padding: 5 }}>
						<Grid item container justifyContent="center" alignItems="center">
							<Grid item>
								<ReportProblemRoundedIcon
									color="primary"
									sx={{
										height: 100,
										width: 100,
									}}
								/>
							</Grid>
						</Grid>
						<Grid item sx={{ textAlign: "center" }}>
							<Typography
								variant="h5"
								gutterBottom
								sx={{ fontWeight: 900 }}
								color="secondary"
							>
								{props.message ? props.message : "Sign Up Disabled"}
							</Typography>
							<Typography
								variant="body1"
								gutterBottom
								color="primary"
								sx={{ fontFamily: "Roboto Condensed, sans-serif" }}
							>
								<div style={{ fontSize: 18, paddingBottom: 10 }}>
									The club has disabled self sign up, please contact your club
									for assistance.
								</div>
							</Typography>
						</Grid>
					</Paper>
				</Grid>
			</div>
		</React.Fragment>
	);
};

export default SignUpError;

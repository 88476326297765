import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as constants from "../constants/schedules.constants";
import * as actions from "../services/schedule.service";
import { ResponseGenerator } from "../types";

export function* getUserSchedule(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestGetUserSchedule, config);
		if (response) {
			yield put({ type: constants.GET_USER_SCHEDULE_SUCCESS, response });
		}
	} catch (error: any) {
		console.log("CAUGHT ERROR", error.response);
		yield put({ type: constants.GET_USER_SCHEDULE_ERROR, error });
	}
}

export function* getClubDetails(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestGetClubDetails, config);
		if (response) {
			yield put({ type: constants.GET_CLUB_DETAILS_SUCCESS, response });
		}
	} catch (error: any) {
		console.log("CAUGHT ERROR", error.response);
		yield put({ type: constants.GET_CLUB_DETAILS_ERROR, error });
	}
}

export function* getGymSchedule(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestGetGymSchedule, config);
		if (response) {
			yield put({ type: constants.GET_GYM_SCHEDULE_SUCCESS, response });
		}
	} catch (error: any) {
		console.log("CAUGHT ERROR", error?.response, error);
		yield put({ type: constants.GET_GYM_SCHEDULE_ERROR, error });
	}
}
export function* bookClass(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestBookClass, config);
		if (response) {
			yield put({ type: constants.BOOK_CLASS_SUCCESS, response });
		}
	} catch (error: any) {
		console.log("CAUGHT ERROR", error);
		yield put({ type: constants.BOOK_CLASS_ERROR, error });
	}
}
export function* cancelClass(config: any) {
	let response: ResponseGenerator;
	let waitlist = config.value.waitlist;
	console.log("config", config);
	try {
		response = yield call(actions.requestCancelClass, config);
		if (response) {
			yield put({ type: constants.CANCEL_CLASS_SUCCESS, response, waitlist });
		}
	} catch (error: any) {
		console.log("CAUGHT ERROR", error.response);
		yield put({ type: constants.CANCEL_CLASS_ERROR, error });
	}
}
export function* getScheduleNames(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestGetScheduleNames, config);
		if (response) {
			yield put({ type: constants.GET_SCHEDULE_NAMES_SUCCESS, response });
		}
	} catch (error: any) {
		console.log("CAUGHT ERROR", error.response);
		yield put({ type: constants.GET_SCHEDULE_NAMES_ERROR, error });
	}
}
export function* getCancelReasons(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestGetCancelReasons, config);
		if (response) {
			yield put({ type: constants.GET_CANCEL_REASONS_SUCCESS, response });
		}
	} catch (error: any) {
		console.log("CAUGHT ERROR", error.response);
		yield put({ type: constants.GET_CANCEL_REASONS_ERROR, error });
	}
}
export function* getRecurringSchedule(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestGetRecurringSchedule, config);
		if (response) {
			yield put({ type: constants.GET_RECURRING_SCHEDULE_SUCCESS, response });
		}
	} catch (error: any) {
		console.log("CAUGHT ERROR", error.response);
		yield put({ type: constants.GET_RECURRING_SCHEDULE_ERROR, error });
	}
}
export function* getScheduleFilters(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestScheduleFilters, config);
		if (response) {
			yield put({ type: constants.GET_SCHEDULE_FILTERS_SUCCESS, response });
		}
	} catch (error: any) {
		console.log("CAUGHT ERROR", error.response);
		yield put({ type: constants.GET_SCHEDULE_FILTERS_ERROR, error });
	}
}
export function* getRelatedClasses(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestGetRelatedClasses, config);
		if (response) {
			yield put({ type: constants.GET_RELATED_CLASSES_SUCCESS, response });
		}
	} catch (error: any) {
		console.log("CAUGHT ERROR", error.response);
		yield put({ type: constants.GET_RELATED_CLASSES_ERROR, error });
	}
}
export function* payBooking(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestPayBooking, config);
		if (response) {
			yield put({ type: constants.PAY_BOOKING_SUCCESS, response });
		}
	} catch (error: any) {
		console.log("CAUGHT ERROR", error.response);
		yield put({ type: constants.PAY_BOOKING_ERROR, error });
	}
}
export function* watchScheduleRequest() {
	yield takeLatest(constants.GET_USER_SCHEDULE_REQUEST, getUserSchedule);
	yield takeEvery(constants.GET_CLUB_DETAILS_REQUEST, getClubDetails);
	yield takeLatest(constants.GET_SCHEDULE_NAMES_REQUEST, getScheduleNames);
	yield takeLatest(constants.GET_GYM_SCHEDULE_REQUEST, getGymSchedule);
	yield takeEvery(constants.GET_CANCEL_REASONS_REQUEST, getCancelReasons);
	yield takeEvery(constants.BOOK_CLASS_REQUEST, bookClass);
	yield takeEvery(constants.PAY_BOOKING_REQUEST, payBooking);
	yield takeEvery(constants.CANCEL_CLASS_REQUEST, cancelClass);
	yield takeLatest(constants.GET_RELATED_CLASSES_REQUEST, getRelatedClasses);
	yield takeLatest(constants.GET_SCHEDULE_FILTERS_REQUEST, getScheduleFilters);
	yield takeEvery(
		constants.GET_RECURRING_SCHEDULE_REQUEST,
		getRecurringSchedule
	);
}

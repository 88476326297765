import React from "react";
import {
	Grid,
	Button,
	FormGroup,
	FormControlLabel,
	Switch,
	DialogActions,
	DialogContent,
	DialogContentText,
} from "@mui/material";
import { createCardValidation } from "./validation";
import { BillingAddressForm } from "./AddressForm";
import { TextInput } from "../../Forms/Inputs";
import AcceptedCards from "./AcceptedCards";
import Title from "../Dialogs/Title";

export default function CreditCardForm(props: any) {
	const { userInfo, userConfig } = props;
	const [errors, setErrors] = React.useState<any>({});
	const [success, setSuccess] = React.useState<any>(false);
	const [checked, setChecked] = React.useState(false);
	const [values, setValues] = React.useState<any>({
		creditcard: "",
		expiryMonth: "",
		expiryYear: "",
		securityCode: "",
		address: "",
		city: "",
		zip: "",
		state: "",
		country: "",
		firstName: userInfo?.firstName,
		lastName: userInfo?.lastName,
		nameOnCard: "",
	});
	React.useEffect(() => {
		props.getProfileInfo(props.config);
	}, []);
	const handleChange = (event: any) => {
		let prop: any = [event.target.name];
		if (errors[prop]) {
			setErrors({ ...errors, [event.target.name]: "" });
		}
		if (event.target.name === "creditcard") {
			setErrors({ ...errors, luhnCheck: "", creditcard: "" });
		}
		setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};
	const handleSubmitPayment = (event: any) => {
		event.preventDefault();
		let config: any = {
			headers: { Authorization: `Bearer ${userConfig.token}` },
			BusinessAlias: userConfig.businessAlias,
			PartnerApiKey: userConfig.partnerApiKey,
			ClientId: userConfig.clientId,
			FirstName: values.firstName,
			LastName: values.lastName,
			Address: values.address,
			City: values.city,
			State: values.state,
			Country: values.country,
			Zip: values.zip,
			PaymentMethodType: "CreditCard",
			CreditCard: {
				CardNumber: values.creditcard,
				NameOnCard: values.nameOnCard,
				ExpiryMonth: values.expiryMonth,
				ExpiryYear: values.expiryYear,
				SecurityCode: values.securityCode,
			},
		};

		let billingAddress: any = {
			firstName: values.firstName,
			lastName: values.lastName,
			address: values.address,
			city: values.city,
			zip: values.zip,
			state: values.state,
			country: values.country,
		};

		let valid: any;
		valid = createCardValidation(
			config.CreditCard,
			setErrors,
			billingAddress,
			props.acceptedCards
		);
		if (valid) {
			if (checked) {
				props.setDefault(true);
				if (props.setIsDefault) {
					props.setIsDefault(true);
				}
			}
			setSuccess(true);
			props.createBillingProfile(config);
		}
	};
	const handleDefaultChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};
	if (success && !props.errors && !props.requesting && props.successful) {
		props.handleClose();
		setSuccess(false);
	}
	const gridItem = { xs: 12, md: 6 };
	return (
		<>
			<Title title="Add Credit/Debit Card" onClose={props.handleClose} />
			<DialogContent>
				<form autoComplete="off" noValidate>
					<DialogContentText>
						<AcceptedCards acceptedCards={props.acceptedCards} />
						<Grid container justifyContent="center" sx={{ mb: 2 }}>
							<FormGroup row>
								<FormControlLabel
									control={
										<Switch
											checked={checked}
											onChange={handleDefaultChange}
											color="primary"
											name="default"
											disabled={
												props.profileInfo?.isDefaultProfile ? true : false
											}
										/>
									}
									label="Make Default Payment"
								/>
							</FormGroup>
						</Grid>
						<Grid container justifyContent="center" spacing={3}>
							<Grid item {...gridItem}>
								<TextInput
									label={"Name on Card"}
									name="nameOnCard"
									fullWidth
									onChange={handleChange}
									autoFocus
									value={values.nameOnCard}
									error={errors.nameOnCard ? true : false}
									helperText={errors.nameOnCard}
								/>
							</Grid>
							<Grid item {...gridItem}>
								<TextInput
									fullWidth
									label={"Card Number"}
									type="number"
									name="creditcard"
									onChange={handleChange}
									value={values.creditcard}
									error={errors.creditcard || errors.luhnCheck ? true : false}
									helperText={errors.creditcard || errors.luhnCheck}
								/>
							</Grid>
							<Grid item xs={4} sm={3}>
								<TextInput
									label={"CVV"}
									name="securityCode"
									onChange={handleChange}
									type="number"
									value={values.securityCode}
									error={errors.securityCode ? true : false}
									helperText={errors.securityCode}
								/>
							</Grid>
							<Grid item xs={4} sm={3}>
								<TextInput
									type="number"
									label={"Exp Mo."}
									placeholder="MM"
									name="expiryMonth"
									onChange={handleChange}
									value={values.expiryMonth}
									error={errors.expiryMonth ? true : false}
									helperText={errors.expiryMonth}
								/>
							</Grid>
							<Grid item xs={4} sm={3}>
								{" "}
								<TextInput
									label={"Exp Yr."}
									name="expiryYear"
									placeholder="YYYY"
									type="number"
									onChange={handleChange}
									value={values.expiryYear}
									error={errors.expiryYear ? true : false}
									helperText={errors.expiryYear}
								/>
							</Grid>
						</Grid>
						<Grid container justifyContent="center">
							<BillingAddressForm
								{...props}
								values={values}
								errors={errors}
								userInfo={props.userInfo}
								setValues={setValues}
								setErrors={setErrors}
								handleChange={handleChange}
							/>
						</Grid>
					</DialogContentText>
				</form>
			</DialogContent>
			<DialogActions sx={{ p: 2 }}>
				<Button
					fullWidth
					onClick={() => props.handleClose()}
					variant="outlined"
					color="primary"
					sx={{ mr: 1 }}
				>
					Cancel
				</Button>

				<Button
					fullWidth
					onClick={handleSubmitPayment}
					variant="contained"
					color="primary"
					sx={{ ml: 1 }}
				>
					Save
				</Button>
			</DialogActions>
		</>
	);
}

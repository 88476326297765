import React from "react";
import {
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	MenuItem,
	Menu,
	Button,
	ButtonGroup,
	Box,
} from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";
import { connect } from "react-redux";
import { history } from "../../utils/history";

const classes: any = {
	grow: {
		flexGrow: 1,
	},
	headerLinks: {
		textDecoration: "none",
		padding: 20,
		paddingLeft: "50px",
		paddingRight: "50px",
		color: "#0B2240",
		fontWeight: 900,
	},
	sectionDesktop: {
		display: { xs: "none", md: "flex" },
	},
	sectionMobile: {
		display: { xs: "flex", md: "none" },
		justifyContent: "space-between",
		padding: "0 !important",
	},
	appBar: {
		display: "flex",
		borderBottom: `solid 1px #1976D2`,
		backgroundColor: "text.disabled",
		alignItems: "space-between",
		justifyContent: "center",
		top: 0,
		bottom: "auto",
		height: 55,
		overflow: "hidden",
		zIndex: 400,
	},
	mobileMenuStyle: {
		textDecoration: "none",
		padding: "10",
		backgroundColor: "#FFFFFF",
		color: "black",
	},
	popover: {
		marginTop: 40,
	},
	menuLinks: {
		width: 200,
		alignText: "center",
		justifyContent: "center",
	},
	logo: {
		width: 150,
	},
	clubName: {
		textDecoration: "none",
		fontWeight: 900,
		fontSize: 20,
		textTransform: "uppercase",
		backgroundColor: "white",
	},
};

function Header(props: any) {
	const { userConfig } = props;
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
		React.useState<null | HTMLElement>(null);
	const [profileMoreAnchorEl, setProfileMoreAnchorEl] =
		React.useState<null | HTMLElement>(null);
	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
	const isProfileMenuOpen = Boolean(profileMoreAnchorEl);
	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};
	const handleProfileMenuClose = () => {
		setProfileMoreAnchorEl(null);
	};
	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};
	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};
	const signOut = () => {
		props.onSignOut();
		history.push(`/${userConfig.businessAlias}/signin`);
		handleMenuClose();
	};
	const menuId = "primary-search-account-menu";
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<MenuItem onClick={handleMenuClose}>Profile</MenuItem>
			<MenuItem onClick={handleMenuClose}>My account</MenuItem>
		</Menu>
	);
	const renderMobileMenu = (
		<div className={classes.mobileMenuStyle}>
			<Menu
				sx={{ mt: 4 }}
				anchorEl={mobileMoreAnchorEl}
				id={"mobileMenuId"}
				keepMounted
				transformOrigin={{ vertical: "bottom", horizontal: "right" }}
				open={isMobileMenuOpen}
				onClose={handleMobileMenuClose}
			>
				<div className={classes.headerLinks}>
					<ButtonGroup
						sx={{ minWidth: 120 }}
						variant="text"
						orientation="vertical"
					>
						<Button sx={{ minWidth: 120 }} fullWidth onClick={() => signOut()}>
							Sign Out
						</Button>
					</ButtonGroup>
				</div>
			</Menu>
		</div>
	);
	return (
		<div className={classes.grow}>
			{props.user ? (
				<div>
					<AppBar sx={classes.appBar} position="fixed">
						<Toolbar
							sx={{
								justifyContent: "space-between",
							}}
						>
							<div style={{ overflow: "hidden" }}>
								<Typography
									color="primary.main"
									sx={{
										bgcolor: "text.disabled",
										textDecoration: "none",
										fontWeight: 900,
										fontSize: 20,
										textTransform: "uppercase",
									}}
									variant="h5"
								>
									{props.clubData?.clubName}
								</Typography>
							</div>
							<div className={classes.grow} />
							{props.user ? (
								<Box sx={classes.sectionMobile}>
									<IconButton
										aria-label="show more"
										aria-controls={"mobileMenuId"}
										aria-haspopup="true"
										onClick={handleMobileMenuOpen}
										color="inherit"
										style={{ padding: 0 }}
										size="large"
									>
										<MoreIcon />
									</IconButton>
								</Box>
							) : (
								""
							)}
						</Toolbar>
					</AppBar>
					{renderMobileMenu}
					{renderMenu}
				</div>
			) : null}
		</div>
	);
}
const mapStateToProps = (state: any) => {
	return {
		user: state.auth.currentUser,
		errors: state.auth.errors,
		userConfig: state.auth.userConfig,
		clubData: state.billingProfile.clubData,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onSignOut: () => dispatch({ type: "LOGOUT" }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);

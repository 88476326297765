import React from "react";
import moment from "moment";
import {
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Typography,
	Grid,
	Badge,
	IconButton,
	Dialog,
	DialogContent,
	DialogContentText,
	Grow,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import PersonalDetails from "./PersonalDetails";
import ManageUsers from "../../containers/ManageUsers";
import HealthInfo from "../../containers/HealthInfo";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PaymentMethods from "../../containers/PaymentMethods";
import ManageMemberships from "../../containers/Memberships";
import Invoices from "../../containers/Invoices";
import ImageUploader from "./Dialogs/ImageUploader";
import RecurringPayments from "./RecurringPayments/RecurringPayments";
import ManageQuestionnaires from "../../containers/Questionnaires";
import Notifications from "../../components/AlertMessage/Notifications";
import BeltRank from "./Dialogs/BeltRank";
import CommunicationPreferences from "./Dialogs/ComPreferences";
import styles from "../../styles";
import Title from "./Dialogs/Title";
import { MetroSpinner } from "react-spinners-kit";
import { blue } from "../../theme";

const classes: any = {
	root: {
		mt: { xs: 9.5 },
		width: { xs: "100%", md: "95%", lg: "80%", xl: "75%" },
		ml: "auto",
		mr: "auto",
		pl: { xs: 0, md: 7.5 },
		mb: { xs: 5, md: 1 },
	},
	avatarBadge: {
		height: 30,
		width: 30,
		border: `solid 2px secondary.main`,
		color: "white",
		backgroundColor: "secondary.main",
		borderRadius: 20,
	},
	capitalize: {
		textTransform: "capitalize",
	},
};

export default function Profile(props: any) {
	const [open, setOpen] = React.useState("0");
	const [error, setError] = React.useState<any>("");
	const { userInfo, messages, userConfig, profileImage, clubData } = props;
	let config = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		id: userConfig ? userConfig.clientId : null,
		imageName: "",
	};
	React.useEffect(() => {
		props.getProfileInfo(config);
		props.getBeltRank(config);
	}, []);
	React.useEffect(() => {
		if (props.clientErrors) {
			setError("There was an error loading your profile");
		}
	}, [props.clientErrors]);
	React.useEffect(() => {
		if (userInfo?.profilePictureName) {
			let imageName: any = userInfo.profilePictureName;
			config.imageName = imageName;
			props.getProfileImage(config);
		}
	}, [userInfo?.profilePictureName]);

	React.useEffect(() => {
		if (
			messages === "Profile image successfully uploaded" ||
			messages === "Notifications successfully updated" ||
			messages === "Your health information has been successfully updated"
		) {
			props.getProfileInfo(config);
		}
	}, [messages]);
	React.useEffect(() => {
		let mediaPath: any;
		if (messages === "Profile image successfully uploaded") {
			if (userInfo.profilePictureName) {
				mediaPath = userInfo.profilePictureName;
				config.imageName = mediaPath;
				props.getProfileImage(config);
			} else {
				console.log("PROFILE IMAGE NAME NOT PRESENT");
			}
		}
	}, [messages]);
	const handleOpen = (number: any) => {
		setOpen(number);
	};
	const handleClose = () => {
		setOpen("0");
	};
	const deleteProfileImage = () => {
		props.deleteProfileImage(config);
		handleClose();
	};
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("sm"));
	let since = userInfo?.since?.slice(0, 10);
	let profileImageLoad: any =
		profileImage && profileImage !== "none"
			? `data:image/png;base64,${profileImage}`
			: "https://www.clipartkey.com/mpngs/m/152-1520367_user-profile-default-image-png-clipart-png-download.png";

	return (
		<div>
			<div style={{ display: "relative" }}>
				{props.messages || props.errors || props.clientErrors ? (
					<Notifications
						messages={props.messages}
						errors={props.errors || props.clientErrors}
					/>
				) : null}
			</div>
			{userInfo ? (
				<Box sx={classes.root}>
					<Grid direction="row" container justifyContent="center">
						<Grid item xs={11} md={10}>
							<div style={{ marginBottom: 20, color: "#FF0000" }}>
								<Card raised={true}>
									<CardHeader
										sx={styles.cardHeader}
										title={"Profile"}
									></CardHeader>
									<CardContent>
										<Box
											sx={{
												display: { xs: "none", sm: "flex" },
											}}
										>
											<Grid
												container
												alignContent="center"
												justifyContent="center"
											>
												<Grid
													item
													sm={4}
													sx={{
														display: "flex",
														alignContent: "center",
														justifyContent: "center",
														pr: "2.5rem",
														pt: 0.5,
													}}
												>
													<Badge
														overlap="circular"
														anchorOrigin={{
															vertical: "bottom",
															horizontal: "right",
														}}
														badgeContent={
															<IconButton onClick={() => handleOpen("4")}>
																<EditOutlinedIcon sx={classes.avatarBadge} />
															</IconButton>
														}
													>
														<Avatar
															sx={{ height: 160, width: 160, ml: "2rem" }}
															src={profileImageLoad}
														/>
													</Badge>
												</Grid>
												<Grid item sm={4} sx={{ pl: "1.5rem", pt: 2.5 }}>
													<Typography
														sx={classes.capitalize}
														color="textPrimary"
														gutterBottom
														variant="h5"
													>
														{userInfo
															? userInfo.firstName + " " + userInfo.lastName
															: null}
													</Typography>
													{since === "1970-01-01" ? null : (
														<Typography color="primary" variant="body1">
															Member Since: {moment(since).format("MM/YYYY")}
														</Typography>
													)}
													{userInfo.memberID ? (
														<Typography
															color="primary"
															variant="body1"
															gutterBottom
														>
															Member ID: {userInfo.memberID}
														</Typography>
													) : (
														""
													)}
													{props.beltRank?.length > 0 ? (
														<Typography align="left">
															<Button
																sx={{
																	p: 0.5,
																	pl: 2,
																	pr: 2,
																	mt: 0.5,
																}}
																variant="outlined"
																onClick={() => handleOpen("6")}
															>
																Belt Rank
															</Button>
														</Typography>
													) : null}
												</Grid>
												<Grid item sm={4}>
													<Grid container rowSpacing={1}>
														{" "}
														{clubData.questionnairePermissionStatus ===
														"HIDE" ? (
															""
														) : (
															<Grid item sm={12}>
																<Button
																	fullWidth
																	variant="outlined"
																	onClick={() => handleOpen("1")}
																>
																	QUESTIONNAIRES
																</Button>
															</Grid>
														)}
														<Grid item sm={12}>
															<Button
																fullWidth
																variant="outlined"
																onClick={() => handleOpen("5")}
															>
																NOTIFICATION SETTINGS
															</Button>
														</Grid>
														<Grid item sm={12}>
															<Button
																fullWidth
																variant="outlined"
																onClick={() => handleOpen("3")}
															>
																Health Information
															</Button>
														</Grid>
														<Grid item sm={12}>
															<Button
																fullWidth
																variant="outlined"
																onClick={() => handleOpen("2")}
															>
																MANAGE USERS
															</Button>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Box>
										<Box
											sx={{
												alignItems: "center",
												justifyContent: "center",
												display: { xs: "flex", sm: "none" },
												flexDirection: "column",
											}}
										>
											<Badge
												overlap="circular"
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "right",
												}}
												badgeContent={
													<IconButton onClick={() => handleOpen("4")}>
														<EditOutlinedIcon sx={classes.avatarBadge} />
													</IconButton>
												}
											>
												<Avatar
													sx={{ height: 150, width: 150 }}
													src={profileImageLoad}
												/>
											</Badge>

											<Typography
												sx={classes.capitalize}
												color="textPrimary"
												gutterBottom
												variant="h5"
											>
												{userInfo
													? userInfo.firstName + " " + userInfo.lastName
													: null}
											</Typography>
											{userInfo.memberID ? (
												<Typography color="primary" variant="body1">
													Member ID: {userInfo.memberID}
												</Typography>
											) : (
												""
											)}
											{since === "1970-01-01" ? null : (
												<Typography color="primary" variant="body1">
													Member Since: {moment(since).format("MM/YYYY")}
												</Typography>
											)}
											{props.beltRank?.length > 0 ? (
												<Typography align="center">
													<Button
														sx={{
															p: 0.5,
															pl: 2,
															pr: 2,
															mt: 0.5,
														}}
														variant="outlined"
														onClick={() => handleOpen("6")}
													>
														Belt Rank
													</Button>
												</Typography>
											) : null}
										</Box>
										<Dialog
											open={open === "4"}
											onClose={handleClose}
											sx={styles.modal}
											maxWidth="xs"
											fullWidth
											aria-labelledby="Edit Profile Pic"
											aria-describedby="Update profile pic"
										>
											<Title title="Edit Profile Image" onClose={handleClose} />
											<DialogContent sx={{ p: 0 }}>
												<DialogContentText>
													<ImageUploader
														profileImage={
															profileImageLoad ? profileImageLoad : null
														}
														config={config}
														deleteProfileImage={deleteProfileImage}
														uploadProfileImage={props.uploadProfileImage}
														handleClose={handleClose}
														getProfileInfo={props.getProfileInfo}
														userConfig={props.userConfig}
													/>
												</DialogContentText>
											</DialogContent>
										</Dialog>
										<Dialog
											sx={styles.modal}
											maxWidth="md"
											fullWidth
											open={open === "6"}
											onClose={handleClose}
										>
											<DialogContent sx={{ p: 0 }}>
												<DialogContentText>
													<BeltRank
														config={config}
														userConfig={props.userConfig}
														beltRank={props.beltRank}
														getBeltRank={props.getBeltRank}
														onClose={handleClose}
													/>
												</DialogContentText>
											</DialogContent>
										</Dialog>
									</CardContent>
									<Divider />
									<Box sx={{ p: 2, display: { xs: "block", sm: "none" } }}>
										<Grid container spacing={2}>
											<Grid item xs={12} md={6} lg={4}>
												<Button
													fullWidth
													variant="outlined"
													onClick={() => handleOpen("3")}
												>
													Health Information
												</Button>
											</Grid>

											<Grid item xs={12} md={6} lg={4}>
												<Button
													fullWidth
													variant="outlined"
													onClick={() => handleOpen("2")}
												>
													MANAGE USERS
												</Button>
											</Grid>

											{clubData.questionnairePermissionStatus === "HIDE" ? (
												""
											) : (
												<Grid item xs={12} md={6} lg={4}>
													<Button
														fullWidth
														variant="outlined"
														onClick={() => handleOpen("1")}
													>
														QUESTIONNAIRES
													</Button>
												</Grid>
											)}
											<Grid item xs={12} md={6} lg={4}>
												<Button
													fullWidth
													variant="outlined"
													onClick={() => handleOpen("5")}
												>
													NOTIFICATION SETTINGS
												</Button>
											</Grid>
										</Grid>
										<Dialog
											open={open === "2"}
											onClose={handleClose}
											fullWidth
											maxWidth="sm"
											sx={styles.modal}
										>
											<ManageUsers config={config} onClose={handleClose} />
										</Dialog>
										<Dialog
											open={open === "5"}
											onClose={handleClose}
											sx={styles.modal}
										>
											<CommunicationPreferences
												config={config}
												userInfo={userInfo}
												userConfig={props.userConfig}
												updateNotifications={props.updateNotifications}
												onClose={handleClose}
											/>
										</Dialog>
										<Dialog
											open={open === "1"}
											maxWidth="xs"
											sx={styles.modal}
											fullWidth
											onClose={handleClose}
											aria-labelledby="Questionnaires"
											aria-describedby="Questionnaires Info"
										>
											<ManageQuestionnaires
												config={config}
												matches={matches}
												onClose={handleClose}
											/>
										</Dialog>
									</Box>

									<Dialog
										open={open === "3"}
										onClose={handleClose}
										sx={styles.modal}
										aria-labelledby="Health"
										aria-describedby="Health Info"
									>
										<HealthInfo
											user={userInfo}
											config={config}
											onClose={handleClose}
										/>
									</Dialog>
								</Card>
							</div>
							<div>
								<PersonalDetails {...props} user={userInfo} config={config} />
								<ManageMemberships />
								<Invoices config={config} />
								<RecurringPayments {...props} />
								<Grow in={true} timeout={1750}>
									<div style={{ marginTop: 20, marginBottom: 20 }}>
										<PaymentMethods config={config} />
									</div>
								</Grow>
							</div>
						</Grid>
					</Grid>
				</Box>
			) : null}
			{error ? (
				<Typography
					variant="subtitle1"
					style={{ marginTop: 100 }}
					align="center"
					sx={styles.modal}
				>
					{error}
				</Typography>
			) : null}
			{props.requesting || !userInfo ? (
				<Grid
					container
					justifyContent="center"
					sx={{ mt: 25, ml: { xs: 0, sm: 3.5 } }}
				>
					<MetroSpinner size={50} color={blue} />
				</Grid>
			) : null}
		</div>
	);
}

const global: any = {
	modal: {
		ml: { xs: 0, md: 5.5 },
		width: "100%",
	},
	cardHeader: {
		backgroundColor: "primary.main",
		color: "white",
		alignItems: "flex-start",
	},
	header: {
		backgroundColor: "primary.main",
		color: "white",
		padding: 2,
	},
	form: {
		width: "100%",
		height: "100%",
		maxWidth: 580,
		flexGrow: 1,
		overflowY: "auto",
	},
	paper: {
		padding: 2,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	accordion: {
		backgroundColor: "primary.main",
		color: "white",
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
		fontSize: 22,
		lineHeight: 1,
		margin: 0,
	},
};

export default global;

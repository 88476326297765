import { call, put, takeEvery } from "redux-saga/effects";
import * as constants from "../constants/enroll.constants";
import {
	requestGetEnrollMemberships,
	requestGetMembershipDetails,
	requestGetMembershipTerms,
	requestEnrollMembership,
} from "../services/enroll.service";

export interface ResponseGenerator {
	config?: any;
	data?: any;
	headers?: any;
	request?: any;
	status?: number;
	statusText?: string;
}
export function* getEnrollMemberships(action: any) {
	try {
		let response: ResponseGenerator = yield call(
			requestGetEnrollMemberships,
			action.value
		);
		if (response) {
			yield put({ type: constants.GET_ENROLL_MEMBERSHIPS_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.GET_ENROLL_MEMBERSHIPS_ERROR, error });
	}
}
export function* getEnrollMembershipDetails(action: any) {
	try {
		let response: ResponseGenerator = yield call(
			requestGetMembershipDetails,
			action.value
		);
		if (response) {
			yield put({
				type: constants.GET_ENROLL_MEMBERSHIP_DETAILS_SUCCESS,
				response,
			});
		}
	} catch (error) {
		yield put({ type: constants.GET_ENROLL_MEMBERSHIP_DETAILS_ERROR, error });
	}
}
export function* getEnrollMembershipTerms(action: any) {
	try {
		let response: ResponseGenerator = yield call(
			requestGetMembershipTerms,
			action.value
		);
		if (response) {
			yield put({
				type: constants.GET_ENROLL_MEMBERSHIP_TERMS_SUCCESS,
				response,
			});
		}
	} catch (error) {
		yield put({ type: constants.GET_ENROLL_MEMBERSHIP_TERMS_ERROR, error });
	}
}

export function* enrollMembership(action: any) {
	try {
		let response: ResponseGenerator = yield call(
			requestEnrollMembership,
			action.value
		);
		if (response) {
			yield put({
				type: constants.ENROLL_MEMBERSHIP_SUCCESS,
				response,
			});
		}
	} catch (error) {
		yield put({ type: constants.ENROLL_MEMBERSHIP_ERROR, error });
	}
}
export function* watchEnrollMembershipsRequest() {
	yield takeEvery(
		constants.GET_ENROLL_MEMBERSHIPS_REQUEST,
		getEnrollMemberships
	);
	yield takeEvery(
		constants.GET_ENROLL_MEMBERSHIP_DETAILS_REQUEST,
		getEnrollMembershipDetails
	);
	yield takeEvery(
		constants.GET_ENROLL_MEMBERSHIP_TERMS_REQUEST,
		getEnrollMembershipTerms
	);
	yield takeEvery(constants.ENROLL_MEMBERSHIP_REQUEST, enrollMembership);
}

import React from "react";
import {
	Typography,
	Button,
	Select,
	Grid,
	Divider,
	DialogContent,
	DialogActions,
	Dialog,
} from "@mui/material";
import Title from "../Dialogs/Title";
import { renderPaymentProfile } from "./RenderPaymentProfile";
import { renderPaymentMethods } from "../../Forms/PaymentMethods";
import PaymentMethods from "../../../containers/PaymentMethods";
import ButtonLoader from "../../../utils/ButtonLoader";
import styles from "../../../styles";
import moment from "moment";

export const ChangePaymentDialog = (props: any) => {
	const [openModal, setOpenModal] = React.useState(0);
	const {
		setOpen,
		payment,
		sortedBillingProfiles,
		paymentMethod,
		handlePaymentChange,
		userConfig,
		messages,
		getDashboardData,
		requesting,
	} = props;
	const [endDate] = React.useState(moment().add(7, "days"));
	let config = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		id: userConfig ? userConfig.clientId : null,
		startDate: new Date().toISOString().slice(0, 10),
		endDate: endDate.toISOString().slice(0, 10),
	};
	React.useEffect(() => {
		if (messages === "Payment profile successfully updated") {
			setOpen(-1);
			getDashboardData(config);
		}
	}, [messages]);
	let renderPayments: any = renderPaymentMethods(sortedBillingProfiles);
	const updateRecurringProfile = () => {
		let config: any = {
			headers: {
				Authorization: `Bearer ${userConfig?.token}`,
			},
			businessAlias: userConfig?.businessAlias,
			partnerApiKey: userConfig?.partnerApiKey,
			id: payment.id,
			billingProfileId: paymentMethod,
		};
		props.updateRecurringProfile(config);
	};
	return (
		<>
			<Title title={payment.productName} onClose={() => setOpen(-1)} />
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={10} sm={6}>
						<Typography variant="body1" sx={{ pt: 1.5, pb: 0.5 }}>
							Current Payment Method:
						</Typography>
						<Divider sx={{ mb: 1 }} />
						{sortedBillingProfiles.find(
							(profile: any) => profile.id === payment.paymentMethodId
						) ? (
							renderPaymentProfile(
								sortedBillingProfiles.find(
									(profile: any) => profile.id === payment.paymentMethodId
								)
							)
						) : (
							<Typography>Not Available</Typography>
						)}
					</Grid>
					<Grid item xs={12} sm={6} sx={{ m: 0 }}>
						<Typography variant="body1" sx={{ pt: 1.5, pb: 0.5 }}>
							New Payment Method:
						</Typography>
						<Divider sx={{ mb: 1 }} />
						<Select
							native
							color="primary"
							fullWidth
							value={paymentMethod}
							onChange={handlePaymentChange}
							inputProps={{
								name: "paymentMethod",
								id: "outlined-age-native-simple",
								padding: 5,
							}}
						>
							{renderPayments}
						</Select>
						<Grid item xs={12} sx={{ mt: 1 }}>
							<Button
								fullWidth
								onClick={() => setOpenModal(1)}
								variant="outlined"
								color="primary"
							>
								Add Payment Method
							</Button>
						</Grid>
						{paymentMethod === payment.paymentMethodId && (
							<Grid item xs={12}>
								<Typography
									variant="body2"
									sx={{ fontSize: ".8rem", fontStyle: "italic" }}
								>
									The new payment method must be different than the current
									payment method
								</Typography>
							</Grid>
						)}

						<Dialog
							PaperProps={{
								style: {
									backgroundColor: "transparent",
								},
							}}
							sx={styles.modal}
							open={openModal === 1}
							onClose={() => setOpenModal(0)}
						>
							<PaymentMethods
								handleClose={() => setOpenModal(0)}
								{...props}
								setIsDefault={props.setIsDefault}
								open={true}
							/>
						</Dialog>
					</Grid>
				</Grid>
				<Typography variant="body2" sx={{ pt: 2 }}>
					Payment methods changed here will effect future recurring payments. If
					payment is due in the next 1-3 days, changes may not take effect until
					the next cycle.
				</Typography>
			</DialogContent>
			<DialogActions sx={{ p: 2, pt: 0 }}>
				<Button
					fullWidth
					onClick={() => setOpen(-1)}
					variant="outlined"
					sx={{ mr: 1 }}
				>
					Back
				</Button>
				<Button
					variant="contained"
					fullWidth
					sx={{ ml: 1 }}
					disabled={paymentMethod === payment.paymentMethodId || requesting}
					onClick={updateRecurringProfile}
				>
					{requesting ? <ButtonLoader /> : "Submit"}
				</Button>
			</DialogActions>
		</>
	);
};

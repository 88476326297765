import { connect } from "react-redux";
import PaymentMethods from "../components/Profile/Payments/PaymentMethods";

const mapStateToProps = (state: any) => {
	return {
		userConfig: state.auth.userConfig,
		billingProfiles: state.billingProfile.billingProfiles,
		messages: state.billingProfile.messages,
		errors: state.billingProfile.errors,
		userInfo: state.client.user,
		clubData: state.billingProfile.clubData,
		requesting: state.billingProfile.requesting,
		successful: state.billingProfile.successful,
		defaultId: state.billingProfile.defaultId,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getProfileInfo: (config: any) =>
			dispatch({ type: "PROFILE_INFO_REQUEST", value: config }),
		getAcceptedCards: (config: any) =>
			dispatch({ type: "ACCEPTED_CARDS_REQUEST", value: config }),
		getBillingProfile: (config: any) =>
			dispatch({ type: "BILLING_PROFILE_REQUEST", value: config }),
		updateBillingProfile: (config: any) =>
			dispatch({ type: "UPDATE_BILLING_PROFILE_REQUEST", value: config }),
		createBillingProfile: (config: any) =>
			dispatch({ type: "CREATE_BILLING_PROFILE_REQUEST", value: config }),
		deleteBillingProfile: (config: any) =>
			dispatch({ type: "DELETE_BILLING_PROFILE_REQUEST", value: config }),
		defaultBillingProfile: (config: any) =>
			dispatch({ type: "DEFAULT_BILLING_PROFILE_REQUEST", value: config }),
		clearErrors: () => dispatch({ type: "CLEAR_ERRORS" }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);

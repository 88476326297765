import * as React from "react";
import {
	Grid,
	Fade,
	Input,
	Typography,
	FormControl,
	InputAdornment,
	IconButton,
	InputLabel,
	Button,
	Grow,
} from "@mui/material";
import PasswordMeter from "./PasswordMeter";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as validate from "./validate";
import PasswordRequirements from "./PasswordRequirements";
import { connect } from "react-redux";
import { getQueryParams } from "../../helpers/browser";
import ButtonLoader from "../../utils/ButtonLoader";
import Alerts from "../AlertMessage/AlertMessage";
import { BackToSignInButton } from "../../utils/Buttons";
import Error from "../AlertMessage/ErrorPage";

const classes: any = {
	paper: {
		marginTop: 10,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		maxWidth: "500px",
		marginLeft: "auto",
		marginRight: "auto",
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: 1,
	},
	submit: {
		mt: 4,
	},
	padding: {
		margin: "16px 24px 0",
	},
};

const SetPassword = (props: any) => {
	const [errors, setErrors] = React.useState<any>("");
	const [values, setValues] = React.useState({
		newPassword: "",
		confirmPassword: "",
		showPassword: false,
		showConfirmPassword: false,
	});
	const params = getQueryParams(window.location.search);
	const userId = params.userId || undefined;
	const code = params.code || undefined;
	const systemName = "MP2";
	const businessAlias = window.location.pathname.split("/")[1];
	const isLengthInValid = validate.isLengthInValid(values.newPassword);
	const isContainNumericInValid = validate.isContainNumericInValid(
		values.newPassword
	);
	const isContainAlphabeticInValid = validate.isContainAlphabeticInValid(
		values.newPassword
	);
	const matching = values.newPassword === values.confirmPassword ? true : false;
	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};
	const handleMouseDownPassword = (event: any) => {
		event.preventDefault();
	};
	const handleChange = (prop: any) => (event: any) => {
		setValues({ ...values, [prop]: event.target.value });
	};
	let config = {
		UserId: userId,
		Password: values.newPassword,
		ConfirmPassword: values.confirmPassword,
		ConfirmationCode: code,
		SystemName: systemName,
		BusinessAlias: businessAlias,
		PartnerApiKey: "mm",
	};
	const handleResetPassword = () => {
		props.onRequestPasswordReset(config);
		if (props.successful) {
			setValues({
				newPassword: "",
				confirmPassword: "",
				showPassword: false,
				showConfirmPassword: false,
			});
		}
	};
	const passwordConfig = {
		userId: userId,
		Password: values.newPassword,
	};

	const handleValidPassword = (e: any) => {
		e.preventDefault();
		if (values.newPassword.length > 6) {
			props.isPasswordValid(passwordConfig);
		}
	};
	React.useEffect(() => {
		let config = { businessAlias: businessAlias, partnerApiKey: "mm" };
		props.getClubLogo(config);
		props.checkAlias(config);
		setTimeout(() => {
			props.clearMessage();
			setErrors("");
		}, 200);
	}, []);
	const passwordsMatch = () => {
		if (values.confirmPassword?.length > 0) {
			if (matching) {
				setErrors("");
			} else {
				setErrors("Passwords do not match");
			}
		}
	};
	return (
		<>
			{!props.active ? (
				<Error />
			) : (
				<Grid
					sx={classes.paper}
					container
					justifyContent="center"
					alignItems="center"
				>
					{props.clubLogo && (
						<img src={props.clubLogo} width={240} alt="logo" />
					)}
					<Grow in={true} timeout={600}>
						<Typography sx={{ mt: 2, mb: 1 }} component="h1" variant="h5">
							Set Password
						</Typography>
					</Grow>
					<Grow in={true} timeout={900}>
						<div>
							<Typography align="center">
								<em>
									Make sure your password meets the following complexity
									requirements:
								</em>
							</Typography>
							<Grid container justifyContent="center">
								<Grid item>
									<PasswordRequirements
										isLengthInValid={isLengthInValid}
										isContainNumericAndAlphaInValid={
											isContainNumericInValid || isContainAlphabeticInValid
										}
										isNewUser={true}
									/>
								</Grid>
							</Grid>
							<Grid item>
								<Fade in={true} timeout={1500}>
									<div style={{ marginTop: 20 }}>
										<PasswordMeter value={values.newPassword} />
									</div>
								</Fade>
							</Grid>
						</div>
					</Grow>

					<Grow in={true} timeout={800}>
						<FormControl variant="standard" sx={classes.form}>
							<div style={classes.padding}>
								<InputLabel
									color="secondary"
									sx={classes.padding}
									htmlFor="standard-adornment-password"
								>
									Password *
								</InputLabel>
								<Input
									required
									color="secondary"
									fullWidth
									id="standard-adornment-password"
									type={values.showPassword ? "text" : "password"}
									value={values.newPassword}
									onChange={handleChange("newPassword")}
									onBlur={(event: any) => {
										handleValidPassword(event);
										passwordsMatch();
									}}
									error={errors.newPassword ? true : false}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												size="large"
											>
												{values.showPassword ? (
													<Visibility />
												) : (
													<VisibilityOff />
												)}
											</IconButton>
										</InputAdornment>
									}
								/>
								<Typography color="error" variant="caption">
									{errors.password}
								</Typography>
							</div>
						</FormControl>
					</Grow>
					<Grow in={true} timeout={950}>
						<FormControl variant="standard" sx={classes.form}>
							<div style={classes.padding}>
								<Grid>
									<InputLabel
										color="secondary"
										sx={classes.padding}
										htmlFor="standard-adornment-password2"
									>
										Confirm Password *
									</InputLabel>
									<Input
										color="secondary"
										required
										fullWidth
										id="standard-adornment-password2"
										type={values.showConfirmPassword ? "text" : "password"}
										value={values.confirmPassword}
										onChange={handleChange("confirmPassword")}
										onBlur={passwordsMatch}
										error={errors.confirmPassword ? true : false}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() =>
														setValues({
															...values,
															showConfirmPassword: !values.showConfirmPassword,
														})
													}
													size="large"
												>
													{values.showConfirmPassword ? (
														<Visibility />
													) : (
														<VisibilityOff />
													)}
												</IconButton>
											</InputAdornment>
										}
									/>
								</Grid>
								<Grid item>
									{" "}
									<Alerts
										messages={props.messages}
										errors={props.errors || errors}
										userMessages={props.messages}
									/>
								</Grid>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									onClick={handleResetPassword}
									sx={classes.submit}
									disabled={
										isLengthInValid ||
										isContainNumericInValid ||
										errors?.length > 0
									}
								>
									{props.requesting ? <ButtonLoader /> : "Set Password"}
								</Button>

								<Grid
									style={{ marginTop: "-10px", marginBottom: 10 }}
									container
									justifyContent="center"
								>
									<BackToSignInButton />
								</Grid>
							</div>
						</FormControl>
					</Grow>
				</Grid>
			)}
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {
		errors: state.user.errors,
		messages: state.user.messages,
		requesting: state.user.requesting,
		canReusePassword: state.user.canReusePassword,
		clubLogo: state.auth.clubLogo,
		active: state.auth.active,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onRequestPasswordReset: (config: any) =>
			dispatch({ type: "SET_PASSWORD_REQUEST", value: config }),
		isPasswordValid: (config: any) =>
			dispatch({ type: "VALID_PASSWORD_REQUEST", value: config }),
		clearMessage: () => dispatch({ type: "CLEAR_MESSAGE" }),
		getClubLogo: (config: any) =>
			dispatch({ type: "CLUB_LOGO_REQUEST", value: config }),
		checkAlias: (config: any) =>
			dispatch({ type: "CHECK_ALIAS_REQUEST", value: config }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);

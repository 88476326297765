import { TextField, FormControl, InputLabel, Select } from "@mui/material";
import states from "../../utils/states";
import provinces from "../../utils/provinces";
import { countries } from "../../utils/countries";

export function SelectState(props: any) {
	return (
		<TextField
			fullWidth
			margin={props.margin}
			variant="outlined"
			color="secondary"
			select
			className={props.className}
			label={"State"}
			name="state"
			onChange={props.onChange}
			SelectProps={{ native: true }}
			value={props.value}
			error={props.error}
			helperText={props.helperText}
			InputLabelProps={{ shrink: true }}
			onBlur={props.onBlur}
		>
			{states.map((option) => (
				<option key={option.value} value={option.value}>
					{option.label}
				</option>
			))}
		</TextField>
	);
}
export function SelectProvince(props: any) {
	return (
		<TextField
			fullWidth
			margin={props.margin}
			variant="outlined"
			color="secondary"
			select
			className={props.className}
			label={"Province"}
			name="state"
			onChange={props.onChange}
			SelectProps={{ native: true }}
			value={props.value}
			error={props.error}
			helperText={props.helperText}
			InputLabelProps={{ shrink: true }}
			onBlur={props.onBlur}
		>
			{provinces.map((option) => (
				<option key={option.value} value={option.value}>
					{option.label}
				</option>
			))}
		</TextField>
	);
}
export function SelectCountry(props: any) {
	return (
		<TextField
			fullWidth
			margin={props.margin}
			label="Country"
			name="country"
			onChange={props.onChange}
			select
			SelectProps={{ native: true }}
			value={props.value}
			variant="outlined"
			color="secondary"
			error={props.error}
			helperText={props.helperText}
			InputLabelProps={{ shrink: true }}
			onBlur={props.onBlur}
		>
			{countries.map((option) => (
				<option key={option.name} value={option.value}>
					{option.name}
				</option>
			))}
		</TextField>
	);
}

export function RoutingNumber(props: any) {
	return (
		<TextField
			className={props.className}
			InputLabelProps={{ shrink: true }}
			margin="normal"
			variant="outlined"
			fullWidth={props.fullWidth}
			autoComplete="new-password"
			label={"Routing Number"}
			name="routing"
			onChange={props.onChange}
			color="secondary"
			value={props.value}
			error={props.error ? true : false}
			helperText={props.helperText}
		/>
	);
}
export function AccountNumber(props: any) {
	return (
		<TextField
			className={props.className}
			InputLabelProps={{ shrink: true }}
			margin={props.margin}
			variant="outlined"
			label={"Bank Account Number"}
			name="accountNumber"
			fullWidth={props.fullWidth}
			onChange={props.onChange}
			inputProps={{ maxLength: 17 }}
			color="secondary"
			value={props.value}
			error={props.error ? true : false}
			helperText={props.helperText}
		/>
	);
}

export function AccountType(props: any) {
	return (
		<TextField
			InputLabelProps={{ shrink: true }}
			label="Account Type"
			fullWidth={props.fullWidth}
			className={props.className}
			select
			sx={props.sx}
			variant="outlined"
			SelectProps={{ native: true }}
			value={props.value}
			error={props.error ? true : false}
			helperText={props.helperText}
			onChange={props.onChange}
			inputProps={{
				name: "accountTypeFull",
				id: "outlined-age-native-simple",
			}}
		>
			<option value="">Select One</option>
			<option value={"Checking"}>Checking</option>
			<option value={"Saving"}>Savings</option>
		</TextField>
	);
}
export function TextInput(props: any) {
	return (
		<TextField
			fullWidth={props.fullWidth}
			required={props.required}
			className={props.className}
			InputLabelProps={{ shrink: true }}
			margin={props.margin}
			disabled={props.disabled}
			autoFocus={props.autoFocus}
			autoComplete={props.autoComplete}
			variant={props.variant}
			color={props.color}
			size="medium"
			multiline={props.multiline}
			rows={props.rows}
			style={props.style}
			onBlur={props.onBlur}
			onFocus={props.onFocus}
			type={props.type}
			inputProps={props.inputProps}
			placeholder={props.placeholder}
			label={props.label}
			name={props.name}
			onChange={props.onChange}
			value={props.value}
			error={props.error ? true : false}
			helperText={props.helperText}
		/>
	);
}

export function PaymentType(props: any) {
	return (
		<FormControl margin="normal" variant="outlined">
			<InputLabel htmlFor="outlined-age-native-simple">
				Payment Method
			</InputLabel>
			<Select
				label="Payment MEthod"
				native
				value={props.value}
				onChange={props.onChange}
				inputProps={{
					name: "paymentMethod",
					id: "outlined-age-native-simple",
					padding: 5,
				}}
			>
				<option value="CreditCard">Credit/Debit Card</option>
				<option value="ach">Bank Account</option>
			</Select>
		</FormControl>
	);
}

import React from "react";
import ImageUploading from "react-images-uploading";
import { Button, Grid, Container } from "@mui/material";

export default function ImageUploader(props: any) {
	const [images, setImages] = React.useState([]);
	const { userConfig, handleClose, uploadProfileImage } = props;
	const maxNumber = 1;
	let profileImage: any;
	const onChange = (imageList: any) => {
		setImages(imageList);
	};
	const handleSubmitImage = () => {
		profileImage = images[0];
		profileImage = profileImage.img;
		profileImage = profileImage.substring(profileImage.indexOf(",") + 1);
		const config = {
			headers: { Authorization: `Bearer ${userConfig.token}` },
			ClientId: userConfig.clientId,
			Base64Image: profileImage,
			ImageExtension: "png",
			BusinessAlias: userConfig.businessAlias,
			PartnerApiKey: userConfig.partnerApiKey,
		};
		uploadProfileImage(config);
		handleClose();
	};
	const handleImage = (onImageUpload: any, onImageUpdate: any) => {
		if (!images) {
			onImageUpload();
		} else {
			setImages([]);
			onImageUpdate();
		}
	};
	return (
		<>
			<ImageUploading
				multiple
				value={images}
				onChange={onChange}
				maxNumber={maxNumber}
				dataURLKey="img"
			>
				{({
					imageList,
					onImageUpload,
					onImageRemoveAll,
					onImageUpdate,
					onImageRemove,
					isDragging,
					dragProps,
				}) => (
					<Container sx={{ p: "0 !important", width: "100%" }}>
						<Grid container>
							{images.length === 0 ? (
								<img src={props.profileImage} alt="" width="100%" />
							) : null}
						</Grid>
						{imageList.length < 1 ? (
							<Grid container justifyContent="center" spacing={2} sx={{ p: 2 }}>
								<Grid item xs={12} sm={6}>
									<Button
										fullWidth
										variant="outlined"
										onClick={() => props.deleteProfileImage(props.config)}
									>
										Delete
									</Button>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Button
										fullWidth
										variant="contained"
										color="primary"
										style={isDragging ? { color: "red" } : undefined}
										onClick={() => handleImage(onImageUpload, onImageUpdate)}
										{...dragProps}
									>
										Upload
									</Button>
								</Grid>
							</Grid>
						) : null}
						{imageList.map((image, index) => (
							<div key={index} className="image-item">
								<Grid container justifyContent="center">
									<img src={image["img"]} alt="" width="100%" />
								</Grid>
								<Grid container justifyContent="center" style={{ padding: 5 }}>
									<Grid item xs={12} sm={6} style={{ padding: 10 }}>
										<Button
											fullWidth
											variant="outlined"
											onClick={() => onImageRemove(index)}
										>
											Cancel
										</Button>
									</Grid>
									<Grid item xs={12} sm={6} style={{ padding: 10 }}>
										<Button
											fullWidth
											variant="contained"
											color="primary"
											onClick={() => handleSubmitImage()}
										>
											Submit
										</Button>
									</Grid>
								</Grid>
							</div>
						))}
					</Container>
				)}
			</ImageUploading>
		</>
	);
}

import React from "react";
import {
	FormControl,
	Select,
	MenuItem,
	ListItemText,
	InputLabel,
	Button,
	Dialog,
} from "@mui/material";
import ManageUsers from "../../containers/ManageUsers";
import styles from "../../styles";
import ButtonLoader from "../../utils/ButtonLoader";

const classes: any = {
	formControl: {
		width: "160px",
		padding: 0,
	},
	menuItemRoot: {
		"&$menuItemSelected, &$menuItemSelected:focus, &$menuItemSelected:hover": {
			backgroundColor: "secondary.main",
		},
	},
	menuItemSelected: {},
};
export default function PurchaseFor(props: any) {
	const {
		userInfo,
		config,
		id,
		cart,
		userConfig,
		purchaseForUsers,
		userMessages,
		userErrors,
		index,
	} = props;
	const [purchaseFor, setPurchaseFor] = React.useState<string[]>(id);
	const [open, setOpen] = React.useState(0);

	React.useEffect(() => {
		props.getRelatedUsers(config);
	}, []);

	const handleChange = (event: any) => {
		if (event.target.value !== "none") {
			setPurchaseFor(event.target.value);
			let config = props.config;
			let items: any = cart;
			items[index].purchaseForId = event.target.value;
			config.cartItems = items;
			config.clientId = userConfig?.clientId;
			props.saveCart(config);
			setTimeout(() => {
				props.clearMessage();
			}, 2000);
			props.setCartItems(items);
		}
	};
	return (
		<>
			{purchaseForUsers ? (
				<FormControl variant="outlined" key={id} sx={classes.formControl}>
					<InputLabel htmlFor="select">Purchase For</InputLabel>
					<Select
						fullWidth
						size="small"
						value={purchaseFor}
						label="Purchase For"
						sx={{ padding: 0 }}
						onChange={handleChange}
						inputProps={{
							id: userInfo?.id,
							padding: 0,
							width: 120,
						}}
						MenuProps={{
							variant: "menu",
							MenuListProps: {
								disablePadding: true,
							},
						}}
						SelectDisplayProps={{ style: { padding: 0 } }}
						defaultValue={userInfo?.id}
					>
						{purchaseForUsers?.map((user: any) => (
							<MenuItem
								classes={{
									root: classes.menuItemRoot,
									selected: classes.menuItemSelected,
								}}
								disableGutters
								key={user.id}
								value={user.id}
							>
								<ListItemText
									style={{
										paddingLeft: 10,
										width: 120,
										overflow: "hidden",
										whiteSpace: "nowrap",
									}}
									primary={user.fullName}
								/>
							</MenuItem>
						))}
						<MenuItem key={99} value={"none"}>
							<Button
								fullWidth
								onClick={() => setOpen(99)}
								color="primary"
								variant="outlined"
							>
								Manage Users
							</Button>
						</MenuItem>
					</Select>
				</FormControl>
			) : (
				<ButtonLoader />
			)}

			<Dialog
				fullWidth
				maxWidth="sm"
				open={open === 99}
				onClose={() => setOpen(0)}
				sx={styles.modal}
			>
				<ManageUsers
					{...props}
					config={config}
					messages={userMessages}
					errors={userErrors}
					onClose={() => setOpen(0)}
				/>
			</Dialog>
		</>
	);
}

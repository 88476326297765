import axios from "axios";
import * as URL from "../config";

export function loginAttempt(action: any) {
	const loginUrl = `${URL.MP_AUTH}/user/login`;
	return axios
		.post(loginUrl, action, { timeout: 10000 })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function registerUser(action: any) {
	const registerUrl = `${URL.MP_MEMBER}/clients`;
	return axios
		.post(registerUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function getQuestionnaires(action: any) {
	const questionnaireUrl = `${URL.MP_MEMBER}/questionnaire?businessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}&isSignup=true`;
	return axios
		.get(questionnaireUrl)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function submitQuestionnaire(action: any) {
	const submitUrl = `${URL.MP_MEMBER}/questionnaire/assignment`;
	return axios
		.post(submitUrl, action.value)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function updateQuestionnaire(action: any) {
	const submitUrl = `${URL.MP_MEMBER}/questionnaire/assignment`;
	return axios
		.put(submitUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function validateUserName(action: any) {
	const userNameUrl = `${URL.MP_AUTH}/user?UserName=${action.value.username}&PartnerApiKey=${action.value.partnerApiKey}&BusinessAlias=${action.value.businessAlias}`;
	return axios
		.get(userNameUrl)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestClubLogo(action: any) {
	const clubLogoUrl = `${URL.MP_MEMBER}/clubs/logopath?businessAlias=${action.businessAlias}&partnerApiKey=${action.partnerApiKey}`;
	return axios
		.get(clubLogoUrl)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestCheckAlias(action: any) {
	const checkAliasUrl = `${URL.MP_MEMBER}/clubs/exists?businessAlias=${action.businessAlias}&partnerApiKey=${action.partnerApiKey}`;
	return axios
		.get(checkAliasUrl)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

import { connect } from "react-redux";
import Enroll from "../components/Enroll/Enroll";

const mapStateToProps = (state: any) => {
	return {
		userConfig: state.auth.userConfig,
		billingProfiles: state.billingProfile.billingProfiles,
		messages: state.billingProfile.messages,
		userMessages: state.client.messages,
		userErrors: state.client.errors,
		errors: state.billingProfile.errors,
		userInfo: state.client.user,
		clubData: state.billingProfile.clubData,
		requesting: state.billingProfile.requesting,
		successful: state.billingProfile.successful,
		enrollRequesting: state.enroll.enrollRequesting,
		enrollSuccessful: state.enroll.enrollSuccessful,
		users: state.client.users,
		userTaken: state.auth.userTaken,
		credits: state.client.credits,
		paymentMessage: state.enroll.messages,
		paymentError: state.enroll.errors,
		paymentRequesting: state.enroll.requesting,
		paymentSuccessful: state.enroll.successful,
		enrollResponse: state.enroll.membershipResponse,
		invoicePdf: state.invoices.invoicePdf,
		pdfRequesting: state.invoices.pdfRequesting,
		pdfSuccessful: state.invoices.pdfSuccessful,
		pdfError: state.invoices.pdfError,
		memberships: state.enroll.memberships,
		membershipDetails: state.enroll.membershipDetails,
		membershipTerms: state.enroll.membershipTerms,
		purchaseForUsers: state.client.purchaseForUsers,
		loading: state.enroll.requesting,
		defaultId: state.billingProfile.defaultId,
		loadError: state.enroll.loadError,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getEnrollMemberships: (config: any) =>
			dispatch({ type: "GET_ENROLL_MEMBERSHIPS_REQUEST", value: config }),
		getEnrollMembershipDetails: (config: any) =>
			dispatch({
				type: "GET_ENROLL_MEMBERSHIP_DETAILS_REQUEST",
				value: config,
			}),
		getEnrollMembershipTerms: (config: any) =>
			dispatch({
				type: "GET_ENROLL_MEMBERSHIP_TERMS_REQUEST",
				value: config,
			}),
		getRelatedUsers: (config: any) =>
			dispatch({
				type: "GET_RELATED_USERS_REQUEST",
				value: config,
			}),
		enrollMembership: (config: any) =>
			dispatch({
				type: "ENROLL_MEMBERSHIP_REQUEST",
				value: config,
			}),
		getProfileInfo: (config: any) =>
			dispatch({ type: "PROFILE_INFO_REQUEST", value: config }),
		getAcceptedCards: (config: any) =>
			dispatch({ type: "ACCEPTED_CARDS_REQUEST", value: config }),
		getBillingProfile: (config: any) =>
			dispatch({ type: "BILLING_PROFILE_REQUEST", value: config }),
		updateBillingProfile: (config: any) =>
			dispatch({ type: "UPDATE_BILLING_PROFILE_REQUEST", value: config }),
		createBillingProfile: (config: any) =>
			dispatch({ type: "CREATE_BILLING_PROFILE_REQUEST", value: config }),
		deleteBillingProfile: (config: any) =>
			dispatch({ type: "DELETE_BILLING_PROFILE_REQUEST", value: config }),
		defaultBillingProfile: (config: any) =>
			dispatch({ type: "DEFAULT_BILLING_PROFILE_REQUEST", value: config }),
		updateProfileInfo: (config: any) =>
			dispatch({ type: "PROFILE_UPDATE_REQUEST", value: config }),
		clearErrors: () => dispatch({ type: "CLEAR_ERRORS" }),
		getUsers: (config: any) =>
			dispatch({ type: "GET_USERS_REQUEST", value: config }),
		getCredits: (config: any) =>
			dispatch({ type: "GET_CREDITS_REQUEST", value: config }),
		createUser: (config: any) =>
			dispatch({ type: "CREATE_USER_REQUEST", value: config }),
		updateUser: (config: any) =>
			dispatch({ type: "UPDATE_USER_REQUEST", value: config }),
		checkUserName: (action: any) =>
			dispatch({ type: "CHECK_USERNAME", value: action }),
		clearUserNameError: () => dispatch({ type: "CLEAR_USERNAME_ERROR" }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Enroll);

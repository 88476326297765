import React from "react";
import {
	Card,
	CardContent,
	CssBaseline,
	Grid,
	Typography,
	Container,
	AppBar,
	Toolbar,
	FormControl,
	MenuItem,
	InputLabel,
	Select,
	Dialog,
	InputBase,
	InputAdornment,
	IconButton,
	Tooltip,
	Button
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import ItemInfo from "./ItemInfo";
import Notifications from "../AlertMessage/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import placeholder from "../../media/placeholder3.jpg";
import { formatCurrency } from "../../utils/FormatCurrency";
import styles from "../../styles";
import Image from "../../utils/Image";
import { MetroSpinner } from "react-spinners-kit";
import { blue } from "../../theme";

const classes: any = {
	"@global": {
		ul: {
			margin: 0,
			padding: 0,
			listStyle: "none",
		},
	},
	appBar: {
		justifyContent: "space-between",
		display: "flex",
		top: 55,
		bottom: "auto",
		height: "55px",
		zIndex: 50,
	},
	storeContainer: {
		mb: 2,
		height: "100%",
		pt: { xs: 10, md: 8 },
		mt: { xs: 8, md: 10 },
		pb: { xs: 4, md: 0 },
		pl: { xs: 1, md: 8.5 },
		maxWidth: { xs: 370, sm: 557, md: 885, lg: 1150 },
	},
	media: {
		height: 200,
		padding: 0,
	},
	categories: {
		width: 220,
		display: "flex",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: { xs: 2.5, sm: 2 },
	},
	margin: {
		color: "white",
		marginLeft: { xs: 0, md: 5.5 },
	},
	searchBar: {
		margin: 1,
		width: 230,
		minWidth: 200,
		backgroundColor: "#FAFAFA",
		borderRadius: 1.5,
		zIndex: 1000,
		height: 40,
		display: "flex",
		boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
		transition: "width .3s",
		"&:hover": {
			borderBottom: "none",
		},
	},
	searchFocused: {
		transition: "width .3s",
		width: 290,
	},
	mobileHidden: {
		justifyContent: "center",
		paddingLeft: { xs: 5.5, sm: 0 },
		display: { xs: "none", sm: "flex" },
	},
	desktopHidden: {
		display: { xs: "flex", sm: "none" },
		paddingLeft: { xs: 2, sm: 5.5 },
	},
	card: {
		height: 220,
		width: 245,
		color: "primary.main",
	},
	searchGrid: {
		pb: 3,
		ml: 2.5,
	},
};

export default function Enroll(props: any) {
	const [category, setCategory] = React.useState("All");
	const [error, setError] = React.useState<any>("");
	const [open, setOpen] = React.useState(0);
	const [search, setSearch] = React.useState("");
	const [results, setResults] = React.useState<any>();
	const [filterDisplay, setFilterDisplay] = React.useState<any>([]);
	const [itemsPerPage] = React.useState(12);
	const [noOfPages, setNoOfPages] = React.useState(0);
	const [page, setPage] = React.useState(1);
	const {
		userConfig,
		messages,
		errors,
		paymentMessage,
		paymentError,
		userMessages,
		userErrors,
		getEnrollMemberships,
		memberships,
		clubData,
		acceptedCards,
	} = props;
	let items: any = props.memberships;

	let config: any = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		id: userConfig ? userConfig.clientId : null,
	};
	React.useEffect(() => {
		if (paymentError) {
			setError("There was an error loading the store");
		}
	}, [paymentError]);
	React.useEffect(() => {
		if (results) {
			setNoOfPages(Math.ceil(results.length / itemsPerPage));
		}
	}, [results]);
	React.useEffect(() => {
		if (userConfig) {
			getEnrollMemberships(config);
		}
	}, [userConfig]);
	React.useEffect(() => {
		let filteredResults: any = items?.filter((item: any) => {
			if (category === "All") {
				return item;
			} else {
				if (item.categoryName === category) {
					return item;
				}
			}
		});
		if (search?.length > 0) {
			let sResults: any = searchResults();
			setResults(sResults);
		} else {
			setResults(filteredResults);
		}
	}, [search, category]);
	React.useEffect(() => {
		let sortedMemberships: any = memberships?.sort((a: any, b: any) =>
			a.name.localeCompare(b.name)
		);
		setResults(sortedMemberships);
	}, [props.memberships]);
	const handlePageChange = (event: any, value: any) => {
		setPage(value);
	};
	const handleCategoryChange = (event: SelectChangeEvent) => {
		setCategory(event.target.value as string);
	};
	const handleSearch = (e: any) => {
		setSearch(e.target.value);
		let oldList: any = items?.map((item: any) => {
			return item.name.toLowerCase();
		});
		if (e.target.value !== "") {
			let newList: any = [];
			newList = oldList.filter((item: any) => {
				return item.includes(e.target.value.toLowerCase());
			});
			setFilterDisplay(newList);
		} else {
			setFilterDisplay(items);
		}
	};
	const handleClick = (event: any, id: any) => {
		event.preventDefault();
		setOpen(id);
	};
	const handleClose = () => {
		setOpen(0);
	};
	let searchResults = () => {
		let results: any = [];
		let filteredResults: any = items?.filter((item: any) => {
			if (category === "All") {
				return item;
			} else {
				if (item.categoryName === category) {
					return item;
				}
			}
		});
		filteredResults?.map((item: any) => {
			if (filterDisplay?.includes(item?.name?.toLowerCase())) {
				results.push(item);
			}
		});
		return results;
	};
	const defaultImage: any = placeholder;
	let categories = clubData?.membershipCategories;
	const renderCategories = (
		<FormControl
			sx={classes.categories}
			margin="dense"
			variant="outlined"
			size="small"
		>
			<InputLabel shrink={true} htmlFor="outlined-age-native-simple">
				Categories
			</InputLabel>
			<Select
				label="Categories"
				value={category}
				onChange={handleCategoryChange}
				inputProps={{
					name: "paymentMethod",
					id: "outlined-age-native-simple",
					padding: 5,
				}}
			>
				<MenuItem value={"All"}>All</MenuItem>
				{categories
					? categories
							.sort((a: any, b: any) => {
								return a === b ? 0 : a < b ? -1 : 1;
							})
							.map((category: any, index: any) => (
								<MenuItem key={index} value={category}>
									{category}
								</MenuItem>
							))
					: null}
			</Select>
		</FormControl>
	);

	return (
		<React.Fragment>
			<CssBaseline />
			{paymentMessage || paymentError || errors || messages ? (
				<Notifications
					messages={paymentMessage || messages}
					errors={paymentError || errors}
				/>
			) : null}
			<AppBar sx={classes.appBar} position="fixed">
				<Toolbar
					disableGutters={true}
					sx={classes.margin}
					style={{ minHeight: 55 }}
				>
					<Grid
						container
						justifyContent="space-between"
						alignItems="center"
						alignContent="center"
						style={{ height: 55 }}
					>
						<Grid item xs={4}>
							<Typography
								sx={classes.desktopHidden}
								component="h3"
								variant="h5"
								align="center"
							>
								Enroll
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography
								sx={classes.mobileHidden}
								component="h3"
								variant="h5"
								align="center"
							>
								Enroll
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Grid container justifyContent="flex-end">
								<InputBase
									sx={classes.searchBar}
									classes={{ focused: classes.searchFocused }}
									placeholder="Search..."
									startAdornment={
										<InputAdornment position="start" sx={{ pl: 1.5 }}>
											<SearchIcon style={{ fill: "grey" }} />
										</InputAdornment>
									}
									endAdornment={
										<InputAdornment position="start">
											{search ? (
												<IconButton
													onClick={() => {
														setSearch("");
													}}
													size="large"
												>
													<CloseIcon
														style={{
															fill: "grey",
														}}
													/>
												</IconButton>
											) : null}
										</InputAdornment>
									}
									value={search}
									onChange={(e: any) => handleSearch(e)}
									inputProps={{ pl: 1.5 }}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			{userMessages || userErrors ? (
				<Notifications messages={userMessages} errors={userErrors} />
			) : null}
			<Container sx={classes.storeContainer} component="main">
				<Grid
					container
					justifyContent="center"
					spacing={4}
					style={{ height: "100%" }}
				>
					<Grid
						container
						spacing={3}
						alignItems="flex-start"
						justifyContent="center"
						sx={classes.searchGrid}
					>
						<Grid item xs={12} style={{ paddingBottom: 0 }}>
							{renderCategories}
						</Grid>
						{results?.length > 0 && props.enrollSuccessful
							? results
									?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
									?.map((item: any, index: any) => (
										<React.Fragment key={index}>
											<Grid
												onClick={(event: any) =>
													handleClick(event, item.productId)
												}
												item
												key={item.productId}
												xs={9}
												sm={6}
												md={4}
												lg={3}
											>
												<Card raised sx={classes.card}>
													<CardContent
														sx={{
															cursor: "pointer",
															padding: 0,
															position: "relative",
															height: 184,
															width: 245,
															backgroundColor: item.imageUrl
																? "white"
																: "#E9EEF1",
														}}
													>
														<Image
															src={item.imageUrl ? item.imageUrl : defaultImage}
															alt={item.name}
														/>
														<Typography
															color="secondary"
															sx={{
																fontWeight: 600,
																fontSize: 16,
																position: "absolute",
																top: "0px",
																right: "0px",
																color: "secondary.main",
															}}
														>
															{item.proratedAmount > 0 ? (
																<Typography
																color="secondary"
																sx={{
																	padding: 1,
																	pt: 1.2,
																	fontWeight: 600,
																	fontSize: 16,
																	textAlign: "right",
																}}
															>
																	<div
																		style={{
																			textDecoration: "line-through",
																			color: "red",
																			marginRight: 5,
																		}}
																	>
																		{formatCurrency(item.amount, clubData)}
																	</div>

																	<div
																		style={{
																			backgroundColor: "white",
																			padding: "2px 4px 0px",
																			borderRadius: "5px",
																			fontWeight: 600,
																		}}
																	>
																		{formatCurrency(item.proratedAmount, clubData)}
																		{item.isTieredPricing && <Tooltip arrow placement="top" title="Tiered Pricing Contract">
																		<Button style={{ padding: 2, minHeight: 0, minWidth: 5, marginTop: -3}}>T</Button>
																		</Tooltip>}
																	</div>
																</Typography>
															) : (
																<Typography
																	color="secondary"
																	sx={{
																		padding: 1,
																		pt: 1.2,
																		fontWeight: 600,
																		fontSize: 16,
																		textAlign: "right",
																	}}
																>
																	<span
																		style={{
																			backgroundColor: "white",
																			padding: 5,
																			paddingRight: item.isTiredPricing ? 0 : 5,
																			borderRadius: 5,
																		}}
																	>
																		{formatCurrency(item.amount, clubData)}
																		{item.isTieredPricing && <Tooltip arrow placement="top" title="Tiered Pricing Contract">
																		<Button style={{ padding: 2, minHeight: 0, minWidth: 5, marginTop: -3}}>T</Button>
																		</Tooltip>}
																	</span>
																</Typography>
															)}
														</Typography>
													</CardContent>

													<Typography sx={{ padding: 0.9 }} align="center">
														{item.name}
													</Typography>
												</Card>
												{results.length < 9 ? <Grid item /> : null}
											</Grid>

											<Dialog
												fullWidth
												maxWidth="sm"
												sx={styles.modal}
												open={open === item.productId}
												onClose={handleClose}
											>
												<ItemInfo
													{...props}
													config={config}
													membership={item}
													defaultImage={defaultImage}
													additionalItems={item.additionalItems}
													acceptedCards={clubData.validCCTypes || acceptedCards}
													onClose={handleClose}
												/>
											</Dialog>
										</React.Fragment>
									))
							: null}
						{props.enrollSuccessful && results?.length === 0 && (
							<Grid container justifyContent="center">
								<Typography style={{ marginTop: 50 }} align="center">
									There are no results
								</Typography>
							</Grid>
						)}
						{props.enrollRequesting && (
							<Grid container justifyContent="center" sx={{ mt: 10, pl: 0 }}>
								<MetroSpinner size={50} color={blue} />
							</Grid>
						)}

						{/* {error && (
							<Grid container justifyContent="center">
								<Typography style={{ marginTop: 50 }} align="center">
									{error}
								</Typography>
							</Grid>
						)} */}
					</Grid>
					{props.loadError && (
						<Grid
							container
							justifyContent="center"
							sx={[styles.modal, { mt: 7 }]}
						>
							<Typography>There was an error loading Enroll</Typography>
						</Grid>
					)}
					{noOfPages < 2 ||
					results?.length === 0 ||
					props.enrollRequesting ? null : (
						<div
							style={{
								padding: 10,
								paddingLeft: 55,
								paddingTop: 0,
								marginLeft: "auto",
								marginRight: "auto",
							}}
						>
							<Pagination
								showFirstButton
								showLastButton
								page={page}
								onChange={handlePageChange}
								count={noOfPages}
								color="secondary"
							/>
						</div>
					)}
				</Grid>
			</Container>
		</React.Fragment>
	);
}

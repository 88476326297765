import React from "react";
import { withStyles } from "@mui/styles";
import {
	Table,
	FormControlLabel,
	Checkbox,
	Button,
	Grid,
	TableBody,
	TableCell,
	TableContainer,
	Typography,
	TableRow,
	DialogContent,
	DialogContentText,
	DialogActions,
	DialogTitle,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ResponsiveTable from "./ResponsiveTable";

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
		width: "100%",
	},
}))(TableRow);

const classes: any = {
	header: {
		backgroundColor: "primary.main",
		color: "common.white",
	},
};

export default function Recurrence(props: any) {
	const { userConfig } = props;
	const [selected, setSelected] = React.useState<any>([]);
	const [schedule, setSchedule] = React.useState<any>([]);
	const matches = useMediaQuery("(min-width:600px)");

	let config: any = {
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		clientId: userConfig ? userConfig.clientId : null,
		scheduleId: props.scheduleId,
	};
	React.useEffect(() => {
		props.getRecurringSchedule(config);
	}, []);
	React.useEffect(() => {
		setSchedule(props.recurringSchedule);
	}, [props.recurringSchedule]);
	const isSelected = (name: string) => selected.indexOf(name) !== -1;
	const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};
	const setRecurrence = () => {
		console.log("set recurrence");
	};
	return (
		<>
			{matches ? (
				<>
					<DialogTitle
						sx={[
							classes.header,
							{ justifyContent: "space-between", p: 2, px: 5 },
						]}
					>
						<Grid container direction="row" justifyContent="space-between">
							<Typography align="left">Name</Typography>
							<Typography align="right">Date</Typography>
							<Typography align="right">Time</Typography>
							<Typography align="right">Staff</Typography>
							<Typography align="left">Location</Typography>
						</Grid>
					</DialogTitle>
					<TableContainer style={{ overflowX: "hidden" }}>
						<Table aria-label="customized table">
							<DialogContent sx={{ p: 0 }}>
								<DialogContentText>
									<TableBody>
										{schedule?.map((row: any, index: any) => {
											const isItemSelected = isSelected(index);
											return (
												<StyledTableRow key={index}>
													<TableCell style={{ maxWidth: 20 }} align="left">
														{" "}
														<FormControlLabel
															control={
																<Checkbox
																	onClick={(event: any) =>
																		handleClick(row.scheduleId, index)
																	}
																	checked={isItemSelected}
																	color="primary"
																/>
															}
															label=""
														/>
													</TableCell>
													<TableCell align="left">{row.className}</TableCell>
													<TableCell align="right" sx={{ ml: 6 }}>
														{new Date(
															row.eventDate + row.startTime
														).toLocaleDateString()}
													</TableCell>
													<TableCell align="right">{`${row.scheduleStartTime} - ${row.scheduleEndTime} `}</TableCell>
													<TableCell align="right">{row.instructor}</TableCell>
													<TableCell align="center">{row.location}</TableCell>
												</StyledTableRow>
											);
										})}
									</TableBody>
								</DialogContentText>
							</DialogContent>
						</Table>
					</TableContainer>
				</>
			) : (
				<DialogContent sx={{ p: 0 }}>
					<DialogContentText sx={{ pt: 1, mt: 1 }}>
						<ResponsiveTable rows={schedule} />
					</DialogContentText>
				</DialogContent>
			)}
			<DialogActions>
				<Grid container justifyContent="center" spacing={2} sx={{ p: 2 }}>
					<Grid item xs={12} sm={6}>
						<Button
							onClick={() => props.setOpen(0)}
							fullWidth
							color="primary"
							variant="outlined"
						>
							Back
						</Button>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Button
							onClick={setRecurrence}
							fullWidth
							color="primary"
							variant="contained"
						>
							Select
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</>
	);
}

import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as constants from "../constants/dashboard.constants";
import {
	requestGetDashboardData,
	requestGetDashboardAlerts,
} from "../services/dashboard.service";

export interface ResponseGenerator {
	config?: any;
	data?: any;
	headers?: any;
	request?: any;
	status?: number;
	statusText?: string;
}

export function* getDashboardAlerts(action: any) {
	try {
		let response: ResponseGenerator = yield call(
			requestGetDashboardAlerts,
			action.value
		);
		if (response) {
			yield put({ type: constants.GET_DASHBOARD_ALERTS_SUCCESS, response });
		}
	} catch (error) {
		console.log("error saga", error);
		yield put({ type: constants.GET_DASHBOARD_ALERTS_ERROR, error });
	}
}
export function* getDashboardData(action: any) {
	try {
		let response: ResponseGenerator = yield call(
			requestGetDashboardData,
			action.value
		);
		if (response) {
			yield put({ type: constants.GET_DASHBOARD_DATA_SUCCESS, response });
		}
	} catch (error) {
		console.log("error saga", error);
		yield put({ type: constants.GET_DASHBOARD_DATA_ERROR, error });
	}
}
export function* watchDashboardRequest() {
	yield takeLatest(constants.GET_DASHBOARD_ALERTS_REQUEST, getDashboardAlerts);
	yield takeLatest(constants.GET_DASHBOARD_DATA_REQUEST, getDashboardData);
}

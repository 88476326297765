import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
	Button,
	Grid,
	Hidden,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grow,
	Card,
	Dialog,
	useMediaQuery,
	Pagination,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MobileRecurring from "./MobileRecurring";
import { ChangePaymentDialog } from "./ChangePaymentDialog";
import { formatCurrency } from "../../../utils/FormatCurrency";
import styles from "../../../styles";

interface Data {
	productName: string;
	remainingPayments: string;
	amount: number;
	dueDate: string;
	paymentMethodId: string;
	actions: string;
	paymentMethod: string;
	id: string;
}

function createData(
	productName: string,
	remainingPayments: string,
	amount: number,
	dueDate: string,
	paymentMethodId: string,
	actions: string,
	paymentMethod: string,
	id: string
): Data {
	return {
		productName,
		remainingPayments,
		amount,
		dueDate,
		paymentMethodId,
		actions,
		paymentMethod,
		id,
	};
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key
): (
	a: { [key in Key]: number | string },
	b: { [key in Key]: number | string }
) => number {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis?.map((el) => el[0]);
}

interface HeadCell {
	disablePadding: boolean;
	id: keyof Data;
	label: string;
	numeric: boolean;
}

const headCells: HeadCell[] = [
	{
		id: "productName",
		numeric: false,
		disablePadding: true,
		label: "Item",
	},
	{
		id: "remainingPayments",
		numeric: true,
		disablePadding: true,
		label: "Remaining",
	},
	{
		id: "amount",
		numeric: true,
		disablePadding: true,
		label: "Amount",
	},
	{
		id: "dueDate",
		numeric: true,
		disablePadding: true,
		label: "Due Next",
	},
	{
		id: "paymentMethodId",
		numeric: true,
		disablePadding: true,
		label: "Payment Method",
	},
	{ id: "actions", numeric: true, disablePadding: true, label: "" },
];

interface EnhancedTableProps {
	classes: ReturnType<typeof classes>;
	onRequestSort: (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler =
		(property: keyof Data) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

	return (
		<TableHead sx={classes.tableHead}>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={"left"}
						padding={"none"}
						sx={{
							paddingBottom: 1.2,
							paddingTop: 0.8,
							paddingLeft: 1.7,
							fontFamily: "Roboto Condensed, sans-serif",
						}}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						{headCell.id === "actions" ? null : (
							<TableSortLabel
								sx={{ color: "white !important" }}
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
							</TableSortLabel>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

const classes: any = {
	table: {
		minWidth: 500,
	},
	tableHead: {
		backgroundColor: "text.disabled",
	},
	tableWidth: {
		width: { xs: 320, sm: "100%" },
	},
	tableRowRoot: {
		"&:nth-of-type(odd)": {
			backgroundColor: "#DCDCDC",
		},
	},
	header: {
		backgroundColor: "primary.main",
		color: "white",
		borderTopLeftRadius: "5px",
		borderTopRightRadius: "5px",
		fontSize: 22,
		lineHeight: 1,
		margin: 0,
	},
};

export default function RecurringPayments(props: any) {
	const { billingProfiles, recurring, userConfig, getDashboardData } = props;
	const [expand, setExpand] = React.useState(false);
	const [order, setOrder] = React.useState<Order>("asc");
	const [orderBy, setOrderBy] = React.useState<keyof Data>("dueDate");
	const [paymentMethod, setPaymentMethod] = React.useState<string>("");
	const [open, setOpen] = React.useState(-1);
	const [page, setPage] = React.useState(1);
	const [dense] = React.useState(true);
	const [rowsPerPage] = React.useState(5);
	const [noOfPages, setNoOfPages] = React.useState(0);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("sm"));
	const [endDate] = React.useState(moment().add(7, "days"));
	let config = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		id: userConfig ? userConfig.clientId : null,
		startDate: new Date().toISOString().slice(0, 10),
		endDate: endDate.toISOString().slice(0, 10),
	};
	React.useEffect(() => {
		if (recurring) {
			setNoOfPages(
				Math.ceil(recurring?.scheduledPayments?.length / rowsPerPage)
			);
		}
	}, [recurring]);
	React.useEffect(() => {
		setPaymentProfile();
	}, [billingProfiles]);
	React.useEffect(() => {
		if (expand) {
			getDashboardData(config);
		}
	}, [expand]);
	const toggleAccordion = () => {
		setExpand((prev) => !prev);
	};
	const handlePaymentChange = (event: SelectChangeEvent) => {
		setPaymentMethod(event.target.value as string);
	};
	let rows: any = recurring.scheduledPayments ? recurring?.scheduledPayments?.map((row: any) =>
		createData(
			row.productName,
			row.remainingPayments,
			row.amountDue,
			row.dueDate,
			row.paymentMethodId,
			"actions",
			row.paymentMethod,
			row.id
		)
	) : [];
	const setPaymentProfile = () => {
		let isDefault: any = false;
		billingProfiles?.find((profile: any) => {
			if (profile.isDefaultProfile) {
				setPaymentMethod(profile.id);
				isDefault = true;
			}
		});
		if (!isDefault) {
			if (billingProfiles.length > 0) {
				setPaymentMethod(billingProfiles[0].id);
			}
		}
	};
	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChange = (event: any, value: any) => {
		setPage(value);
	};
	const getProfile = (id: any) => {
		let bp = billingProfiles.find((profile: any) => profile.id === id);
		let cardType = bp?.accountTypeFull || bp?.creditCardType || "";

		let renderedType;
		if (bp) {
			renderedType = (
				<>
					{cardType +
						" " +
						bp?.accountNumber?.substr(
							bp?.accountNumber?.length - 4,
							bp?.accountNumber?.length
						)}
				</>
			);
		} else {
			renderedType = "N/A";
		}
		return renderedType;
	};

	return (
		<>
			<Grow in={true} timeout={1250}>
				<Card raised sx={{ mt: 2.5 }}>
					<Accordion expanded={expand} defaultExpanded={false}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							onClick={toggleAccordion}
							sx={classes.header}
						>
							<Typography variant="h5">Recurring Payments</Typography>
						</AccordionSummary>
						<AccordionDetails sx={{ p: 0, borderRadius: 5 }}>
							<Hidden smDown>
								<TableContainer sx={classes.tableWidth}>
									<Table
										sx={classes.table}
										aria-labelledby="tableTitle"
										size={dense ? "small" : "medium"}
										aria-label="enhanced table"
									>
										<EnhancedTableHead
											classes={classes}
											order={order}
											orderBy={orderBy}
											onRequestSort={handleRequestSort}
											rowCount={rows?.length}
										/>

										<TableBody>
											{stableSort(rows, getComparator(order, orderBy))
												?.slice((page - 1) * rowsPerPage, page * rowsPerPage)
												.map((row: any, index: any) => {
													const labelId = `enhanced-table-checkbox-${index}`;
													return (
														<React.Fragment key={index}>
															<TableRow
																sx={classes.tableRowRoot}
																role="checkbox"
																tabIndex={-1}
																key={row.id + index}
															>
																<TableCell
																	component="th"
																	id={labelId}
																	scope="row"
																>
																	{row.productName}
																</TableCell>
																<TableCell align="left">
																	{row.remainingPayments === "2147483647"
																		? "Recurring"
																		: row.remainingPayments}
																</TableCell>
																<TableCell align="left" sx={{ p: 2 }}>
																	{formatCurrency(row.amount, props.clubData)}
																</TableCell>
																<TableCell align="left">
																	{moment(row.dueDate?.slice(0, 10)).format(
																		"MM/DD/YY"
																	)}
																</TableCell>
																<TableCell align="left">
																	{billingProfiles
																		? getProfile(row.paymentMethodId)
																		: null}
																</TableCell>
																<TableCell
																	padding="none"
																	align="right"
																	sx={{ pr: 2 }}
																>
																	<Button
																		variant="outlined"
																		color="primary"
																		fullWidth
																		sx={{ ml: 0, p: 0.5, bgcolor: "#FAFAFA" }}
																		onClick={() => setOpen(index)}
																	>
																		Edit
																	</Button>
																</TableCell>
															</TableRow>
															<Dialog
																maxWidth="sm"
																open={open === index}
																onClose={() => setOpen(-1)}
																fullScreen={matches ? true : false}
																fullWidth={!matches ? true : false}
																sx={styles.modal}
															>
																<ChangePaymentDialog
																	{...props}
																	setOpen={setOpen}
																	payment={row}
																	sortedBillingProfiles={billingProfiles}
																	paymentMethod={paymentMethod}
																	handlePaymentChange={handlePaymentChange}
																	index={index}
																/>
															</Dialog>
														</React.Fragment>
													);
												})}
										</TableBody>
									</Table>

									{noOfPages < 2 || rows.length === 0 ? null : (
										<Grid container justifyContent="center">
											<Grid style={{ padding: 10 }} item>
												<Pagination
													page={page}
													onChange={handleChange}
													count={noOfPages}
													color="secondary"
													boundaryCount={matches ? 0 : 1}
													siblingCount={1}
												/>
											</Grid>
										</Grid>
									)}
								</TableContainer>

								{rows.length === 0 ? (
									<Typography align="center" style={{ padding: 20 }}>
										There are no recurring payments
									</Typography>
								) : null}
							</Hidden>

							<Hidden smUp>
								{rows
									.slice((page - 1) * rowsPerPage, page * rowsPerPage)
									.map((payment: any, index: number) => (
										<MobileRecurring
											key={index}
											payment={payment}
											{...props}
											setOpen={setOpen}
											open={open}
											sortedBillingProfiles={billingProfiles}
											paymentMethod={paymentMethod}
											handlePaymentChange={handlePaymentChange}
											index={index}
											matches={matches}
										/>
									))}

								<Grid container justifyContent="center" sx={{ margin: 0 }}>
									{rows.length === 0 ? (
										<Typography align="center" style={{ padding: 20 }}>
											There are no recurring payments
										</Typography>
									) : null}
								</Grid>
								{noOfPages < 2 || rows.length === 0 ? null : (
									<Grid container justifyContent="center">
										<Grid style={{ padding: 10 }} item>
											<Pagination
												page={page}
												onChange={handleChange}
												count={noOfPages}
												color="secondary"
												boundaryCount={matches ? 0 : 1}
												siblingCount={1}
											/>
										</Grid>
									</Grid>
								)}
							</Hidden>
						</AccordionDetails>
					</Accordion>
				</Card>
			</Grow>
		</>
	);
}

import React from "react";
import { Divider, Grid, Checkbox } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

export default function Recurrence(props: any) {
	const [selected, setSelected] = React.useState<any>([]);
	const handleClick = (event: SelectChangeEvent, name: any) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected: string[] = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};
	return (
		<Grid container>
			{props.rows.map((row: any, index: any) => (
				<React.Fragment key={index}>
					<Grid container sx={{ pl: 2, pr: 2 }}>
						<Grid item xs={2} />
						<Grid item xs={3}>
							Name:
						</Grid>
						<Grid item xs={7}>
							{row.className}
						</Grid>
						<Grid item xs={2} />
						<Grid item xs={3}>
							Date:
						</Grid>
						<Grid item xs={7}>
							{new Date(row.eventDate + row.startTime).toLocaleDateString()}
						</Grid>
						<Grid item xs={2} sx={{ p: 0 }}>
							<Checkbox
								sx={{ height: 2 }}
								onChange={(event: any) => handleClick(event, row.type)}
								checked={selected.includes(row.type)}
								edge="start"
								tabIndex={-1}
								disableRipple
								inputProps={{ "aria-labelledby": "all" }}
							/>
						</Grid>
						<Grid item xs={3}>
							Time:
						</Grid>
						<Grid item xs={6}>
							{`${row.scheduleStartTime} - ${row.scheduleEndTime} `}
						</Grid>
						<Grid item xs={2} />
						<Grid item xs={3}>
							Staff:
						</Grid>

						<Grid item xs={7}>
							{row.instructor}
						</Grid>
						<Grid item xs={2} />
						<Grid item xs={3}>
							Location:
						</Grid>
						<Grid item xs={7} sx={{ pb: 0.5 }}>
							{row.location}
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
					</Grid>
				</React.Fragment>
			))}
		</Grid>
	);
}

import * as React from "react";
import { Typography, Icon, CircularProgress } from "@mui/material";

const classes: any = {
	text: {
		color: "black",
		fontWeight: "normal",
		alignContent: "center",
		paddingTop: "5px",
	},
};
export interface PasswordRequirementsProps {
	isLengthInValid: boolean;
	isContainNumericAndAlphaInValid: boolean;
	isNewUser?: boolean;
	isReUsePrevious4Passwords?: boolean;
	isProgressValidation?: boolean;
}
const PasswordRequirements = (props: PasswordRequirementsProps) => {
	const {
		isLengthInValid,
		isNewUser,
		isContainNumericAndAlphaInValid,
		isReUsePrevious4Passwords,
	} = props;
	return (
		<React.Fragment>
			<Typography sx={classes.text} variant="subtitle2">
				{renderStatus(!isLengthInValid)}
				Minimum length of seven characters
			</Typography>
			<Typography sx={classes.text} variant="subtitle2">
				{renderStatus(!isContainNumericAndAlphaInValid)}
				Contains at least one letter and number
			</Typography>

			{!isNewUser ? (
				<Typography variant="subtitle2" sx={classes.text}>
					{renderStatus(isReUsePrevious4Passwords || false)}
					You cannot re-use previous four passwords
				</Typography>
			) : null}
		</React.Fragment>
	);
};
const renderStatus = (isValid: boolean, isProgress?: boolean) => {
	if (isProgress) {
		return (
			<div
				style={{
					width: "24px",
					height: "20px",
					display: "inline-block",
					verticalAlign: "text-top",
					textAlign: "center",
				}}
			>
				<CircularProgress color="secondary" size={16} />
			</div>
		);
	} else if (isValid) {
		return (
			<Icon color="secondary" style={{ verticalAlign: "bottom" }}>
				check
			</Icon>
		);
	} else {
		return <Icon style={{ verticalAlign: "bottom", color: "red" }}>close</Icon>;
	}
};

export default PasswordRequirements;

import React from "react";
import {
	Button,
	Typography,
	Switch,
	FormControlLabel,
	FormControl,
	FormGroup,
	FormLabel,
	DialogContent,
	DialogActions,
} from "@mui/material";
import Title from "./Title";

export default function CommunicationPreferences(props: any) {
	const { userInfo, userConfig, onClose } = props;
	const [state, setState] = React.useState({
		textAlerts: userInfo?.textAlerts,
		emailAlerts: userInfo?.emailAlerts,
		tcpaEmailAlerts: userInfo?.tcpaEmailAlerts,
	});
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};
	const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({
			...state,
			textAlerts: event.target.checked,
			emailAlerts: event.target.checked,
			tcpaEmailAlerts: event.target.checked,
		});
	};
	const handleSubmit = () => {
		let config: any = {
			headers: {
				Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
			},
			partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
			businessAlias: userConfig ? userConfig.businessAlias : null,
			id: userConfig ? userConfig.clientId : null,
			textAlerts: state.textAlerts,
			emailAlerts: state.emailAlerts,
			tcpaEmailAlerts: state.tcpaEmailAlerts,
		};
		props.updateNotifications(config);
		onClose();
	};
	return (
		<>
			<Title title="Notification Settings" onClose={onClose} />
			<DialogContent sx={{ pb: 0 }}>
				<FormControl component="fieldset">
					<FormLabel component="legend" sx={{ py: 2 }}>
						Change your preferences below:{" "}
					</FormLabel>
					<FormGroup style={{ marginBottom: 20 }}>
						<FormControlLabel
							control={
								<Switch
									checked={
										state.textAlerts &&
										state.emailAlerts &&
										state.tcpaEmailAlerts
											? true
											: false
									}
									onChange={handleChangeAll}
									name="all"
								/>
							}
							label="All"
						/>
						<FormControlLabel
							control={
								<Switch
									checked={state.textAlerts}
									onChange={handleChange}
									name="textAlerts"
								/>
							}
							label={
								<Typography align="left">
									Send promotional, marketing, inspiration updates and account
									updates via text
								</Typography>
							}
						/>
						<FormControlLabel
							control={
								<Switch
									checked={state.emailAlerts}
									onChange={handleChange}
									name="emailAlerts"
								/>
							}
							label={
								<Typography align="left">
									Send account updates via email
								</Typography>
							}
						/>
						<FormControlLabel
							control={
								<Switch
									checked={state.tcpaEmailAlerts}
									onChange={handleChange}
									name="tcpaEmailAlerts"
								/>
							}
							label={
								<Typography align="left">
									Send promotional, marketing, inspiration updates via email
								</Typography>
							}
						/>
					</FormGroup>
				</FormControl>
			</DialogContent>
			<DialogActions sx={{ m: 1 }}>
				<Button
					sx={{ mr: 1 }}
					onClick={onClose}
					fullWidth
					variant="outlined"
					color="primary"
				>
					Cancel
				</Button>
				<Button
					sx={{ ml: 1 }}
					onClick={handleSubmit}
					fullWidth
					variant="contained"
					color="primary"
				>
					Save
				</Button>
			</DialogActions>
		</>
	);
}

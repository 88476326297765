import React from "react";
import {
	DialogContent,
	DialogContentText,
	DialogActions,
	Typography,
	Grid,
	Button,
	Divider,
	Dialog,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import Title from "../../Profile/Dialogs/Title";
import { formatCurrency } from "../../../utils/FormatCurrency";
import ClassOptions from "./ProductOptions";
import styles from "../../../styles";
import PayBooking from "./PayBooking";
import { AddressValidation } from "../../Enroll/AddressPrompt";
import Notifications from "../../AlertMessage/Notifications";

const SelectBooking = (props: any) => {
	const { clubData, relatedClasses, bookFor, userInfo, userConfig } = props;
	const [selected, setSelected] = React.useState<number>(0);
	const [open, setOpen] = React.useState<number>(0);
	const [quantity, setQuantity] = React.useState<number>(0);
	let selectedClass: any = relatedClasses?.find((x: any) => x.id === selected);
	let newSelectedClass: any = {
		id: selectedClass?.id,
		amount: Number(selectedClass?.amount),
		defaultRenewal: selectedClass?.defaultRenewal,
		name: selectedClass?.name,
		price: Number(selectedClass?.price),
		tax: Number(selectedClass?.tax),
	};

	React.useEffect(() => {
		if (!userInfo) {
			props.getProfileInfo(config);
		}
	}, [userInfo]);
	React.useEffect(() => {
		if (
			props.profileMessages ==
			"Your profile information has been successfully updated"
		) {
			props.getProfileInfo(config);
			setOpen(1);
		}
	}, [props.profileMessages]);

	let config: any = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		id: userConfig ? userConfig.clientId : null,
	};
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("sm"));
	const handleSelect = () => {
		if (clubData?.clubType === "FSB ONLY") {
			if (
				!userInfo.address ||
				!userInfo.city ||
				!userInfo.country ||
				!userInfo.state ||
				!userInfo.zip ||
				!userInfo.email ||
				(userInfo?.country && userInfo.country !== "US") ||
				(userInfo?.country && userInfo.country !== "CA") ||
				(!userInfo.cellPhone && !userInfo.homePhone && !userInfo.workPhone)
			) {
				setOpen(2);
			} else {
				setOpen(1);
			}
		} else {
			setOpen(1);
		}
	};
	return (
		<>
			{props.profileMessages ? (
				<Notifications messages={props.profileMessages} hide={false} />
			) : null}
			<Title title="Select Package" onClose={props.handleClose} />
			<DialogContent sx={{ p: 2, pb: 1 }}>
				<DialogContentText>
					<Typography align="center" sx={{ color: "black", m: 1 }}>
						Select the package and quantity you would like to purchase below:
					</Typography>
					<Grid container sx={{ p: 0.5 }}>
						<Grid item xs={5} sm={6}>
							Class Name
						</Grid>
						<Grid item xs={2} sx={{ textAlign: "left" }}>
							Price
						</Grid>
						<Grid
							item
							xs={2}
							sx={{ textAlign: "center", ml: { xs: 1, sm: 0 } }}
						>
							Quantity
						</Grid>
						<Grid item xs={2} sx={{ textAlign: "right", ml: { xs: 2, sm: 0 } }}>
							Select
						</Grid>
					</Grid>
					<Divider sx={{ mb: 1 }} />
					{props.relatedClasses?.map((product: any, index: string) => (
						<ClassOptions
							key={index}
							{...props}
							index={index}
							id={product.id}
							selected={selected}
							setSelected={setSelected}
							setOpen={setOpen}
							product={product}
							quantity={quantity}
							setQuantity={setQuantity}
						/>
					))}
					{selected && quantity === 0 ? (
						<Typography sx={{ fontSize: 14 }} color="error" align="right">
							Quantity must be greater than 0
						</Typography>
					) : null}
					<Divider sx={{ mt: 1.5, mb: 2 }} />
					<Typography align="right">
						Subtotal:{" "}
						{formatCurrency(
							selectedClass ? selectedClass.amount * quantity : 0,
							clubData
						)}
					</Typography>
					<Dialog
						open={open == 1}
						fullScreen={matches ? true : false}
						fullWidth={!matches ? true : false}
						maxWidth="sm"
						sx={styles.modal}
						hideBackdrop={true}
					>
						<PayBooking
							{...props}
							selectedClass={newSelectedClass}
							bookFor={bookFor}
							quantity={quantity}
							handleClose={() => setOpen(0)}
						/>
					</Dialog>
					<Dialog
						sx={styles.modal}
						style={{ minHeight: "100%" }}
						maxWidth="sm"
						open={open == 2}
						onClose={() => setOpen(0)}
					>
						<AddressValidation
							{...props}
							config={props.config}
							handleOpen={() => setOpen(1)}
							onClose={() => setOpen(0)}
						/>
					</Dialog>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Button
							color="primary"
							variant="outlined"
							fullWidth
							onClick={props.handleClose}
						>
							Back
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button
							color="primary"
							variant="contained"
							fullWidth
							onClick={handleSelect}
							disabled={!selectedClass || quantity === 0}
						>
							Continue
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</>
	);
};
export default SelectBooking;

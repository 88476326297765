import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import Footer from "../components/Footer/Footer";
import Timeout from "./Timeout";

export default function Layout() {
	return (
		<div>
			<Timeout />
			<Header />
			<Sidebar />
			<Footer />
		</div>
	);
}

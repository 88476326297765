import React, { useEffect } from "react";
import {
	Stepper,
	Step,
	StepLabel,
	Button,
	Grid,
	CssBaseline,
} from "@mui/material";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { Alert } from "@mui/material";
import { validateStep1, validateStep2, validateStep3 } from "./Validation";
import Alerts from "../AlertMessage/AlertMessage";
import { getOSVersion } from "../../helpers/browser";
import { BackToSignInButton, MemberSolutionsButton } from "../../utils/Buttons";
import { history } from "../../utils/history";
import Loader from "../../utils/ButtonLoader";
import SignUpError from "../AlertMessage/SignUpError";

const classes: any = {
	button: {
		marginRight: 1,
		width: "100%",
		marginTop: "10px",
	},
	nextButton: {
		width: "100%",
		marginTop: "20px",
	},
	instructions: {
		marginTop: 1,
		marginBottom: 1,
	},
};

function getSteps() {
	return ["User", "Account", "Agreements"];
}
export default function SignUpSteps(props: any) {
	const [activeStep, setActiveStep] = React.useState<any>(0);
	const [errors, setErrors] = React.useState<any>({});
	const [questionnaires, setQuestionnaires] = React.useState<any>([]);
	const [values, setValues] = React.useState<any>({
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		phoneType: "",
		emailAlerts: false,
		emailAccountAlerts: false,
		textAlerts: false,
		privacyPolicy: false,
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		captcha: null,
		dob: null,
		username: "",
		password: "",
		confirmPassword: "",
		systemName: "MP2",
		partnerApiKey: "mm",
		businessAlias: window.location.pathname.split("/")[1],
		userAgent: getOSVersion(window),
		requestIp: "unknown",
	});
	function getStepContent(
		step: any,
		values: any,
		handleChange: any,
		errors: any,
		setErrors: any,
		handleBoolean: any,
		handleQuestionnaire: any,
		questionnaires: any
	) {
		switch (step) {
			case 0:
				return (
					<Step1
						key={0}
						handleChange={handleChange}
						clearMessage={props.clearMessage}
						clearError={props.clearError}
						values={values}
						errors={errors}
						setErrors={setErrors}
						regErrors={props.errors}
						handleBoolean={handleBoolean}
					/>
				);
			case 1:
				return (
					<Step2
						key={1}
						handleBoolean={handleBoolean}
						handleChange={handleChange}
						checkUserName={props.checkUserName}
						clearUserNameError={props.clearUserNameError}
						userTaken={props.userTaken}
						values={values}
						errors={errors}
						setErrors={setErrors}
					/>
				);
			case 2:
				return (
					<Step3
						key={2}
						handleChange={handleChange}
						handleQuestionnaire={handleQuestionnaire}
						handleBoolean={handleBoolean}
						values={values}
						errors={errors}
						setErrors={setErrors}
						markQs={props.markQs}
						requestQs={props.requestQs}
						submitted={props.submitted}
						questionnaires={questionnaires}
						data={props.data}
					/>
				);
			default:
				return "Unknown step";
		}
	}
	const steps = getSteps();
	const handleChange = (prop: string) => (event: any) => {
		setValues({ ...values, [prop]: event.target.value });
	};
	const handleBoolean = (prop: string, value: any) => {
		setValues({ ...values, [prop]: value });
	};
	const handleQuestionnaire = (value: any) => {
		setErrors({ ...errors, [value]: "" });
		setQuestionnaires([...questionnaires, value]);
	};
	const handleNext = () => {
		let valid1;
		let valid2;
		if (activeStep === 0) {
			valid1 = validateStep1(values);
			const isValid: any = Object.values(valid1).every((x) => x === "");
			if (isValid) {
				setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
			} else {
				setErrors({ ...valid1 });
			}
		}
		if (activeStep === 1) {
			valid2 = validateStep2(values);
			const isValid: any = Object.values(valid2).every((x) => x === "");
			if (isValid) {
				props.clearUserNameError();
				setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
			}
		}
	};

	const handleBack = () => {
		props.clearMessage();
		setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
	};

	const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		let userInfo = {
			PartnerApiKey: values.partnerApiKey,
			BusinessAlias: values.businessAlias,
			FirstName: values.firstName,
			LastName: values.lastName,
			Email: values.email,
			DateOfBirth: new Date(values.dob)?.toISOString()?.slice(0, 10),
			[values.phoneType]: values.phone,
			UserName: values.username,
			Password: values.password,
			TimeZone: values.timeZone,
			userAgent: values.userAgent,
			requestIp: values.requestIp,
			textAlerts: values.textAlerts,
			emailAlerts: values.emailAccountAlerts,
			tcpaEmailAlerts: values.emailAlerts,
			SystemName: values.systemName,
			captchaToken: values.captcha,
		};
		let valid;
		if (activeStep === 2) {
			valid = validateStep3(
				values,
				setErrors,
				props.submitted.length,
				props.data.length
			);
		}
		if (valid) {
			props.onRegister(userInfo);
		}
	};
	useEffect(() => {
		if (props.clientId) {
			handleSubmitQuestionnaires();
		}
	}, [props.clientId]);

	const handleSubmitQuestionnaires = () => {
		if (questionnaires.length !== 0 && props.clientId) {
			questionnaires.forEach((questionnaire: any) => {
				questionnaire.ClientId = props.clientId;
				if (questionnaire !== null) console.log("not null");
				props.submitQs(questionnaire);
			});
		}
		if (questionnaires.length === 0 && props.clientId) {
			return history.push(`/${values.businessAlias}/signin`);
		}
	};

	return (
		<div style={{ maxWidth: 600 }}>
			<CssBaseline />
			{props.clubData.canUserSelfRegister ? (
				<>
					<Stepper
						sx={{ m: 2, mb: 3 }}
						color="secondary"
						activeStep={activeStep}
					>
						{steps.map((label, index) => {
							const stepProps = {};
							const labelProps = {};
							return (
								<Step key={index} {...stepProps}>
									<StepLabel {...labelProps}>{label}</StepLabel>
								</Step>
							);
						})}
					</Stepper>

					<div style={classes.instructions}>
						{getStepContent(
							activeStep,
							values,
							handleChange,
							errors,
							setErrors,
							handleBoolean,
							handleQuestionnaire,
							questionnaires
						)}
					</div>
					<div>
						{props.messages || props.errors ? (
							<Alerts
								messages={props.messages}
								errors={props.errors}
								close={true}
							/>
						) : (
							""
						)}
						{props.userTaken ? (
							<Alert severity="error">User name is already taken</Alert>
						) : (
							""
						)}
						{activeStep === steps.length - 1 ? (
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmit}
								sx={classes.nextButton}
							>
								{props.requesting ? <Loader /> : "Finish"}
							</Button>
						) : (
							<Button
								variant="contained"
								color="primary"
								onClick={handleNext}
								sx={classes.nextButton}
								disabled={props.userTaken}
							>
								Next
							</Button>
						)}
						<Button
							disabled={activeStep === 0 || props.userTaken}
							onClick={() => handleBack()}
							sx={classes.button}
						>
							Back
						</Button>
					</div>
				</>
			) : (
				<SignUpError />
			)}
			<Grid container justifyContent="center">
				<BackToSignInButton />
				<MemberSolutionsButton />
			</Grid>
		</div>
	);
}

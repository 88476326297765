import { connect } from "react-redux";
import Memberships from "../components/Profile/ManageMemberships/ManageMemberships";

const mapStateToProps = (state: any) => {
	return {
		userConfig: state.auth.userConfig,
		billingProfiles: state.billingProfile.billingProfiles,
		messages: state.billingProfile.messages,
		errors: state.billingProfile.errors,
		successful: state.billingProfile.successful,
		requesting: state.billingProfile.requesting,
		userInfo: state.client.user,
		clubData: state.billingProfile.clubData,
		memberships: state.memberships.memberships,
		invoiceDetails: state.invoices.invoiceDetails,
		emailError: state.invoices.errors,
		emailMessage: state.invoices.messages,
		credits: state.client.credits,
		paymentMessage: state.memberships.messages,
		paymentError: state.memberships.errors,
		paymentRequesting: state.memberships.requesting,
		paymentSuccessful: state.memberships.successful,
		invoicePdf: state.invoices.invoicePdf,
		contractPdfSuccessful: state.memberships.pdfSuccessful,
		pdfRequesting: state.invoices.pdfRequesting,
		pdfSuccessful: state.invoices.pdfSuccessful,
		pdfError: state.invoices.pdfError,
		contractPdf: state.memberships.contractPdf,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		emailInvoice: (config: any) =>
			dispatch({ type: "EMAIL_INVOICE_REQUEST", value: config }),
		getCredits: (config: any) =>
			dispatch({ type: "GET_CREDITS_REQUEST", value: config }),
		getInvoiceDetails: (config: any) =>
			dispatch({ type: "INVOICE_DETAILS_REQUEST", value: config }),
		getInvoicePdf: (config: any) =>
			dispatch({ type: "INVOICE_IMAGE_REQUEST", value: config }),
		getMemberships: (config: any) =>
			dispatch({ type: "GET_MEMBERSHIPS_REQUEST", value: config }),
		getProfileInfo: (config: any) =>
			dispatch({ type: "PROFILE_INFO_REQUEST", value: config }),
		getAcceptedCards: (config: any) =>
			dispatch({ type: "ACCEPTED_CARDS_REQUEST", value: config }),
		getBillingProfile: (config: any) =>
			dispatch({ type: "BILLING_PROFILE_REQUEST", value: config }),
		updateBillingProfile: (config: any) =>
			dispatch({ type: "UPDATE_BILLING_PROFILE_REQUEST", value: config }),
		createBillingProfile: (config: any) =>
			dispatch({ type: "CREATE_BILLING_PROFILE_REQUEST", value: config }),
		deleteBillingProfile: (config: any) =>
			dispatch({ type: "DELETE_BILLING_PROFILE_REQUEST", value: config }),
		defaultBillingProfile: (config: any) =>
			dispatch({ type: "DEFAULT_BILLING_PROFILE_REQUEST", value: config }),
		clearErrors: () => dispatch({ type: "CLEAR_ERRORS" }),
		payMembership: (config: any) =>
			dispatch({ type: "PAY_MEMBERSHIP_REQUEST", value: config }),
		getContractPdf: (config: any) =>
			dispatch({ type: "GET_CONTRACT_PDF_REQUEST", value: config }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Memberships);

import React from "react";
import {
	Button,
	Grid,
	Card,
	Typography,
	Dialog,
	DialogContent,
	DialogContentText,
	Hidden,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import styles from "../../../styles";
import moment from "moment";

export default function BeltRank(props: any) {
	const { userConfig, getBeltRank, beltRank } = props;
	const [open, setOpen] = React.useState("0");
	let config: any = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		id: userConfig ? userConfig.clientId : null,
	};
	React.useEffect(() => {
		getBeltRank(config);
	}, []);
	const handleClose = () => {
		setOpen("0");
	};
	const handleChange = (number: any) => {
		setOpen(number);
	};
	function createData(
		programName: string,
		level: string,
		startDate: string,
		skills: string,
		appointments: string,
		history: any
	) {
		return { programName, level, startDate, skills, appointments, history };
	}

	const rows: any = beltRank.map((row: any) =>
		createData(
			row.programName,
			row.levelName,
			row.startDate.slice(0, 10),
			row.passedSkills + "/" + row.totalSkills,
			row.classesTaken + "/" + row.totalClasses,
			row.programHistory
		)
	);
	let fontStyle = {
		fontFamily: "Roboto Condensed, sans-serif",
		fontSize: 14,
	};
	let cellStyle = { color: "white", py: 0 };
	return (
		<>
			<Card raised>
				<Title title="Belt Rank" onClose={props.onClose} />
				<Grid container justifyContent="center">
					<Hidden smDown>
						<Table aria-label="simple table" style={{ width: "100%" }}>
							<TableHead style={{ backgroundColor: "grey", borderRadius: 0 }}>
								<TableRow sx={{ height: 50 }}>
									<TableCell sx={cellStyle}>Program</TableCell>
									<TableCell sx={cellStyle} align="left">
										Level
									</TableCell>
									<TableCell sx={cellStyle} align="center">
										Start Date
									</TableCell>
									<TableCell sx={cellStyle} align="center">
										Skills Completed
									</TableCell>
									<TableCell sx={cellStyle} align="right">
										Appointments
									</TableCell>
									<TableCell sx={cellStyle} align="center">
										Actions
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row: any, index: any) => (
									<React.Fragment key={index}>
										<TableRow>
											<TableCell component="th" scope="row">
												{row.programName}
											</TableCell>
											<TableCell align="left">{row.level}</TableCell>
											<TableCell align="center">
												{moment(row.startDate).format("MM/DD/YY")}
											</TableCell>
											<TableCell align="center">{row.skills}</TableCell>
											<TableCell align="center">{row.appointments}</TableCell>
											<TableCell align="right">
												<Button
													variant="outlined"
													onClick={() => handleChange(index)}
												>
													History
												</Button>
											</TableCell>
										</TableRow>
										<Dialog
											open={open === index}
											fullWidth
											sx={styles.modal}
											maxWidth="sm"
											onClose={handleClose}
										>
											<DialogContent sx={{ p: 0, m: 0 }}>
												<DialogContentText sx={{ p: 0, m: 0 }}>
													<Card raised>
														<Title
															title={row.programName + " History"}
															onClose={handleClose}
														/>
														<Table aria-label="simple table">
															<TableHead
																style={{
																	backgroundColor: "grey",
																	color: "white",
																}}
															>
																<TableRow>
																	<TableCell style={{ color: "white" }}>
																		Level
																	</TableCell>
																	<TableCell
																		style={{ color: "white" }}
																		align="center"
																	>
																		Start Date
																	</TableCell>
																	<TableCell
																		style={{ color: "white" }}
																		align="right"
																	>
																		Finish Date
																	</TableCell>
																</TableRow>
															</TableHead>
															<TableBody>
																{row.history.map((program: any) => (
																	<TableRow>
																		<TableCell component="th" scope="row">
																			{program.levelName}
																		</TableCell>

																		<TableCell align="center">
																			{program.startDate.slice(0, 10) ===
																			"0001-01-01"
																				? null
																				: moment(program.startDate).format(
																						"MM/DD/YY"
																				  )}
																		</TableCell>
																		<TableCell align="right">
																			{program.endDate.slice(0, 10) ===
																			"0001-01-01"
																				? null
																				: moment(program.endDate).format(
																						"MM/DD/YY"
																				  )}
																		</TableCell>
																	</TableRow>
																))}
															</TableBody>
														</Table>
													</Card>
												</DialogContentText>
											</DialogContent>
										</Dialog>
									</React.Fragment>
								))}
							</TableBody>
						</Table>
					</Hidden>
				</Grid>
				<Hidden smUp>
					{rows.map((row: any, index: any) => (
						<React.Fragment key={index}>
							<Grid
								container
								direction="row"
								style={{
									width: "100%",
									padding: 10,
									borderBottom: "1px solid grey",
								}}
							>
								<Grid
									container
									direction="column"
									justifyContent="space-between"
									style={{ width: "100%" }}
								>
									<Typography variant="caption" sx={{ fontStyle }}>
										<strong>Program:</strong> {row.programName}
									</Typography>

									<Typography variant="caption" sx={{ fontStyle }}>
										<strong>Level:</strong> {row.level}
									</Typography>
									<Typography variant="caption" sx={{ fontStyle }}>
										<strong>Start Date:</strong>{" "}
										{moment(row.startDate).format("MM/DD/YY")}
									</Typography>
									<Typography variant="caption" sx={{ fontStyle }}>
										<strong>Skills Completed:</strong> {row.skills}
									</Typography>
									<Typography variant="caption" sx={{ fontStyle, mb: 1 }}>
										<strong>Appointments:</strong> {row.appointments}
									</Typography>
								</Grid>
								<Grid container direction="column" justifyContent="center">
									<Button
										onClick={() => handleChange(index)}
										variant="outlined"
										disableRipple
									>
										View
									</Button>
								</Grid>
							</Grid>

							<Dialog
								open={open === index}
								onClose={handleClose}
								sx={styles.modal}
							>
								<DialogContent sx={{ p: 0, m: 0 }}>
									<DialogContentText sx={{ p: 0, m: 0 }}>
										<Card raised>
											<Title
												title={row.programName + " History"}
												onClose={handleClose}
											/>

											<Table aria-label="simple table">
												<TableHead
													style={{
														backgroundColor: "grey",
														color: "white",
													}}
												>
													<TableRow>
														<TableCell style={{ color: "white" }}>
															Level
														</TableCell>
														<TableCell
															style={{ color: "white", minWidth: 70 }}
															align="right"
														>
															Start Date
														</TableCell>
														<TableCell
															style={{
																color: "white",
																paddingRight: 10,
																minWidth: 75,
															}}
															align="left"
														>
															Finish Date
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{row.history.map((program: any, index: any) => (
														<TableRow key={index}>
															<TableCell component="th" scope="row">
																{program.levelName}
															</TableCell>

															<TableCell align="right">
																{program.startDate.slice(0, 10) === "0001-01-01"
																	? null
																	: moment(program.startDate).format(
																			"MM/DD/YY"
																	  )}
															</TableCell>
															<TableCell align="right">
																{program.endDate.slice(0, 10) === "0001-01-01"
																	? null
																	: moment(program.endDate).format("MM/DD/YY")}
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</Card>
									</DialogContentText>
								</DialogContent>
							</Dialog>
						</React.Fragment>
					))}
				</Hidden>
			</Card>
		</>
	);
}

import React from "react";
import {
	CssBaseline,
	TextField,
	Button,
	Typography,
	Container,
	Box,
	Grid,
	Grow,
} from "@mui/material";
import { forgotPassword } from "./validate";
import { connect } from "react-redux";
import Alerts from "../AlertMessage/AlertMessage";
import Loader from "../../utils/Loader";
import { BackToSignInButton, ForgotUsernameButton } from "../../utils/Buttons";
import Error from "../AlertMessage/ErrorPage";

const classes: any = {
	paper: {
		margin: 2,
		marginTop: 18,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: 0,
	},
	container: {
		mt: 8,
	},
};

function ForgotPassword(props: any) {
	const [errors, setErrors] = React.useState<any>({});
	const [values, setValues] = React.useState<any>({
		userName: "",
		email: "",
	});
	React.useEffect(() => {
		props.getClubLogo(config);
		props.checkAlias(config);
	}, []);
	const handleChange = (prop: string) => (event: any) => {
		setErrors({ ...errors, [prop]: "" });
		setValues({ ...values, [prop]: event.target.value.trim() });
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		props.clearMessage();
		let valid;
		valid = forgotPassword(values, setErrors);
		if (valid) {
			props.forgotPassword(config);
		}
	};

	let config = {
		userName: values.userName,
		Email: values.email,
		businessAlias: window.location.pathname.split("/")[1],
		partnerApiKey: "mm",
		SystemName: "MP2",
	};

	return (
		<>
			{!props.active ? (
				<Error />
			) : (
				<Container sx={classes.container} component="main" maxWidth="xs">
					<CssBaseline />
					<Box sx={classes.paper}>
						{props.clubLogo && (
							<img src={props.clubLogo} width={240} alt="logo" />
						)}
						<Grow in={true} timeout={450}>
							<Typography style={{ marginTop: 20 }} component="h2" variant="h6">
								Forgot Password
							</Typography>
						</Grow>
						{!props.requesting ? (
							<form style={classes.form} noValidate>
								<Grid container rowSpacing={3} sx={{ mt: 1 }}>
									<Grow in={true} timeout={550}>
										<Grid item xs={12}>
											<TextField
												required
												fullWidth
												autoFocus
												label="Email Address"
												id="email"
												name="email"
												color="secondary"
												value={values.email}
												onChange={handleChange("email")}
												error={errors.email ? true : false}
												helperText={errors.email}
												InputLabelProps={{ shrink: true }}
											/>
										</Grid>
									</Grow>
									<Grow in={true} timeout={900}>
										<Grid item xs={12}>
											<TextField
												required
												fullWidth
												id="userName"
												label="Username"
												name="userName"
												color="secondary"
												value={values.userName}
												onChange={handleChange("userName")}
												error={errors.userName ? true : false}
												helperText={errors.userName}
												InputLabelProps={{ shrink: true }}
											/>
										</Grid>
									</Grow>
									<Grid item xs={12}>
										<Alerts
											messages={props.messages}
											errors={props.errors}
											config={config}
										/>
									</Grid>
									<Grow in={true} timeout={1200}>
										<Grid item xs={12}>
											<Button
												type="submit"
												fullWidth
												variant="contained"
												color="primary"
												onClick={handleSubmit}
											>
												Send Email
											</Button>
										</Grid>
									</Grow>
								</Grid>
							</form>
						) : (
							<Loader messages={props.messages} />
						)}
					</Box>
					<Grow in={true} timeout={1050}>
						<Grid
							container
							justifyContent="center"
							direction="column"
							alignItems="center"
						>
							<Grid item>
								<span onClick={props.clearMessage}>
									<ForgotUsernameButton />
								</span>
							</Grid>
							<Grid item>
								<BackToSignInButton />
							</Grid>
						</Grid>
					</Grow>
				</Container>
			)}
		</>
	);
}

const mapStateToProps = (state: any) => {
	return {
		messages: state.user.messages,
		errors: state.user.errors,
		requesting: state.user.requesting,
		clubLogo: state.auth.clubLogo,
		active: state.auth.active,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		forgotPassword: (config: any) =>
			dispatch({ type: "FORGOT_PASSWORD_REQUEST", value: config }),
		clearMessage: () => dispatch({ type: "CLEAR_MESSAGE" }),
		getClubLogo: (config: any) =>
			dispatch({ type: "CLUB_LOGO_REQUEST", value: config }),
		checkAlias: (config: any) =>
			dispatch({ type: "CHECK_ALIAS_REQUEST", value: config }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

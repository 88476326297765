export const addressValidate = (values: any, setErrors: any) => {
	let temp: any = {};

	if (!values.address) {
		temp.address = "Required field";
	} else {
		temp.address = "";
	}
	if (!values.city) {
		temp.city = "Required field";
	} else {
		temp.city = "";
	}
	if (!values.zip) {
		temp.zip = "Required field";
	} else {
		var regex = /.*/;
		if (values.country === "US") {
			regex = /^\d{5}(?:[-\s]\d{4})?$/;
		} else if (values.country === "CA") {
			regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
		}
		if (!regex.test(values.zip)) {
			temp.zip = "Please enter correct value";
		} else {
			temp.zip = "";
		}
	}
	if (!values.state) {
		temp.state = "Required field";
	} else {
		temp.state = "";
	}
	if (!values.country) {
		temp.country = "Required field";
	} else if (values.country !== "US" && values.country !== "CA") {
		temp.country = "Country must be US or Canada";
	}
	setErrors({ ...temp });
	return Object.values(temp).every((x) => x === "");
};

export function parseJwt(token: any) {
	if (token === null) {
		console.log("token empty");
		return "";
	} else {
		var base64Url = token.split(".")[1];
		var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		var jsonPayload = decodeURIComponent(
			atob(base64)
				.split("")
				.map(function (c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join("")
		);
		return JSON.parse(jsonPayload);
	}
}

export const getQueryParams = (searchStr: any) => {
	var params: any = {},
		tokens: any,
		re: any = /[?&]?([^=]+)=([^&]*)/g;
	while ((tokens = re.exec(searchStr))) {
		params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
	}
	return params;
};

export const getUserParams = () => {
	const params: any = {
		redirect: "follow",
		referrer: "no-referrer",
	};
	let ip = fetch("https://api.ipify.org?format=json", params)
		.then((response) => {
			return response.json();
		})
		.then((res: any) => {
			return res;
		})
		.catch((err: any) => console.error("Problem fetching my IP", err));
	return ip;
};

export function getOSVersion(window: any) {
	let OSName = "unknown";
	if (window.navigator.userAgent.indexOf("Windows NT 10.0") !== -1)
		OSName = "Windows 10";
	if (window.navigator.userAgent.indexOf("Windows NT 6.2") !== -1)
		OSName = "Windows 8";
	if (window.navigator.userAgent.indexOf("Windows NT 6.1") !== -1)
		OSName = "Windows 7";
	if (window.navigator.userAgent.indexOf("Windows NT 6.0") !== -1)
		OSName = "Windows Vista";
	if (window.navigator.userAgent.indexOf("Windows NT 5.1") !== -1)
		OSName = "Windows XP";
	if (window.navigator.userAgent.indexOf("Windows NT 5.0") !== -1)
		OSName = "Windows 2000";
	if (window.navigator.userAgent.indexOf("Mac") !== -1) OSName = "Mac/iOS";
	if (window.navigator.userAgent.indexOf("X11") !== -1) OSName = "UNIX";
	if (window.navigator.userAgent.indexOf("Linux") !== -1) OSName = "Linux";

	return OSName;
}

import {
	Typography,
	Button,
	Card,
	CardHeader,
	Grid,
	ListItem,
	Dialog,
} from "@mui/material";
import moment from "moment";
import { ChangePaymentDialog } from "./ChangePaymentDialog";
import { formatCurrency } from "../../../utils/FormatCurrency";
const classes: any = {
	invoiceHeader: {
		backgroundColor: "text.disabled",
		color: "white",
		borderTopLeftRadius: "0px",
		borderTopRightRadius: "0px",
		margin: 0,
		height: 10,
		maxWidth: "100%",
	},
	tableRowRoot: {
		"&:nth-of-type(odd)": {
			backgroundColor: "#DCDCDC",
		},
	},
};

const MobileRecurring = (props: any) => {
	const {
		payment,
		paymentMethod,
		setOpen,
		open,
		sortedBillingProfiles,
		handlePaymentChange,
		index,
		matches,
	} = props;

	let fontStyle = {
		fontFamily: "Roboto Condensed, sans-serif",
		fontSize: 16,
	};
	const getProfile = (id: any) => {
		let bp = sortedBillingProfiles.find(
			(profile: any) => profile.id === payment.paymentMethodId
		);
		let cardType = bp?.accountTypeFull || bp?.creditCardType || "";
		let renderedType;
		if (bp) {
			renderedType = (
				<>
					{cardType +
						" " +
						bp?.accountNumber?.substr(
							bp?.accountNumber?.length - 4,
							bp?.accountNumber?.length
						)}
				</>
			);
		} else {
			renderedType = "N/A";
		}
		return renderedType;
	};

	return (
		<Card sx={{ borderRadius: 0 }}>
			<CardHeader
				sx={classes.invoiceHeader}
				title={
					<Grid container justifyContent="flex-start">
						<Grid item>
							<Typography
								variant="subtitle2"
								sx={{
									fontFamily: "Roboto Condensed, sans-serif",
									fontSize: 18,
									lineHeight: 1,
								}}
							>
								{payment?.productName?.length > 40
									? payment?.productName.slice(0, 40) + "..."
									: payment?.productName}
							</Typography>
						</Grid>
					</Grid>
				}
			/>
			<ListItem sx={props.index % 2 === 0 ? classes.tableRowRoot : ""}>
				<Grid container direction="row" justifyContent="space-between">
					<Grid
						container
						direction="row"
						sx={{ p: 1, pt: 0 }}
						justifyContent="flex-start"
						columnSpacing={2}
					>
						<Grid item xs={6}>
							<Typography align="left" variant="subtitle2" sx={fontStyle}>
								<strong>Due Next:</strong>{" "}
								{moment(payment?.dueDate?.slice(0, 10)).format("MM/DD/YYYY")}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography align="left" variant="subtitle2" sx={fontStyle}>
								<strong>Amount:</strong>{" "}
								{formatCurrency(payment?.amount, props.clubData)}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography align="left" variant="subtitle2" sx={fontStyle}>
								<strong>Payment Method: </strong>
								{getProfile(payment.paymentMethodId)}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography align="left" variant="subtitle2" sx={fontStyle}>
								<strong>Remaining Payments:</strong>{" "}
								{payment.remainingPayments === "2147483647"
									? "N/A"
									: payment.remainingPayments}
							</Typography>
						</Grid>

						<Grid
							container
							direction="row"
							spacing={2}
							justifyContent="flex-start"
							sx={{ pb: 0, pt: 0.8, pl: 2 }}
						>
							<Grid item xs={6}>
								<Button
									onClick={() => setOpen(index)}
									fullWidth
									variant="outlined"
									color="primary"
									sx={{ backgroundColor: "#FAFAFA" }}
								>
									Edit
								</Button>
							</Grid>
						</Grid>
						<Dialog
							maxWidth="sm"
							open={open == index}
							onClose={() => setOpen(-1)}
							fullScreen={matches ? true : false}
							fullWidth={!matches ? true : false}
						>
							<ChangePaymentDialog
								{...props}
								setOpen={setOpen}
								payment={payment}
								sortedBillingProfiles={sortedBillingProfiles}
								paymentMethod={paymentMethod}
								handlePaymentChange={handlePaymentChange}
								index={index}
							/>
						</Dialog>
					</Grid>
				</Grid>
			</ListItem>
		</Card>
	);
};
export default MobileRecurring;

import { Card, CardContent, CardHeader, Typography, Grid } from "@mui/material";
import { getCardLogo } from "../../../utils/GetCardLogo";

const classes: any = {
	root: {
		width: "100%",
	},
	margin: {
		marginBottom: 2,
		paddingBottom: 0,
	},
	padding: {
		padding: 2,
	},
	header: {
		backgroundColor: "text.disabled",
		color: "white",
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
		lineHeight: 1,
		margin: 0,
		padding: 0.5,
		paddingLeft: 1,
		paddingRight: 1,
		alignContent: "flex-start",
	},
	edit: {
		width: "45%",
		height: "65%",
	},
	backdrop: {
		background: "rbga(0,0,0,.8)",
	},
};

export const renderPaymentProfile = (billingProfile: any) => {
	const renderMaskedCardNumber = (number: any) => {
		number = number?.replace(/\d(?=\d{4})/g, "*");
		return number;
	};
	let id = billingProfile?.id;
	let type = billingProfile?.profileType;
	return (
		<Grid key={id} item>
			<Card sx={{ minWidth: 255, paddingBottom: 0 }} raised={true}>
				<CardHeader
					sx={classes.header}
					title={
						<span
							style={{
								fontSize: 20,
								marginLeft: 2,
								alignContent: "center",
							}}
						>
							{type === "CreditCard" ? "Credit Card" : "Bank Account"}
							{billingProfile?.isDefaultProfile ? " (Default)" : null}
						</span>
					}
				/>
				<CardContent
					sx={{
						mt: 1,
						mb: 0,
						p: 0,
						pb: 0,
						"&:last-child": {
							pb: 0.3,
						},
					}}
				>
					<Grid
						sx={{ pb: 0 }}
						container
						alignItems="center"
						justifyContent="flex-start"
						spacing={2}
					>
						{type !== "Eft" ? (
							<Grid item sx={{ ml: 0 }}>
								<img
									style={{ width: 100, height: 50 }}
									src={getCardLogo(billingProfile?.creditCardType)}
									alt="1"
								/>
							</Grid>
						) : (
							<Grid
								item
								sx={{
									ml: 1.5,
									height: 79,
									alignContent: "center",
								}}
							>
								<Typography sx={{ mt: 0.5 }}>
									Account Type: {billingProfile?.accountTypeFull}
								</Typography>
								<Typography sx={{ pb: 1.1 }}>
									Account Number:{" "}
									{renderMaskedCardNumber(billingProfile?.accountNumber)}
								</Typography>
							</Grid>
						)}
						<Grid item>
							<div style={{ marginTop: 5 }}>
								{type !== "Eft" ? (
									<>
										<Typography
											variant="subtitle1"
											component="h6"
											align="center"
										>
											{renderMaskedCardNumber(billingProfile?.accountNumber)}
										</Typography>
										<Typography
											variant="subtitle2"
											component="h6"
											align="left"
											sx={{ mt: 0, mb: 1 }}
										>
											Exp:{billingProfile?.expDateShort.slice(0, 2)}/
											{billingProfile?.expDateShort.slice(2, 4)}
										</Typography>
									</>
								) : null}
							</div>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};

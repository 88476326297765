import { connect } from "react-redux";
import HealthInfo from "../components/Profile/Dialogs/HealthInfo";

const mapStateToProps = (state: any) => {
	return {
		errors: state.auth.errors,
		data: state.client.data,
		submitted: state.auth.submitted,
		userMessages: state.auth.messages,
		userConfig: state.auth.userConfig,
		successful: state.client.successful,
		clientErrors: state.client.errors,
		userInfo: state.client.user,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getProfileInfo: (config: any) =>
			dispatch({ type: "PROFILE_INFO_REQUEST", value: config }),
		updateHealthInfo: (config: any) =>
			dispatch({ type: "HEALTHINFO_UPDATE_REQUEST", value: config }),
		clearErrors: () => dispatch({ type: "CLEAR_ERRORS" }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(HealthInfo);

import React, { useRef } from "react";
import {
	FormControlLabel,
	Grid,
	FormHelperText,
	Button,
	Radio,
	FormControl,
	TextField,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	Box,
} from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import SignatureCanvas from "react-signature-canvas";
import { validateQuestionnaires } from "./validate";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styles from "../../../styles";
import Title from "../Dialogs/Title";

export default function PendingQuestionnaire(props: any) {
	const { matches } = props;
	const [values, setValue] = React.useState<any>([]);
	const [errors, setErrors] = React.useState<any>([]);
	const sigCanvas: any = useRef({});
	const [open, setOpen] = React.useState(false);
	React.useEffect(() => {
		setAnswers();
	}, []);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let { name, value } = event.target;
		setValue({ ...values, [name]: value });
	};
	let sigImage: any = "";
	const validate = () => {
		let signature;
		if (!sigCanvas.current.isEmpty()) {
			signature = "signature";
		} else {
			signature = null;
		}

		let answerz: any = [];
		let i: any;
		for (i = 0; i < props.questions.length; i++) {
			if (props.questions[i].questionType === "text_block") {
				answerz[i] = null;
			} else {
				answerz[i] = values[props.questions[i].id];
			}
		}
		let answerQuantity = answerz.length;
		return validateQuestionnaires(
			answerz,
			setErrors,
			answerQuantity,
			signature
		);
	};

	const submitAnswers = () => {
		let getAnswers = props.questions.map((question: any) => ({
			Question: question.question,
			Answer:
				question.questionType === "text_block" ? null : values[question.id],
			id: question.id,
			order: question.order,
			QuestionType: question.questionType,
		}));
		return getAnswers;
	};
	const clear = () => {
		sigCanvas.current.clear();
	};
	const submitSig = () => {
		if (!sigCanvas.current.isEmpty()) {
			sigImage = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
		} else {
			sigImage = "";
		}
	};

	const setAnswers = () => {
		let answers: any = [];
		if (props.answers) {
			props.answers.forEach((question: any) => {
				answers = { ...answers, [question.id]: question.answer };
			});
			setValue(answers);
		}
	};
	const handleSubmit = () => {
		submitSig();
		let answers = submitAnswers();
		let valid;
		valid = validate();
		const config = {
			BusinessAlias: props.userConfig ? props.userConfig.businessAlias : null,
			PartnerApiKey: props.userConfig ? props.userConfig.partnerApiKey : null,
			SystemName: props.userConfig ? props.userConfig.systemName : null,
			QuestionnaireId: props.questionnaireId,
			clientId: props.userConfig.clientId,
			QuestionnaireName: props.name,
			Accepted: "Yes",
			AssignmentDate: props.assignments.assignmentDate,
			SubmitDate: new Date().toISOString(),
			base64SignatureImage: sigImage.substring(22),
			signatureImageExtension: "png",
			Answers: answers,
			headers: { Authorization: `Bearer ${props.userConfig.token}` },
			id: props.assignments.id,
		};
		if (valid) {
			props.updateQuestionnaires(config);
			setValue([]);
			handleClose();
		}
	};
	const classes: any = {
		sigPad: {
			height: "20vh",
			border: "1px solid black",
			width: "100%",
		},
	};
	const renderAnswers = (question: any, index: any) => {
		return (
			<div className={classes.body} key={question.id}>
				<div>{ReactHtmlParser(question.question)}</div>
				<FormControl fullWidth component="fieldset">
					<div>
						{question.questionType === "boolean_question" ? (
							<div style={{ display: "flex" }}>
								<FormControlLabel
									value="yes"
									name={question.id.toString()}
									control={
										<Radio
											checked={values[question.id] === "yes"}
											onChange={handleChange}
										/>
									}
									label="Yes"
								/>
								<FormControlLabel
									value="no"
									name={question.id.toString()}
									control={
										<Radio
											checked={values[question.id] === "no"}
											onChange={handleChange}
										/>
									}
									label="No"
								/>
								<FormHelperText
									style={{ paddingTop: 10, width: "100%" }}
									error={errors[index] ? true : false}
								>
									{errors[index]}
								</FormHelperText>
							</div>
						) : null}

						{question.questionType === "text_question" ? (
							<TextField
								onChange={handleChange}
								fullWidth
								value={values[question.id]}
								variant="outlined"
								name={question.id.toString()}
								error={errors[index] ? true : false}
								helperText={errors[index]}
							/>
						) : null}
					</div>
				</FormControl>
			</div>
		);
	};
	let signature: any;
	if (props.signatureImage) {
		signature = `data:image/png;base64,${props.signatureImage}`;
	}
	return (
		<>
			<Grid container justifyContent="center">
				<Button
					style={{ marginTop: 10 }}
					onClick={handleOpen}
					variant="contained"
					color={"primary"}
				>
					{props.completed === "Completed" ? (
						<CheckCircleOutlineIcon
							style={{ marginRight: 5, color: "green" }}
						/>
					) : null}
					{"  "}
					{props.name} Questionnaire
				</Button>
			</Grid>
			<Dialog
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				fullScreen={matches ? true : false}
				fullWidth={!matches ? true : false}
				sx={styles.modal}
			>
				<Title title={`${props.name} Questionnaire`} onClose={handleClose} />
				<DialogContent>
					<DialogContentText sx={{ p: 0, pt: 2, width: "100%" }}>
						{props.questions?.map((question: any, index: any) =>
							renderAnswers(question, index)
						)}
						<Box sx={{ pt: 2 }} />
						<strong>Member Signature:</strong>
						<div>
							<SignatureCanvas
								ref={sigCanvas}
								penColor="black"
								canvasProps={{
									style: classes.sigPad,
								}}
							/>
							<br />
							<Button onClick={clear} variant="outlined">
								Clear
							</Button>
							<FormHelperText
								style={{ paddingTop: 10, paddingLeft: 15, width: "100%" }}
								error={
									errors[errors.length - 1] === "Signature is required"
										? true
										: false
								}
							>
								{errors[errors.length - 1] === "Signature is required"
									? errors[errors.length - 1]
									: null}
							</FormHelperText>
						</div>
					</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ p: 2 }}>
					<Button
						sx={{ mr: 1 }}
						fullWidth
						onClick={handleClose}
						variant="outlined"
					>
						Cancel
					</Button>
					<Button
						fullWidth
						sx={{ ml: 1 }}
						variant="contained"
						color="primary"
						onClick={() => handleSubmit()}
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

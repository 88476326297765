import {
	Button,
	Grid,
	DialogContent,
	DialogContentText,
	DialogActions,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Table,
	TableContainer,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import Notifications from "../../AlertMessage/Notifications";
import PrintIcon from "@mui/icons-material/Print";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import GetAppIcon from "@mui/icons-material/GetApp";
import Loader from "../../../utils/Loader";
import moment from "moment";

const classes: any = {
	tableRow: {
		borderBottom: "none",
		textAlign: "right",
		padding: 0,
		paddingRight: "15px",
	},
};
export default function InvoiceTemplate(props: any) {
	const {
		invoiceDetails,
		messages,
		errors,
		userConfig,
		userInfo,
		pdfRequesting,
		pdfSuccessful,
		clubData,
	} = props;
	let pdf: any = props.invoicePdf;

	const printInvoice = () => {
		printPreview();
	};
	const downloadInvoice = () => {
		download();
	};
	const emailInvoice = () => {
		let config: any = {
			headers: {
				Authorization: `Bearer ${userConfig?.token}`,
			},
			partnerApiKey: userConfig?.partnerApiKey,
			businessAlias: userConfig?.businessAlias,
			invoiceIds: [invoiceDetails[0]?.invoiceId],
			clientId: userInfo?.id,
			email: userInfo?.email,
		};
		props.emailInvoice(config);
	};
	const download = () => {
		const linkSource = `data:application/pdf;base64,${pdf}`;
		const downloadLink = document.createElement("a");
		const fileName = `Invoice_${invoiceDetails[0]?.invoiceId}.pdf`;
		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		downloadLink.click();
	};
	const formatCurrency = (amount: any) => {
		return (
			clubData?.currencyPrefix +
			amount?.toFixed(2) +
			" " +
			clubData?.currencyPostfix
		);
	};
	const dataURItoBlob = (dataURI: any) => {
		const byteString = window.atob(dataURI);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([int8Array], { type: "application/pdf" });
		return blob;
	};
	const blob = dataURItoBlob(pdf);
	const url = URL.createObjectURL(blob);
	const printPreview = (type = "application/pdf") => {
		const theWindow: any = window.open(url);
		const theDoc = theWindow.document;
		const theScript = document.createElement("script");
		function injectThis() {
			window.print();
		}
		theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
		theDoc.body.appendChild(theScript);
	};
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<>
			{invoiceDetails ? (
				<>
					{messages || errors ? (
						<Notifications messages={messages} errors={errors} />
					) : null}
					<DialogActions>
						<Grid container spacing={2} justifyContent="center">
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									onClick={() => printInvoice()}
									disabled={pdfRequesting || !pdfSuccessful}
									startIcon={matches ? "" : <PrintIcon />}
								>
									{matches ? <PrintIcon /> : "Print"}
								</Button>
							</Grid>
							<Grid item>
								<Button
									onClick={() => downloadInvoice()}
									variant="contained"
									color="primary"
									disabled={pdfRequesting || !pdfSuccessful}
									startIcon={matches ? "" : <GetAppIcon />}
								>
									{matches ? <GetAppIcon /> : "Download"}
								</Button>
							</Grid>
							<Grid item>
								<Button
									onClick={() => emailInvoice()}
									variant="contained"
									color="primary"
									startIcon={matches ? "" : <MailOutlineIcon />}
								>
									{matches ? <MailOutlineIcon /> : "Email"}
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
					<DialogContent>
						<DialogContentText
							sx={{ maxWidth: "100%", overflow: "auto", color: "black" }}
						>
							<Grid container justifyContent="space-between">
								<Grid item>
									{invoiceDetails[0]?.club?.logoPath ? (
										<img
											style={{ width: matches ? 100 : 150 }}
											src={invoiceDetails[0]?.club?.logoPath}
											alt="Club Logo"
										/>
									) : (
										" "
									)}
								</Grid>
								<Grid item sx={{ textAlign: "right", pt: 2 }}>
									{invoiceDetails[0]?.club?.clubName}
									<br />
									{invoiceDetails[0]?.club?.address}
									<br />
									{`${invoiceDetails[0]?.club?.city}, 
									${invoiceDetails[0]?.club?.state} 
									${invoiceDetails[0]?.club?.country} 
									${invoiceDetails[0]?.club?.zip}`}
								</Grid>
							</Grid>
							<Grid
								container
								justifyContent="flex-start"
								sx={{
									backgroundColor: "darkgray",
									textAlign: "left",
									padding: "4px",
									fontWeight: "bold",
									fontSize: "medium",
								}}
							>
								Invoice Information
							</Grid>
							<Grid container justifyContent="flex-start">
								<Grid item xs={5} sm={3} md={2}>
									Invoice # :
								</Grid>
								<Grid item xs={7} sm={9} md={10}>
									{invoiceDetails[0]?.invoiceId}
								</Grid>

								<Grid item xs={5} sm={3} md={2}>
									Invoice Date :
								</Grid>
								<Grid item xs={7} sm={9} md={10}>
									{moment(invoiceDetails[0]?.invoiceDate.slice(0, 10)).format(
										"MM/DD/YYYY"
									)}
								</Grid>

								<Grid item xs={5} sm={3} md={2}>
									Sold to :
								</Grid>
								<Grid item xs={7} sm={9} md={10}>
									{invoiceDetails[0]?.soldTo?.fullName}
								</Grid>

								<Grid item xs={5} sm={3} md={2}>
									Sold by :
								</Grid>
								<Grid item xs={7} sm={9} md={10}>
									{invoiceDetails[0]?.soldByFullName}
								</Grid>

								<Grid
									item
									xs={12}
									sx={{
										backgroundColor: "darkgray",
										textAlign: "left",
										padding: "4px",
										fontWeight: "bold",
										fontSize: "medium",
									}}
								>
									Invoice Details
								</Grid>
							</Grid>
							<TableContainer sx={{ overflowX: "auto" }}>
								<Table>
									<TableHead>
										<TableCell
											sx={{
												textAlign: "left",
												minWidth: 150,
												py: 0,
											}}
										>
											Item
										</TableCell>
										<TableCell
											sx={{
												textAlign: "left",
												minWidth: 120,
												py: 0,
											}}
										>
											Date
										</TableCell>
										<TableCell sx={{ textAlign: "left", py: 0 }}>
											Purchased For
										</TableCell>
										<TableCell sx={{ textAlign: "left", py: 0 }}>
											Quantity
										</TableCell>
										<TableCell sx={{ textAlign: "left", py: 0 }}>
											Price
										</TableCell>
										<TableCell sx={{ textAlign: "left", py: 0 }}>
											Discount
										</TableCell>
										<TableCell
											sx={{
												textAlign: "left",
												minWidth: 90,
												py: 0,
											}}
										>
											Tax
										</TableCell>
										<TableCell sx={{ textAlign: "right", py: 0 }}>
											Amount
										</TableCell>
									</TableHead>
									<TableBody sx={{ overflow: "auto" }}>
										{invoiceDetails[0]?.invoiceItems?.map(
											(item: any, index: any) => (
												<TableRow key={index}>
													<TableCell style={{ textAlign: "left" }}>
														{item.itemName}
													</TableCell>
													<TableCell>
														{moment(item.date.slice(0, 10)).format(
															"MM/DD/YYYY"
														)}
													</TableCell>
													<TableCell>{item.purchasedFor}</TableCell>
													<TableCell>{item.quantity}</TableCell>
													<TableCell>{formatCurrency(item.price)}</TableCell>
													<TableCell>{formatCurrency(item.discount)}</TableCell>
													<TableCell style={{ minWidth: 60 }}>
														{item.taxPercent} %
													</TableCell>
													<TableCell style={{ textAlign: "right" }}>
														{formatCurrency(item.amount)}
													</TableCell>
												</TableRow>
											)
										)}
									</TableBody>
								</Table>
							</TableContainer>
							<Grid container justifyContent="flex-end">
								<Table sx={{ maxWidth: 300 }}>
									<TableBody>
										<TableRow>
											<TableCell sx={classes.tableRow}>
												Item(s) Total:
											</TableCell>
											<TableCell sx={classes.tableRow}>
												{formatCurrency(
													invoiceDetails[0]?.totalAmountBeforeTax
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={classes.tableRow}>Total Taxes:</TableCell>
											<TableCell sx={classes.tableRow}>
												{formatCurrency(invoiceDetails[0]?.totalTaxes)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={classes.tableRow}>
												Total Discounts:
											</TableCell>
											<TableCell sx={classes.tableRow}>
												{formatCurrency(invoiceDetails[0]?.totalDiscount)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={classes.tableRow}>
												Invoice Start Total:
											</TableCell>
											<TableCell sx={classes.tableRow}>
												{formatCurrency(
													invoiceDetails[0]?.invoiceStartTotalAmount
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={classes.tableRow}>Refunds:</TableCell>
											<TableCell sx={classes.tableRow}>
												{formatCurrency(invoiceDetails[0]?.refunds)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={classes.tableRow}>Total Paid:</TableCell>
											<TableCell sx={classes.tableRow}>
												{formatCurrency(invoiceDetails[0]?.amountPaid)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell sx={classes.tableRow}>Balance Due:</TableCell>
											<TableCell sx={classes.tableRow}>
												{formatCurrency(invoiceDetails[0]?.balanceDue)}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Grid>
							<Grid container>
								<Grid
									item
									xs={12}
									sx={{
										backgroundColor: "darkgray",
										textAlign: "left",
										padding: "4px",
										fontWeight: "bold",
										fontSize: "medium",
									}}
								>
									Payment Details
								</Grid>
							</Grid>
							<TableContainer sx={{ overflowX: "auto" }}>
								<Table>
									<TableHead>
										<TableCell sx={{ minWidth: 80 }}>Date</TableCell>
										<TableCell>Amount</TableCell>
										<TableCell>Complete</TableCell>
										<TableCell>Type</TableCell>
										<TableCell>Method</TableCell>
										<TableCell>Code</TableCell>
										<TableCell sx={{ textAlign: "right", minWidth: 200 }}>
											Fields
										</TableCell>
									</TableHead>
									<TableBody>
										{invoiceDetails[0]?.invoiceHistoryItems?.map(
											(payment: any, index: any) => (
												<TableRow
													key={index}
													sx={{
														verticalAlign: "text-top",
														borderBottom: "1px solid gray",
													}}
												>
													<TableCell>
														{moment(payment.date.slice(0, 10)).format(
															"MM/DD/YYYY"
														)}
													</TableCell>
													<TableCell style={{ textAlign: "left" }}>
														{formatCurrency(payment.amount)}
													</TableCell>
													<TableCell>
														{payment.approved ? "True" : "False"}
													</TableCell>
													<TableCell>{payment.paymentKind}</TableCell>
													<TableCell>{payment.paymentMethod}</TableCell>
													<TableCell>{payment.code}</TableCell>
													<TableCell style={{ textAlign: "right" }}>
														{payment?.properties
															? Object.entries(
																	payment?.properties?.map((property: any) => {
																		return property;
																	})
															  ).map((property: any, index: any) => {
																	return (
																		<span key={index}>
																			{property[1].field}: {property[1].value}
																			<br />
																		</span>
																	);
															  })
															: null}
													</TableCell>
												</TableRow>
											)
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</DialogContentText>
					</DialogContent>
				</>
			) : (
				<Loader />
			)}
		</>
	);
}

import {
	CardHeader,
	Grow,
	Card,
	Table,
	TableBody,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	Divider,
	Typography,
} from "@mui/material";
import ButtonLoader from "../../../utils/ButtonLoader";
import { classes } from "../styles";

const Renewals = (props: any) => {
	const { data } = props;

	let sortedSessions: any = data?.remainingSessions?.sort(function (
		a: any,
		b: any
	) {
		return a.productName.localeCompare(b.productName);
	});
	return (
		<Grow in={true} timeout={0}>
			<Card raised>
				<CardHeader sx={classes.cardHeader} title={"Remaining Sessions"} />
				<Divider light />
				<TableContainer sx={{ maxHeight: 198 }}>
					<Table stickyHeader aria-label="simple table">
						<TableHead>
							<TableRow sx={classes.tableRow}>
								<TableCell
									padding="none"
									sx={[
										{
											minWidth: { xs: 120, sm: 200 },
											pl: 2,
										},
										classes.tableRow,
									]}
								>
									Item
								</TableCell>
								<TableCell padding="none" sx={[classes.tableRow]} align="right">
									Purchased
								</TableCell>
								<TableCell
									padding="none"
									sx={[classes.tableRow, { minWidth: { xs: 44, sm: 60 } }]}
									align="right"
								>
									Used
								</TableCell>
								<TableCell
									padding="none"
									sx={[
										classes.tableRow,
										{
											pr: { xs: 2 },
											minWidth: { xs: 70, sm: 90 },
											py: 1,
										},
									]}
									align="right"
								>
									Remain
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data?.remainingSessions.length > 0 &&
								props.successful &&
								sortedSessions.map((row: any, index: any) => (
									<TableRow
										key={index}
										sx={[
											{
												"&:last-child td, &:last-child th": { border: 0 },
											},
											classes.table,
										]}
									>
										<TableCell
											component="th"
											scope="row"
											padding="none"
											sx={{
												pl: 2,
											}}
										>
											{row.productName}
										</TableCell>
										<TableCell padding="none" align="right">
											{row.originalQuantity}
										</TableCell>
										<TableCell padding="none" align="right">
											{row.usedQuantity || 0}
										</TableCell>
										<TableCell
											sx={{ pr: { xs: 2 }, py: 2 }}
											padding="none"
											align="right"
										>
											{row.remainingQuantity}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
					{data?.remainingSessions.length == 0 && props.successful && (
						<Typography
							align="center"
							variant="subtitle2"
							sx={{
								p: 2,
								backgroundColor: "secondary.light",
							}}
						>
							There are no remaining sessions
						</Typography>
					)}
					{props.requesting && (
						<div
							style={{
								backgroundColor: "#E5F6FD",
								overflowY: "hidden",
								height: 46,
								paddingTop: 10,
								paddingBottom: 10,
							}}
						>
							<ButtonLoader />
						</div>
					)}
				</TableContainer>
			</Card>
		</Grow>
	);
};

export default Renewals;

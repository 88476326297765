import React from "react";
import {
	Grid,
	FormControl,
	Select,
	MenuItem,
	Tooltip,
	Button,
} from "@mui/material";
import { formatCurrency } from "../../../utils/FormatCurrency";

const BookOptions = (props: any) => {
	const {
		selectedClass,
		purchaseForUsers,
		quantity,
		bookFor,
		setBookFor,
		clubData,
	} = props;
	const allUsers = React.useMemo(() => {
		return purchaseForUsers?.map((item: any) => (
			<MenuItem key={item.id} value={item.id}>
				{item.fullName}
			</MenuItem>
		));
	}, [purchaseForUsers]);
	return (
		<Grid container>
			<Grid item xs={4} sx={{ mt: 1 }}>
				{selectedClass.name}
			</Grid>
			<Grid item xs={4} sx={{ textAlign: { xs: "left" } }}>
				<Grid item xs={3} sm={4}>
					<FormControl
						sx={{ mt: 0, maxWidth: { xs: 100, sm: 180 }, minWidth: 100 }}
						margin="dense"
					>
						<Select
							size="small"
							fullWidth
							name="bookFor"
							margin="none"
							onChange={(event: any) => setBookFor(event.target.value)}
							value={bookFor}
							variant="outlined"
							color="secondary"
						>
							{allUsers}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			<Grid item xs={2} sx={{ mt: 1, textAlign: { xs: "left" } }}>
				{formatCurrency(selectedClass.amount, clubData)}
				{selectedClass.defaultRenewal && (
					<Tooltip arrow placement="top" title="Auto Renewal">
						<Button sx={{ padding: 0.2, minHeight: 0.2, minWidth: 0.2 }}>
							R
						</Button>
					</Tooltip>
				)}
			</Grid>
			<Grid item xs={2} sx={{ mt: 1, textAlign: "right" }}>
				{quantity}
			</Grid>
		</Grid>
	);
};

export default BookOptions;

import { combineReducers } from "redux";
import layout from "./layout.reducer";
import auth from "./auth.reducer";
import user from "./user.reducer";
import client from "./client.reducer";
import storage from "redux-persist/lib/storage";
import billingProfile from "./billingprofile.reducer";
import memberships from "./memberships.reducer";
import marketplace from "./marketplace.reducer";
import invoices from "./invoices.reducer";
import schedule from "./schedule.reducer";
import enroll from "./enroll.reducer";
import dashboard from "./dashboard.reducer";
const appReducer = combineReducers({
	layout,
	auth,
	user,
	client,
	billingProfile,
	memberships,
	marketplace,
	invoices,
	schedule,
	enroll,
	dashboard,
});

const rootReducer = (state: any, action: any) => {
	if (action.type === "LOGOUT") {
		storage.removeItem("persist:root");
		state = {};
	}
	return appReducer(state, action);
};
export type AppState = ReturnType<typeof appReducer>;

export default rootReducer;

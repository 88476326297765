import React from "react";
import {
	InputLabel,
	Button,
	Grid,
	Typography,
	DialogContent,
	DialogActions,
} from "@mui/material";
import { TextInput } from "../../Forms/Inputs";
import Title from "./Title";

const classes: any = {
	headerColor: {
		color: "black",
	},
};

export default function HealthInfo(props: any) {
	const { user, userConfig } = props;
	let healthInfo = {
		disclose: user.importantInformation,
		insCompany: user.insuranceCompany,
		insId: user.insuranceID,
		emergencyName: user.emergencyContact1,
		emergencyNumber: user.phone1,
		emergencyName2: user.emergencyContact2,
		emergencyNumber2: user.phone2,
	};
	const [values, setValues] = React.useState(healthInfo);

	React.useEffect(() => {
		setValues(healthInfo);
	}, [user]);
	const handleChange = (event: any) => {
		setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};
	const handleUpdate = () => {
		let config = {
			headers: { Authorization: `Bearer ${userConfig.token}` },
			Id: userConfig.clientId ? userConfig.clientId : null,
			BusinessAlias: userConfig ? userConfig.businessAlias : null,
			partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
			InsuranceID: values.insId,
			InsuranceCompany: values.insCompany,
			ImportantInformation: values.disclose,
			EmergencyContact1: values.emergencyName,
			Phone1: values.emergencyNumber,
			EmergencyContact2: values.emergencyName2,
			Phone2: values.emergencyNumber2,
		};
		props.updateHealthInfo(config);
		props.onClose();
	};
	let disabled: any;
	if (
		!values.insId &&
		!values.insCompany &&
		!values.disclose &&
		!values.emergencyName &&
		!values.emergencyNumber &&
		!values.emergencyName2 &&
		!values.emergencyNumber2
	) {
		disabled = true;
	}
	return (
		<>
			<Title title="Health Information" onClose={props.onClose} />
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<InputLabel>
							<Typography variant="h6" sx={[classes.headerColor, { pt: 1 }]}>
								Emergency Contacts:
							</Typography>
						</InputLabel>
					</Grid>
					<Grid item xs={12}>
						<InputLabel sx={classes.headerColor}>
							<i>Emergency Contact 1</i>
						</InputLabel>
					</Grid>
					<Grid item md={6} xs={12}>
						<TextInput
							label="Full Name"
							fullWidth
							name="emergencyName"
							onChange={handleChange}
							value={values.emergencyName}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<TextInput
							fullWidth
							label={"Phone Number"}
							name="emergencyNumber"
							onChange={handleChange}
							value={values.emergencyNumber}
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel sx={classes.headerColor}>
							<i>Emergency Contact 2</i>
						</InputLabel>
					</Grid>
					<Grid item md={6} xs={12}>
						<TextInput
							label={"Full Name"}
							fullWidth
							name="emergencyName2"
							onChange={handleChange}
							value={values.emergencyName2}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<TextInput
							fullWidth
							label={"Phone Number"}
							name="emergencyNumber2"
							onChange={handleChange}
							value={values.emergencyNumber2}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<InputLabel>
							<Typography variant="h6" sx={[classes.headerColor, { mt: 2 }]}>
								Health Insurance:
							</Typography>
						</InputLabel>
					</Grid>
					<Grid item md={6} xs={12}>
						<TextInput
							fullWidth
							label={"Company Name"}
							name="insCompany"
							onChange={handleChange}
							value={values.insCompany}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<TextInput
							fullWidth
							label={"Insurance ID"}
							name="insId"
							onChange={handleChange}
							value={values.insId}
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel style={{ marginBottom: 10 }}>
							<Typography variant="h6" sx={classes.headerColor}>
								Additional Information:
							</Typography>
						</InputLabel>
						<InputLabel
							style={{
								lineHeight: "1.5rem",
								color: "black",
								whiteSpace: "normal",
							}}
						>
							<i>
								Do you wish to share anything about your health that may impact
								your performance?
							</i>
						</InputLabel>
						<br />
						<TextInput
							fullWidth
							name="disclose"
							multiline
							rows={4}
							onChange={handleChange}
							required
							value={values.disclose}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions sx={{ p: 2 }}>
				<Button
					fullWidth
					onClick={() => props.onClose()}
					color="primary"
					variant="outlined"
					sx={{ mr: 1 }}
				>
					Cancel
				</Button>
				<Button
					fullWidth
					onClick={() => handleUpdate()}
					color="primary"
					variant="contained"
					disabled={disabled}
					sx={{ ml: 1 }}
				>
					Save
				</Button>
			</DialogActions>
		</>
	);
}

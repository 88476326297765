import * as constants from "../constants/memberships.constants";

const initialState = {
	requesting: false,
	successful: false,
	messages: null,
	errors: null,
	memberships: null,
	pdfSuccessful: null,
	contractPdf: null,
};

function membershipsReducer(state = initialState, action: any) {
	switch (action.type) {
		case constants.GET_MEMBERSHIPS_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: false,
				errors: null,
				memberships: action.response.data,
			};
		case constants.PAY_MEMBERSHIP_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages: "Payment submitted",
			};
		case constants.GET_CONTRACT_PDF_REQUEST:
			return {
				...state,
				requesting: true,
				successful: false,
				errors: null,
				pdfSuccessful: false,
			};
		case constants.GET_CONTRACT_PDF_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				pdfSuccessful: true,
				contractPdf: action.response.data.base64Content,
			};
		case constants.PAY_MEMBERSHIP_REQUEST:
			return {
				...state,
				requesting: true,
				successful: false,
			};
		case constants.PAY_MEMBERSHIP_ERROR:
			const error =
				action?.error?.response?.data?.title || action.response.data.error;
			return {
				...state,
				requesting: false,
				successful: false,
				pdfSuccessful: false,
				errors: error,
			};
		case constants.GET_CONTRACT_PDF_ERROR:
			return {
				...state,
				requesting: false,
				successful: false,
				pdfSuccessful: false,
				errors: action.error.response.data.errors,
			};
		case constants.GET_MEMBERSHIPS_ERROR:
			return {
				...state,
				requesting: false,
				successful: false,
			};
		case constants.CLEAR_MEMBERSHIP_ERRORS:
			return {
				...state,
				requesting: false,
				errors: null,
				messages: null,
			};
		default:
			return state;
	}
}

export default membershipsReducer;

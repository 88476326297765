export interface IState {
	isMinify: boolean;
}

const initialState: IState = {
	isMinify: false,
};

export default function layout(
	state: IState = initialState,
	action: any
): IState {
	switch (action.type) {
		case "MINIFY":
			return { isMinify: action.payload };
		default:
			return state;
	}
}

import React from "react";
import {
	Card,
	CardContent,
	CardHeader,
	Button,
	Typography,
	Grid,
	Dialog,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	IconButton,
} from "@mui/material";
import Notifications from "../../../components/AlertMessage/Notifications";
import EditCardForm from "./EditCardForm";
import AchAccountForm from "./AchAccountForm";
import EftAccountForm from "./EftAccountForm";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CloseButton } from "../../../utils/Buttons";
import { getCardLogo } from "../../../utils/GetCardLogo";
import SettingsIcon from "@mui/icons-material/Settings";
import CreateCardForm from "./CreateCardForm";
import styles from "../../../styles";
import Loader from "../../../utils/Loader";

const classes: any = {
	root: {
		width: "100%",
	},
	header: {
		backgroundColor: "text.disabled",
		color: "white",
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
		lineHeight: 1,
		margin: 0,
		padding: 0.5,
		paddingLeft: 1,
		paddingRight: 1,
		alignContent: "flex-start",
	},
};

const PaymentMethods = (props: any) => {
	const { userConfig, billingProfiles, clubData } = props;
	const [open, setOpen] = React.useState<string>("0");
	const [isDefault, setIsDefault] = React.useState<any>(false);
	const [editCard, setEditCard] = React.useState<string>("0");
	const [editBank, setEditBank] = React.useState<string>("0");
	const [expanded, setExpanded] = React.useState(props.open || false);
	React.useEffect(() => {
		if (expanded && !billingProfiles?.length) {
			props.getBillingProfile(props.config);
		}
	}, [expanded]);
	React.useEffect(() => {
		if (props.messages) {
			props.getBillingProfile(props.config);
		}
	}, [props.messages]);

	let sortedBillingProfiles: any = billingProfiles.sort(function (
		a: any,
		b: any
	) {
		return a.id - b.id || a.name.localeCompare(b.name);
	});
	React.useEffect(() => {
		if (isDefault && props.defaultId) {
			let defaultConfig: any = {
				headers: { Authorization: `Bearer ${userConfig.token}` },
				BusinessAlias: userConfig.businessAlias,
				PartnerApiKey: userConfig.partnerApiKey,
				id: props.defaultId,
			};
			props.defaultBillingProfile(defaultConfig);
			setIsDefault(false);
		}
	}, [props.defaultId]);

	const handleEditMethod = (id: any, type: any) => {
		if (type === "CreditCard") {
			setEditCard(id.toString());
		} else {
			setEditBank(id.toString());
		}
	};
	const handleCreateCard = () => {
		setOpen("3");
	};
	const handleCreateBank = () => {
		if (clubData?.paymentMethods?.includes("Ach")) {
			setOpen("5");
		} else if (clubData?.paymentMethods?.includes("Eft")) {
			setOpen("4");
		}
	};
	const handleClose = () => {
		setOpen("0");
		setEditBank("0");
		setEditCard("0");
	};
	const handleDeleteClick = () => {
		setOpen("6");
	};
	const handleDeleteCancel = () => {
		setOpen("0");
	};
	const renderMaskedCardNumber = (number: any) => {
		number = number.replace(/\d(?=\d{4})/g, "*");
		return number;
	};
	const handleExpansion = () => {
		if (props.open === true) {
			props.handleClose();
		} else if (expanded) {
			setExpanded(false);
		} else {
			setExpanded(true);
		}
	};
	const renderPaymentProfile = () => {
		let paymentProfiles: any = [];
		let i: any;
		if (billingProfiles) {
			for (i = 0; i < sortedBillingProfiles.length; i++) {
				let id = sortedBillingProfiles[i].id;
				let type = sortedBillingProfiles[i].profileType;
				let paymentProfile = (
					<Grid key={id} item>
						<Card sx={{ minWidth: 255, paddingBottom: 0 }} raised={true}>
							<CardHeader
								sx={classes.header}
								title={
									<span
										style={{
											fontSize: 20,
											marginLeft: 2,
											alignContent: "center",
										}}
									>
										{type === "CreditCard" ? "Credit Card" : "Bank Account"}
										{sortedBillingProfiles[i].isDefaultProfile
											? " (Default)"
											: null}
									</span>
								}
								action={
									<IconButton
										onClick={() => handleEditMethod(id, type)}
										size="large"
										sx={{ mt: 0.6 }}
									>
										<SettingsIcon sx={{ color: "white" }} />
									</IconButton>
								}
							/>
							<CardContent
								sx={{
									mt: 1,
									mb: 0,
									p: 0,
									pb: 0,
									"&:last-child": {
										pb: 0.3,
									},
								}}
							>
								<Grid
									sx={{ pb: 0 }}
									container
									alignItems="center"
									justifyContent="flex-start"
									spacing={2}
								>
									{type !== "Eft" ? (
										<Grid item sx={{ ml: 0 }}>
											<img
												style={{ width: 100, height: 50 }}
												src={getCardLogo(
													sortedBillingProfiles[i].creditCardType
												)}
												alt="1"
											/>
										</Grid>
									) : (
										<Grid
											item
											sx={{
												ml: 1.5,
												height: 79,
												alignContent: "center",
											}}
										>
											<Typography sx={{ mt: 0.5 }}>
												Account Type: {billingProfiles[i].accountTypeFull}
											</Typography>
											<Typography sx={{ pb: 1.1 }}>
												Account Number:{" "}
												{renderMaskedCardNumber(
													sortedBillingProfiles[i].accountNumber
												)}
											</Typography>
										</Grid>
									)}
									<Grid item>
										<div style={{ marginTop: 5 }}>
											{type !== "Eft" ? (
												<>
													<Typography
														variant="subtitle1"
														component="h6"
														align="center"
													>
														{renderMaskedCardNumber(
															sortedBillingProfiles[i].accountNumber
														)}
													</Typography>
													<Typography
														variant="subtitle2"
														component="h6"
														align="left"
														sx={{ mt: 0, mb: 1 }}
													>
														Exp:{" "}
														{sortedBillingProfiles[i].expDateShort.slice(0, 2)}/
														{sortedBillingProfiles[i].expDateShort.slice(2, 4)}
													</Typography>
												</>
											) : null}
										</div>
									</Grid>
								</Grid>
							</CardContent>
							<Dialog scroll="paper" open={editCard === id} sx={styles.modal}>
								<EditCardForm
									{...props}
									type={"edit"}
									handleClose={handleClose}
									delete={handleDeleteClick}
									open={open}
									default={isDefault}
									setDefault={setIsDefault}
									deleteCancel={handleDeleteCancel}
									setIsDefault={props.setIsDefault ? props.setIsDefault : null}
									acceptedCards={props.acceptedCards || clubData.validCCTypes}
									profileInfo={sortedBillingProfiles[i]}
									maskNumber={renderMaskedCardNumber}
								/>
							</Dialog>
							<Dialog scroll="paper" open={editBank === id} sx={styles.modal}>
								{clubData?.paymentMethods?.includes("Ach") ? (
									<AchAccountForm
										{...props}
										type={"edit"}
										handleClose={handleClose}
										setDefault={setIsDefault}
										setIsDefault={
											props.setIsDefault ? props.setIsDefault : null
										}
										open={open}
										deleteCancel={handleDeleteCancel}
										delete={handleDeleteClick}
										billingProfiles={sortedBillingProfiles}
										profileInfo={sortedBillingProfiles[i]}
										maskNumber={renderMaskedCardNumber}
									/>
								) : null}
								{clubData?.paymentMethods?.includes("Eft") ? (
									<EftAccountForm
										{...props}
										type={"edit"}
										handleClose={handleClose}
										setDefault={setIsDefault}
										setIsDefault={
											props.setIsDefault ? props.setIsDefault : null
										}
										open={open}
										delete={handleDeleteClick}
										deleteCancel={handleDeleteCancel}
										billingProfiles={sortedBillingProfiles}
										profileInfo={sortedBillingProfiles[i]}
										maskNumber={renderMaskedCardNumber}
									/>
								) : null}
							</Dialog>
						</Card>
					</Grid>
				);
				paymentProfiles.push(paymentProfile);
			}
		}
		return paymentProfiles;
	};
	return (
		<div className={classes.root}>
			<Accordion
				onChange={() => handleExpansion()}
				expanded={expanded}
				defaultExpanded={false}
			>
				<AccordionSummary
					expandIcon={
						props.open === true ? (
							<CloseButton style="open" />
						) : (
							<ExpandMoreIcon style={{ color: "white" }} />
						)
					}
					aria-controls="panel1a-content"
					id="panel1a-header"
					sx={styles.accordion}
				>
					<Typography variant="h5">Payment Methods</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container justifyContent="center" spacing={2} sx={{ pt: 1 }}>
						{billingProfiles?.length > 0 && renderPaymentProfile()}
						{billingProfiles?.length < 1 && props.successful && (
							<Typography sx={{ pt: 3 }}>
								There are no payment methods
							</Typography>
						)}

						{billingProfiles?.length < 1 && props.requesting && (
							<Grid item>
								<Loader />
							</Grid>
						)}

						<Grid container justifyContent="center">
							{props.messages || props.errors ? (
								<Notifications
									messages={props.messages}
									errors={props.errors}
								/>
							) : null}
						</Grid>
						<Dialog scroll="paper" open={open === "4"} sx={styles.modal}>
							<EftAccountForm
								{...props}
								type={"create"}
								handleClose={handleClose}
								setDefault={setIsDefault}
								setIsDefault={props.setIsDefault ? props.setIsDefault : ""}
							/>
						</Dialog>
						<Dialog scroll="paper" open={open === "5"} sx={styles.modal}>
							<AchAccountForm
								{...props}
								type={"create"}
								handleClose={handleClose}
								setDefault={setIsDefault}
								setIsDefault={props.setIsDefault ? props.setIsDefault : ""}
							/>
						</Dialog>
						<Dialog scroll="paper" open={open === "3"} sx={styles.modal}>
							<CreateCardForm
								{...props}
								type={"create"}
								handleClose={handleClose}
								acceptedCards={
									props.acceptedCards || props.clubData.validCCTypes
								}
								config={props.config}
								setDefault={setIsDefault}
								setIsDefault={props.setIsDefault ? props.setIsDefault : null}
							/>
						</Dialog>
						<Grid item xs={12} sm={5} lg={5}>
							<Button
								fullWidth
								sx={{ minWidth: 227 }}
								onClick={handleCreateCard}
								size="large"
								variant="contained"
								color="primary"
							>
								Add Credit Card
							</Button>
						</Grid>
						{clubData.paymentMethods?.includes("Ach") ||
						clubData.paymentMethods?.includes("Eft") ? (
							<Grid item xs={12} sm={5} lg={5}>
								<Button
									fullWidth
									sx={{ minWidth: 227 }}
									onClick={handleCreateBank}
									size="large"
									variant="contained"
									color="primary"
								>
									Add Bank Account
								</Button>
							</Grid>
						) : null}
					</Grid>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};
export default PaymentMethods;

import ReactDOM from "react-dom/client";
import Setup from "./setup";
import * as serviceWorker from "./serviceWorker";
import "./i18n/i18n";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(<Setup />);
serviceWorker.unregister();

import React from "react";
import {
	CssBaseline,
	TextField,
	Button,
	Grid,
	Typography,
	Box,
	Container,
	Grow,
} from "@mui/material";
import { forgotUser } from "./validate";
import { connect } from "react-redux";
import Loader from "../../utils/Loader";
import Alerts from "../AlertMessage/AlertMessage";
import { BackToSignInButton, ForgotPasswordButton } from "../../utils/Buttons";
import Error from "../AlertMessage/ErrorPage";

const classes: any = {
	paper: {
		margin: 2,
		marginTop: 18,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: 2,
	},
	container: {
		marginTop: 8,
	},
};

function ForgotUsername(props: any) {
	const [errors, setErrors] = React.useState<any>({});
	const [values, setValues] = React.useState<any>({
		firstName: "",
		lastName: "",
		email: "",
		businessAlias: window.location.pathname.split("/")[1],
		partnerApiKey: "mm",
	});
	React.useEffect(() => {
		let config2 = {
			businessAlias: window.location.pathname.split("/")[1],
			partnerApiKey: "mm",
		};
		props.getClubLogo(config2);
		props.checkAlias(config2);
	}, []);
	const handleChange = (prop: string) => (event: any) => {
		setErrors({ ...errors, [prop]: "" });
		setValues({ ...values, [prop]: event.target.value.trim() });
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		props.clearMessage();
		let valid;
		valid = forgotUser(values, setErrors);
		if (valid) {
			props.forgotUsername(config);
		}
	};
	let config = {
		Email: values.email,
		Firstname: values.firstName,
		Lastname: values.lastName,
		BusinessAlias: values.businessAlias,
		PartnerApiKey: values.partnerApiKey,
		SystemName: "MP2",
	};
	return (
		<>
			{!props.active ? (
				<Error />
			) : (
				<Container sx={classes.container} component="main" maxWidth="xs">
					<CssBaseline />
					<Box sx={classes.paper}>
						{props.clubLogo && (
							<img src={props.clubLogo} width={240} alt="logo" />
						)}
						<Grow in={true} timeout={300}>
							<Grid
								sx={{ mt: "20px", mb: "20px" }}
								container
								justifyContent="center"
							>
								<Typography component="h2" variant="h6" align="center">
									Forgot Username
								</Typography>
							</Grid>
						</Grow>
						{!props.requesting ? (
							<form style={classes.form} noValidate>
								<Grid container spacing={3}>
									<Grow in={true} timeout={550}>
										<Grid item xs={12} sm={12}>
											<TextField
												required
												fullWidth
												id="firstName"
												label="First Name"
												name="firstName"
												autoComplete="fname"
												autoFocus
												color="secondary"
												value={values.firstName}
												InputLabelProps={{ shrink: true }}
												onChange={handleChange("firstName")}
												error={errors.firstName ? true : false}
												helperText={errors.firstName}
											/>
										</Grid>
									</Grow>
									<Grow in={true} timeout={800}>
										<Grid item xs={12} sm={12}>
											<TextField
												required
												fullWidth
												id="lastName"
												label="Last Name"
												name="lastName"
												autoComplete="lname"
												color="secondary"
												value={values.lastName}
												InputLabelProps={{ shrink: true }}
												onChange={handleChange("lastName")}
												error={errors.lastName ? true : false}
												helperText={errors.lastName}
											/>
										</Grid>
									</Grow>
									<Grow in={true} timeout={1050}>
										<Grid item xs={12} sm={12}>
											<TextField
												required
												fullWidth
												id="email"
												label="Email Address"
												name="email"
												autoComplete="email"
												color="secondary"
												value={values.email}
												InputLabelProps={{ shrink: true }}
												onChange={handleChange("email")}
												error={errors.email ? true : false}
												helperText={errors.email}
											/>
										</Grid>
									</Grow>
									<Grid item xs={12}>
										<Alerts messages={props.messages} errors={props.errors} />
									</Grid>
									<Grow in={true} timeout={1200}>
										<Grid item xs={12} sm={12}>
											<Button
												type="submit"
												fullWidth
												variant="contained"
												color="primary"
												onClick={handleSubmit}
											>
												Recover Username
											</Button>
										</Grid>
									</Grow>
								</Grid>
							</form>
						) : (
							<Loader message={props.message} />
						)}
					</Box>
					<Grow in={true} timeout={1050}>
						<Grid
							container
							justifyContent="center"
							direction="column"
							alignItems="center"
						>
							<Grid item>
								<span onClick={props.clearMessage}>
									<ForgotPasswordButton />
								</span>
							</Grid>
							<Grid item>
								<BackToSignInButton />
							</Grid>
						</Grid>
					</Grow>
				</Container>
			)}
		</>
	);
}

const mapStateToProps = (state: any) => {
	return {
		messages: state.user.messages,
		requesting: state.user.requesting,
		errors: state.user.errors,
		clubLogo: state.auth.clubLogo,
		active: state.auth.active,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		forgotUsername: (config: any) =>
			dispatch({ type: "FORGOT_USERNAME_REQUEST", value: config }),
		clearMessage: () => dispatch({ type: "CLEAR_MESSAGE" }),
		getClubLogo: (config: any) =>
			dispatch({ type: "CLUB_LOGO_REQUEST", value: config }),
		checkAlias: (config: any) =>
			dispatch({ type: "CHECK_ALIAS_REQUEST", value: config }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotUsername);

import axios from "axios";
import * as URL from "../config";

export function requestProfileInfo(action: any) {
	const getProfileInfoUrl = `${URL.MP_MEMBER}/clients?Id=${action.id}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}`;
	return axios
		.get(getProfileInfoUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestUpdateProfile(action: any) {
	const updateProfileUrl = `${URL.MP_MEMBER}/clients/updateGeneralProfile`;
	return axios
		.put(updateProfileUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestQuestionnaires(action: any) {
	const getQuestionnairesUrl = `${URL.MP_MEMBER}/questionnaire/details?businessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}&ClientId=${action.id}`;
	return axios
		.get(getQuestionnairesUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestUpdateHealthInfo(action: any) {
	const updateHealthInfoUrl = `${URL.MP_MEMBER}/clients/updateHealthProfile`;
	return axios
		.put(updateHealthInfoUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestUploadProfileImage(action: any) {
	const uploadProfileImageUrl = `${URL.MP_MEMBER}/clients/uploadProfileImage`;
	return axios
		.post(uploadProfileImageUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestGetProfileImage(action: any) {
	const getProfileImageUrl = `${URL.MP_MEMBER}/media?MediaPath=${action.imageName}&PartnerApiKey=${action.partnerApiKey}&BusinessAlias=${action.businessAlias}`;
	return axios
		.get(getProfileImageUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestGetSignatureImage(action: any) {
	const getSignatureImageUrl = `${URL.MP_MEMBER}/media/images?ImageName=${action.imageName}&PartnerApiKey=${action.partnerApiKey}&BusinessAlias=${action.businessAlias}`;
	return axios
		.get(getSignatureImageUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestDeleteProfileImage(action: any) {
	const deleteProfileImageUrl = `${URL.MP_MEMBER}/clients/deleteProfileImage?ClientId=${action.id}&PartnerApiKey=${action.partnerApiKey}&BusinessAlias=${action.businessAlias}`;
	return axios
		.delete(deleteProfileImageUrl, { data: action, headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestGetUsers(action: any) {
	const getUsersUrl = `${URL.MP_MEMBER}/dependentClients?ClientId=${action.clientId}&PartnerApiKey=${action.partnerApiKey}&BusinessAlias=${action.businessAlias}`;
	return axios
		.get(getUsersUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestGetCredits(action: any) {
	const getCreditsUrl = `${URL.MP_MEMBER}/clients/credits?ClientId=${action.id}&PartnerApiKey=${action.partnerApiKey}&BusinessAlias=${action.businessAlias}`;
	return axios
		.get(getCreditsUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestBeltRank(action: any) {
	const getBeltRankUrl = `${URL.MP_MEMBER}/clients/programs?ClientId=${action.id}&PartnerApiKey=${action.partnerApiKey}&BusinessAlias=${action.businessAlias}`;
	return axios
		.get(getBeltRankUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestCreateUser(action: any) {
	const createUserUrl = `${URL.MP_MEMBER}/dependentClients`;
	return axios
		.post(createUserUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestUpdateUser(action: any) {
	const updateUserUrl = `${URL.MP_MEMBER}/dependentClients`;
	return axios
		.put(updateUserUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestSubmitProfileQuestionnaire(action: any) {
	const submitUrl = `${URL.MP_MEMBER}/questionnaire/assignment`;
	return axios
		.post(submitUrl, action.value)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestUpdateProfileQuestionnaire(action: any) {
	const updateProfileQuesitonnaireUrl = `${URL.MP_MEMBER}/questionnaire/assignment`;
	return axios
		.put(updateProfileQuesitonnaireUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestUpdateNotifications(action: any) {
	const updateNotificationsUrl = `${URL.MP_MEMBER}/clients/notificationSettings`;
	return axios
		.put(updateNotificationsUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestGetRelatedUsers(action: any) {
	const getRelatedUsers = `${URL.MP_MEMBER}/clients/relatedAccounts?ClientId=${action.id}&PartnerApiKey=${action.partnerApiKey}&BusinessAlias=${action.businessAlias}`;
	return axios
		.get(getRelatedUsers, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestUpdateRecurring(action: any) {
	const updateRecurringUrl = `${URL.MP_MEMBER}/clients/updateRecurringBilling`;
	return axios
		.put(updateRecurringUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

import * as constants from "../constants/clients.constants";

const initialState = {
	requesting: false,
	requestingUpdate: false,
	successful: false,
	successfulUpdate: false,
	messages: null,
	errors: null,
	user: null,
	data: null,
	profileImage: "",
	profileImageMessage: null,
	signatureImage: [],
	users: null,
	credits: null,
	beltRank: null,
	creditsSuccess: null,
	purchaseForUsers: null,
	recurringSuccess: null,
};

function clientReducer(state = initialState, action: any) {
	switch (action.type) {
		case constants.PROFILE_UPDATE_REQUEST:
			return {
				...state,
				requestingUpdate: true,
				successfulUpdate: false,
			};
		case constants.UPDATE_RECURRING_REQUEST:
			return {
				...state,
				requesting: true,
			};
		case constants.PROFILE_INFO_REQUEST:
			return {
				...state,
				requesting: true,
				successful: false,
			};
		case constants.PROFILE_INFO_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				user: action.response.data,
			};
		case constants.GET_RELATED_USERS_SUCCESS:
			const users: any = action.response.data?.sort(function (a: any, b: any) {
				if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) {
					return -1;
				}
				if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) {
					return 1;
				}
				return 0;
			});
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				purchaseForUsers: users,
			};
		case constants.PROFILE_UPDATE_SUCCESS:
			return {
				...state,
				requestingUpdate: false,
				successfulUpdate: true,
				errors: null,
				messages: "Your profile information has been successfully updated",
			};
		case constants.GET_QUESTIONNAIRE_REQUEST:
			return {
				...state,
				requesting: true,
				successful: false,
			};
		case constants.GET_QUESTIONNAIRE_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				data: action.response.data,
			};
		case constants.HEALTHINFO_UPDATE_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages: "Your health information has been successfully updated",
			};
		case constants.PROFILE_IMAGE_UPLOAD_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages: "Profile image successfully uploaded",
			};
		case constants.GET_USERS_SUCCESS: {
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				users: action.response.data,
			};
		}
		case constants.GET_CREDITS_REQUEST: {
			return {
				...state,
				requesting: true,
				creditsSuccess: false,
			};
		}
		case constants.GET_CREDITS_SUCCESS: {
			return {
				...state,
				requesting: false,
				creditsSuccess: true,
				errors: null,
				credits: action.response.data,
			};
		}
		case constants.BELT_RANK_SUCCESS: {
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				beltRank: action.response.data,
			};
		}
		case constants.CREATE_USER_SUCCESS: {
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages: "User successfully created",
			};
		}
		case constants.UPDATE_USER_SUCCESS: {
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages: "User successfully updated",
			};
		}
		case constants.UPDATE_NOTIFICATIONS_SUCCESS: {
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages: "Notifications successfully updated",
			};
		}
		case constants.GET_PROFILE_IMAGE_SUCCESS: {
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				profileImage: action.response.data.base64Content,
			};
		}
		case constants.UPDATE_RECURRING_SUCCESS: {
			return {
				...state,
				requesting: false,
				successful: true,
				messages: "Payment profile successfully updated",
			};
		}
		case constants.GET_SIGNATURE_IMAGE_SUCCESS: {
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				signatureImage: action.response.data.base64Image,
			};
		}
		case constants.DELETE_PROFILE_IMAGE_SUCCESS: {
			return {
				...state,
				requesting: false,
				successful: true,
				profileImage: null,
			};
		}
		case constants.SUBMIT_PROFILE_QUESTIONNAIRE_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				messages: "Successfully submitted Questionnaire",
			};
		case constants.UPDATE_PROFILE_QUESTIONNAIRE_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				messages: "Successfully updated Questionnaire",
			};
		case constants.UPDATE_PROFILE_QUESTIONNAIRE_ERROR:
		case constants.BELT_RANK_ERROR:
		case constants.SUBMIT_PROFILE_QUESTIONNAIRE_ERROR:
		case constants.GET_QUESTIONNAIRE_ERROR:
		case constants.HEALTHINFO_UPDATE_ERROR:
		case constants.UPDATE_RECURRING_ERROR:
			return {
				...state,
				errors: action.error.message || action.error.response.data.error,
				messages: null,
				requesting: false,
				successful: false,
			};
		case constants.GET_RELATED_USERS_ERROR:
		case constants.PROFILE_IMAGE_UPLOAD_ERROR:
		case constants.GET_PROFILE_IMAGE_ERROR:
		case constants.GET_SIGNATURE_IMAGE_ERROR:
		case constants.UPDATE_NOTIFICATIONS_ERROR:
			return {
				...state,
				errors: action.error?.response?.data?.errors,
				messages: null,
				requesting: false,
				successful: false,
			};

		case constants.CREATE_USER_ERROR:
			return {
				...state,
				errors: action.error?.response?.data?.errors,
				messages: null,
				requesting: false,
				successful: false,
			};
		case constants.PROFILE_INFO_ERROR:
		case constants.PROFILE_UPDATE_ERROR:
		case constants.GET_USERS_ERROR:
		case constants.UPDATE_USER_ERROR:
		case constants.GET_CREDITS_ERROR:
			return {
				...state,
				errors:
					action.error?.response?.data?.error ||
					action.error?.response?.data?.detail,
				messages: null,
				requesting: false,
				successful: false,
			};
		case constants.CLEAR_ERRORS:
			return {
				...state,
				errors: null,
				messages: null,
			};
		default:
			return state;
	}
}

export default clientReducer;

import React from "react";
import App from "./App";
import { history } from "./utils/history";
import { routerMiddleware } from "react-router-redux";
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import Routes from "./routes";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "./theme";
import reducers from "./reducers";
import rootSaga from "./sagas";
import "./index.css";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routerMiddleware(history)];
const persistConfig = {
	key: "root",
	storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default class Setup extends React.PureComponent<{}> {
	storeWithMiddleware: any;

	constructor(props: {}) {
		super(props);

		this.state = {
			rehydrated: false,
		};
		this.createStore();
	}

	createStore = () => {
		const arr = applyMiddleware(...middlewares);
		this.storeWithMiddleware =
			composeWithDevTools(arr)(createStore)(persistedReducer);
		sagaMiddleware.run(rootSaga);
	};

	render() {
		const persistor = persistStore(this.storeWithMiddleware);
		return (
			<React.Fragment>
				<Provider store={this.storeWithMiddleware}>
					<Router history={history}>
						<StyledEngineProvider injectFirst>
							<ThemeProvider theme={theme}>
								<PersistGate loading={null} persistor={persistor}>
									<App />
									<Routes />
								</PersistGate>
							</ThemeProvider>
						</StyledEngineProvider>
					</Router>
				</Provider>
			</React.Fragment>
		);
	}
}

import React, { useState } from "react";
import {
	Button,
	Grid,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	AccordionActions,
	Grow,
	Card,
	TextField,
} from "@mui/material";
import {
	TextInput,
	SelectCountry,
	SelectState,
	SelectProvince,
} from "../Forms/Inputs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { profileInfoValidate } from "./validate";
import ButtonLoader from "../../utils/ButtonLoader";
import Loader from "../../utils/Loader";
import styles from "../../styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { validateStep1 } from "../SignUp/Validation";
import { addUserValidate } from "../Profile/validate";
import { LocalizationProvider } from "@mui/x-date-pickers";

const classes: any = {
	root: {
		margin: "0px",
		width: "100%",
	},
	header: {
		backgroundColor: "primary.main",
		color: "white",
		borderTopLeftRadius: "5px",
		borderTopRightRadius: "5px",
		fontSize: 22,
		lineHeight: 1,
		margin: 0,
	},
	accordion: {
		width: "100%",
		paddingTop: "20px",
	},
	capitalize: {
		textTransform: "capitalize",
	},
};

export default function PersonalDetails(props: any) {
	const { user, userConfig } = props;
	const [values, setValues] = useState<any>({
		email: user.email ? user.email : "",
		firstName: user.firstName ? user.firstName : "",
		lastName: user.lastName ? user.lastName : "",
		cellPhone: user.cellPhone ? user.cellPhone : "",
		homePhone: user.homePhone ? user.homePhone : "",
		workPhone: user.workPhone ? user.workPhone : "",
		dateOfBirth: new Date(user.dateOfBirth).toISOString().slice(0, 10),
		address: user.address ? user.address : "",
		zip: user.zip ? user.zip : "",
		city: user.city ? user.city : "",
		state: user.state ? user.state : "",
		country: user.country ? user.country : "",
	});

	React.useEffect(() => {
		if (user) {
			setSelectedDate(props.user.dateOfBirth.slice(0, 10));
		}
	}, [user]);
	React.useEffect(() => {
		if (
			props.messages ===
			"Your profile information has been successfully updated"
		) {
			props.getProfileInfo(props.config);
		}
	}, [props.messages]);
	const [selectedDate, setSelectedDate] = React.useState<any>();
	const [errors, setErrors] = React.useState<any>({});
	const handleChange = (event: any) => {
		setErrors({ ...errors, [event.target.name]: "" });
		setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};
	const handleBoolean = (prop: string, value: any) => {
		setValues({ ...values, [prop]: value });
	};
	const handleUpdateProfile = () => {
		let config = {
			headers: { Authorization: `Bearer ${userConfig.token}` },
			partnerApiKey: userConfig.partnerApiKey,
			businessAlias: userConfig.businessAlias,
			id: userConfig.clientId,
			userId: userConfig.userId,
			userName: userConfig.userName,
			...values,
		};
		let valid;
		valid = profileInfoValidate(values);
		const isValid: any = Object.values(valid).every((x) => x === "");
		if (isValid) {
			props.updateProfileInfo(config);
		} else {
			setErrors({ ...errors, ...valid });
		}
	};
	const handleCountryChange = (event: any) => {
		setValues({
			...values,
			country: event.target.value,
			state: "",
		});
	};
	const handleDateChange = (value: any) => {
		if (value != null || value != undefined) {
			setSelectedDate(dayjs(value));
			if (value != "Invalid Date") {
				handleBoolean(
					"dateOfBirth",
					new Date(value).toISOString().slice(0, 10)
				);
			}
		} else {
			setSelectedDate(dayjs(null));
			handleBoolean("dateOfBirth", null);
		}
	};
	const validateInput = () => {
		let isValid: any = profileInfoValidate(values);
		setErrors({ ...errors, ...isValid });
	};
	let date: any = values.dateOfBirth ? values.dateOfBirth : null;
	let gridProps: any = {
		xl: 3,
		lg: 4,
		md: 4,
		sm: 6,
		xs: 12,
	};

	return (
		<div className={classes.root}>
			<Grow in={true} timeout={250}>
				<div>
					<Card raised>
						<Accordion defaultExpanded={false}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								sx={styles.accordion}
							>
								<Typography variant="h5">Personal Details</Typography>
							</AccordionSummary>
							<AccordionDetails sx={{ pb: 0 }}>
								{values ? (
									<form autoComplete="off" noValidate style={classes.accordion}>
										<Grid container spacing={2}>
											<Grid item {...gridProps}>
												<TextInput
													fullWidth
													label={"First Name"}
													name="firstName"
													required
													color="primary"
													onChange={handleChange}
													value={values.firstName}
													error={errors.firstName ? true : false}
													helperText={errors.firstName}
												/>
											</Grid>
											<Grid item {...gridProps}>
												<TextInput
													fullWidth
													required
													label={"Last Name"}
													name="lastName"
													onChange={handleChange}
													value={values.lastName}
													error={errors.lastName ? true : false}
													helperText={errors.lastName}
												/>
											</Grid>
											<Grid item {...gridProps}>
												<TextInput
													fullWidth
													required
													label={"Email Address"}
													name="email"
													onChange={handleChange}
													value={values.email}
													error={errors.email ? true : false}
													helperText={errors.email}
												/>
											</Grid>
											<Grid item {...gridProps}>
												<TextInput
													fullWidth
													label={"Cell Phone"}
													name="cellPhone"
													onChange={handleChange}
													onBlur={validateInput}
													value={values.cellPhone}
													error={errors.cellPhone ? true : false}
													helperText={errors.cellPhone}
												/>
											</Grid>
											<Grid item {...gridProps}>
												<TextInput
													fullWidth
													label={"Home Phone"}
													name="homePhone"
													onChange={handleChange}
													onBlur={validateInput}
													value={values.homePhone}
													error={errors.homePhone ? true : false}
													helperText={errors.homePhone}
												/>
											</Grid>
											<Grid item {...gridProps}>
												<TextInput
													fullWidth
													label={"Work Phone"}
													name="workPhone"
													onChange={handleChange}
													onBlur={validateInput}
													value={values.workPhone}
													error={errors.workPhone ? true : false}
													helperText={errors.workPhone}
												/>
											</Grid>
											<Grid item {...gridProps}>
												{props.user.dateOfBirth?.slice(0, 10) ===
												"1970-01-01" ? (
													<LocalizationProvider dateAdapter={AdapterDayjs}>
														<Grid container justifyContent="space-around">
															<DatePicker
																openTo="year"
																label="Date of birth"
																views={["year", "month", "day"]}
																value={dayjs(selectedDate)}
																maxDate={new Date()}
																minDate={new Date("1900/01/01")}
																showToolbar={true}
																toolbarPlaceholder="Select Date"
																toolbarFormat="MMM D, YYYY"
																onChange={(newValue: any) => {
																	const isValid: any = validateStep1({
																		dateOfBirth: newValue?.$d || null,
																	});
																	handleDateChange(newValue?.$d || null);
																	setErrors({
																		...errors,
																		dateOfBirth: isValid.dateOfBirth,
																	});
																}}
																renderInput={(params: any) => (
																	<TextField
																		{...params}
																		required
																		helperText={errors.dateOfBirth}
																		error={Boolean(errors.dateOfBirth)}
																		onBlur={() => {
																			const isValid: any = validateStep1({
																				dateOfBirth: selectedDate?.$d,
																			});
																			setErrors({
																				...errors,
																				dateOfBirth: isValid.dateOfBirth,
																			});
																		}}
																		color="secondary"
																		margin={props.margin}
																		InputLabelProps={{ shrink: true }}
																		fullWidth
																		variant={props.variant}
																	/>
																)}
															/>
														</Grid>
													</LocalizationProvider>
												) : (
													<TextInput
														fullWidth
														label="Date of Birth"
														value={moment(date).format("MM/DD/YYYY")}
														disabled
													/>
												)}
											</Grid>
											<Grid item {...gridProps}>
												<SelectCountry
													value={values?.country}
													error={errors?.country ? true : false}
													helperText={errors?.country}
													onChange={handleCountryChange}
													onBlur={(event: any) => {
														const isValid: any = profileInfoValidate({
															country: event.target.value,
															city: values.city,
															address: values.address,
															zip: values.zip,
															state: values.state,
														});
														setErrors({
															...errors,
															state: isValid.state,
															country: isValid.country,
															address: isValid.address,
															city: isValid.city,
															zip: isValid.zip,
														});
													}}
												/>
											</Grid>
											<Grid item {...gridProps}>
												{values.country !== "US" && values.country !== "CA" ? (
													<TextInput
														error={Boolean(errors.state)}
														helperText={errors.state}
														fullWidth
														label={"State/Province/Region"}
														name="state"
														disabled={!values.country}
														onChange={handleChange}
														onBlur={(event: any) => {
															const isValid: any = profileInfoValidate({
																state: event.target.value,
																city: values.city,
																address: values.address,
																zip: values.zip,
																country: values.country,
															});
															setErrors({
																...errors,
																state: isValid.state,
																country: isValid.country,
																address: isValid.address,
																city: isValid.city,
																zip: isValid.zip,
															});
														}}
														value={values.state}
													/>
												) : null}
												{values.country === "US" ? (
													<SelectState
														onChange={handleChange}
														value={values.state}
														error={errors?.state ? true : false}
														helperText={errors?.state}
														onBlur={(event: any) => {
															const isValid: any = profileInfoValidate({
																state: event.target.value,
																city: values.city,
																address: values.address,
																zip: values.zip,
																country: values.country,
															});
															setErrors({
																...errors,
																state: isValid.state,
																country: isValid.country,
																address: isValid.address,
																city: isValid.city,
																zip: isValid.zip,
															});
														}}
													/>
												) : null}
												{values.country === "CA" ? (
													<SelectProvince
														onChange={handleChange}
														value={values.state}
														error={errors?.state ? true : false}
														helperText={errors?.state}
														onBlur={(event: any) => {
															const isValid: any = profileInfoValidate({
																state: event.target.value,
																city: values.city,
																address: values.address,
																zip: values.zip,
																country: values.country,
															});
															setErrors({
																...errors,
																state: isValid.state,
																country: isValid.country,
																address: isValid.address,
																city: isValid.city,
																zip: isValid.zip,
															});
														}}
													/>
												) : null}
											</Grid>

											<Grid item {...gridProps}>
												<TextInput
													error={Boolean(errors.address)}
													helperText={errors.address}
													fullWidth
													label={"Street Address"}
													name="address"
													onChange={handleChange}
													onBlur={(event: any) => {
														const isValid: any = profileInfoValidate({
															address: event.target.value,
															city: values.city,
															state: values.state,
															zip: values.zip,
															country: values.country,
														});
														setErrors({
															...errors,
															state: isValid.state,
															country: isValid.country,
															address: isValid.address,
															city: isValid.city,
															zip: isValid.zip,
														});
													}}
													value={values.address}
												/>
											</Grid>
											<Grid item {...gridProps}>
												<TextInput
													error={Boolean(errors.city)}
													helperText={errors.city}
													fullWidth
													label={"City"}
													name="city"
													value={values.city}
													onBlur={(event: any) => {
														const isValid: any = profileInfoValidate({
															city: event.target.value,
															state: values.state,
															address: values.address,
															zip: values.zip,
															country: values.country,
														});
														setErrors({
															...errors,
															state: isValid.state,
															country: isValid.country,
															address: isValid.address,
															city: isValid.city,
															zip: isValid.zip,
														});
													}}
													onChange={handleChange}
												/>
											</Grid>
											<Grid item {...gridProps}>
												<TextInput
													error={Boolean(errors?.zip)}
													helperText={errors?.zip}
													fullWidth
													name="zip"
													label={"Zip/Postal Code"}
													onChange={handleChange}
													onBlur={(event: any) => {
														const isValid: any = profileInfoValidate({
															zip: event.target.value,
															country: values.country,
															city: values.city,
															address: values.address,
															state: values.state,
														});
														setErrors({
															...errors,
															state: isValid.state,
															country: isValid.country,
															address: isValid.address,
															city: isValid.city,
															zip: isValid.zip,
														});
													}}
													value={values.zip}
												/>
											</Grid>

											<Grid item {...gridProps} />
										</Grid>
									</form>
								) : (
									<Loader />
								)}
							</AccordionDetails>
							<AccordionActions sx={{ m: 1, mt: 0 }}>
								<Grid container justifyContent="center">
									<Grid item {...gridProps}>
										<Button
											fullWidth
											onClick={() => handleUpdateProfile()}
											color="primary"
											variant="contained"
											disabled={
												!Object.values(errors).every(
													(x) => x === "" || x === undefined
												) || props.requestingUpdate
											}
										>
											{props.requestingUpdate ? <ButtonLoader /> : "Save"}
										</Button>
									</Grid>
								</Grid>
							</AccordionActions>
						</Accordion>
					</Card>
				</div>
			</Grow>
		</div>
	);
}

import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { Box, Backdrop, Hidden } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Icon from "@mui/material/Icon";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	backgroundColor: theme.palette.text.disabled,
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	backgroundColor: theme.palette.text.disabled,
	overflowX: "hidden",
	width: `calc(${theme.spacing(6)} )`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(7)})`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,

	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));
const classes: any = {
	links: {
		textDecoration: "none",
		color: "primary.main",
		"&:hover": {
			backgroundColor: "#505050",
		},
	},
};
function SideBar(props: any) {
	const { userConfig } = props;
	let path: any = window.location.pathname.split("/")[2];
	const [open, setOpen] = React.useState(false);
	const [selectedIndex, setSelectedIndex] = React.useState<any>(path);
	const items = [
		{
			icon: "dashboard_icon",
			text: "Dashboard",
			link: `/${userConfig?.businessAlias}/dashboard`,
		},
		{
			icon: "person",
			text: "Profile",
			link: `/${userConfig?.businessAlias}/profile`,
		},
		{
			icon: "event",
			text: "Schedule",
			link: `/${userConfig?.businessAlias}/schedule`,
		},
		{
			icon: "post_add_icon",
			text: "Enroll",
			link: `/${userConfig?.businessAlias}/enroll`,
		},
		{
			icon: "shopping_cart",
			text: "Marketplace",
			link: `/${userConfig?.businessAlias}/marketplace`,
		},
	];
	React.useEffect(() => {
		if (path === "signin") {
			path = "profile";
		}
		setSelectedIndex(path);
	}, [path]);

	const handleDrawerToggle = () => {
		setOpen(!open);
	};
	const handleSignOut = () => {
		props.onSignOut();
		setOpen(false);
	};
	const handleMenuClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		index: number
	) => {
		setSelectedIndex(index);
		setOpen(false);
	};

	return (
		<Hidden mdDown>
			{props.user && (
				<Box sx={{ display: "flex" }}>
					<CssBaseline />
					<Backdrop
						open={open}
						style={{ backgroundColor: "rgba(0,0,0,.6)", zIndex: 100 }}
						onClick={handleDrawerToggle}
					/>
					<Drawer variant="permanent" open={open}>
						<DrawerHeader sx={{ pt: "61px", pb: 1 }}>
							<IconButton sx={{ p: 1, pt: 1.2 }} onClick={handleDrawerToggle}>
								{open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
							</IconButton>
						</DrawerHeader>
						<Divider />
						<List component="div" disablePadding>
							{items.map((item, index) => {
								return (
									<Link key={index} style={classes.links} to={item.link}>
										<ListItemButton
											selected={selectedIndex === item.text.toLowerCase()}
											onClick={(event: any) => handleMenuClick(event, index)}
											sx={classes.links}
										>
											<ListItemIcon>
												<Icon>{item.icon}</Icon>
											</ListItemIcon>
											<ListItemText primary={item.text} />
										</ListItemButton>
									</Link>
								);
							})}
							<Link
								style={classes.links}
								to={`/${props.userConfig?.businessAlias}/signin`}
							>
								<ListItemButton
									selected={selectedIndex === 11}
									sx={classes.links}
									onClick={handleSignOut}
								>
									<ListItemIcon>
										<ExitToAppIcon />
									</ListItemIcon>
									<ListItemText primary="Sign Out" />
								</ListItemButton>
							</Link>
						</List>
					</Drawer>
				</Box>
			)}
		</Hidden>
	);
}
const mapStateToProps = (state: any) => {
	return {
		user: state.auth.currentUser,
		errors: state.auth.errors,
		userConfig: state.auth.userConfig,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onSignOut: () => dispatch({ type: "LOGOUT" }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);

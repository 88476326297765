import { call, put, takeEvery } from "redux-saga/effects";
import {
	LOGIN_ERROR,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	CLUB_LOGO_REQUEST,
	CLUB_LOGO_SUCCESS,
	CLUB_LOGO_ERROR,
	CHECK_ALIAS_SUCCESS,
	CHECK_ALIAS_ERROR,
	CHECK_ALIAS_REQUEST,
	LOGOUT,
} from "../constants/users.constants";
import { push } from "react-router-redux";
import {
	loginAttempt,
	requestClubLogo,
	requestCheckAlias,
} from "../services/auth.service";
import { ResponseGenerator } from "../types";

export function* loginRequest(action: any) {
	let token: ResponseGenerator;
	try {
		token = yield call(loginAttempt, action.value);
		if (token && token?.error !== "timeout of 10000ms exceeded") {
			token = { ...token, action };
			yield put({ type: LOGIN_SUCCESS, token });
			if (action.value.path) {
				localStorage.removeItem("path");
				yield put(
					push(`/${action.value.businessAlias}/schedule/${action.value.path}`)
				);
			} else {
				yield put(push(`/${action.value.businessAlias}/dashboard`));
			}
		}
	} catch (error) {
		yield put({ type: LOGIN_ERROR, error });
	}
}

function* getClubLogo(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestClubLogo, action.value);
		if (response) {
			yield put({ type: CLUB_LOGO_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: CLUB_LOGO_ERROR, error });
	}
}

function* checkAlias(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestCheckAlias, action.value);
		if (response.data) {
			yield put({ type: CHECK_ALIAS_SUCCESS, response });
		} else {
			yield put({ type: CHECK_ALIAS_ERROR, response });
		}
	} catch (error) {
		yield put({ type: CHECK_ALIAS_ERROR, error });
	}
}
function logout() {
	console.log("logging out");
}
export function* watchLoginRequest() {
	yield takeEvery(LOGIN_REQUEST, loginRequest);
	yield takeEvery(CLUB_LOGO_REQUEST, getClubLogo);
	yield takeEvery(CHECK_ALIAS_REQUEST, checkAlias);
	yield takeEvery(LOGOUT, logout);
}

export const GET_INVOICES_REQUEST = "GET_INVOICES_REQUEST";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_ERROR = "GET_INVOICES_ERROR";

export const PAY_INVOICES_REQUEST = "PAY_INVOICES_REQUEST";
export const PAY_INVOICES_SUCCESS = "PAY_INVOICES_SUCCESS";
export const PAY_INVOICES_ERROR = "PAY_INVOICES_ERROR";

export const EMAIL_INVOICE_REQUEST = "EMAIL_INVOICE_REQUEST";
export const EMAIL_INVOICE_SUCCESS = "EMAIL_INVOICE_SUCCESS";
export const EMAIL_INVOICE_ERROR = "EMAIL_INVOICE_ERROR";

export const INVOICE_DETAILS_REQUEST = "INVOICE_DETAILS_REQUEST";
export const INVOICE_DETAILS_SUCCESS = "INVOICE_DETAILS_SUCCESS";
export const INVOICE_DETAILS_ERROR = "INVOICE_DETAILS_ERROR";

export const INVOICE_IMAGE_REQUEST = "INVOICE_IMAGE_REQUEST";
export const INVOICE_IMAGE_SUCCESS = "INVOICE_IMAGE_SUCCESS";
export const INVOICE_IMAGE_ERROR = "INVOICE_IMAGE_ERROR";

export const CLEAR_INVOICES_ERROR = "CLEAR_INVOICES_ERROR";

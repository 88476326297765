export const BILLING_PROFILE_REQUEST = "BILLING_PROFILE_REQUEST";
export const BILLING_PROFILE_SUCCESS = "BILLING_PROFILE_SUCCESS";
export const BILLING_PROFILE_ERROR = "BILLING_PROFILE_ERROR";

export const ACCEPTED_CARDS_REQUEST = "ACCEPTED_CARDS_REQUEST";
export const ACCEPTED_CARDS_SUCCESS = "ACCEPTED_CARDS_SUCCESS";
export const ACCEPTED_CARDS_ERROR = "ACCEPTED_CARDS_ERROR";

export const DEFAULT_BILLING_PROFILE_REQUEST =
	"DEFAULT_BILLING_PROFILE_REQUEST";
export const DEFAULT_BILLING_PROFILE_SUCCESS =
	"DEFAULT_BILLING_PROFILE_SUCCESS";
export const DEFAULT_BILLING_PROFILE_ERROR = "DEFAULT_BILLING_PROFILE_ERROR";

export const UPDATE_BILLING_PROFILE_REQUEST = "UPDATE_BILLING_PROFILE_REQUEST";
export const UPDATE_BILLING_PROFILE_SUCCESS = "UPDATE_BILLING_PROFILE_SUCCESS";
export const UPDATE_BILLING_PROFILE_ERROR = "UPDATE_BILLING_PROFILE_ERROR";

export const CREATE_BILLING_PROFILE_REQUEST = "CREATE_BILLING_PROFILE_REQUEST";
export const CREATE_BILLING_PROFILE_SUCCESS = "CREATE_BILLING_PROFILE_SUCCESS";
export const CREATE_BILLING_PROFILE_ERROR = "CREATE_BILLING_PROFILE_ERROR";

export const DELETE_BILLING_PROFILE_REQUEST = "DELETE_BILLING_PROFILE_REQUEST";
export const DELETE_BILLING_PROFILE_SUCCESS = "DELETE_BILLING_PROFILE_SUCCESS";
export const DELETE_BILLING_PROFILE_ERROR = "DELETE_BILLING_PROFILE_ERROR";

export const CLEAR_BILLING_MESSAGE = "CLEAR_BILLING_MESSAGE";

import React from "react";
import {
	FormControlLabel,
	Checkbox,
	Grid,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	Typography,
	TextField,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { validateStep1 } from "./Validation";
import CustomDatePicker from "../Forms/CustomDatePicker";
import dayjs, { Dayjs } from "dayjs";

const classes: any = {
	selectEmpty: {
		marginTop: 1.6,
		fontSize: "12px",
		height: "32px",
		alignItems: "center",
		width: "85px",
		marginRight: "22px",
	},
	type: {
		fontSize: "14px",
		paddingTop: "15px",
		width: "95px",
	},
	color: {
		headerColor: "primary.main",
	},
};

export default function Step1(props: any) {
	const { errors, values, handleBoolean, handleChange, setErrors } = props;
	const [checked, setChecked] = React.useState(values.textAlerts);
	const [phoneType, setPhoneType] = React.useState(values.phoneType || "");
	const [selectedDate, setSelectedDate] = React.useState<Dayjs>(
		dayjs(values.dob)
	);
	React.useEffect(() => {
		if (!props.submitted) {
			setTimeout(() => {
				props.clearError();
			}, 200);
		}
	}, [props.submitted]);
	const handleDateChange = (value: any) => {
		if (value?.$d != null || value != null) {
			setSelectedDate(dayjs(value));
			if (value.$d != "Invalid Date") {
				handleBoolean("dob", new Date(value.$d).toISOString().slice(0, 10));
			}
		} else {
			setSelectedDate(dayjs(null));
			handleBoolean("dob", null);
		}
	};
	const handlePhoneChange = (event: SelectChangeEvent) => {
		setPhoneType(event.target.value as string);
		handleBoolean("phoneType", event.target.value);
	};
	const onCheck = () => {
		checked ? setChecked(false) : setChecked(true);
		handleBoolean("textAlerts", !checked);
	};
	return (
		<form noValidate>
			<Grid container spacing={2}>
				<Grid item xs={12} xl={6}>
					<TextField
						name="firstName"
						color="secondary"
						variant="standard"
						required
						fullWidth
						label={"First Name"}
						autoFocus
						value={values.firstName}
						onChange={handleChange("firstName")}
						onBlur={(event: any) => {
							const isValid: any = validateStep1({
								firstName: event.target.value,
							});
							setErrors({
								...errors,
								firstName: isValid.firstName,
							});
						}}
						error={errors.firstName ? true : false}
						helperText={errors.firstName}
						inputProps={{ maxLength: 50 }}
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>
				<Grid item xs={12} xl={6}>
					<TextField
						required
						fullWidth
						variant="standard"
						color="secondary"
						label={"Last Name"}
						name="lastName"
						InputLabelProps={{ shrink: true }}
						value={values.lastName}
						onChange={handleChange("lastName")}
						onBlur={(event: any) => {
							const isValid: any = validateStep1({
								lastName: event.target.value,
							});
							setErrors({
								...errors,
								lastName: isValid.lastName,
							});
						}}
						error={errors.lastName ? true : false}
						helperText={errors.lastName}
						inputProps={{ maxLength: 50 }}
					/>
				</Grid>
				<Grid item xs={12} xl={6}>
					<div style={{ display: "flex" }}>
						<FormControl
							variant="standard"
							error={errors.phoneType ? true : false}
						>
							<InputLabel shrink id="select-placeholder-label" required>
								Phone Type
							</InputLabel>
							<Select
								color="secondary"
								variant="standard"
								labelId="phoneType"
								id="type"
								required
								value={phoneType}
								onChange={handlePhoneChange}
								onBlur={(event: any) => {
									const isValid: any = validateStep1({
										phoneType: event.target.value,
									});
									setErrors({
										...errors,
										phoneType: isValid.phoneType,
									});
								}}
								sx={[classes.selectEmpty, { pt: 1 }]}
								size="small"
								error={errors.phoneType ? true : false}
							>
								<MenuItem sx={classes.type} value={"CellPhone"}>
									CELL
								</MenuItem>
								<MenuItem sx={classes.type} value={"HomePhone"}>
									HOME
								</MenuItem>
								<MenuItem sx={classes.type} value={"WorkPhone"}>
									WORK
								</MenuItem>
							</Select>
							<Typography
								sx={{ mt: 0.3 }}
								color="error"
								variant="caption"
								component={"span"}
							>
								{errors.phoneType}
							</Typography>
						</FormControl>
						<FormControl fullWidth variant="standard" sx={{ mr: 2 }}>
							<TextField
								InputLabelProps={{
									shrink: true,
								}}
								sx={{ ml: 2, mr: 2 }}
								variant="standard"
								color="secondary"
								margin="none"
								required
								fullWidth
								onChange={handleChange("phone")}
								onBlur={(event: any) => {
									const isValid: any = validateStep1({
										phone: event.target.value,
									});
									setErrors({
										...errors,
										phone: isValid.phone,
									});
								}}
								label="Phone Number"
								name="phone"
								inputProps={{ maxLength: 10 }}
								value={values.phone}
								error={errors.phone ? true : false}
								helperText={errors.phone}
							/>
						</FormControl>
					</div>
				</Grid>
				<Grid item xs={12} xl={6}>
					<CustomDatePicker
						errors={errors}
						values={values}
						setErrors={setErrors}
						setSelectedDate={setSelectedDate}
						selectedDate={selectedDate}
						handleDateChange={handleDateChange}
						handleBoolean={handleBoolean}
						variant="standard"
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Checkbox
								onChange={onCheck}
								value={checked}
								defaultChecked={values.textAlerts ? values.textAlerts : false}
								color="secondary"
								name="textAlerts"
							/>
						}
						label={
							<Typography variant="subtitle2">
								I want to receive inspiration, marketing promotions and updates
								via text
							</Typography>
						}
						color="secondary"
					/>
					<h6 style={{ margin: 10, textAlign: "center" }}>
						*Users under the age of 13 must be added through a guardian's
						account
					</h6>
				</Grid>
			</Grid>
		</form>
	);
}

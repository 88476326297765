import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Toolbar,
	Typography,
	Button,
	FormControl,
	InputLabel,
	Select,
	Grid,
	Dialog,
	Hidden,
	Popover,
	Divider,
	MenuItem,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import PayMembership from "./PayMembership";
import { Membership } from "./MobileMembership";
import InvoiceHistory from "./InvoiceHistory";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ContractPdf from "./ContractPdf";
import { formatCurrency } from "../../../utils/FormatCurrency";
import styles from "../../../styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";

interface Data {
	contractName: string;
	contractEndingDate: string;
	isAutoRenewed: boolean;
	purchasedFor: string;
	dueAmount: any;
	id: number;
	contractStatus: string;
	paidAmount: string;
	actions: any;
	pdfUrl: any;
	contractPayments: any;
}

function createData(
	contractName: string,
	contractEndingDate: string,
	isAutoRenewed: boolean,
	purchasedFor: string,
	dueAmount: any,
	paidAmount: string,
	id: number,
	contractStatus: string,
	actions: any,
	pdfUrl: string,
	contractPayments: any
): Data {
	return {
		contractName,
		contractEndingDate,
		isAutoRenewed,
		purchasedFor,
		dueAmount,
		paidAmount,
		id,
		contractStatus,
		actions,
		pdfUrl,
		contractPayments,
	};
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key
): (
	a: { [key in Key]: number | string },
	b: { [key in Key]: number | string }
) => number {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
	disablePadding: boolean;
	id: keyof Data;
	label: string;
	numeric: boolean;
}

const headCells: HeadCell[] = [
	{
		id: "contractName",
		numeric: false,
		disablePadding: true,
		label: "Description",
	},
	{
		id: "contractEndingDate",
		numeric: true,
		disablePadding: true,
		label: "End Date",
	},
	{
		id: "isAutoRenewed",
		numeric: true,
		disablePadding: true,
		label: "Auto Renewal",
	},
	{
		id: "purchasedFor",
		numeric: true,
		disablePadding: true,
		label: "Purchase For",
	},
	{
		id: "dueAmount",
		numeric: true,
		disablePadding: false,
		label: "Amount Due",
	},
	{ id: "actions", numeric: true, disablePadding: false, label: "" },
];

interface EnhancedTableProps {
	classes: any;
	onRequestSort: (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler =
		(property: keyof Data) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

	return (
		<TableHead sx={classes.tableHead}>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={
							headCell.id === "actions"
								? "center"
								: headCell.id === "isAutoRenewed"
								? "center"
								: "left"
						}
						padding={headCell.id === "contractName" ? "normal" : "none"}
						style={{
							paddingBottom: 10,
							paddingTop: 5,
							fontFamily: "Roboto Condensed, sans-serif",
						}}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							style={{ color: "white" }}
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

const useToolbarStyles: any = {
	title: {
		flex: "1 1 100%",
		fontFamily: "Roboto Condensed, sans-serif",
	},
	header: {
		backgroundColor: "text.disabled",
		color: "white",
		margin: 0,
		px: "15px !important",
		textAlign: "left",
	},
};

interface EnhancedTableToolbarProps {
	filterType: string;
	setFilterType: any;
	onRequestSort: any;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
	const classes = useToolbarStyles;
	const handleFilterType = (event: SelectChangeEvent) => {
		props.setFilterType(event.target.value as string);
	};
	return (
		<Toolbar sx={classes.header}>
			<Typography
				sx={classes.title}
				variant="h6"
				id="tableTitle"
				component="div"
			>
				Memberships
			</Typography>
			<FormControl
				sx={{ width: "19%", color: "white", paddingRight: 1 }}
				size="small"
				variant="outlined"
			>
				<InputLabel
					htmlFor="outlined-age-native-simple"
					style={{ color: "white" }}
				>
					Filter
				</InputLabel>
				<Select
					label="Filter By"
					value={props.filterType}
					onChange={handleFilterType}
					inputProps={{
						name: "filterType",
						id: "outlined-age-native-simple",
						margin: 0,
					}}
					style={{ color: "white" }}
				>
					<MenuItem value="ALL">All</MenuItem>
					<MenuItem value="ACTIVE">Active</MenuItem>
					<MenuItem value="FROZEN">Frozen</MenuItem>
					<MenuItem value="CANCELLED">Cancelled</MenuItem>
					<MenuItem value="PAID">Paid</MenuItem>
					<MenuItem value="UNPAID">Unpaid</MenuItem>
				</Select>
			</FormControl>
		</Toolbar>
	);
};

const classes: any = {
	table: {
		minWidth: 500,
	},
	tableHead: {
		backgroundColor: "text.disabled",
	},
	tableWidth: {
		width: { xs: 320, sm: "100%" },
	},
	tableRowRoot: {
		"&:nth-of-type(odd)": {
			backgroundColor: "#DCDCDC",
		},
	},
};

export default function MembershipsTable(props: any) {
	const { userConfig, memberships, billingProfiles, clubData } = props;
	const [order, setOrder] = React.useState<Order>("asc");
	const [open, setOpen] = React.useState("0");
	const [popoverOpen, setPopoverOpen] = React.useState("");
	const [orderBy, setOrderBy] =
		React.useState<keyof Data>("contractEndingDate");
	const [page, setPage] = React.useState(1);
	const [sortBy, setSortBy] = React.useState("contractEndingDate");
	const [dense] = React.useState(true);
	const [rowsPerPage] = React.useState(5);
	const [filterType, setFilterType] = React.useState("ALL");
	const [noOfPages, setNoOfPages] = React.useState(0);
	const [isDefault, setIsDefault] = React.useState<any>(false);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("sm"));
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	React.useEffect(() => {
		if (memberships) {
			setNoOfPages(Math.ceil(memberships?.length / rowsPerPage));
		}
	}, [memberships]);

	const handlePayMembership = (id: any) => {
		setOpen(id);
	};
	const filterByStatus = (filter: any, contracts: any) => {
		let filteredContracts: any = [];
		contracts.map((contract: any) => {
			if (contract.contractStatus === filter) {
				filteredContracts.push(contract);
			}
		});
		return filteredContracts;
	};
	let rows: any = props.memberships?.map((row: any) =>
		createData(
			row.contractName,
			row.contractEndingDate.slice(0, 10),
			row.isAutoRenewed,
			row.purchasedFor,
			row.dueAmount,
			row.paidAmount,
			row.id,
			row.contractStatus,
			row.actions,
			row.pdfUrl,
			row.contractPayments
		)
	);
	if (filterType === "ALL") {
	} else {
		rows = filterByStatus(filterType, memberships);
	}

	if (!rows) {
		rows = [];
	}
	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	let sortedBillingProfiles: any = [];
	if (props.billingProfiles) {
		sortedBillingProfiles = billingProfiles;
	}
	sortedBillingProfiles = sortedBillingProfiles.sort(function (a: any, b: any) {
		return a.id - b.id || a.name.localeCompare(b.name);
	});
	React.useEffect(() => {
		let billingProfile: any;
		let defaultConfig: any;
		if (billingProfiles) {
			billingProfile = sortedBillingProfiles[sortedBillingProfiles.length - 1];
			defaultConfig = {
				headers: { Authorization: `Bearer ${userConfig.token}` },
				BusinessAlias: userConfig.businessAlias,
				PartnerApiKey: userConfig.partnerApiKey,
				id: billingProfile?.id,
			};
			if (isDefault) {
				props.defaultBillingProfile(defaultConfig);
				setIsDefault(false);
				props.getBillingProfile(props.config);
			}
		}
	}, [sortedBillingProfiles]);
	const handleChange = (event: any, value: any) => {
		setPage(value);
	};
	const handleClose = () => {
		setOpen("0");
	};
	const handleFilterType = (event: SelectChangeEvent) => {
		setFilterType(event.target.value as string);
	};

	const handleSortBy = (event: SelectChangeEvent) => {
		setSortBy(event.target.value as string);
		setOrderBy(event.target.value as keyof Data);
	};

	const handleSortOrder = () => {
		const isAsc = order === "asc";
		setOrder(isAsc ? "desc" : "asc");
	};
	const handlePopoverClick = (
		id: any,
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		setAnchorEl(event.currentTarget);
		setPopoverOpen(id);
	};
	const closePopover = () => {
		setAnchorEl(null);
		setPopoverOpen("0");
	};
	const id = popoverOpen !== "0" ? "simple-popover" : undefined;
	return (
		<>
			<Hidden smDown>
				<EnhancedTableToolbar
					filterType={filterType}
					setFilterType={setFilterType}
					onRequestSort={handleRequestSort}
				/>
				<Divider sx={{ color: "primary.main" }} />
				<TableContainer sx={classes.tableWidth}>
					<Table
						sx={classes.table}
						aria-labelledby="tableTitle"
						size={dense ? "small" : "medium"}
						aria-label="enhanced table"
					>
						<EnhancedTableHead
							classes={classes}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
						/>

						<TableBody>
							{stableSort(rows, getComparator(order, orderBy))
								.slice((page - 1) * rowsPerPage, page * rowsPerPage)
								.map((row: any, index: any) => {
									const labelId = `enhanced-table-checkbox-${index}`;
									return (
										<React.Fragment key={index}>
											<TableRow
												sx={classes.tableRowRoot}
												role="checkbox"
												tabIndex={-1}
												key={row.id + index}
											>
												<TableCell component="th" id={labelId}>
													{row.contractName}
												</TableCell>
												<TableCell padding="none" align="left">
													{row.contractEndingDate.slice(0, 10) === "1970-01-01"
														? null
														: moment(
																row.contractEndingDate.slice(0, 10)
														  ).format("MM/DD/YY")}
												</TableCell>
												<TableCell align="center" sx={{ pr: 5 }}>
													{row.isAutoRenewed ? "Yes" : "No"}
												</TableCell>
												<TableCell padding="none" align="left">
													{row.purchasedFor}
												</TableCell>
												<TableCell padding="none" align="left">
													{formatCurrency(row.dueAmount, clubData)}
												</TableCell>
												<TableCell align="right" sx={{ pr: 2, pl: 0 }}>
													<Button
														style={{ marginBottom: 10 }}
														fullWidth
														variant="contained"
														color="primary"
														onClick={() => handlePayMembership(row.id)}
													>
														Pay
													</Button>
													<Button
														onClick={(event: any) =>
															handlePopoverClick(row.id, event)
														}
														fullWidth
														variant="outlined"
														sx={{ bgcolor: "#FAFAFA" }}
													>
														View
													</Button>
												</TableCell>
											</TableRow>
											<Popover
												id={id}
												key={row.id}
												open={popoverOpen === row.id}
												anchorEl={anchorEl}
												sx={{ margin: 0 }}
												onClose={closePopover}
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "left",
												}}
												transformOrigin={{
													vertical: "bottom",
													horizontal: "right",
												}}
											>
												<Button
													onClick={() => setOpen(`contract ${row.id}`)}
													fullWidth
													variant="outlined"
												>
													View Contract
												</Button>
												<Button
													onClick={() => setOpen(`invoice ${row.id}`)}
													fullWidth
													variant="outlined"
												>
													View Invoices
												</Button>
											</Popover>
											<Dialog
												maxWidth="sm"
												open={open === row.id}
												sx={styles.modal}
											>
												<PayMembership
													{...props}
													isDefault={isDefault}
													setIsDefault={setIsDefault}
													memberships={row}
													handlePayMembership={handlePayMembership}
													onClose={handleClose}
												/>
											</Dialog>
											<Dialog
												maxWidth="sm"
												sx={styles.modal}
												open={open === `invoice ${row.id}`}
												onClose={handleClose}
											>
												<InvoiceHistory
													{...props}
													memberships={row}
													handlePayMembership={() =>
														handlePayMembership(row.id)
													}
													onClose={handleClose}
												/>
											</Dialog>
											<Dialog
												maxWidth="md"
												fullWidth
												open={open === `contract ${row.id}`}
											>
												<ContractPdf
													{...props}
													id={row.id}
													url={row.pdfUrl}
													onClose={handleClose}
													mobile={false}
												/>
											</Dialog>
										</React.Fragment>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
				{noOfPages < 2 || rows.length === 0 ? null : (
					<Grid container justifyContent="center">
						<Grid style={{ padding: 10 }} item>
							<Pagination
								page={page}
								onChange={handleChange}
								count={noOfPages}
								color="secondary"
								boundaryCount={matches ? 0 : 1}
								siblingCount={1}
							/>
						</Grid>
					</Grid>
				)}
				{rows.length === 0 ? (
					<Typography align="center" style={{ padding: 20 }}>
						There are no{" "}
						{filterType === "ALL" ? null : filterType.toLowerCase()} memberships
					</Typography>
				) : null}
			</Hidden>
			<Hidden smUp>
				{rows.length !== 0 && (
					<Grid container direction="column" justifyContent="center">
						<Grid item>
							<Grid style={{ padding: 10 }} container justifyContent="center">
								<FormControl size="small" variant="outlined">
									<InputLabel htmlFor="outlined-age-native-simple">
										Sort By
									</InputLabel>
									<Select
										label="Sort By"
										value={sortBy}
										onChange={handleSortBy}
										style={{ width: 150 }}
										inputProps={{
											name: "sortType",
											id: "sortBy",
											padding: 5,
											margin: 10,
										}}
										color="secondary"
									>
										<MenuItem value="contractEndingDate">Date</MenuItem>
										<MenuItem value="dueAmount">Amount</MenuItem>
										<MenuItem value="purchasedFor">Purchase For</MenuItem>
									</Select>
								</FormControl>
								<Button
									color="primary"
									variant="contained"
									onClick={handleSortOrder}
									style={{ marginLeft: 15 }}
								>
									{order === "asc" ? (
										<ArrowUpwardIcon />
									) : (
										<ArrowDownwardIcon />
									)}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				)}
				<Grid container justifyContent="center" sx={{ margin: 0 }}>
					{rows
						? stableSort(rows, getComparator(order, orderBy))
								.slice((page - 1) * rowsPerPage, page * rowsPerPage)
								.map((membership: any, index: any) => (
									<React.Fragment key={index}>
										<Membership
											key={index}
											index={index}
											membership={membership}
											clubData={props.clubData}
											setOpen={setOpen}
											handlePayMembership={() =>
												handlePayMembership(membership.id)
											}
										/>
										<Dialog
											sx={styles.modal}
											open={open === membership.id}
											fullScreen
										>
											<PayMembership
												{...props}
												isDefault={isDefault}
												setIsDefault={setIsDefault}
												memberships={membership}
												handlePayMembership={handlePayMembership}
												onClose={handleClose}
											/>
										</Dialog>
										<Dialog
											open={open === `invoice ${membership.id}`}
											onClose={handleClose}
											sx={styles.modal}
										>
											<InvoiceHistory
												{...props}
												memberships={membership}
												handlePayMembership={() =>
													handlePayMembership(membership.id)
												}
												onClose={handleClose}
											/>
										</Dialog>
										<Dialog
											maxWidth="md"
											open={open === `contract ${membership.id}`}
											onClose={handleClose}
											fullScreen
											sx={styles.modal}
										>
											<ContractPdf
												{...props}
												id={membership.id}
												setOpen={setOpen}
												url={membership.pdfUrl}
												onClose={handleClose}
												mobile={true}
											/>
										</Dialog>
									</React.Fragment>
								))
						: null}
					{rows.length === 0 ? (
						<Typography align="center" style={{ padding: 20 }}>
							There are no{" "}
							{filterType === "ALL" ? null : filterType.toLowerCase()}{" "}
							memberships
						</Typography>
					) : null}
				</Grid>
				{rows.length !== 0 && (
					<Grid container direction="column" justifyContent="center">
						<Grid item>
							<Grid style={{ padding: 10 }} container justifyContent="center">
								<FormControl
									sx={{ minWidth: 140 }}
									size="small"
									variant="outlined"
								>
									<InputLabel htmlFor="outlined-age-native-simple">
										Filter
									</InputLabel>
									<Select
										label="Filter By"
										value={filterType}
										onChange={handleFilterType}
										inputProps={{
											name: "filterType",
											id: "outlined-age-native-simple",
											margin: 0,
										}}
									>
										<MenuItem value="ALL">All</MenuItem>
										<MenuItem value="ACTIVE">Active</MenuItem>
										<MenuItem value="FROZEN">Frozen</MenuItem>
										<MenuItem value="CANCELLED">Cancelled</MenuItem>
										<MenuItem value="PAID">Paid</MenuItem>
										<MenuItem value="UNPAID">Unpaid</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
				)}
				{noOfPages < 2 || rows.length === 0 ? null : (
					<Grid container justifyContent="center">
						<Grid style={{ padding: 10 }} item>
							<Pagination
								page={page}
								onChange={handleChange}
								count={noOfPages}
								color="secondary"
								boundaryCount={matches ? 0 : 1}
								siblingCount={1}
							/>
						</Grid>
					</Grid>
				)}
			</Hidden>
		</>
	);
}

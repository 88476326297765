import * as React from "react";
import {
	Grid,
	Fade,
	Input,
	Typography,
	FormControl,
	InputAdornment,
	IconButton,
	InputLabel,
	Button,
	Grow,
} from "@mui/material";
import PasswordMeter from "./PasswordMeter";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as validate from "./validate";
import PasswordRequirements from "./PasswordRequirements";
import { connect } from "react-redux";
import Alerts from "../AlertMessage/AlertMessage";
import { BackToSignInButton } from "../../utils/Buttons";
import ButtonLoader from "../../utils/ButtonLoader";
import Error from "../AlertMessage/ErrorPage";

const classes: any = {
	paper: {
		marginTop: 10,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		maxWidth: "500px",
		marginLeft: "auto",
		marginRight: "auto",
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: 1,
	},
	submit: {
		mt: 4,
	},
};

const ChangePassword = (props: any) => {
	const [errors, setErrors] = React.useState<any>("");
	const [isValidPassword, setIsValidPassword] = React.useState<boolean>(false);
	const [values, setValues] = React.useState({
		currentPassword: "",
		newPassword: "",
		confirmPassword: "",
		showCurrentPassword: false,
		showConfirmPassword: false,
		showPassword: false,
		userName: "",
	});
	const systemName = "MP2";
	const businessAlias = window.location.pathname.split("/")[1];
	const partnerApiKey = "mm";
	const isLengthInValid = validate.isLengthInValid(values.newPassword);
	const isContainNumericInValid = validate.isContainNumericInValid(
		values.newPassword
	);
	const isContainAlphabeticInValid = validate.isContainAlphabeticInValid(
		values.newPassword
	);
	const matching = values.newPassword === values.confirmPassword ? true : false;

	React.useEffect(() => {
		let config = { businessAlias: businessAlias, partnerApiKey: partnerApiKey };
		props.getClubLogo(config);
		props.checkAlias(config);
		setTimeout(() => {
			props.clearUserMessages();
			setErrors("");
		}, 200);
	}, []);
	React.useEffect(() => {
		if (values.newPassword.length > 6) {
			setIsValidPassword(props.canReusePassword);
		}
	}, [props.canReusePassword]);
	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};
	const handleShowPassword = () => {
		setValues({ ...values, showCurrentPassword: !values.showCurrentPassword });
	};
	const handleShowConfirmPassword = () => {
		setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
	};
	const handleChange = (prop: any) => (event: any) => {
		setValues({ ...values, [prop]: event.target.value.trim() });
	};
	let config = {
		Username: values.userName,
		OldPassword: values.currentPassword,
		NewPassword: values.newPassword,
		ConfirmPassword: values.confirmPassword,
		SystemName: systemName,
		BusinessAlias: businessAlias,
		PartnerApiKey: partnerApiKey,
	};

	const handleResetPassword = (event: any) => {
		event.preventDefault();
		props.onRequestPasswordChange(config);
	};
	const passwordConfig = {
		Username: values.userName,
		BusinessAlias: businessAlias,
		PartnerApiKey: partnerApiKey,
		Password: values.newPassword,
	};
	const handleValidPassword = (e: any) => {
		e.preventDefault();
		if (values.newPassword?.length > 6) {
			props.isPasswordValid(passwordConfig);
		}
	};
	const passwordsMatch = (e: any) => {
		e.preventDefault();
		if (values.confirmPassword?.length > 0) {
			if (matching) {
				setErrors("");
			} else {
				setErrors("Passwords do not match");
			}
		}
	};
	return (
		<>
			{!props.active ? (
				<Error />
			) : (
				<Grid
					sx={classes.paper}
					container
					justifyContent="center"
					alignItems="center"
				>
					{props.clubLogo && (
						<img src={props.clubLogo} width={240} alt="logo" />
					)}
					<Grow in={true} timeout={600}>
						<Typography style={{ marginTop: 20 }} component="h1" variant="h5">
							Change Password
						</Typography>
					</Grow>
					<form style={{ padding: 20 }}>
						<Grow in={true} timeout={900}>
							<div>
								<Typography align="center" sx={{ pt: 1 }}>
									<em>
										Make sure your new password meets the following complexity
										requirements:
									</em>
								</Typography>
								<Grid container justifyContent="center">
									<Grid item>
										<PasswordRequirements
											isLengthInValid={isLengthInValid}
											isContainNumericAndAlphaInValid={
												isContainNumericInValid || isContainAlphabeticInValid
											}
											isReUsePrevious4Passwords={isValidPassword}
										/>
									</Grid>
								</Grid>
								<Grid item>
									<Fade in={true} timeout={1500}>
										<div style={{ marginTop: 20 }}>
											<PasswordMeter value={values.newPassword} />
										</div>
									</Fade>
								</Grid>
							</div>
						</Grow>
						<Grow in={true} timeout={800}>
							<div>
								<FormControl variant="standard" sx={classes.form}>
									<InputLabel
										color="secondary"
										margin="dense"
										htmlFor="standard-adornment-password"
									>
										User Name *
									</InputLabel>
									<Input
										autoComplete="new-password"
										required
										margin="none"
										color="secondary"
										fullWidth
										type="text"
										value={values.userName}
										onChange={handleChange("userName")}
										error={errors.userName ? true : false}
									/>
								</FormControl>
							</div>
						</Grow>
						<Grow in={true} timeout={800}>
							<div>
								<FormControl variant="standard" sx={classes.form}>
									<>
										<InputLabel
											color="secondary"
											htmlFor="standard-adornment-password"
										>
											Current Password *
										</InputLabel>
										<Input
											autoComplete="new-password"
											required
											color="secondary"
											fullWidth
											type={values.showCurrentPassword ? "text" : "password"}
											value={values.currentPassword}
											onChange={handleChange("currentPassword")}
											error={errors.currentPassword ? true : false}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleShowPassword}
														size="large"
													>
														{values.showCurrentPassword ? (
															<Visibility />
														) : (
															<VisibilityOff />
														)}
													</IconButton>
												</InputAdornment>
											}
										/>
									</>
								</FormControl>
							</div>
						</Grow>
						<Grow in={true} timeout={800}>
							<div>
								<FormControl variant="standard" sx={classes.form}>
									<InputLabel
										color="secondary"
										htmlFor="standard-adornment-password"
									>
										New Password *
									</InputLabel>

									<Input
										required
										color="secondary"
										fullWidth
										autoComplete="new-password"
										type={values.showPassword ? "text" : "password"}
										value={values.newPassword}
										onChange={handleChange("newPassword")}
										onBlur={(event: any) => {
											handleValidPassword(event);
											passwordsMatch(event);
										}}
										error={errors.newPassword ? true : false}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													size="large"
												>
													{values.showPassword ? (
														<Visibility />
													) : (
														<VisibilityOff />
													)}
												</IconButton>
											</InputAdornment>
										}
									/>
									<Typography color="error" variant="caption">
										{errors.password}
									</Typography>
								</FormControl>
							</div>
						</Grow>
						<Grow in={true} timeout={950}>
							<div>
								<FormControl variant="standard" sx={classes.form}>
									<InputLabel
										htmlFor="standard-adornment-password2"
										color="secondary"
									>
										Confirm Password *
									</InputLabel>
									<Input
										color="secondary"
										required
										fullWidth
										autoComplete="new-password"
										type={values.showConfirmPassword ? "text" : "password"}
										value={values.confirmPassword}
										onChange={handleChange("confirmPassword")}
										onBlur={passwordsMatch}
										error={errors.confirmPassword ? true : false}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleShowConfirmPassword}
													size="large"
												>
													{values.showConfirmPassword ? (
														<Visibility />
													) : (
														<VisibilityOff />
													)}
												</IconButton>
											</InputAdornment>
										}
									/>
									{props.errors || errors ? (
										<Alerts
											messages={props.messages}
											errors={props.errors || errors}
											userMessages={props.userMessages}
										/>
									) : null}
								</FormControl>
							</div>
						</Grow>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							onClick={handleResetPassword}
							sx={classes.submit}
							disabled={
								isLengthInValid || isContainNumericInValid || !isValidPassword
							}
						>
							{props.requesting ? <ButtonLoader /> : "Change Password"}
						</Button>
					</form>
					<Grid container justifyContent="center">
						<BackToSignInButton />
					</Grid>
				</Grid>
			)}
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {
		user: state.auth.currentUser,
		errors: state.user.errors,
		userMessages: state.user.messages,
		requesting: state.user.requesting,
		canReusePassword: state.user.canReusePassword,
		clubLogo: state.auth.clubLogo,
		active: state.auth.active,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onRequestPasswordChange: (config: any) =>
			dispatch({ type: "CHANGE_PASSWORD_REQUEST", value: config }),
		isPasswordValid: (config: any) =>
			dispatch({ type: "VALID_PASSWORD_REQUEST", value: config }),
		clearUserMessages: () => dispatch({ type: "CLEAR_USER_MESSAGE" }),
		getClubLogo: (config: any) =>
			dispatch({ type: "CLUB_LOGO_REQUEST", value: config }),
		checkAlias: (config: any) =>
			dispatch({ type: "CHECK_ALIAS_REQUEST", value: config }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

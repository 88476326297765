import React from "react";
import {
	Grid,
	Typography,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableContainer,
	Button,
	Dialog,
	DialogContent,
} from "@mui/material";
import Invoice from "../Invoices/InvoiceView";
import Title from "../Dialogs/Title";
import styles from "../../../styles";
import moment from "moment";

const classes: any = {
	table: {
		width: "100%",
		padding: 20,
		paddingTop: 10,
	},
	link: {
		textDecoration: "underline",
		cursor: "pointer",
	},
	red: {
		color: "red",
	},
};

export default function InvoiceHistory(props: any) {
	const { userConfig, clubData } = props;
	const [open, setOpen] = React.useState("0");
	const handleClose = () => {
		setOpen("0");
	};
	const formatCurrency = (amount: any) => {
		return (
			clubData?.currencyPrefix +
			amount?.toFixed(2) +
			" " +
			(clubData?.currencyPostfix ? clubData?.currencyPostfix : "")
		);
	};
	let membership: any = props.memberships;
	const openInvoice = (id: any) => {
		let config = {
			headers: {
				Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
			},
			partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
			businessAlias: userConfig ? userConfig.businessAlias : null,
			Ids: [id],
			id: userConfig ? userConfig.clientId : null,
		};
		props.getInvoiceDetails(config);
		setTimeout(function () {
			setOpen("100");
			props.getInvoicePdf(config);
		}, 500);
	};
	let config = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		Ids: membership ? [membership.contractPayments[0]?.invoiceId] : null,
		id: userConfig ? userConfig.clientId : null,
	};

	let date = new Date();
	let todaysDate = date.getTime();

	return (
		<>
			<Title title="Invoice History" onClose={props.onClose} />
			<DialogContent>
				<Grid container direction="row" justifyContent="flex-start">
					<Grid key={membership.id} container justifyContent="center">
						<Typography sx={{ pt: 1, px: 1 }} align="center" variant="h6">
							<strong>
								{membership.contractName} - Contract # {membership.id}
							</strong>
						</Typography>
						{membership.contractStatus === "CANCELLED" ||
						membership.contractStatus === "FROZEN" ? (
							<Typography
								style={{ color: "red", marginTop: 10, width: "100%" }}
								align="center"
							>
								This membership has been{" "}
								{membership.contractStatus?.toLowerCase()}
							</Typography>
						) : null}
					</Grid>
					{membership.contractPayments[0]?.invoiceId !== "0" ? (
						<TableContainer style={{ maxHeight: 220, margin: 0, padding: 0 }}>
							<Table
								stickyHeader
								className={classes.table}
								aria-label="simple table"
							>
								<TableHead>
									<TableRow>
										<TableCell
											style={{ padding: 5, paddingLeft: 10 }}
											align="left"
										>
											<Typography align="left" variant="subtitle2">
												<strong>Invoice #</strong>
											</Typography>
										</TableCell>
										<TableCell style={{ padding: 5 }} align="center">
											<strong>Payment Date</strong>
										</TableCell>
										<TableCell style={{ padding: 5 }} align="center">
											<strong>Invoice Amount</strong>
										</TableCell>
										<TableCell style={{ padding: 5 }} align="center">
											<strong>Amount Paid</strong>
										</TableCell>
										<TableCell
											style={{ padding: 5, paddingRight: 10 }}
											align="right"
										>
											<strong>Balance Due</strong>
										</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{membership.contractPayments.map((payment: any) =>
										payment.invoiceId == 0 ? null : (
											<TableRow key={payment.id}>
												<TableCell style={{ padding: 10 }} align="left">
													<a
														className={classes.link}
														onClick={() => openInvoice(payment.invoiceId)}
													>
														{payment.invoiceId}
													</a>
												</TableCell>
												<TableCell
													className={
														payment.paidAmount < payment.amount &&
														todaysDate >
															new Date(
																payment.paymentDate.slice(0, 10)
															).getTime() +
																86400000
															? classes.red
															: ""
													}
													style={{ padding: 10 }}
													align="center"
												>
													{moment(payment.paymentDate.slice(0, 10)).format(
														"MM/DD/YYYY"
													)}
												</TableCell>
												<TableCell
													className={
														payment.paidAmount < payment.amount &&
														todaysDate >
															new Date(
																payment.paymentDate.slice(0, 10)
															).getTime() +
																86400000
															? classes.red
															: ""
													}
													style={{ padding: 10 }}
													align="center"
												>
													{formatCurrency(payment?.amount)}
												</TableCell>
												<TableCell
													className={
														payment.paidAmount < payment.amount &&
														todaysDate >
															new Date(
																payment.paymentDate.slice(0, 10)
															).getTime() +
																86400000
															? classes.red
															: ""
													}
													style={{ padding: 10 }}
													align="center"
												>
													{formatCurrency(payment.paidAmount)}
												</TableCell>
												<TableCell
													className={
														payment.paidAmount < payment.amount &&
														todaysDate >
															new Date(
																payment.paymentDate.slice(0, 10)
															).getTime() +
																86400000
															? classes.red
															: ""
													}
													style={{ padding: 10, paddingRight: 10 }}
													align="right"
												>
													{formatCurrency(payment.amount - payment.paidAmount)}
												</TableCell>
												<Dialog
													open={open === "100"}
													maxWidth="md"
													fullWidth
													sx={styles.modal}
												>
													<Title
														title="Membership Invoice"
														onClose={handleClose}
													/>
													<Invoice
														{...props}
														type={"membership"}
														config={config}
														membership={membership}
													/>
												</Dialog>
											</TableRow>
										)
									)}
								</TableBody>
							</Table>
						</TableContainer>
					) : (
						<Grid container justifyContent="center">
							<Typography style={{ color: "red" }} align="center">
								There are no past invoices
							</Typography>
						</Grid>
					)}

					<Grid container justifyContent="center" sx={{ mt: 2 }} spacing={2}>
						<Grid item xs={12} sm={6}>
							<Button
								fullWidth
								variant="outlined"
								color="primary"
								onClick={props.onClose}
							>
								Back
							</Button>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Button
								onClick={props.handlePayMembership}
								fullWidth
								variant="contained"
								color="primary"
							>
								Pay
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</>
	);
}

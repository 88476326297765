import React from "react";
import {
	Dialog,
	Typography,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import Countdown from "react-countdown";
import { connect } from "react-redux";
import { history } from "../utils/history";

function Timeout(props: any) {
	const [showModal, setShowModal] = React.useState(false);
	const pathList = [
		"signin",
		"signup",
		"forgot",
		"resetpassword",
		"setpassword",
		"changepassword",
	];
	const path: any = window.location.pathname.split("/")[2];
	const alias: any = window.location.pathname.split("/")[1];
	const validPath: any = !pathList.includes(path);

	let t: any;
	function idleLogout() {
		window.onload = resetTimer;
		window.onmousemove = resetTimer;
		window.onmousedown = resetTimer; // catches touchscreen presses as well
		window.ontouchstart = resetTimer; // catches touchscreen swipes as well
		window.ontouchmove = resetTimer; // required by some devices
		window.onclick = resetTimer; // catches touchpad clicks as well
		window.onkeydown = resetTimer;
		window.addEventListener("scroll", resetTimer, true); // improved; see comments
	}
	const openModal = () => {
		setShowModal(true);
	};

	function resetTimer() {
		clearTimeout(t);
		t = setTimeout(openModal, 1800000); // time is in milliseconds (30 mins)
	}
	React.useEffect(() => {
		idleLogout();
	}, []);

	React.useEffect(() => {
		if (props.user && validPath) {
			window.onload = resetTimer;
			setShowModal(false);
		}
	}, [props.user]);

	const signOut = () => {
		setShowModal(false);
		props.onSignOut();
		history.push(`/${alias}/signin`);
	};

	return (
		<Dialog
			maxWidth="sm"
			fullWidth
			open={showModal && props.user && validPath ? true : false}
		>
			<DialogTitle
				sx={{
					bgcolor: "primary.main",
					color: "white",
					width: "100%",
					textAlign: "center",
				}}
			>
				Are you still there?
			</DialogTitle>
			<DialogContent sx={{ p: 2, pb: 1 }}>
				<Typography variant="h6" align="center" sx={{ pt: 1 }}>
					You will be signed out in:
				</Typography>
				<Typography align="center" variant="h4" sx={{ pt: 1.5 }}>
					<Countdown
						date={Date.now() + 60000}
						zeroPadTime={2}
						daysInHours={true}
						onComplete={signOut}
					/>
				</Typography>
			</DialogContent>
			<DialogActions sx={{ p: 2 }}>
				<Button
					onClick={signOut}
					fullWidth
					color="primary"
					variant="contained"
					sx={{ mr: 1 }}
				>
					Sign Out
				</Button>
				<Button
					onClick={() => setShowModal(false)}
					fullWidth
					color="primary"
					variant="contained"
					sx={{ ml: 1 }}
				>
					I'm Here
				</Button>
			</DialogActions>
		</Dialog>
	);
}
const mapStateToProps = (state: any) => {
	return {
		user: state.auth.currentUser,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onSignOut: () => dispatch({ type: "LOGOUT" }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Timeout);

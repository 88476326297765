import axios from "axios";
import * as URL from "../config";

export function requestBillingProfile(action: any) {
	const getBillingProfileUrl = `${URL.MP_MEMBER}/clients/billingProfiles?ClientId=${action.id}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}`;
	return axios
		.get(getBillingProfileUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestCreateBillingProfile(action: any) {
	const createBillingProfileUrl = `${URL.MP_MEMBER}/billingProfiles`;
	return axios
		.post(createBillingProfileUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestUpdateBillingProfile(action: any) {
	const updateBillingProfileUrl = `${URL.MP_MEMBER}/billingProfiles`;
	return axios
		.put(updateBillingProfileUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestDeleteBillingProfile(action: any) {
	const deleteBillingProfileUrl = `${URL.MP_MEMBER}/billingProfiles?Id=${action.id}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}`;
	return axios
		.delete(deleteBillingProfileUrl, { data: action, headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestDefaultBillingProfile(action: any) {
	const defaultBillingProfileUrl = `${URL.MP_MEMBER}/billingProfiles/makeDefault`;
	return axios
		.post(defaultBillingProfileUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestGetAcceptedCards(action: any) {
	const getAcceptedCardsUrl = `${URL.MP_MEMBER}/clubs/details?businessAlias=${action.businessAlias}&partnerApiKey=${action.partnerApiKey}`;
	return axios
		.get(getAcceptedCardsUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

import { call, put, takeEvery } from "redux-saga/effects";
import * as constants from "../constants/memberships.constants";
// import { push } from "react-router-redux";
import {
	requestGetMemberships,
	requestPayMembership,
	requestContractPdf,
} from "../services/memberships.service";
import { ResponseGenerator } from "../types";

export function* getMemberships(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestGetMemberships, action.value);
		if (response) {
			yield put({ type: constants.GET_MEMBERSHIPS_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.GET_MEMBERSHIPS_ERROR, error });
	}
}
export function* payMembership(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestPayMembership, action.value);
		if (response.status === 200) {
			yield put({ type: constants.PAY_MEMBERSHIP_SUCCESS, response });
		} else {
			yield put({ type: constants.PAY_MEMBERSHIP_ERROR, response });
		}
	} catch (error) {
		yield put({ type: constants.PAY_MEMBERSHIP_ERROR, error });
	}
}
export function* getContractPdf(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestContractPdf, action.value);
		if (response) {
			yield put({ type: constants.GET_CONTRACT_PDF_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.GET_CONTRACT_PDF_ERROR, error });
	}
}
export function* watchMembershipsRequest() {
	yield takeEvery(constants.GET_MEMBERSHIPS_REQUEST, getMemberships);
	yield takeEvery(constants.PAY_MEMBERSHIP_REQUEST, payMembership);
	yield takeEvery(constants.GET_CONTRACT_PDF_REQUEST, getContractPdf);
}

import React from "react";
import { CssBaseline, Grid, Typography, Box } from "@mui/material";
import Stepper from "./Stepper";
import Error from "../AlertMessage/ErrorPage";

const classes: any = {
	paper: {
		mt: 12,
		mx: 4,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	headerText: {
		paddingTop: "20px",
		paddingBottom: "5px",
		marginBottom: "20px",
		borderBottom: `solid 2px`,
		borderColor: "secondary.main",
	},
};

export default function SignUp(props: any) {
	const alias: any = window.location.pathname.split("/")[1];
	const config: any = { businessAlias: alias, partnerApiKey: "mm" };
	React.useEffect(() => {
		props.getClubDetails(config);
	}, []);

	return (
		<Grid container justifyContent="center">
			<CssBaseline />
			{!props.clubData && props.clubDataSuccess ? (
				<Error />
			) : (
				<Grid item xs={12} sm={12} md={5}>
					<Box sx={classes.paper}>
						{props.clubData?.logoPath ? (
							<img src={props.clubData?.logoPath} width={240} alt="logo" />
						) : null}
						<Typography sx={classes.headerText} component="h3" variant="h5">
							SIGN UP
						</Typography>
						{props.clubData.canUserSelfRegister && (
							<Typography style={{ fontSize: 16 }} component="h6" variant="h5">
								SIGN UP IN 3 EASY STEPS
							</Typography>
						)}
						<Stepper {...props} />
					</Box>
				</Grid>
			)}
		</Grid>
	);
}

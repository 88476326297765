import * as constants from "../constants/dashboard.constants";

const initialState = {
	requesting: false,
	successful: false,
	requestinAlerts: false,
	successfulAlerts: false,
	errors: null,
	loadError: false,
	messages: null,
	alerts: null,
	data: null,
};

function dashboardReducer(state = initialState, action: any) {
	switch (action.type) {
		case constants.GET_DASHBOARD_ALERTS_REQUEST:
			return {
				...state,
				requestingAlerts: true,
				successfulAlerts: false,
			};
		case constants.GET_DASHBOARD_ALERTS_SUCCESS:
			return {
				...state,
				requestingAlerts: false,
				successfulAlerts: true,
				alerts: action.response,
			};
		case constants.GET_DASHBOARD_ALERTS_ERROR:
			return {
				...state,
				requestingAlerts: false,
				successfulAlerts: false,
				errors: action.data?.error || action.error?.message,
			};
		case constants.GET_DASHBOARD_DATA_REQUEST:
			return {
				...state,
				requesting: true,
				successful: false,
				loadError: false,
			};
		case constants.GET_DASHBOARD_DATA_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				data: action.response.data,
				loadError: false,
			};
		case constants.GET_DASHBOARD_DATA_ERROR:
			console.log("action", action);
			return {
				...state,
				requesting: false,
				successful: true,
				errors: action.data?.error || action.error?.message,
				loadError: true,
			};
		default:
			return state;
	}
}

export default dashboardReducer;

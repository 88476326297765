import React from "react";
import {
	Typography,
	Button,
	Grid,
	Grow,
	TextField,
	FormControlLabel,
	Checkbox,
	DialogContent,
	DialogActions,
	DialogContentText,
} from "@mui/material";
import { addUserValidate } from "../validate";
import moment from "moment";
import Notifications from "../../AlertMessage/Notifications";
import Title from "./Title";
import Loader from "../../../utils/Loader";
import CustomDatePicker from "../../Forms/CustomDatePicker";
import ButtonLoader from "../../../utils/ButtonLoader";
import dayjs from "dayjs";

const classes: any = {
	form: {
		justifyContent: "center",
		padding: 20,
	},
};
export default function ManageUsers(props: any) {
	const [userId, setUserId] = React.useState<any>("");
	const [currentUser, setCurrentUser] = React.useState<any>({});
	const [errors, setErrors] = React.useState<any>({});
	const [openScreen, setOpenScreen] = React.useState<any>("");
	const [disabled, setDisabled] = React.useState<boolean>(false);
	const [initialLoad, setInitialLoad] = React.useState<boolean>(false);
	const [values, setValues] = React.useState<any>({
		userName: "",
		email: "",
		firstName: "",
		lastName: "",
		dob: "",
		address: "",
		city: "",
		state: "",
		zip: "",
		country: "",
	});
	const [selectedDate, setSelectedDate] = React.useState<any>(null);
	const [checked, setChecked] = React.useState<boolean>(false);
	const { users, getProfileInfo, clearUserNameError, userInfo, userConfig } =
		props;
	const handleCheck = () => {
		if (checked) {
			setChecked(false);
			handleSameAddress();
		} else {
			setChecked(true);
			handleSameAddress();
		}
	};
	const handleBoolean = (prop: string, value: any) => {
		setErrors({ ...errors, [prop]: "" });
		setValues({ ...values, [prop]: value });
	};
	const handleSameAddress = () => {
		if (!checked) {
			setValues({
				...values,
				address: userInfo.address ? userInfo.address : "",
				city: userInfo.city ? userInfo.city : "",
				zip: userInfo.zip ? userInfo.zip : "",
				state: userInfo.state ? userInfo.state : "",
				country: userInfo.country ? userInfo.country : "",
			});
		} else {
			setValues({
				...values,
				address: "",
				city: "",
				zip: "",
				state: "",
				country: "",
			});
		}
	};
	React.useEffect(() => {
		if (!initialLoad) {
			getUsers();
			getProfileInfo(props.config);
			clearUserNameError();
			setInitialLoad(true);
		}
	}, [initialLoad]);
	React.useEffect(() => {
		if (
			props.messages === "User successfully updated" ||
			props.messages === "User successfully created"
		)
			getUsers();
	}, [props.messages]);

	let sortedUsers: any = users?.sort(function (a: any, b: any) {
		if (a.fullName < b.fullName) {
			return -1;
		}
		if (a.fullName > b.fullName) {
			return 1;
		}
		return 0;
	});
	const handleChange = (event: any) => {
		let prop: any = event.target.name;
		if (errors[prop]) {
			setErrors({ ...errors, [event.target.name]: "" });
		}
		if (event.target.name === "userName") {
			props.clearUserNameError();
		}
		setValues({ ...values, [event.target.name]: event.target.value });
	};
	const handleDateChange = (value: any) => {
		if (value != null) {
			setSelectedDate(value?.$d);
			if (value?.$d != "Invalid Date") {
				handleBoolean("dob", new Date(value?.$d)?.toISOString()?.slice(0, 10));
			}
		} else {
			setSelectedDate(dayjs(null));
			handleBoolean("dob", null);
		}
	};
	const getUsers = () => {
		let config = {
			headers: { Authorization: `Bearer ${userConfig.token}` },
			partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
			businessAlias: userConfig ? userConfig.businessAlias : null,
			clientId: userConfig.clientId,
		};
		props.getUsers(config);
	};
	const handleAddProfile = (name: any) => {
		props.clearUserNameError();
		setErrors({});
		setDisabled(false);
		setValues({
			userName: "",
			email: "",
			firstName: "",
			lastName: "",
			dob: "",
		});
		setSelectedDate(null);
		setChecked(false);
		setOpenScreen(name);
	};
	let date: any;
	const handleEditProfile = (user: any) => {
		setErrors({});
		setCurrentUser(user);
		setUserId(user.id);
		setOpenScreen("editUser");
		setValues({
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email ? user.email : "",
			userName: user.userName ? user.userName : "",
			dob: user.dateOfBirth ? user.dateOfBirth : "",
		});
		setSelectedDate(user.dateOfBirth ? user.dateOfBirth.slice(0, 10) : null);
		if (user.userName) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
		if (user.address === userInfo.address) {
			setChecked(true);
		} else {
			setChecked(false);
		}
	};
	const handleEditUser = () => {
		let config = {
			id: userId,
			headers: { Authorization: `Bearer ${userConfig.token}` },
			parentClientId: userConfig.clientId,
			partnerApiKey: userConfig.partnerApiKey,
			businessAlias: userConfig.businessAlias,
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			firstName: values.firstName,
			lastName: values.lastName,
			email: values.email ? values.email : "",
			userName: values.userName ? values.userName : "",
			dateOfBirth: values.dob ? values.dob : "",
			address: values.address ? values.address : "",
			zip: values.zip ? values.zip : "",
			city: values.city ? values.city : "",
			country: values.country ? values.country : "",
			state: values.state ? values.state : "",
		};
		let valid: any;
		valid = addUserValidate(values);
		if (valid) {
			props.updateUser(config);
			setOpenScreen("");
		}
	};
	const handleCreateUser = (e: any) => {
		e.preventDefault();
		let config = {
			headers: { Authorization: `Bearer ${userConfig.token}` },
			parentClientId: userConfig.clientId,
			partnerApiKey: userConfig.partnerApiKey,
			businessAlias: userConfig.businessAlias,
			firstName: values.firstName,
			lastName: values.lastName,
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			email: values.email ? values.email : "",
			userName: values.userName ? values.userName : "",
			dateOfBirth:
				values.dob && values.dob !== "invalid"
					? new Date(values.dob)?.toISOString()?.slice(0, 10)
					: "invalid",
			address: values.address ? values.address : "",
			zip: values.zip ? values.zip : "",
			city: values.city ? values.city : "",
			country: values.country ? values.country : "",
			state: values.state ? values.state : "",
		};
		if (!props.userTaken) {
			let valid: any;
			valid = addUserValidate(values);
			const isValid = Object.values(valid).every((x) => x === "");
			if (isValid) {
				props.createUser(config);
				if (!props.requesting) {
					setOpenScreen("");
				}
			} else {
				setErrors({ ...errors, ...valid });
			}
		}
	};
	const config = {
		username: values.userName,
		partnerApiKey: userConfig.partnerApiKey,
		businessAlias: userConfig.businessAlias,
	};
	date = values.dob?.toString().slice(0, 10);

	return (
		<>
			{openScreen ? <div /> : null}
			{sortedUsers ? (
				<>
					<Title title="Manage Users" onClose={props.onClose} />
					<DialogContent sx={{ pb: 0 }}>
						<DialogContentText sx={{ pb: 0 }}>
							{props.messages || props.errors ? (
								<Notifications
									messages={props.messages}
									errors={props.errors}
								/>
							) : null}
							<Grid container direction="row" justifyContent="center">
								{openScreen !== "editUser" &&
									(openScreen !== "addUser" ? (
										<>
											<Typography
												align="center"
												variant="h5"
												color="secondary"
												sx={{ pt: 1, width: "100%", mb: 1 }}
											>
												Current Users
											</Typography>
											{props.users.length === 0 ? (
												<Typography style={{ margin: 50 }} align="center">
													There are no current users at this time.
												</Typography>
											) : (
												<>
													{sortedUsers?.map((user: any, index: any) => (
														<Grid
															key={index}
															container
															direction="row"
															justifyContent="space-between"
															alignItems="center"
															sx={{
																textTransform: "uppercase",
																maxWidth: 320,
															}}
														>
															<Grid
																item
																xs={7}
																style={{
																	overflowWrap: "break-word",
																	justifyContent: "flex-end",
																}}
															>
																<Typography align="left" variant="subtitle1">
																	{user.fullName}
																</Typography>
															</Grid>

															<Grid item xs={5}>
																<Button
																	style={{
																		marginTop: 10,
																		marginBottom: 10,
																	}}
																	variant="outlined"
																	onClick={() => handleEditProfile(user)}
																>
																	Edit User
																</Button>
															</Grid>
														</Grid>
													))}
												</>
											)}
										</>
									) : null)}
								<Grid
									container
									direction="row"
									justifyContent="center"
									columnSpacing={2}
									sx={{ p: 2, pb: 0 }}
								>
									<Grid item>
										{openScreen === "addUser" || openScreen === "editUser" ? (
											<div>
												<form style={classes.form}>
													<Typography
														color="secondary"
														variant="h5"
														align="center"
														sx={{ mb: 0.5 }}
													>
														{openScreen === "addUser"
															? "Add User Profile"
															: null}
														{openScreen === "editUser"
															? "Edit User Profile"
															: null}
													</Typography>
													<Grid
														container
														justifyContent="space-between"
														direction="row"
														columnSpacing={2}
													>
														<Grow in={true} timeout={150}>
															<Grid item xs={12} md={6} lg={6}>
																<TextField
																	margin="normal"
																	fullWidth
																	required
																	variant="outlined"
																	autoFocus
																	InputLabelProps={{ shrink: true }}
																	label={"First Name"}
																	name="firstName"
																	onChange={handleChange}
																	onBlur={(event: any) => {
																		const isValid: any = addUserValidate({
																			firstName: event.target.value,
																		});
																		setErrors({
																			...errors,
																			firstName: isValid.firstName,
																		});
																	}}
																	color="secondary"
																	value={values.firstName}
																	error={errors.firstName ? true : false}
																	helperText={errors.firstName}
																/>
															</Grid>
														</Grow>
														<Grow in={true} timeout={150}>
															<Grid item xs={12} md={6} lg={6}>
																<TextField
																	margin="normal"
																	fullWidth
																	required
																	InputLabelProps={{ shrink: true }}
																	variant="outlined"
																	label={"Last Name"}
																	name="lastName"
																	onChange={handleChange}
																	onBlur={(event: any) => {
																		const isValid: any = addUserValidate({
																			lastName: event.target.value,
																		});
																		setErrors({
																			...errors,
																			lastName: isValid.lastName,
																		});
																	}}
																	color="secondary"
																	value={values.lastName}
																	error={errors.lastName ? true : false}
																	helperText={errors.lastName}
																/>
															</Grid>
														</Grow>
														<Grow in={true} timeout={150}>
															<Grid item xs={12} md={6} lg={6}>
																<TextField
																	margin="normal"
																	fullWidth
																	disabled={disabled}
																	InputLabelProps={{ shrink: true }}
																	label={"Username"}
																	name="userName"
																	onChange={handleChange}
																	onBlur={(event: any) => {
																		const isValid: any = addUserValidate({
																			userName: event.target.value,
																			email: values.email,
																		});
																		setErrors({
																			...errors,
																			userName: isValid.userName,
																			email: isValid.email,
																		});
																		props.checkUserName(config);
																	}}
																	variant="outlined"
																	color="secondary"
																	value={values.userName || ""}
																	error={
																		errors.userName || props.userTaken
																			? true
																			: false
																	}
																	helperText={
																		props.userTaken
																			? "User name is already taken"
																			: errors.userName
																	}
																/>
															</Grid>
														</Grow>
														<Grow in={true} timeout={150}>
															<Grid item xs={12} md={6} lg={6}>
																<TextField
																	margin="normal"
																	fullWidth
																	variant="outlined"
																	InputLabelProps={{ shrink: true }}
																	label={"Email"}
																	name="email"
																	onChange={handleChange}
																	onBlur={(event: any) => {
																		const isValid: any = addUserValidate({
																			email: event.target.value,
																			userName: values.userName,
																		});
																		setErrors({
																			...errors,
																			email: isValid.email,
																		});
																	}}
																	color="secondary"
																	value={values.email || ""}
																	error={errors.email ? true : false}
																	helperText={errors.email}
																/>
															</Grid>
														</Grow>
														<Grow in={true} timeout={150}>
															<Grid item xs={12} md={6} lg={6}>
																{openScreen === "addUser" ||
																currentUser?.dateOfBirth?.slice(0, 10) ===
																	"1970-01-01" ? (
																	<CustomDatePicker
																		errors={errors}
																		value={values}
																		setErrors={setErrors}
																		selectedDate={selectedDate}
																		handleDateChange={handleDateChange}
																		handleBoolean={handleBoolean}
																		variant="outlined"
																		margin="normal"
																		addUser={true}
																	/>
																) : (
																	<TextField
																		fullWidth
																		margin="normal"
																		variant="outlined"
																		label="Date of Birth"
																		value={moment(date).format("MM/DD/YYYY")}
																		disabled
																	/>
																)}
															</Grid>
														</Grow>

														<Grow in={true} timeout={150}>
															<Grid
																item
																sx={{ alignSelf: "center" }}
																xs={12}
																md={6}
																lg={6}
															>
																<FormControlLabel
																	control={
																		<Checkbox
																			checked={checked}
																			onChange={handleCheck}
																			name="Same as address on account"
																		/>
																	}
																	label="Same as address on account"
																/>
															</Grid>
														</Grow>
													</Grid>
												</form>
											</div>
										) : null}
									</Grid>
								</Grid>
							</Grid>
						</DialogContentText>
					</DialogContent>
					{openScreen ? (
						<DialogActions sx={{ p: 2 }}>
							<Button
								sx={{ mr: 1 }}
								fullWidth
								onClick={() => setOpenScreen("")}
								color="primary"
								variant="outlined"
							>
								Cancel
							</Button>

							{openScreen === "addUser" && (
								<Button
									sx={{ ml: 1 }}
									fullWidth
									onClick={handleCreateUser}
									color="primary"
									variant="contained"
									disabled={
										!Object.values(errors).every(
											(x) => x === "" || x === undefined
										)
									}
								>
									{props.requesting ? <ButtonLoader /> : "Save"}
								</Button>
							)}
							{openScreen === "editUser" && (
								<Button
									sx={{ ml: 1 }}
									fullWidth
									onClick={() => handleEditUser()}
									color="primary"
									variant="contained"
									disabled={
										!Object.values(errors).every(
											(x) => x === "" || x === undefined
										)
									}
								>
									Save
								</Button>
							)}
						</DialogActions>
					) : (
						<DialogActions>
							<Grid container justifyContent="center">
								<Grid item xs={12} sm={6}>
									<Button
										fullWidth
										variant="contained"
										color="primary"
										onClick={() => handleAddProfile("addUser")}
										sx={{ mb: 1, mt: 1 }}
									>
										Add User
									</Button>
								</Grid>
							</Grid>
						</DialogActions>
					)}
				</>
			) : (
				<Loader />
			)}
		</>
	);
}

import React, { useRef } from "react";
import {
	FormControlLabel,
	Grid,
	FormHelperText,
	Typography,
	Button,
	Radio,
	FormControl,
	TextField,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	Box,
} from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import SignatureCanvas from "react-signature-canvas";
import { validateQuestionnaires } from "./validate";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import axios from "axios";
import { MP_MEMBER } from "../../../config";
import Title from "../Dialogs/Title";
import styles from "../../../styles";

const classes: any = {
	sigPad: {
		height: "20vh",
		border: "1px solid black",
		width: "100%",
	},
};
export default function CompletedQuestionnaire(props: any) {
	const { matches } = props;
	const [values, setValue] = React.useState<any>([]);
	const [errors, setErrors] = React.useState<any>([]);
	const [signatureImage, setSignatureImage] = React.useState<any>("");
	const sigCanvas: any = useRef({});
	const [open, setOpen] = React.useState(false);
	React.useEffect(() => {
		setAnswers();
	}, []);
	React.useEffect(() => {
		if (props.messages === "Successfully updated Questionnaire") {
			setAnswers();
		}
	}, [props.messages]);
	React.useEffect(() => {
		let config = {
			headers: {
				Authorization: `Bearer ${
					props.userConfig ? props.userConfig.token : null
				}`,
			},
			partnerApiKey: props.userConfig ? props.userConfig.partnerApiKey : null,
			businessAlias: props.userConfig ? props.userConfig.businessAlias : null,
			id: props.userConfig ? props.userConfig.clientId : null,
			imageName: props.assignments.signature,
		};
		const getSignatureImageUrl: any = `${MP_MEMBER}/media?MediaPath=${config.imageName}&PartnerApiKey=${config.partnerApiKey}&BusinessAlias=${config.businessAlias}`;
		axios
			.get(getSignatureImageUrl, config)
			.then((response) => {
				setSignatureImage(response.data.base64Content);
				return response;
			})
			.catch((error) => {
				console.log("error", error);
				throw error;
			});
	}, []);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let { name, value } = event.target;
		setValue({ ...values, [name]: value });
	};
	let sigImage: any = "";
	const validate = () => {
		let signature;
		if (!sigCanvas.current.isEmpty()) {
			signature = "signature";
		} else {
			signature = null;
		}
		let answerz: any = [];
		let i: any;
		for (i = 0; i < props.questions.length; i++) {
			answerz[i] = values[props.assignments.answers[i].id];
		}
		let answerQuantity = answerz.length;
		return validateQuestionnaires(
			answerz,
			setErrors,
			answerQuantity,
			signature
		);
	};

	const submitAnswers = () => {
		let getAnswers = props.assignments.answers.map((answer: any) => ({
			Question: answer.question,
			Answer: answer.questionType === "text_block" ? null : values[answer.id],
			id: answer.id,
			order: answer.order,
			QuestionType: answer.questionType,
		}));
		return getAnswers;
	};
	const clear = () => {
		sigCanvas.current.clear();
	};
	const submitSig = () => {
		if (!sigCanvas.current.isEmpty()) {
			sigImage = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
		} else {
			sigImage = "";
		}
	};

	const setAnswers = () => {
		let answers: any = [];
		if (answers.length <= props.questions.length) {
			props.assignments.answers.forEach((answer: any) => {
				answers = { ...answers, [answer.id]: answer.answer };
			});
		}
		setValue(answers);
	};
	const handleUpdate = () => {
		submitSig();
		let answers = submitAnswers();
		let valid;
		valid = validate();
		const config = {
			BusinessAlias: props.userConfig ? props.userConfig.businessAlias : null,
			PartnerApiKey: props.userConfig ? props.userConfig.partnerApiKey : null,
			SystemName: props.userConfig ? props.userConfig.systemName : null,
			id: props.assignments.id,
			QuestionnaireId: props.assignments.questionnaireId,
			clientId: props.userConfig.clientId,
			QuestionnaireName: props.assignments.questionnaireName,
			Accepted: props.assignments.accepted,
			AssignmentDate: props.assignments.assignmentDate,
			SubmitDate: new Date().toISOString(),
			base64SignatureImage: sigImage.substring(22),
			signatureImageExtension: "png",
			Answers: answers,
			headers: { Authorization: `Bearer ${props.userConfig.token}` },
		};
		if (valid) {
			props.onUpdate(config);
			handleClose();
		}
	};
	const sortedAnswers = props.assignments.answers.sort(function (
		a: any,
		b: any
	) {
		return a.order - b.order || a.name.localeCompare(b.name);
	});
	const renderAnswers = (question: any, index: any) => {
		return (
			<React.Fragment key={question.id}>
				<div>{ReactHtmlParser(question.question)}</div>
				<FormControl fullWidth component="fieldset">
					<div>
						{question.questionType === "boolean_question" ? (
							<>
								{props.status === "WRITE" ? (
									<div style={{ display: "flex" }}>
										<FormControlLabel
											value="yes"
											name={question.id.toString()}
											control={
												<Radio
													checked={values[question.id] === "yes"}
													onChange={handleChange}
												/>
											}
											label="Yes"
										/>
										<FormControlLabel
											value="no"
											name={question.id.toString()}
											control={
												<Radio
													checked={values[question.id] === "no"}
													onChange={handleChange}
												/>
											}
											label="No"
										/>
										<FormHelperText
											style={{ paddingTop: 10, width: "100%" }}
											error={errors[index] ? true : false}
										>
											{errors[index]}
										</FormHelperText>
									</div>
								) : (
									""
								)}
								{props.status === "READ" &&
								question.questionType === "boolean_question" ? (
									<div
										style={{
											marginLeft: 20,
											textTransform: "uppercase",
										}}
									>
										<Typography paragraph variant="subtitle1">
											{values[question.id]}
										</Typography>
									</div>
								) : (
									""
								)}
							</>
						) : null}

						{question.questionType === "text_question" ? (
							<>
								{props.status === "WRITE" ? (
									<TextField
										onChange={handleChange}
										fullWidth
										value={values[question.id]}
										variant="outlined"
										name={question.id.toString()}
										error={errors[index] ? true : false}
										helperText={errors[index]}
									/>
								) : null}
								{props.status === "READ" ? (
									<div
										style={{
											marginLeft: 20,
											textTransform: "uppercase",
										}}
									>
										<Typography paragraph variant="subtitle1">
											{values[question.id]}
										</Typography>
									</div>
								) : null}
							</>
						) : null}
					</div>
				</FormControl>
			</React.Fragment>
		);
	};
	return (
		<>
			<Grid container justifyContent="center">
				<Button
					style={{ marginTop: 10 }}
					onClick={handleOpen}
					variant="contained"
					color={"primary"}
				>
					{props.completed === "Completed" ? (
						<CheckCircleOutlineIcon
							style={{ marginRight: 5, color: "green" }}
						/>
					) : null}
					{"  "}
					{props.name} Questionnaire
				</Button>
			</Grid>
			<Dialog
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				maxWidth="sm"
				fullScreen={matches ? true : false}
				fullWidth={!matches ? true : false}
				open={open}
				onClose={handleClose}
				sx={styles.modal}
			>
				<Title title={`${props.name} Questionnaire`} onClose={handleClose} />
				<DialogContent>
					<DialogContentText sx={{ p: 0, pt: 2, width: "100%" }}>
						{sortedAnswers.map((answer: any, index: any) =>
							renderAnswers(answer, index)
						)}
						<Box sx={{ pt: 2 }} />
						{props.status === "WRITE" ? (
							<>
								<strong>Member Signature:</strong>
								<div style={{ width: "100%", minHeight: 100 }}>
									<SignatureCanvas
										ref={sigCanvas}
										penColor="black"
										canvasProps={{
											style: classes.sigPad,
										}}
									/>
									<br />
									<Button onClick={clear} variant="outlined">
										Clear
									</Button>

									<FormHelperText
										style={{ paddingTop: 10, paddingLeft: 15, width: "100%" }}
										error={
											errors[errors.length - 1] === "Signature is required"
												? true
												: false
										}
									>
										{errors[errors.length - 1] === "Signature is required"
											? errors[errors.length - 1]
											: null}
									</FormHelperText>
								</div>
							</>
						) : null}
						{props.status === "READ" && signatureImage ? (
							<>
								<strong>Member Signature:</strong>
								<br />
								<Grid
									sx={{ my: 2, py: 2 }}
									container
									justifyContent="flex-start"
								>
									<img
										src={`data:image/png;base64,${signatureImage}`}
										alt="signature"
										style={{ maxWidth: "100%" }}
									/>
								</Grid>
							</>
						) : null}
					</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ p: 2 }}>
					<Button
						sx={{ mr: 1 }}
						fullWidth
						onClick={handleClose}
						variant="outlined"
					>
						{props.status === "WRITE" ? "Cancel" : "Go Back"}
					</Button>

					{props.status === "WRITE" ? (
						<Button
							fullWidth
							sx={{ ml: 1 }}
							variant="contained"
							color="primary"
							onClick={() => handleUpdate()}
						>
							Save
						</Button>
					) : null}
				</DialogActions>
			</Dialog>
		</>
	);
}

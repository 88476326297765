import { Typography, Grid, DialogTitle } from "@mui/material";
import { CloseButton } from "../../../utils/Buttons";

const Title = (props: any) => {
	return (
		<DialogTitle
			sx={{
				bgcolor: "primary.main",
				color: "white",
				alignItems: "flex-start",
				pt: 1,
				pb: 1.5,
				pr: 0,
				minWidth: { xs: 270, md: 444 },
			}}
		>
			<Grid
				container
				sx={{ pr: 0, display: "flex", width: "100%" }}
				justifyContent="space-between"
				alignItems="space-between"
			>
				<Grid item sx={{ maxWidth: !props.hide ? "85%" : "100%" }}>
					<Typography variant="h5" sx={{ pt: 1, pr: 0 }}>
						{props.title}
					</Typography>
				</Grid>
				<Grid item>
					{!props.hide && (
						<CloseButton
							sx={{ p: "0!important" }}
							style="open"
							onClose={props.onClose}
						/>
					)}
				</Grid>
			</Grid>
		</DialogTitle>
	);
};
export default Title;

import React from "react";
import {
	Button,
	Typography,
	Grid,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@mui/material";
import {
	SelectState,
	SelectCountry,
	SelectProvince,
	TextInput,
} from "../Forms/Inputs";
import { addressValidate } from "./validate";
import Title from "../Profile/Dialogs/Title";

export const AddressValidation = (props: any) => {
	const {
		userInfo,
		userMessages,
		userConfig,
		getProfileInfo,
		updateProfileInfo,
		onClose,
		handleOpen,
	} = props;
	const [errors, setErrors] = React.useState<any>({});
	const [values, setValues] = React.useState<any>({
		address: userInfo?.address,
		city: userInfo?.city,
		zip: userInfo?.zip,
		state: userInfo?.state,
		country: userInfo?.country,
		email: userInfo?.email,
		phone: userInfo?.cellPhone || userInfo?.homePhone || userInfo?.workPhone,
	});
	React.useEffect(() => {
		addressValidate(values, setErrors);
	}, []);
	const handleChange = (event: any) => {
		setErrors({ ...errors, [event.target.name]: "" });
		setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};
	React.useEffect(() => {
		if (
			userMessages === "Your profile information has been successfully updated"
		) {
			getProfileInfo(props.config);
		}
	}, [userMessages]);

	const handleSubmit = () => {
		let config = {
			headers: { Authorization: `Bearer ${userConfig.token}` },
			partnerApiKey: userConfig.partnerApiKey,
			businessAlias: userConfig.businessAlias,
			id: userConfig.clientId,
			userId: userConfig.userId,
			userName: userConfig.userName,
			Email: values.email,
			FirstName: userInfo.firstName,
			LastName: userInfo.lastName,
			dateOfBirth: new Date(userInfo.dateOfBirth).toISOString().slice(0, 10),
			CellPhone: values.phone,
			address: values.address,
			zip: values.zip,
			city: values.city,
			state: values.state,
			country: values.country,
		};
		let valid = addressValidate(values, setErrors);
		if (valid) {
			updateProfileInfo(config);

			getProfileInfo(props.config);
		}
	};
	if (
		userInfo?.address &&
		userInfo?.city &&
		userInfo?.country &&
		userInfo?.state &&
		userInfo?.zip &&
		userInfo?.country &&
		(userInfo?.country == "US" || userInfo?.country == "CA") &&
		userInfo?.email &&
		(userInfo.cellPhone || userInfo.homePhone || userInfo.workPhone)
	) {
		onClose();
		handleOpen();
	}
	let gridProps: any = {
		sm: 6,
		xs: 12,
	};
	const handleCountryChange = (event: any) => {
		setValues({
			...values,
			country: event.target.value,
			state: "",
		});
		setErrors({ ...errors, [event.target.name]: "" });
	};
	return (
		<>
			<Title title="Profile Address Validation" onClose={onClose} />
			<DialogContent>
				<DialogContentText>
					<Typography
						paragraph
						sx={{ color: "error.main", marginTop: "20px" }}
						align="center"
					>
						The following information is required before making a purchase
					</Typography>
					<Grid container spacing={2}>
						<Grid item {...gridProps}>
							<TextInput
								label={"Email"}
								name="email"
								fullWidth
								onChange={handleChange}
								value={values?.email}
								error={errors.email || !values.email ? true : false}
								helperText={errors.email}
							/>
						</Grid>
						<Grid item {...gridProps}>
							<TextInput
								label={"Phone"}
								name="phone"
								fullWidth
								onChange={handleChange}
								value={values?.phone}
								error={errors?.phone ? true : false}
								helperText={errors?.phone}
							/>
						</Grid>
						<Grid item {...gridProps}>
							<SelectCountry
								onChange={handleCountryChange}
								value={values?.country}
								error={errors?.country ? true : false}
								helperText={errors?.country}
							/>
						</Grid>
						<Grid item {...gridProps}>
							<TextInput
								label={"Street Address"}
								name="address"
								fullWidth
								onChange={handleChange}
								value={values?.address}
								error={errors?.address ? true : false}
								helperText={errors?.address}
							/>
						</Grid>
						<Grid item {...gridProps}>
							<TextInput
								label={"City"}
								name="city"
								fullWidth
								onChange={handleChange}
								value={values?.city}
								error={errors?.city ? true : false}
								helperText={errors?.city}
							/>
						</Grid>
						<Grid item {...gridProps}>
							<TextInput
								label={"Zip/Postal Code"}
								name="zip"
								fullWidth
								onChange={handleChange}
								value={values?.zip}
								error={errors?.zip ? true : false}
								helperText={errors?.zip}
							/>
						</Grid>
						<Grid item {...gridProps}>
							{values.country !== "US" && values.country !== "CA" ? (
								<TextInput
									label={"State/Province/Region"}
									name="state"
									fullWidth
									onChange={handleChange}
									value={values.state}
									disabled={!values.country}
									error={errors?.state ? true : false}
									helperText={errors?.state}
								/>
							) : values.country === "US" ? (
								<SelectState
									onChange={handleChange}
									value={values.state}
									error={errors?.state ? true : false}
									helperText={errors?.state}
								/>
							) : values.country === "CA" ? (
								<SelectProvince
									onChange={handleChange}
									value={values.state}
									error={errors?.state ? true : false}
									helperText={errors?.state}
								/>
							) : null}
						</Grid>
					</Grid>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ p: 2 }}>
				<Button
					fullWidth
					onClick={() => props.onClose()}
					variant="outlined"
					color="primary"
					sx={{ mr: 1 }}
				>
					Back
				</Button>
				<Button
					onClick={handleSubmit}
					fullWidth
					variant="contained"
					color="primary"
					sx={{ ml: 1 }}
				>
					Continue
				</Button>
			</DialogActions>
		</>
	);
};

import React from "react";
import Calendar from "./Calendar";
import {
	Typography,
	Grid,
	MenuItem,
	Select,
	FormControl,
	Hidden,
	Box,
} from "@mui/material";
import Notifications from "../../components/AlertMessage/Notifications";
import { SelectChangeEvent } from "@mui/material/Select";
import moment from "moment";
import FilterTypes from "./FilterTypes";
import MobileFilterTypes from "./MobileFilterTypes";
import { MetroSpinner } from "react-spinners-kit";
import { blue } from "../../theme";
import "moment-timezone";

const classes: any = {
	root: {
		width: "100%",
		marginTop: 0,
		mb: { xs: "70px", md: 0 },
		height: "100%",
		mt: { xs: "70px", sm: "130px" },
	},
	header: {
		backgroundColor: "primary.main",
		color: "white",
		height: "70px",
		borderTopRightRadius: "5px",
		borderTopLeftRadius: "5px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		marginBottom: "5px",
	},
	schedule: {
		width: { md: "220px" },
		color: "white",
		marginRight: "20px",
		borderRadius: "5px",
		justifyContent: "flex-end",
	},
	select: {
		"& .MuiOutlinedInput-input": {
			backgroundColor: "white",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
			backgroundColor: "white",
			borderRadius: "5px",
		},
		minWidth: "150px",
	},
};

export const GymSchedule = (props: any) => {
	const { schedule, userConfig, scheduleNames, clubData } = props;
	const [scheduleId, setScheduleId] = React.useState<any>(null);
	const [initialLoad, setInitialLoad] = React.useState<any>(false);
	const [filteredSchedule, setFilteredSchedule] = React.useState([]);
	const [appointmentId, setAppointmentId] = React.useState(0);
	const [appointmentType, setAppointmentType] = React.useState("");
	const [staffId, setStaffId] = React.useState(0);
	const path: any = window.location.pathname.split("/")[3];
	const [startDate] = React.useState<any>(
		moment().tz(clubData?.timezone).format("YYYY-MM-DD")
	);
	const config: any = {
		businessAlias: userConfig?.businessAlias,
		clientId: userConfig?.clientId,
		startDate: startDate,
		endDate: moment(startDate).add(30, "days").format("YYYY-MM-DD"),
		scheduleType: "Class",
	};

	React.useEffect(() => {
		if (!scheduleNames) {
			props.getScheduleNames(config);
		}
	}, []);

	const allScheduleNames = React.useMemo(() => {
		return scheduleNames?.map((item: any) => ({
			id: item.id,
			type: item.type,
			name: item.name,
		}));
	}, [scheduleNames]);

	React.useEffect(() => {
		if (!scheduleId && scheduleNames && !path) {
			setScheduleId(scheduleNames[0]?.id);
		} else if (path && !initialLoad) {
			const id = path.substring(path.indexOf("=") + 1);
			localStorage.removeItem("path");
			setScheduleId(id);
			setInitialLoad(true);
		}
	}, [scheduleNames]);
	React.useEffect(() => {
		if (schedule) {
			setFilteredSchedule(schedule);
		}
	}, [schedule]);

	const handleScheduleChange = async (event: SelectChangeEvent) => {
		setScheduleId(event.target.value);
		if (path) {
			window.location.pathname =
				window.location.pathname.slice(0, -1) + event.target.value;
		}
	};
	React.useEffect(() => {
		if (scheduleId != undefined && scheduleId) {
			let config3 = {
				businessAlias: props.userConfig.businessAlias,
				tabId: scheduleId,
			};
			props.getScheduleFilters(config3);
		}
	}, [scheduleId]);
	return (
		<Box sx={classes.root}>
			{props.messages || props.errors ? (
				<Notifications messages={props.messages} errors={props.errors} />
			) : null}
			<Box sx={classes.header}>
				<Grid container justifyContent="space-between">
					<Grid
						item
						sx={{ display: { xs: "none", sm: "block" } }}
						sm={4}
					></Grid>
					<Grid item xs={6} sm={4}>
						<Typography align="center" variant="h5">
							Schedule
						</Typography>
					</Grid>
					<Grid item xs={6} sm={4}>
						{scheduleId && scheduleNames?.length > 0 && (
							<Grid container justifyContent={"flex-end"}>
								<FormControl
									sx={[classes.schedule, { mr: 1.5 }]}
									margin="dense"
									variant="outlined"
									color="primary"
								>
									<Select
										sx={classes.select}
										size="small"
										inputProps={{
											name: "schedule",
											id: "outlined-age-native-simple",
											padding: 5,
											background: "white",
										}}
										disabled={props.requesting}
										value={scheduleId}
										onChange={handleScheduleChange}
									>
										{allScheduleNames?.map((schedule: any) => (
											<MenuItem key={schedule.id} value={schedule.id}>
												{schedule.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Box>

			{props.scheduleFilters && props.filtersSuccessful && (
				<>
					<Hidden mdDown>
						<FilterTypes
							{...props}
							scheduleId={scheduleId}
							setAppointmentId={setAppointmentId}
							setAppointmentType={setAppointmentType}
							setStaffId={setStaffId}
						/>
					</Hidden>
					<Hidden mdUp>
						<MobileFilterTypes
							{...props}
							scheduleId={scheduleId}
							setAppointmentId={setAppointmentId}
							setAppointmentType={setAppointmentType}
							setStaffId={setStaffId}
						/>
					</Hidden>
				</>
			)}
			{!props.scheduleNames && props.schedule && props.successful && (
				<Typography align="center" sx={{ pt: 8, pb: 8 }}>
					There are no schedules available. Please contact your club to schedule
					your appointments.
				</Typography>
			)}
			{!props.requesting && props.successful && props.schedule && (
				<Calendar
					{...props}
					type="gym"
					appointments={filteredSchedule}
					appointmentId={appointmentId}
					appointmentType={appointmentType}
					staffId={staffId}
				/>
			)}
			{props.requesting && (
				<Grid container justifyContent="center" sx={{ p: 10 }}>
					<MetroSpinner size={50} color={blue} />
				</Grid>
			)}
			{!props.requesting && !props.successful && (
				<Typography sx={{ pt: 5, pb: 5 }} align="center">
					There was an error loading schedule
				</Typography>
			)}
		</Box>
	);
};

import React from "react";
import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import FullCalendar, { EventApi } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import Event from "./BookingView";
import "./calendar.css";
import styles from "../../styles";

interface DemoAppState {
	weekendsVisible: boolean;
	currentEvents: EventApi[];
	open: boolean;
	def: any;
	range: any;
	otherInfo: any;
}

const Calendar = (props: any) => {
	let date: any = new Date();
	date.setDate(date.getDate() + 30);
	const [open, setOpen] = React.useState<boolean>(false);
	const [title, setTitle] = React.useState<any>([]);
	const [events, setEvents] = React.useState<any>([]);
	const [otherInfo, setOtherInfo] = React.useState<any>([]);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("md"));
	React.useEffect(() => {
		setEvents(
			props.appointments?.map((appointment: any) => ({
				id: appointment.tabId,
				scheduleId: appointment.scheduleId,
				category: appointment.category,
				title: `${
					appointment.className ? appointment.className : props.appointmentType
				}${appointment.instructor ? " by " + appointment.instructor : ""}
				 ${appointment.location ? "(" + appointment.location + ")" : ""}`,
				location: appointment.location || appointment.locationName,
				instructor: appointment.instructor || appointment.trainerFullName,
				allDay: false,
				start: appointment.eventDate + appointment.startTime,
				extendedProps: {
					startTimeString: appointment.scheduleStartTime,
					endTimeString: appointment.scheduleEndTime,
					startTime: appointment.startTime,
					endTime: appointment.endTime,
					eventDate: appointment.eventDate,
					tabId: appointment.tabId,
					scheduleId: appointment.scheduleId,
					staffId: props.staffId,
					appointmentId: props.appointmentId,
					description: appointment.description,
					meetingUrl: appointment.meetingUrl,
					title: appointment.className || props.appointmentType,
					booked: appointment.booked || false,
					canBeBookedOnline: appointment.canBeBookedOnline,
					canBeCancelledOnline: appointment.canBeCancelledOnline,
					canBeWaitlistedOnline: appointment.canBeWaitlistedOnline,
					waitListFull: appointment.waitListFull,
					waitListed: appointment.waitListed,
					category: appointment.category,
					classFull:
						appointment.classFull ||
						appointment.capacityRemaining === (0 || null),
					capacityRemaining: appointment.capacityRemaining,
				},
			}))
		);
	}, []);
	React.useEffect(() => {
		setEvents(
			props.appointments?.map((appointment: any) => ({
				id: appointment.tabId,
				scheduleId: appointment.scheduleId,
				category: appointment.category,
				title: `${
					appointment.className ? appointment.className : props.appointmentType
				}${appointment.instructor ? " by " + appointment.instructor : ""}
				 ${appointment.location ? "(" + appointment.location + ")" : ""}`,
				location: appointment.location || appointment.locationName,
				instructor: appointment.instructor || appointment.trainerFullName,
				allDay: false,
				color: appointment.waitListed ? "red" : "",
				start: appointment.eventDate + appointment.startTime,
				extendedProps: {
					startTimeString: appointment.scheduleStartTime,
					endTimeString: appointment.scheduleEndTime,
					startTime: appointment.startTime,
					endTime: appointment.endTime,
					eventDate: appointment.eventDate,
					description: appointment.description,
					meetingUrl: appointment.meetingUrl,
					staffId: props.staffId,
					appointmentId: props.appointmentId || appointment.appointmentTypeId,
					title: appointment.className || props.appointmentType,
					tabId: appointment.tabId,
					scheduleId: appointment.scheduleId,
					booked: appointment.booked || false,
					canBeBookedOnline: appointment.canBeBookedOnline,
					canBeCancelledOnline: appointment.canBeCancelledOnline,
					canBeWaitlistedOnline: appointment.canBeWaitlistedOnline,
					waitListFull: appointment.waitListFull,
					waitListed: appointment.waitListed,
					classFull: appointment.classFull,
					capacityRemaining: appointment.capacityRemaining,
					classRecurring: appointment.classRecurring,
				},
			}))
		);
	}, [props.appointments]);
	const handleEventClick = (clickInfo: any) => {
		let title: any = clickInfo.event._def.title;
		let otherInfo: any = clickInfo.event.extendedProps;
		setOpen(true);
		setTitle(title);
		setOtherInfo(otherInfo);
	};
	return (
		<div className="demo-app-main">
			<FullCalendar
				themeSystem="bootstrap"
				plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
				contentHeight={matches ? "35em" : "30em"}
				headerToolbar={{
					left: matches ? "prev,today,next" : "prev,next",
					center: "title",
					right: "listDay,listWeek,listMonth",
				}}
				titleFormat={{
					month: matches ? "long" : "short",
					day: "numeric",
				}}
				buttonText={{
					listDay: "Day",
					listWeek: "Week",
					listMonth: "Month",
				}}
				views={{
					listWeek: {
						buttonText: "Week",
						duration: { days: 7 },
					},
					listMonth: {
						buttonText: "Month",
						duration: { days: 30 },
					},
				}}
				visibleRange={function (currentDate) {
					// Generate a new date for manipulating in the next step
					var startDate = new Date(currentDate.valueOf());
					return { start: startDate };
				}}
				allDaySlot={false}
				expandRows={false}
				nowIndicator={true}
				validRange={{
					start: new Date().toISOString().slice(0, 10),
					end: date.toISOString().slice(0, 10),
				}}
				timeZone={"UTC"}
				initialView="listWeek"
				fixedWeekCount={true}
				showNonCurrentDates={false}
				editable={false}
				selectable={false}
				selectMirror={true}
				dayMaxEvents={4}
				weekends={true}
				events={events ? events : null}
				eventClick={handleEventClick}
			/>
			<Dialog hideBackdrop={true} open={open} sx={styles.modal}>
				<Event
					{...props}
					type={props.type}
					matches={matches}
					user={props.user}
					title={title}
					otherInfo={otherInfo}
					onClose={() => setOpen(false)}
				/>
			</Dialog>
		</div>
	);
};

export default Calendar;

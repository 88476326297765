export const GET_MEMBERSHIPS_REQUEST = "GET_MEMBERSHIPS_REQUEST";
export const GET_MEMBERSHIPS_SUCCESS = "GET_MEMBERSHIPS_SUCCESS";
export const GET_MEMBERSHIPS_ERROR = "GET_MEMBERSHIPS_ERROR";

export const GET_CONTRACT_PDF_REQUEST = "GET_CONTRACT_PDF_REQUEST";
export const GET_CONTRACT_PDF_SUCCESS = "GET_CONTRACT_PDF_SUCCESS";
export const GET_CONTRACT_PDF_ERROR = "GET_CONTRACT_PDF_ERROR";

export const PAY_MEMBERSHIP_REQUEST = "PAY_MEMBERSHIP_REQUEST";
export const PAY_MEMBERSHIP_SUCCESS = "PAY_MEMBERSHIP_SUCCESS";
export const PAY_MEMBERSHIP_ERROR = "PAY_MEMBERSHIP_ERROR";

export const CLEAR_MEMBERSHIP_ERRORS = "CLEAR_MEMBERSHIP_ERRORS";

import { call, put, takeEvery } from "redux-saga/effects";
import * as constants from "../constants/clients.constants";
import * as actions from "../services/clients.service";
import { ResponseGenerator } from "../types";

export function* getProfileInfo(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestProfileInfo, action.value);
		if (response.status === 200) {
			yield put({ type: constants.PROFILE_INFO_SUCCESS, response });
		}
	} catch (error: any) {
		yield put({ type: constants.PROFILE_INFO_ERROR, error });
	}
}

export function* getCredits(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestGetCredits, action.value);
		if (response) {
			yield put({ type: constants.GET_CREDITS_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.GET_CREDITS_ERROR, error });
	}
}

export function* updateProfileInfo(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestUpdateProfile, action.value);
		if (response) {
			yield put({ type: constants.PROFILE_UPDATE_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.PROFILE_UPDATE_ERROR, error });
	}
}
export function* updateNotifications(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestUpdateNotifications, action.value);
		if (response) {
			yield put({ type: constants.UPDATE_NOTIFICATIONS_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.UPDATE_NOTIFICATIONS_ERROR, error });
	}
}
export function* updateHealthInfo(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestUpdateHealthInfo, action.value);
		if (response) {
			yield put({ type: constants.HEALTHINFO_UPDATE_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.HEALTHINFO_UPDATE_ERROR, error });
	}
}
export function* uploadProfileImage(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestUploadProfileImage, action.value);
		if (response) {
			yield put({ type: constants.PROFILE_IMAGE_UPLOAD_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.PROFILE_IMAGE_UPLOAD_ERROR, error });
	}
}
export function* getProfileImage(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestGetProfileImage, action.value);
		if (response) {
			yield put({ type: constants.GET_PROFILE_IMAGE_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.GET_PROFILE_IMAGE_ERROR, error });
	}
}

export function* getSignatureImage(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestGetSignatureImage, action.value);
		if (response) {
			yield put({ type: constants.GET_SIGNATURE_IMAGE_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.GET_SIGNATURE_IMAGE_ERROR, error });
	}
}
export function* deleteProfileImage(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestDeleteProfileImage, action.value);
		if (response) {
			yield put({ type: constants.DELETE_PROFILE_IMAGE_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.DELETE_PROFILE_IMAGE_ERROR, error });
	}
}

export function* getUsers(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestGetUsers, action.value);
		if (response) {
			yield put({ type: constants.GET_USERS_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.GET_USERS_ERROR, error });
	}
}
export function* createUser(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestCreateUser, action.value);
		if (response) {
			yield put({ type: constants.CREATE_USER_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.CREATE_USER_ERROR, error });
	}
}
export function* updateUser(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestUpdateUser, action.value);
		if (response) {
			yield put({ type: constants.UPDATE_USER_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.UPDATE_USER_ERROR, error });
	}
}
export function* getQuestionnaires(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestQuestionnaires, action.value);
		if (response) {
			yield put({ type: constants.GET_QUESTIONNAIRE_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.GET_QUESTIONNAIRE_ERROR, error });
	}
}
export function* getBeltRank(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestBeltRank, action.value);
		if (response) {
			yield put({ type: constants.BELT_RANK_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.BELT_RANK_ERROR, error });
	}
}
export function* submitProfileQuestionnaires(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestSubmitProfileQuestionnaire, config);
		if (response) {
			yield put({
				type: constants.SUBMIT_PROFILE_QUESTIONNAIRE_SUCCESS,
				response,
			});
		}
	} catch (error) {
		yield put({ type: constants.SUBMIT_PROFILE_QUESTIONNAIRE_ERROR, error });
	}
}
export function* updateProfileQuestionnaires(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(
			actions.requestUpdateProfileQuestionnaire,
			config.value
		);
		if (response) {
			yield put({ type: constants.UPDATE_PROFILE_QUESTIONNAIRE_SUCCESS });
		}
	} catch (error) {
		yield put({ type: constants.UPDATE_PROFILE_QUESTIONNAIRE_ERROR, error });
	}
}
export function* getRelatedUsers(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestGetRelatedUsers, action.value);
		if (response) {
			yield put({ type: constants.GET_RELATED_USERS_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.GET_RELATED_USERS_ERROR, error });
	}
}
export function* updateRecurringProfile(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(actions.requestUpdateRecurring, action.value);
		if (response) {
			yield put({ type: constants.UPDATE_RECURRING_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.UPDATE_RECURRING_ERROR, error });
	}
}
export function* watchClientRequest() {
	yield takeEvery(constants.PROFILE_INFO_REQUEST, getProfileInfo);
	yield takeEvery(constants.PROFILE_UPDATE_REQUEST, updateProfileInfo);
	yield takeEvery(constants.PROFILE_IMAGE_UPLOAD_REQUEST, uploadProfileImage);
	yield takeEvery(constants.GET_QUESTIONNAIRE_REQUEST, getQuestionnaires);
	yield takeEvery(
		constants.SUBMIT_PROFILE_QUESTIONNAIRE_REQUEST,
		submitProfileQuestionnaires
	);
	yield takeEvery(
		constants.UPDATE_PROFILE_QUESTIONNAIRE_REQUEST,
		updateProfileQuestionnaires
	);
	yield takeEvery(constants.GET_USERS_REQUEST, getUsers);
	yield takeEvery(constants.CREATE_USER_REQUEST, createUser);
	yield takeEvery(constants.UPDATE_USER_REQUEST, updateUser);
	yield takeEvery(constants.GET_PROFILE_IMAGE_REQUEST, getProfileImage);
	yield takeEvery(constants.GET_SIGNATURE_IMAGE_REQUEST, getSignatureImage);
	yield takeEvery(constants.GET_RELATED_USERS_REQUEST, getRelatedUsers);
	yield takeEvery(constants.DELETE_PROFILE_IMAGE_REQUEST, deleteProfileImage);
	yield takeEvery(constants.HEALTHINFO_UPDATE_REQUEST, updateHealthInfo);
	yield takeEvery(constants.GET_CREDITS_REQUEST, getCredits);
	yield takeEvery(constants.BELT_RANK_REQUEST, getBeltRank);
	yield takeEvery(constants.UPDATE_NOTIFICATIONS_REQUEST, updateNotifications);
	yield takeEvery(constants.UPDATE_RECURRING_REQUEST, updateRecurringProfile);
}

import { Typography, CircularProgress, Box } from "@mui/material";

const classes: any = {
	loader: {
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexFlow: "row wrap",
	},
};
export default function Loader(props: any) {
	return (
		<Box sx={classes.loader}>
			<CircularProgress size={25} color="secondary" />
			<div style={{ width: "100%", flexBasis: "100%" }} />
			<Typography align="center" variant="subtitle2" color="primary">
				{props.messages}
			</Typography>
		</Box>
	);
}

import { call, put, takeEvery } from "redux-saga/effects";
import * as constants from "../constants/marketplace.constants";
import {
	requestGetMarketplace,
	requestSaveCart,
	requestGetCart,
	requestAddItem,
	requestRemoveItem,
	requestCompletePurchase,
} from "../services/marketplace.service";
import { ResponseGenerator } from "../types";
export function* getMarketplace(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestGetMarketplace, action.value);
		if (response) {
			yield put({ type: constants.GET_MARKETPLACE_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.GET_MARKETPLACE_ERROR, error });
	}
}
export function* saveCart(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestSaveCart, action.value);
		if (response) {
			yield put({ type: constants.SAVE_CART_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.SAVE_CART_ERROR, error });
	}
}
export function* addItem(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestAddItem, action.value);
		if (response) {
			yield put({ type: constants.ADD_ITEM_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.ADD_ITEM_ERROR, error });
	}
}
export function* removeItem(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestRemoveItem, action.value);
		if (response) {
			yield put({ type: constants.REMOVE_ITEM_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.REMOVE_ITEM_ERROR, error });
	}
}
export function* getCart(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestGetCart, action.value);
		if (response) {
			yield put({ type: constants.GET_CART_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.GET_CART_ERROR, error });
	}
}
export function* applyPromo(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestGetCart, action.value);
		if (response) {
			yield put({ type: constants.APPLY_PROMO_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.APPLY_PROMO_ERROR, error });
	}
}
export function* completePurchase(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestCompletePurchase, action.value);
		if (response) {
			yield put({ type: constants.COMPLETE_PURCHASE_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.COMPLETE_PURCHASE_ERROR, error });
	}
}
export function* watchMarketplaceRequest() {
	yield takeEvery(constants.GET_MARKETPLACE_REQUEST, getMarketplace);
	yield takeEvery(constants.SAVE_CART_REQUEST, saveCart);
	yield takeEvery(constants.GET_CART_REQUEST, getCart);
	yield takeEvery(constants.ADD_ITEM_REQUEST, addItem);
	yield takeEvery(constants.REMOVE_ITEM_REQUEST, removeItem);
	yield takeEvery(constants.APPLY_PROMO_REQUEST, applyPromo);
	yield takeEvery(constants.COMPLETE_PURCHASE_REQUEST, completePurchase);
}

import React from "react";
import {
	Grid,
	Typography,
	Dialog,
	Button,
	FormControl,
	InputLabel,
	Select,
	DialogContent,
	DialogContentText,
	DialogActions,
	Divider,
} from "@mui/material";
import ManageUsers from "../../containers/ManageUsers";
import ReviewContract from "./ReviewContract";
import PaymentMethods from "../Profile/Payments/PaymentMethods";
import Notifications from "../AlertMessage/Notifications";
import Loader from "../../utils/Loader";
import { formatCurrency } from "../../utils/FormatCurrency";
import { SelectChangeEvent } from "@mui/material/Select";
import styles from "../../styles";
import SelectCheckmark from "../Forms/DropdownTreeSelect/DropdownTreeSelect";
import Title from "../Profile/Dialogs/Title";
import { renderPaymentMethods } from "../Forms/PaymentMethods";

const classes: any = {
	paddingList: {
		paddingLeft: { xs: 0, sm: "75px" },
		paddingRight: { xs: 0, sm: "75px" },
	},
};

export default function Summary(props: any) {
	const {
		billingProfiles,
		messages,
		userMessages,
		userErrors,
		membership,
		onClose,
		onCloseAll,
		getEnrollMembershipDetails,
		getRelatedUsers,
		membershipDetails,
		id,
		loading,
		errors,
		clubData,
		config,
		matches,
		userConfig,
	} = props;
	const [open, setOpen] = React.useState(0);
	const [paymentMethod, setPaymentMethod] = React.useState("");
	const [initialLoad, setInitialLoad] = React.useState(false);
	const [paymentType, setPaymentType] = React.useState("");
	const [selected, setSelected] = React.useState<any>([userConfig?.clientId]);
	let sortedBillingProfiles: any = billingProfiles?.sort(function (
		a: any,
		b: any
	) {
		return b.isDefaultProfile - a.isDefaultProfile;
	});
	React.useEffect(() => {
		if (!initialLoad) {
			setPaymentProfile();
			props.getRelatedUsers(props.config);
			if (billingProfiles?.length == 0) {
				props.getBillingProfile(config);
			}
			setInitialLoad(true);
		}
	}, [config]);
	React.useEffect(() => {
		setPaymentProfile();
	}, [billingProfiles]);
	React.useEffect(() => {
		let detailsConfig: any = props.config;
		detailsConfig.productId = id;
		getEnrollMembershipDetails(detailsConfig);
	}, []);
	React.useEffect(() => {
		if (
			props.userMessages === "User successfully created" ||
			props.userMessages === "User successfully updated"
		) {
			getRelatedUsers(props.config);
		}
	}, [props.userMessages]);
	const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {

		if (selected.includes(event.target.value)) {
			selected.filter((select: any) => [select !== event.target.value]);
			setSelected(
				selected.filter((select: any) => select !== event.target.value)
			);
		} else {
			setSelected([...selected, event.target.value]);
		}
	};
	const setPaymentProfile = () => {
		let isDefault: any = false;
		sortedBillingProfiles?.find((profile: any) => {
			if (profile.isDefaultProfile) {
				setPaymentMethod(profile.id);
				setPaymentType(profile.profileType);
				isDefault = true;
			}
		});
		if (!isDefault) {
			if (sortedBillingProfiles.length > 0) {
				setPaymentMethod(sortedBillingProfiles[0].id);
				setPaymentType(sortedBillingProfiles[0].profileType);
			}
		}
	};
	const handleClose = () => {
		setOpen(0);
	};
	const handleReviewContract = () => {
		setOpen(4);
	};
	const openAddPayment = () => {
		setOpen(1);
	};
	const handlePaymentChange = (event: SelectChangeEvent) => {
		let value: any = event.target.value;
		setPaymentMethod(value as any);
		const type: any = billingProfiles.find(
			(x: any) => x.id === event.target.value
		);
		if (type.profileType === "CreditCard") {
			setPaymentType("CreditCard");
		}
		if (type.profileType === "Eft") {
			props.clubData.country === "CA"
				? setPaymentType("Eft")
				: setPaymentType("Ach");
		}
	};
	let formattedUsers: any = props.purchaseForUsers?.map((user: any) => {
		return { value: user.id, label: user.fullName };
	});
	let details: any = membershipDetails;
	let renderPayments: any = renderPaymentMethods(billingProfiles, paymentType);
	return (
		<>
			{(open === 0 || open === 1 || open === 99) && (
				<>
					{messages || errors ? (
						<Notifications messages={messages} errors={errors} />
					) : null}
					<Title title="Enrollment Summary" onClose={onCloseAll} />
					<DialogContent>
						<DialogContentText>
							<form>
								<Typography sx={{ mb: 2, mt: 2 }} variant="h5" align="center">
									{membership?.name}
								</Typography>

								<Grid
									container
									justifyContent="flex-start"
									sx={classes.paddingList}
								>
									<Typography variant="subtitle1">
										<b>Membership Terms</b>
									</Typography>

									{!loading && details ? (
										<>
											<Grid container justifyContent="space-between">
												<Typography variant="subtitle1">
													Number of Payments:
												</Typography>
												<Typography variant="subtitle1">
													{details?.noPayments}
												</Typography>
											</Grid>
											<Grid container justifyContent="space-between">
												<Typography variant="subtitle1">
													Payment Amount:
												</Typography>
												<Typography variant="subtitle1">
													{details?.tieredPricingPaymentAmounts.length > 0  ? 
													selected.length > 0 ?
													formatCurrency(
													selected.length > 5 ? details?.tieredPricingPaymentAmounts[4].paymentAmount : details?.tieredPricingPaymentAmounts[selected.length - 1].paymentAmount,
													clubData
													): formatCurrency(0,clubData)
													: formatCurrency(
														details?.paymentAmount * selected.length,
														clubData
													)}{" "}
													{details?.paymentAmount > 0 ? "+ tax" : null}
												</Typography>
											</Grid>
											<Grid container justifyContent="space-between">
												<Typography variant="subtitle1">
													Payment Frequency:
												</Typography>
												<Typography variant="subtitle1">
													{details?.paymentFrequency}
												</Typography>
											</Grid>

											<Grid container justifyContent="space-between">
												<Typography variant="subtitle1">
													First Payment Date:
												</Typography>
												<Typography variant="subtitle1">
													{new Date(
														details?.firstPaymentDate
													).toLocaleDateString("en-US")}
												</Typography>
											</Grid>
											<Grid container justifyContent="space-between">
												<Typography variant="subtitle1">
													Initiation Fee:
												</Typography>
												<Typography variant="subtitle1">
													{formatCurrency(
														Number(details?.initiationFee * selected.length),
														clubData
													)}
												</Typography>
											</Grid>
											{/* <Grid container justifyContent="space-between">
										<Typography variant="subtitle1">Down Payment:</Typography>
										<Typography variant="subtitle1">
											{formatCurrency(
												Number(details?.downPayment * selected.length),
												clubData
											)}
										</Typography>
									</Grid> */}
										</>
									) : (
										<Grid container justifyContent="center">
											<Loader />
										</Grid>
									)}
								</Grid>
								<Grid container justifyContent="flex-start" spacing={1}>
									<Grid item xs={12}>
									<Divider sx={{ p: 1, mb: 2 }} />
									</Grid>
									<Grid item xs={12} sm={6}>
										<Grid item xs={12}>
											<Typography>
												<strong>Who is the purchase for ?</strong>
											</Typography>
										</Grid>
									<Grid item xs={12} sx={{ pb: 1 }}>
										<Typography variant="caption">
											Select up to 5 users
										</Typography>
										<SelectCheckmark
											nodes={formattedUsers}
											label={"none"}
											id={userConfig.clientId}
											currentUser={userConfig.fullName}
											helperText={
												selected.length == 0
													? "You must select at least one user"
													: ""
											}
											value={selected}
											selected={selected}
											error={selected.length === 0}
											name="users"
											onChange={(e: any) => handleUserChange(e)}
										/>
										<Grid item xs={12}>
											{selected.length > 5 && (
												<Typography
													paragraph
													align="center"
													style={{ color: "red" }}
												>
													There can only be 5 selected users
												</Typography>
											)}
											<Button
												fullWidth
												onClick={() => setOpen(99)}
												color="primary"
												variant="outlined"
												sx={{mt:1}}
											>
												Manage Users
											</Button>
										</Grid>
									</Grid>
									</Grid>
									{details?.tieredPricingPaymentAmounts?.length > 0 ?
									<Grid item xs={12} sm={6}>
									<Grid container justifyContent="center">
										<Grid item>
											<Typography align="center" sx={{fontWeight: 600}}>Tiered Pricing:</Typography>
											{details.tieredPricingPaymentAmounts.map((payment: any, index:any) => {
											return <Typography align="left">{index + 1} {index === 0 ? "Member" :"Members"}: {formatCurrency(payment.paymentAmount, clubData)} + tax</Typography>})}
									</Grid>
									</Grid>
									</Grid>
									
								: null}
									</Grid>
									<Grid item xs={12}>
										<Typography style={{ marginTop: 20 }}>
											<strong>Select Payment Method for Membership:</strong>
										</Typography>
										<Grid
											container
											direction="column"
											justifyContent="flex-end"
										>
											<FormControl margin="normal" variant="outlined">
												<InputLabel
													shrink={true}
													htmlFor="outlined-age-native-simple"
												>
													Payment Method
												</InputLabel>
												<Select
													label="Payment Method"
													native
													value={paymentMethod}
													onChange={handlePaymentChange}
													inputProps={{
														name: "paymentMethod",
														id: "outlined-age-native-simple",
														padding: 5,
													}}
												>
													{renderPayments}
												</Select>
											</FormControl>
											<Grid container spacing={2}>
												<Grid item xs={12} lg={6}>
													<Button
														fullWidth
														onClick={openAddPayment}
														variant="outlined"
														color="primary"
													>
														Add Payment Method
													</Button>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
							</form>
						</DialogContentText>
					</DialogContent>
					<DialogActions sx={{ p: 2 }}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<Button
									onClick={onClose}
									fullWidth
									variant="outlined"
									color="primary"
								>
									Back
								</Button>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Button
									disabled={
										selected.length > 5 || !paymentType || selected.length === 0
									}
									fullWidth
									variant="contained"
									color="primary"
									onClick={handleReviewContract}
								>
									Review Contract
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</>
			)}
			<Dialog
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
					},
				}}
				sx={styles.modal}
				open={open === 1}
				onClose={handleClose}
			>
				<PaymentMethods handleClose={handleClose} {...props} open={true} />
			</Dialog>
			<Dialog
				fullScreen={matches ? true : false}
				fullWidth={!matches ? true : false}
				maxWidth="md"
				sx={styles.modal}
				open={open === 4}
				onClose={handleClose}
			>
				<ReviewContract
					{...props}
					billingProfileId={paymentMethod}
					details={details}
					selected={selected}
					productId={membership?.productId}
					messages={userMessages}
					errors={userErrors}
					onClose={handleClose}
					onCloseAll={onCloseAll}
					matches={matches}
				/>
			</Dialog>
			<Dialog
				open={open === 99}
				onClose={handleClose}
				fullWidth
				maxWidth="sm"
				sx={styles.modal}
			>
				<ManageUsers
					{...props}
					messages={userMessages}
					errors={userErrors}
					onClose={handleClose}
				/>
			</Dialog>
		</>
	);
}

import * as constants from "../constants/users.constants";

const initialState = {
	requesting: false,
	successful: false,
	messages: null,
	errors: null,
	userTaken: null,
	currentUser: null,
	data: [],
	submitted: [],
	clientId: null,
	userConfig: {},
	signUpMessage: null,
	clubLogo: null,
	active: true,
};

function authReducer(state = initialState, action: any) {
	switch (action.type) {
		case constants.LOGIN_REQUEST:
			return {
				...state,
				requesting: true,
				successful: false,
				messages: "Signing in...",
				errors: null,
			};
		case constants.LOGIN_SUCCESS: {
			return {
				...state,
				currentUser: action.token.token,
				signUpMessage: null,
				requesting: false,
				successful: true,
				messages: null,
				errors: null,
				clientId: action.token.clientId,
				userConfig: {
					businessAlias: action.token.action.value.businessAlias,
					systemName: action.token.action.value.SystemName,
					ipAddress: action.token.ip,
					partnerApiKey: action.token.action.value.partnerApiKey,
					userName: action.token.userName,
					userId: action.token.userId,
					clientId: action.token.clientId,
					token: action.token.token,
					fullName: action.token.firstName + " " + action.token.lastName,
					firstName: action.token.firstName,
					lastName: action.token.lastName,
				},
			};
		}
		case constants.LOGIN_ERROR:
			let error:any = "Unknown Error";
			if (action.error?.message === "timeout of 10000ms exceeded" || action.error?.message === "Network Error") {
				error = action.error?.message;
			} else{
				if (Object.keys(action.error.response.data.error).length){
					error = action.error.response.data?.error;
				}
			}
			return {
				...state,
				requesting: false,
				successful: false,
				errors: error,
				messages: null,
			};
		case constants.LOGOUT:
			return {
				active: state.active,
				currentUser: null,
				messages: null,
			};
		default:
			return state;

		case constants.REGISTER_REQUEST:
			return {
				...state,
				requesting: true,
				successful: false,
				messages: "Signing up...",
				errors: null,
			};
		case constants.REGISTER_SUCCESS:
			return {
				...state,
				errors: null,
				signUpMessage: `Successfully created account for ${action.response.data.email}, please confirm email to continue`,
				clientId: action.response.data.id,
				requesting: true,
				successful: false,
			};
		case constants.REGISTER_ERROR:
			return {
				...state,
				errors: action.error.response.data.error,
				messages: null,
				requesting: false,
				successful: false,
			};
		case constants.CLUB_LOGO_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: false,
				clubLogo: action.response.data,
			};
		case constants.CHECK_ALIAS_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: false,
				active: true,
			};
		case constants.CHECK_ALIAS_ERROR:
			return {
				...state,
				requesting: false,
				successful: false,
				active: false,
			};
		case constants.CLUB_LOGO_ERROR:
			return {
				...state,
				requesting: false,
				successful: false,
			};
		case constants.REQUEST_QUESTIONNAIRES: {
			return {
				...state,
				requesting: true,
				messages: "Loading...",
			};
		}
		case constants.REQUEST_QUESTIONNAIRE_SUCCESS: {
			return {
				...state,
				requesting: false,
				messages: null,
				data: action.response.data,
			};
		}
		case constants.SUBMIT_QUESTIONNAIRE_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				data: action.response.data,
			};
		case constants.QUESTION_FAILURE:
			return {
				...state,
				requesting: false,
				successful: false,
				errors: action.error.response.data.error,
				messages: null,
			};

		case constants.UPDATE_QUESTIONNAIRE_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages: "Your questionnaire has been successfully updated",
			};
		case constants.MARK_QUESTIONNAIRE_SUCCESS:
			return {
				...state,
				submitted: [...state.submitted, action.id],
				messages: "Questionnaire has been submitted",
				errors: null,
			};
		case constants.USERNAME_ERROR:
			return {
				...state,
				errors: action.error.response.data,
			};
		case constants.USERNAME_TAKEN:
			return {
				...state,
				errors: null,
				userTaken: action.response,
			};
		case constants.CLEAR_ERROR:
			return {
				...state,
				errors: null,
				userTaken: null,
				submitted: [],
				clientId: null,
				requesting: false,
				successful: true,
				messages: null,
			};
		case constants.CLEAR_MESSAGE:
			return {
				...state,
				errors: null,
				messages: null,
				userTaken: null,
				signUpMessage: null,
				clientId: null,
			};
		case constants.CLEAR_USERNAME_ERROR:
			return {
				...state,
				userTaken: null,
			};
	}
}

export default authReducer;

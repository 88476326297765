import React from "react";
import {
	Grid,
	Typography,
	Grow,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Card,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SortTable from "./SortTable";
import Notifications from "../../AlertMessage/Notifications";
import styles from "../../../styles";
import Loader from "../../../utils/Loader";

export default function Invoices(props: any) {
	const { config } = props;
	const [expand, setExpand] = React.useState(false);
	const toggleAccordion = () => {
		setExpand((prev) => !prev);
	};
	React.useEffect(() => {
		if (expand) {
			console.log("getting invoices on expand");
			props.getInvoices(config);
		}
	}, [expand]);
	React.useEffect(() => {
		if (props.paymentMessage === "Invoice payment submitted") {
			props.getInvoices(config);
		}
	}, [props.paymentMessage]);

	const invoices: any = props.invoices?.filter(
		(x: any) => x.isMembershipInvoice === false
	);
	return (
		<>
			{props.paymentMessage ||
			props.paymentError ||
			props.errors ||
			props.messages ? (
				<Notifications
					messages={props.paymentMessage || props.messages}
					errors={props.paymentError || props.errors}
				/>
			) : null}
			<Grow in={true} timeout={750}>
				<Card raised>
					<Accordion expanded={expand} defaultExpanded={false}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							sx={styles.accordion}
							onClick={toggleAccordion}
						>
							<Typography variant="h5">Manage Invoices</Typography>
						</AccordionSummary>
						<AccordionDetails sx={{ p: 0, m: 0 }}>
							<Grid
								container
								justifyContent="center"
								sx={{
									minWidth: 300,
									width: "100%",
									m: 0,
								}}
							>
								<Grid item xs={12}>
									{invoices ? (
										<SortTable
											{...props}
											acceptedCards={
												props.clubData.validCCTypes || props.acceptedCards
											}
											filteredInvoices={invoices}
										/>
									) : (
										<Loader />
									)}
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
				</Card>
			</Grow>
		</>
	);
}

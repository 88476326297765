import { fork } from "redux-saga/effects";
import { watchLoginRequest } from "./auth.saga";
import { watchRegisterRequest } from "./register.saga";
import { watchUserRequest } from "./user.saga";
import { watchClientRequest } from "./clients.saga";
import { watchScheduleRequest } from "./schedule.saga";
import { watchMembershipsRequest } from "./memberships.saga";
import { watchInvoicesRequest } from "./invoices.saga";
import { watchBillingProfileRequest } from "./billingprofiles.saga";
import { watchEnrollMembershipsRequest } from "./enroll.saga";
import { watchMarketplaceRequest } from "./marketplace.saga";
import { watchDashboardRequest } from "./dashboard.saga";
export default function* rootSaga() {
	yield fork(watchLoginRequest);
	yield fork(watchRegisterRequest);
	yield fork(watchUserRequest);
	yield fork(watchClientRequest);
	yield fork(watchScheduleRequest);
	yield fork(watchBillingProfileRequest);
	yield fork(watchMembershipsRequest);
	yield fork(watchInvoicesRequest);
	yield fork(watchEnrollMembershipsRequest);
	yield fork(watchMarketplaceRequest);
	yield fork(watchDashboardRequest);
}

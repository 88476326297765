import { connect } from "react-redux";
import Dashboard from "../components/Dashboard/Dashboard";

const mapStateToProps = (state: any) => {
	return {
		requesting: state.dashboard.requesting,
		successful: state.dashboard.successful,
		requestingAlerts: state.dashboard.requestingAlerts,
		successfulAlerts: state.dashboard.successfulAlerts,
		errors: state.dashboard.errors,
		messages: state.dashboard.messages,
		alerts: state.dashboard.alerts,
		dashboardData: state.dashboard.dashboardData,
		userConfig: state.auth.userConfig,
		user: state.client.user,
		data: state.dashboard.data,
		purchaseForUsers: state.client.purchaseForUsers,
		clubData: state.billingProfile.clubData,
		loadError: state.dashboard.loadError,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getDashboardAlerts: (config: any) =>
			dispatch({ type: "GET_DASHBOARD_ALERTS_REQUEST", value: config }),
		getDashboardData: (config: any) =>
			dispatch({ type: "GET_DASHBOARD_DATA_REQUEST", value: config }),
		getRelatedUsers: (config: any) =>
			dispatch({
				type: "GET_RELATED_USERS_REQUEST",
				value: config,
			}),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

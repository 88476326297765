const provinces = [
	{
		value: "",
		label: "",
	},
	{
		value: "AB",
		label: "Alberta",
		country: "CA",
		timezone: "America/Edmonton",
	},
	{
		value: "BC",
		label: "British Columbia",
		country: "CA",
		timezone: "America/Creston",
	},
	{
		value: "MB",
		label: "Manitoba",
		country: "CA",
		timezone: "America/Winnipeg",
	},
	{
		value: "NB",
		label: "New Brunswick",
		country: "CA",
		timezone: "America/Moncton",
	},
	{
		value: "NL",
		label: "Newfoundland and Labrador",
		country: "CA",
		timezone: "America/St_Johns",
	},
	{
		value: "NS",
		label: "Nova Scotia",
		country: "CA",
		timezone: "America/Halifax",
	},
	{
		value: "NU",
		label: "Nunavut",
		country: "CA",
		timezone: "America/Iqaluit",
	},
	{
		value: "NT",
		label: "Northwest Territories",
		country: "CA",
		timezone: "America/Yellowknife",
	},
	{
		value: "ON",
		label: "Ontario",
		country: "CA",
		timezone: "America/Toronto",
	},
	{
		value: "PE",
		label: "Prince Edward Island",
		country: "CA",
		timezone: "America/Halifax",
	},
	{
		value: "QC",
		label: "Quebec",
		country: "CA",
	},
	{
		value: "SK",
		label: "Saskatchewan",
		country: "CA",
		timezone: "America/Regina",
	},
	{
		value: "YT",
		label: "Yukon",
		country: "CA",
		timezone: "America/Whitehorse",
	},
];

export default provinces;

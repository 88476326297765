import React from "react";
import {
	Button,
	CssBaseline,
	TextField,
	FormControlLabel,
	Checkbox,
	Grid,
	Typography,
	Container,
	IconButton,
	Input,
	InputLabel,
	InputAdornment,
	FormControl,
	Grow,
	FormHelperText,
	Box,
} from "@mui/material";
import { Alert } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../utils/Loader";
import Alerts from "../AlertMessage/AlertMessage";
import { signInValidate } from "./validate";
import { getUserParams, getOSVersion } from "../../helpers/browser";
import { MemberSolutionsButton } from "../../utils/Buttons";
import { history } from "../../utils/history";
import Error from "../AlertMessage/ErrorPage";

const classes: any = {
	paper: {
		marginTop: 18,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	form: {
		width: "100%",
		marginTop: 0,
		marginLeft: 0,
	},
	submit: {
		my: 3,
	},
	padding: {
		pt: 2,
	},
	headerText: {
		paddingTop: "20px",
		paddingBottom: "5px",
		marginBottom: "20px",
		borderBottom: `solid 2px`,
		borderColor: "secondary.main",
		textAlign: "center",
	},
	links: {
		paddingRight: "10px",
		paddingLeft: "10px",
		fontSize: "14px",
	},
};
export interface Props {
	handleChange: (userName: string) => void;
	onCheck: (checked: boolean) => void;
	signIn: (username: string, password: string, checked: boolean) => void;
	checked: boolean;
}

function SignIn(props: any) {
	const { user } = props;
	const [errors, setErrors] = React.useState<any>({});
	let username = localStorage.getItem("username");
	let path = localStorage.getItem("path");
	const [values, setValues] = React.useState({
		userName: username ? username : "",
		password: "",
		remember: username ? true : false,
		showPassword: false,
		requestIp: null,
		userAgent: getOSVersion(window),
	});
	const params: any = window.location.pathname.split("/")[1];
	const [checked, setChecked] = React.useState(username ? true : false);
	React.useEffect(() => {
		props.clearErrors();
		config.businessAlias = params;
		getUserParams()
			.then(function (result: any) {
				return setValues({ ...values, requestIp: result?.ip || "unknown" });
			})
			.catch((errors: any) => {
				console.log(errors);
			});
	}, []);
	React.useEffect(() => {
		const config2 = { businessAlias: params, partnerApiKey: "mm" };
		props.getClubDetails(config2);
	}, []);
	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};
	const handleMouseDownPassword = (event: any) => {
		event.preventDefault();
	};
	const handleChange =
		(prop: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({ ...values, [prop]: event.target.value });
		};
	const clearErrors = () => {
		props.clearMessage();
	};
	const onCheck = () => {
		checked ? setChecked(false) : setChecked(true);
		setValues({ ...values, remember: !checked });
	};
	const signIn = (e: any) => {
		e.preventDefault();
		clearErrors();
		let valid;
		valid = signInValidate(values, setErrors);
		if (valid) {
			storeUsername(values.userName, values.remember);
			props.onSignIn(config);
		}
	};

	const storeUsername = (username: string, remember?: boolean) => {
		if (remember) {
			try {
				localStorage.setItem("username", username);
			} catch (e) {
				window.console.error(e);
			}
		} else {
			try {
				localStorage.removeItem("username");
			} catch (e) {
				window.console.error(e);
			}
		}
	};
	const config: any = {
		userName: values.userName,
		password: values.password,
		userAgent: values.userAgent,
		requestIp: values.requestIp ? values.requestIp : "unknown",
		partnerApiKey: "mm",
		businessAlias: params,
		SystemName: "MP2",
		path: path ? path : null,
	};
	React.useEffect(() => {
		if (user) {
			history.push(`/${params}/dashboard`);
		}
	}, [user]);

	return (
		<div>
			<CssBaseline />
			{!props.clubData && props.clubDataSuccessful ? (
				<Error />
			) : (
				<Container maxWidth="xs">
					<Box sx={classes.paper}>
						{props.clubData?.logoPath ? (
							<img src={props.clubData?.logoPath} width={240} alt="logo" />
						) : null}
						<Grow in={true} timeout={150}>
							<Typography sx={classes.headerText} component="h3" variant="h5">
								SIGN IN
							</Typography>
						</Grow>
						<Grow in={true} timeout={150}>
							<Typography style={{ fontSize: 16 }} component="h2" variant="h5">
								SIGN IN TO MEMBER PORTAL
							</Typography>
						</Grow>

						<form id="signin-form" style={classes.form} noValidate>
							<input type="hidden" value="something" />
							{!props.requesting ? (
								<div>
									<Grow in={true} timeout={150}>
										<TextField
											margin="normal"
											variant="standard"
											fullWidth
											aria-label="userName"
											id="userName"
											data-testid="userName"
											autoComplete="userName"
											label={"Username"}
											name="userName"
											onChange={handleChange("userName")}
											autoFocus
											color="secondary"
											value={values.userName}
											error={errors.userName ? true : false}
											helperText={errors.userName}
										/>
									</Grow>
									<Grow in={true} timeout={300}>
										<FormControl color="secondary" sx={classes.form}>
											<InputLabel
												style={{
													marginBottom: 10,
													marginLeft: -13,
													marginTop: 8,
												}}
												htmlFor="standard-adornment-password"
												error={errors.password ? true : false}
											>
												Password
											</InputLabel>
											<Input
												id="standard-adornment-password"
												name="password"
												type={values.showPassword ? "text" : "password"}
												value={values.password}
												onChange={handleChange("password")}
												error={errors.password ? true : false}
												inputProps={{
													"data-testid": "password",
													style: { marginBottom: 5 },
												}}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleClickShowPassword}
															onMouseDown={handleMouseDownPassword}
															size="large"
														>
															{values.showPassword ? (
																<Visibility />
															) : (
																<VisibilityOff />
															)}
														</IconButton>
													</InputAdornment>
												}
											/>
											<FormHelperText
												sx={{ ml: 0 }}
												error={errors.password ? true : false}
											>
												{errors.password}
											</FormHelperText>
										</FormControl>
									</Grow>
									<Grow in={true} timeout={450}>
										<FormControlLabel
											control={
												<Checkbox
													onChange={onCheck}
													data-testid="remember"
													checked={values.remember}
													color="secondary"
												/>
											}
											label={
												<span style={{ fontSize: "14px" }}>Remember Me</span>
											}
										/>
									</Grow>
								</div>
							) : (
								<Loader messages={props.messages} />
							)}

							<Alerts
								config={config}
								messages={props.messages}
								errors={props.errors}
								userMessages={props.userMessages}
							/>
							{props.signUpMessage ? (
								<Alert severity="success">{props.signUpMessage}</Alert>
							) : null}
							<Grow in={true} timeout={750}>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									sx={classes.submit}
									onClick={signIn}
								>
									SIGN IN
								</Button>
							</Grow>
							<Grow in={true} timeout={600}>
								<Grid container justifyContent="center">
									<Grid item>
										<Link
											style={classes.links}
											to={`/${params}/forgot/password`}
											color="primary"
											onClick={clearErrors}
										>
											Forgot Password
										</Link>
										|
									</Grid>
									<Grid item>
										<Link
											style={classes.links}
											to={`/${params}/forgot/username`}
											color="primary"
											onClick={clearErrors}
										>
											Forgot Username
										</Link>
										|
									</Grid>
									<Grid item>
										<a
											style={classes.links}
											href="https://membersolutions.com/privacy-policy/"
											color="primary"
											target="_blank"
											rel="noreferrer"
										>
											Privacy Policy
										</a>
									</Grid>
								</Grid>
							</Grow>
							<Grow in={true} timeout={750}>
								<Grid container justifyContent="center">
									<Typography
										variant="body2"
										component="h4"
										sx={classes.padding}
									>
										Not a Member?
										<Link
											style={classes.links}
											to={`/${params}/signup`}
											color="primary"
											onClick={clearErrors}
										>
											Sign Up
										</Link>
									</Typography>
									<Grid container justifyContent="center">
										<div style={{ paddingTop: 20 }}>
											<MemberSolutionsButton />
										</div>
									</Grid>
								</Grid>
							</Grow>
						</form>
					</Box>
				</Container>
			)}
		</div>
	);
}

const mapStateToProps = (state: any) => {
	return {
		user: state.auth.currentUser,
		errors: state.auth.errors,
		messages: state.auth.messages,
		requesting: state.auth.requesting,
		userMessages: state.user.messages,
		signUpMessage: state.auth.signUpMessage,
		clubData: state.billingProfile.clubData,
		clubDataSuccessful: state.billingProfile.successful,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onSignIn: (config: any) =>
			dispatch({ type: "LOGIN_REQUEST", value: config }),
		resendEmail: (config: any) =>
			dispatch({ type: "RESEND_EMAIL_REQUEST", value: config }),
		clearErrors: () => dispatch({ type: "CLEAR_ERROR" }),
		clearMessage: () => dispatch({ type: "CLEAR_MESSAGE" }),
		getClubDetails: (config: any) =>
			dispatch({ type: "ACCEPTED_CARDS_REQUEST", value: config }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

export function validatePassword(password: string) {
	if (isLengthInValid(password)) {
		return "* Passwords must be a minimum of 7 characters";
	} else if (isCharactersInValid(password)) {
		return "* Passwords must contain one number, one letter, and one symbol";
	}
	return;
}
export function isLengthInValid(password: string, length: number = 7) {
	return !password || password.length < length;
}
export function isContainNumericInValid(password: string) {
	return !password || password.search(/\d/) === -1;
}

export function isContainAlphabeticInValid(password: string) {
	return !password || password.search(/[a-zA-Z]/) === -1;
}

export function isCharactersInValid(password: string) {
	return (
		!password || password.search(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*\W).*$/) === -1
	);
}

import { parseCreditCard } from "../../Forms/normalizeCreditCard";
import { validateFirstName, validateLastName } from "../../SignUp/Validation";

export function isNameInValid(str: string) {
	return !str || str.search(/^[a-zA-Z-'&. ]+$/) === -1;
}
export function isNumericInValid(str: string) {
	return !str || str.search(/^[0-9]+$/) === -1;
}
export function bankAccountNumberValidation(
	accountNumber: string,
	setErrors: any
) {
	let errors: any = {};
	if (!accountNumber) {
		errors.accountNumber = "Account Number is required";
	} else if (isNumericInValid(accountNumber)) {
		errors.accountNumber = "Account Number can only be digits";
	}
	setErrors({ ...errors });
	return Object.values(errors).every((x) => x === "");
}
export function creditCardNumberValidation(
	values: any,
	acceptedCards: any,
	setErrors: any
) {
	let errors: any = {};
	let lCheck: any = luhnCheck(values.CardNumber);
	if (!values.CardNumber) {
		errors.creditcard = "Credit Card Number is required";
	} else if (isNumericInValid(values.CardNumber)) {
		errors.creditcard = "Card number must be only digits";
	} else if (lCheck === false) {
		errors.creditcard = "Card Number is invalid";
	}
	let typeErrors = validateCardType(values, acceptedCards);
	setErrors({ ...errors, ...typeErrors });
	return Object.values({ ...errors, ...typeErrors }).every((x) => x === "");
}
export function bankAccountValidation(
	values: any,
	setErrors: any,
	billingAddress: any,
	type: any
) {
	let billingErrors = billingAddressValidation(billingAddress);
	let achErrors;
	let eftErrors;
	if (type === "ach") {
		let ach = achValidation(values);
		achErrors = { ...ach };
	} else {
		let eft = eftValidation(values);
		eftErrors = { ...eft };
	}
	setErrors({ ...billingErrors, ...achErrors, ...eftErrors });
	return Object.values({ ...billingErrors, ...achErrors, ...eftErrors }).every(
		(x) => x === ""
	);
}

function getMonth() {
	var month = new Date().getMonth() + 1;
	return month < 10 ? "0" + month : "" + month; // ('' + month) for string result
}
export function validateCard(values: {
	CardNumber: string;
	NameOnCard: string;
	ExpiryMonth: string;
	ExpiryYear: string;
	SecurityCode: string;
	firstName: string;
	lastName: string;
}) {
	let errors: any = {};
	if (!values.CardNumber) {
		errors.creditcard = "Credit Card Number is required";
	} else if (isNumericInValid(values.CardNumber)) {
		errors.creditcard = "Card number must be only digits";
	}
	if (!values.SecurityCode) {
		errors.securityCode = "Required";
	}
	if (!values.NameOnCard) {
		errors.nameOnCard = "Card Name is required";
	} else if (isNameInValid(values.NameOnCard)) {
		errors.nameOnCard = "Card Name should accept alphabets only";
	}
	let expiryErrors = validateExpiry(values.ExpiryMonth, values.ExpiryYear);

	errors = Object.assign({ ...errors }, expiryErrors);
	return errors;
}

export function validateCardType(values: any, acceptedCards: any) {
	let errors: any = {};

	if (values.CardNumber) {
		let cardType = GetCardType(values.CardNumber);
		if (values.CardNumber && acceptedCards.includes(cardType)) {
		} else {
			errors.creditcard = "Card type not accepted";
		}
		if (cardType === "American Express") {
			if (values.SecurityCode && values.SecurityCode.length !== 4) {
				errors.securityCode = "CVV must be 4 digits";
			}
			if (values.CardNumber.length !== 15) {
				errors.creditcard = "Amex must be 15 digits";
			}
		} else {
			if (values.SecurityCode && values.SecurityCode.length !== 3) {
				errors.securityCode = "CVV must be 3 digits";
			}
			if (values.CardNumber.length !== 16) {
				errors.creditcard = "Card number must be 16 digits";
			}
		}
	}
	return errors;
}
export function oneTimeCardValidation(
	values: {
		CardNumber: string;
		NameOnCard: string;
		ExpiryMonth: string;
		ExpiryYear: string;
		SecurityCode: string;
		firstName: string;
		lastName: string;
	},

	setErrors: any,
	acceptedCards: any
) {
	let errors: any = {};
	const cardNumber = values.CardNumber
		? parseCreditCard(values.CardNumber)
		: "";
	let lCheck: any = luhnCheck(cardNumber);
	if (lCheck === false) {
		lCheck = errors.luhnCheck = "Card Number is invalid";
	}

	errors = Object.assign(
		validateCard(values),
		validateCardType(values, acceptedCards),
		validateExpiry(values.ExpiryMonth, values.ExpiryYear),
		lCheck
	);
	errors.firstName = validateFirstName(values.firstName);
	errors.lastName = validateLastName(values.lastName);

	setErrors({ ...errors });
	return Object.values(errors).every((x) => x === "");
}
export function oneTimeBankValidation(values: any, setErrors: any, type: any) {
	let errors: any = {};
	if (type === "Ach") {
		errors = achValidation(values);
	}
	if (type === "Eft") {
		errors = eftValidation(values);
	}
	errors.firstName = validateFirstName(values.firstName);
	errors.lastName = validateLastName(values.lastName);

	setErrors({ ...errors });
	return Object.values(errors).every((x) => x === "");
}
export function createCardValidation(
	values: {
		CardNumber: string;
		NameOnCard: string;
		ExpiryMonth: string;
		ExpiryYear: string;
		SecurityCode: string;
		firstName: string;
		lastName: string;
	},
	setErrors: any,
	billingAddress: any,
	acceptedCards: any
) {
	let errors: any = {};
	const cardNumber = values.CardNumber
		? parseCreditCard(values.CardNumber)
		: "";
	let lCheck: any = luhnCheck(cardNumber);
	let validateCC = validateCard(values);
	let validateBillingAddress = billingAddressValidation(billingAddress);
	let validateCCType = validateCardType(values, acceptedCards);
	errors = Object.assign(validateCC, validateBillingAddress, validateCCType);
	if (lCheck === false) {
		errors.luhnCheck = "Card Number is invalid";
	}
	console.log("errors", errors);
	setErrors({ ...errors });
	return Object.values(errors).every((x) => x === "");
}
function validateExpiry(expiryMonth: any, expiryYear: any) {
	let errors: any = {};
	if (!expiryMonth) {
		errors.expiryMonth = "Required";
	} else if (expiryMonth.length != 2) {
		errors.expiryMonth = "Must be MM";
	} else if (expiryMonth > 12) {
		errors.expiryMonth = "Invalid";
	}
	if (!expiryYear) {
		errors.expiryYear = "Required";
	} else if (expiryYear.length != 4) {
		errors.expiryYear = "Must be YYYY";
	} else if (
		expiryYear &&
		expiryYear.length < 5 &&
		expiryYear < new Date().getFullYear().toString()
	) {
		errors.expiryYear = "Expired";
	} else if (
		expiryYear &&
		expiryYear.length < 5 &&
		expiryYear === new Date().getFullYear().toString()
	) {
		if (expiryMonth < getMonth()) {
			errors.expiryMonth = "Expired";
		}
	}
	return errors;
}
export function editCardValidation(
	values: {
		CardNumber: string;
		NameOnCard: string;
		ExpiryMonth: string;
		ExpiryYear: string;
		SecurityCode: string;
		firstName: string;
		lastName: string;
	},
	setErrors: any,
	billingAddress: any,
	acceptedCards: any,
	onFileNumber: any
) {
	let errors: any = {};
	errors = billingAddressValidation(billingAddress);
	const cardNumber = values.CardNumber
		? parseCreditCard(values.CardNumber)
		: "";
	if (onFileNumber !== values.CardNumber || values.SecurityCode) {
		let validateCC = validateCard(values);
		let lCheck = luhnCheck(cardNumber);
		if (lCheck === false) {
			errors.creditcard = "Card Number is invalid";
		}

		let validateCCType: any = validateCardType(values, acceptedCards);
		errors = Object.assign({ ...errors }, validateCCType, validateCC);
	}
	if (!values.NameOnCard) {
		errors.nameOnCard = "Card Name is required";
	} else if (isNameInValid(values.NameOnCard)) {
		errors.nameOnCard = "Card Name should accept alphabets only";
	}
	let expiryErrors = validateExpiry(values.ExpiryMonth, values.ExpiryYear);
	errors = Object.assign({ ...errors }, expiryErrors);
	setErrors({ ...errors });
	return Object.values(errors).every((x) => x === "");
}

export function billingAddressValidation(billingAddress: any) {
	const errors: any = {};
	if (!billingAddress.firstName) {
		errors.firstName = "First name is required";
	} else if (isNameInValid(billingAddress.firstName)) {
		errors.firstName = "First name should have only letters";
	}
	if (!billingAddress.lastName) {
		errors.lastName = "Last name is required";
	}
	if (!billingAddress.address) {
		errors.address = "Address name is required";
	}
	if (!billingAddress.zip) {
		errors.zip = "Zip/Postal Code is required";
	}
	if (!billingAddress.city) {
		errors.city = "City is required";
	}
	if (!billingAddress.state) {
		errors.state = "State/Province/Region is required";
	}
	if (!billingAddress.country) {
		errors.country = "Country is required";
	}
	return errors;
}

export function achValidation(values: any) {
	const errors: any = {};
	if (!values.RoutingNumber) {
		errors.routing = "Routing Number is required";
	} else if (isNumericInValid(values.RoutingNumber)) {
		errors.routing = "Routing Number can only be digits";
	} else if (values.RoutingNumber.length !== 9) {
		errors.routing = "Routing Number must be 9 digits";
	}
	if (!values.BankName) {
		errors.bankName = "Bank Name is required";
	}
	if (!values.AccountNumber || values.AccountNumber?.length < 2) {
		errors.accountNumber = "Account Number is required";
	} else if (isNumericInValid(values.AccountNumber)) {
		errors.accountNumber = "Account Number can only be digits";
	}

	if (!values.BankAccountType) {
		errors.accountTypeFull = "Account Type is required";
	}

	return errors;
}

export function eftValidation(values: any) {
	const errors: any = {};

	if (!values.InstitutionNumber) {
		errors.institutionNumber = "Institution Number is required";
	} else if (isNumericInValid(values.InstitutionNumber)) {
		errors.institutionNumber = "Institution Number should accept only digits";
	} else if (values.InstitutionNumber.length !== 3) {
		errors.institutionNumber = "Institution Number must be 3 digits";
	}
	if (!values.TransitNumber) {
		errors.transitNumber = "Transit Number is required";
	} else if (isNumericInValid(values.TransitNumber)) {
		errors.transitNumber = "Transit Number should accept only digits";
	} else if (values.TransitNumber.length !== 5) {
		errors.transitNumber = "Transit Number must be 5 digits";
	}
	if (!values.AccountNumber) {
		errors.accountNumber = "Account Number is required";
	} else if (isNumericInValid(values.AccountNumber)) {
		errors.accountNumber = "Account Number can only be digits";
	}

	if (!values.BankAccountType) {
		errors.accountTypeFull = "Account Type is required";
	}

	return errors;
}

export var luhnCheck = (function (arr) {
	return function (ccNum: any) {
		let len = ccNum.length,
			bit = 1,
			sum = 0,
			val;

		while (len) {
			val = parseInt(ccNum.charAt(--len), 10);
			sum += (bit ^= 1) ? arr[val] : val;
		}

		return sum && sum % 10 === 0;
	};
})([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]);

function GetCardType(number: string) {
	// Visa
	var re = new RegExp("^4");
	if (number.match(re) != null) return "Visa";
	// Mastercard
	if (
		/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
			number
		)
	)
		return "Master Card";

	// AMEX
	re = new RegExp("^3[47]");
	if (number.match(re) != null) return "American Express";

	// Discover
	re = new RegExp(
		"^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
	);
	if (number.match(re) != null) return "Discover";

	return "";
}

import { call, put, takeEvery } from "redux-saga/effects";
import * as constants from "../constants/users.constants";
import { push } from "react-router-redux";
import {
	registerUser,
	getQuestionnaires,
	submitQuestionnaire,
	validateUserName,
	updateQuestionnaire,
} from "../services/auth.service";
import { ResponseGenerator } from "../types";
const clubName = window.location.pathname.split("/")[1];

export function* registerRequest(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(registerUser, action.value);
		if (response) {
			yield put({ type: constants.REGISTER_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.REGISTER_ERROR, error });
	}
}

export function* requestQuestionnaires(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(getQuestionnaires, action.value);
		yield put({ type: constants.REQUEST_QUESTIONNAIRE_SUCCESS, response });
	} catch (error) {
		yield put({ type: constants.REGISTER_ERROR, error });
	}
}

export function* submitQuestionnaires(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(submitQuestionnaire, config);
		if (response) {
			yield put(push(`/${clubName}/signin`));
			yield put({ type: constants.SUBMIT_QUESTIONNAIRE_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.REGISTER_ERROR, error });
	}
}
export function* markQuestionnaires(config: any) {
	let id = config.value;
	try {
		yield put({ type: constants.MARK_QUESTIONNAIRE_SUCCESS, id });
	} catch (error) {
		yield put({ type: constants.REGISTER_ERROR, error });
	}
}

export function* updateQuestionnaires(config: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(updateQuestionnaire, config.value);
		if (response) {
			yield put({ type: constants.UPDATE_QUESTIONNAIRE_SUCCESS });
		}
	} catch (error) {
		yield put({ type: constants.REGISTER_ERROR, error });
	}
}

export function* checkUserName(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(validateUserName, action);
		if (response) {
			response = response.data;
			yield put({ type: constants.USERNAME_TAKEN, response });
		}
		return response;
	} catch (error) {
		yield put({ type: constants.USERNAME_ERROR, error });
	}
}

export function* watchRegisterRequest() {
	yield takeEvery(constants.REGISTER_REQUEST, registerRequest);
	yield takeEvery(constants.REQUEST_QUESTIONNAIRES, requestQuestionnaires);
	yield takeEvery(constants.UPDATE_QUESTIONNAIRE_REQUEST, updateQuestionnaires);
	yield takeEvery(constants.MARK_QUESTIONNAIRE, markQuestionnaires);
	yield takeEvery(constants.SUBMIT_QUESTIONNAIRES, submitQuestionnaires);
	yield takeEvery(constants.CHECK_USERNAME, checkUserName);
}

import React from "react";
import {
	Grid,
	Typography,
	Button,
	Dialog,
	CardHeader,
	Paper,
	Select,
	MenuItem,
	useMediaQuery,
	FormControl,
} from "@mui/material";
import Notifications from "../../components/AlertMessage/Notifications";
import Recurrence from "./Recurrence";
import styles from "../../styles";
import CancelClass from "./CancelClass";
import ButtonLoader from "../../utils/ButtonLoader";
import { useTheme } from "@mui/material/styles";
import SelectBooking from "./PayBooking/SelectPackage";

const classes: any = {
	header: {
		width: "100%",
		margin: 0,
		padding: "20px",
		backgroundColor: "primary.main",
		borderTopLeftRadius: "3px",
		borderTopRightRadius: "3px",
		color: "white",
		justifyContent: "space-between",
	},
};
const BookingView = (props: any) => {
	const {
		otherInfo,
		userConfig,
		clearCancelSuccess,
		onClose,
		messages,
		purchaseForUsers,
		payBook,
		relatedClasses,
	} = props;
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("sm"));
	const [open, setOpen] = React.useState(0);
	const [bookFor, setBookFor] = React.useState(userConfig.clientId);
	let cancelled: any = props.cancelSuccess;
	let booked: any = props.bookedSuccess;
	React.useEffect(() => {
		let config = {
			headers: { Authorization: `Bearer ${userConfig.token}` },
			partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
			businessAlias: userConfig ? userConfig.businessAlias : null,
			id: userConfig.clientId,
		};
		props.getRelatedUsers(config);
	}, []);

	React.useEffect(() => {
		if (cancelled || booked) {
			onClose();
			setOpen(0);
		}
	}, [messages]);

	React.useEffect(() => {
		if (cancelled) {
			clearCancelSuccess();
		}
	}, [cancelled]);
	React.useEffect(() => {
		if (booked) {
			clearCancelSuccess();
		}
	}, [booked]);
	React.useEffect(() => {
		if (payBook) {
			let config: any = {
				clientId: userConfig.clientId,
				businessAlias: userConfig.businessAlias,
				appointmentTypeId: otherInfo.appointmentId,
			};
			props.getRelatedClasses(config);
		}
	}, [payBook]);
	let date: any = new Date();
	date.setDate(date.getDate() + 180);
	const handleBookClass = () => {
		const config: any = {
			businessAlias: userConfig.businessAlias,
			clientId: bookFor,
			waitlist: "no",
			tabId: otherInfo.tabId,
			scheduleId: otherInfo.scheduleId,
			startTime: otherInfo.startTime,
			endTime: otherInfo.endTime,
			startDate: otherInfo.eventDate,
			appointmentTypeId: otherInfo.appointmentId,
			trainerId: otherInfo.staffId,
		};
		props.bookClass(config);
	};
	const handleJoinWaitlist = () => {
		const config: any = {
			businessAlias: userConfig.businessAlias,
			clientId: bookFor,
			tabId: otherInfo.tabId,
			scheduleId: otherInfo.scheduleId,
			waitlist: "yes",
		};
		props.bookClass(config);
	};

	const cancelWaitlist = () => {
		const config = {
			businessAlias: userConfig.businessAlias,
			clientId: props.user,
			tabId: otherInfo.tabId,
			scheduleId: otherInfo.scheduleId,
			waitlist: "yes",
		};
		console.log("cancel config", config);
		props.cancelClass(config);
	};
	const allUsers = React.useMemo(() => {
		return purchaseForUsers?.map((item: any) => (
			<MenuItem key={item.id} value={item.id}>
				{item.fullName}
			</MenuItem>
		));
	}, [purchaseForUsers]);
	let isAvailable = relatedClasses?.length > 0 ? true : false;
	return (
		<div>
			{props.messages || props.errors ? (
				<Notifications messages={props.messages} errors={props.errors} />
			) : null}
			<Paper>
				<Grid container justifyContent="flex-start" alignItems="center">
					<CardHeader
						title={
							<Grid container justifyContent="space-between">
								<Grid item>{otherInfo.title}</Grid>
								<Grid item>
									<Typography variant="h5">
										{props.matches &&
											otherInfo.startTimeString +
												" - " +
												otherInfo.endTimeString}
									</Typography>
								</Grid>
							</Grid>
						}
						sx={classes.header}
					/>
					<Typography style={{ fontSize: 16 }}></Typography>

					<Grid container sx={{ p: 2, pb: 0 }} spacing={2}>
						<Grid item xs={12} sm={6}>
							{!props.matches ? (
								<Typography
									style={{ fontSize: 14, paddingTop: 5 }}
									variant="subtitle2"
								>
									<strong>Time: </strong>
									{otherInfo.startTimeString + " - " + otherInfo.endTimeString}
								</Typography>
							) : null}
							<Typography
								align="left"
								style={{ fontSize: 14, paddingTop: 5 }}
								variant="subtitle2"
							>
								<strong>Location:</strong>{" "}
								{otherInfo.location ? otherInfo.location : "N/A"}
							</Typography>
							<Typography
								style={{ fontSize: 14, paddingTop: 5 }}
								variant="subtitle2"
							>
								<strong>Instructor:</strong>{" "}
								{otherInfo.instructor?.trim()?.length > 0
									? otherInfo.instructor
									: "N/A"}
							</Typography>
							{props.type !== "my" && otherInfo.capacityRemaining != 0 && (
								<Typography
									style={{
										fontSize: 14,
										paddingTop: 5,
										display: "inline-block",
										marginRight: 5,
									}}
									variant="subtitle2"
								>
									<strong>Slots left: </strong>{" "}
								</Typography>
							)}
							{otherInfo.classFull ? (
								<Typography
									align="left"
									style={{
										fontSize: 14,
										padding: 0,
										color: "red",
										display: "inline-block",
									}}
									variant="subtitle1"
								>
									Class is full
								</Typography>
							) : otherInfo.capacityRemaining != 0 ? (
								otherInfo.capacityRemaining
							) : null}
							<Typography
								style={{ fontSize: 14, paddingTop: 5 }}
								variant="subtitle2"
							>
								<strong>Description: </strong>
								{otherInfo.description || "N/A"}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6}>
							{props.type !== "my" ? (
								<>
									<Typography
										style={{ fontSize: 14, paddingTop: 5 }}
										variant="subtitle2"
									>
										<strong>Book For: </strong>
									</Typography>
									<FormControl
										sx={{ mt: 0 }}
										margin="dense"
										style={{ width: 207 }}
									>
										<Select
											size="small"
											fullWidth
											name="bookFor"
											onChange={(event: any) => setBookFor(event.target.value)}
											value={bookFor}
											variant="outlined"
											color="secondary"
										>
											{allUsers}
										</Select>
									</FormControl>
								</>
							) : null}
							{otherInfo.meetingUrl &&
								props.type == "my" &&
								!otherInfo.waitListed && (
									<Grid xs={12} md={9} item>
										<Typography
											sx={{ fontSize: 14, pb: 0, mb: 0, mt: 0.5 }}
											variant="subtitle2"
										>
											<b>Live Meeting:</b>
											<br />
											<Button
												color="primary"
												variant="outlined"
												href={
													otherInfo.meetingUrl.includes("http")
														? otherInfo.meetingUrl
														: "http://" + otherInfo.meetingUrl
												}
												target="_blank"
												rel="noopener noreferrer"
											>
												Join Now
											</Button>
										</Typography>
									</Grid>
								)}
							{/* {otherInfo.classRecurring && (
								<Grid xs={12} md={9} item sx={{ pt: 1, pb: 2 }}>
									<Button
										fullWidth
										color="primary"
										variant="outlined"
										onClick={() => setOpen(3)}
									>
										Recurrence
									</Button>
								</Grid>
							)} */}
						</Grid>
					</Grid>
				</Grid>
				<Grid container justifyContent="center" spacing={2} sx={{ p: 2 }}>
					<Grid item xs={12} sm={6}>
						<Button
							fullWidth
							variant="outlined"
							color="primary"
							onClick={() => props.onClose()}
						>
							Back
						</Button>
					</Grid>
					<Grid item xs={12} sm={6}>
						{props.type === "my" &&
						otherInfo.booked &&
						otherInfo.canBeCancelledOnline ? (
							<Button
								onClick={() => setOpen(1)}
								fullWidth
								variant="contained"
								color="primary"
							>
								Cancel Booking
							</Button>
						) : otherInfo.waitListed ? (
							<Button
								onClick={cancelWaitlist}
								fullWidth
								variant="contained"
								color="primary"
							>
								Cancel Waitlist
							</Button>
						) : !otherInfo.booked &&
						  !otherInfo.waitlisted &&
						  !otherInfo.classFull ? (
							<Button
								onClick={handleBookClass}
								fullWidth
								variant="contained"
								color="primary"
								disabled={
									!props.otherInfo.canBeBookedOnline || props.bookRequesting
								}
							>
								{props.bookRequesting ? <ButtonLoader /> : "Book Now"}
							</Button>
						) : otherInfo.canBeWaitlistedOnline &&
						  !otherInfo.waitListFull &&
						  !otherInfo.waitListed &&
						  otherInfo.classFull ? (
							<Button
								onClick={handleJoinWaitlist}
								fullWidth
								variant="contained"
								color="primary"
							>
								Join Wait List
							</Button>
						) : null}
					</Grid>
					<Dialog
						open={open === 1}
						onClose={props.handleClose}
						sx={styles.modal}
						hideBackdrop
					>
						<CancelClass
							{...props}
							setOpen={setOpen}
							user={props.user}
							handleClose={props.onClose}
						/>
					</Dialog>
					<Dialog
						open={open === 3}
						fullWidth
						maxWidth="sm"
						onClose={() => setOpen(0)}
						sx={styles.modal}
					>
						<Recurrence
							{...props}
							scheduleId={otherInfo?.scheduleId}
							setOpen={setOpen}
						/>
					</Dialog>
					{/* <Dialog
						open={payBook}
						fullScreen={matches ? true : false}
						fullWidth={!matches ? true : false}
						maxWidth="sm"
						sx={styles.modal}
						hideBackdrop={true}
					>
						<PayBooking
							{...props}
							bookFor={bookFor}
							appointmentTypeId={otherInfo.appointmentId}
							handleClose={() => props.closePayBook()}
						/>
					</Dialog> */}
					<Dialog
						open={isAvailable}
						fullScreen={matches ? true : false}
						fullWidth={!matches ? true : false}
						maxWidth="sm"
						sx={styles.modal}
						hideBackdrop={true}
					>
						<SelectBooking
							{...props}
							bookFor={bookFor}
							appointmentTypeId={otherInfo.appointmentId}
							handleClose={() => props.closePayBook()}
						/>
					</Dialog>
				</Grid>
			</Paper>
		</div>
	);
};

export default BookingView;

import { CardHeader, Card, CardContent, Badge } from "@mui/material";
import Alert from "./Alert";
import ButtonLoader from "../../../utils/ButtonLoader";

const classes: any = {
	cardHeader: {
		backgroundColor: "primary.light",
		color: "white",
		alignItems: "center",
		textAlign: "center",
	},
	badgeStyle: {
		"& .MuiBadge-badge": {
			right: 0,
			zIndex: 0,
		},
	},
};
export default function Alerts(props: any) {
	const { alerts, successfulAlerts, requestingAlerts } = props;
	let renderedAlerts = alerts?.data?.map((alert: any, index: any) => (
		<Alert
			content={alert.clientMessage}
			severity={"info"}
			key={index}
			id={index}
		/>
	));
	const noAlerts = (
		<Alert content={"There are no new alerts at this time"} severity={"info"} />
	);
	return (
		<Card raised={true} sx={{ mt: 3, pb: 0 }}>
			<CardHeader
				sx={classes.cardHeader}
				title={
					<Badge
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						overlap="circular"
						badgeContent={props.alerts?.data?.length}
						color="secondary"
						sx={classes.badgeStyle}
					>
						Messages
					</Badge>
				}
			/>
			<CardContent
				sx={{
					maxHeight: { xs: 132, sm: 150 },
					p: 0,
					mb: 0,
					overflowY: "auto",
					pb: "0 !important",
				}}
			>
				{alerts?.data?.length > 0 && successfulAlerts && renderedAlerts}
				{alerts?.data?.length < 1 && successfulAlerts && noAlerts}
				{requestingAlerts && (
					<div
						style={{
							backgroundColor: "#E5F6FD",
							overflowY: "hidden",
							height: 50,
							paddingTop: 10,
							paddingBottom: 10,
						}}
					>
						<ButtonLoader />
					</div>
				)}
			</CardContent>
		</Card>
	);
}

export function isPhoneInValid(str: string) {
	return (
		!str ||
		str.search(
			/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
		) === -1
	);
}

const validateStep1 = (values: any) => {
	let errors: any = {};
	errors.firstName = validateFirstName(values.firstName);
	errors.lastName = validateLastName(values.lastName);
	errors.phoneType = values.phoneType ? "" : "Required";
	errors.phone = values.phone ? "" : "Phone number is required";
	errors.phone = isPhoneInValid(values.phone)
		? "Must be a valid phone number"
		: "";
	if (values.dob && !values.dateOfBirth) {
		errors.dob = validateDob(values.dob);
	}
	if (values.dob === null) {
		errors.dob = "Date of birth is required";
	}
	if (values.dateOfBirth === null) {
		errors.dateOfBirth = "Date of birth is required";
	}
	if (!values.dob && values.dateOfBirth) {
		errors.dateOfBirth = validateDob(values.dateOfBirth);
	}
	return errors;
};
const validateStep2 = (values: any) => {
	let errors: any = {};
	errors.email = /\S+@\S+\.\S+/.test(values.email) ? "" : "Email is not valid";
	errors.username = validateUserName(values.username);
	errors.password = validatePassword(values.password);
	if (values.confirmPassword && values.password) {
		errors.confirmPassword =
			values.confirmPassword === values.password
				? ""
				: "Passwords do not match";
	}
	if (!values.confirmPassword) {
		errors.confirmPassword = "Password must be confirmed";
	}
	return errors;
};

const validateStep3 = (
	values: any,
	setErrors: any,
	submitted: any,
	questionnaires: any
) => {
	let temp: any = {};
	if (submitted < questionnaires) {
		temp.questionnaires = "All Questionnaires must be filled out";
	} else {
		temp.questionnaires = "";
	}
	temp.privacyPolicy = values.privacyPolicy
		? ""
		: "Privacy Policy must be read before continuing";
	temp.captcha = values.captcha ? "" : "reCaptcha is required";
	setErrors({ ...temp });
	return Object.values(temp).every((x) => x === "");
};
const validateQs = (
	values: any,
	setErrors: any,
	answers: any,
	sigImage: any
) => {
	let temp: any = [];
	let answer: any = "";
	let i: any;
	for (i = 0; answers > i; i++) {
		if (values[i] === null) {
			answer = "";
		} else {
			answer = values[i.toString()] ? "" : "Answer is required";
		}
		temp.push(answer);
	}
	if (sigImage === null) {
		temp.push("Signature is required");
	}
	setErrors([...temp]);
	return temp.every((x: any) => x === "");
};

const validatePassword = (password: any) => {
	if (!password) {
		return "Password is required";
	} else if (password.length < 7 && password.length > 0) {
		return "Password must be at least 7 characters long";
	} else if (!/^(?=.*[a-zA-Z])(?=.*[0-9]).*$/.test(password)) {
		return "Password must contain at least one letter and one number";
	} else {
		return "";
	}
};

const validateUserName = (userName: any) => {
	if (!userName) {
		return "Username is required";
	} else if (userName.length > 0 && userName.length < 4) {
		return "Username must be at least 4 characters long";
	} else if (/\s/.test(userName)) {
		return "Username must not contain spaces";
	} else {
		return "";
	}
};

const validateFirstName = (firstName: any) => {
	if (!firstName) {
		return "First name is required";
	} else if (!/^[a-z ,.'-]+$/i.test(firstName)) {
		return "First name must contain only valid characters";
	} else {
		return "";
	}
};

const validateLastName = (lastName: any) => {
	if (!lastName) {
		return "Last name is required";
	} else if (!/^[a-z ,.'-]+$/i.test(lastName)) {
		return "Last name must contain only valid characters";
	} else {
		return "";
	}
};

const validateDob = (dateOfBirth: any) => {
	let dob: any = new Date(dateOfBirth);
	let date: any = new Date();
	let calculateYear = dob.getFullYear();
	if (!dob || dateOfBirth === undefined) {
		return "Date of birth is required";
	} else if (
		Number(calculateYear) < date.getFullYear() - 100 ||
		dob == "Invalid Date" ||
		dateOfBirth == "Invalid Date"
	) {
		return "Valid date of birth is required (MM/DD/YYYY)";
	} else if (getAge(dob) < 13) {
		return "Users must be at least 13 years of age";
	} else {
		return "";
	}
};
export function getAge(dateString: any) {
	var today = new Date();
	var birthDate = new Date(dateString);
	var age = today.getFullYear() - birthDate.getFullYear();
	var m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
}
export interface IErrors {
	token?: any;
	salesRepresentativeId?: string;
}

const validateCaptcha = (values: IErrors) => {
	const errors: IErrors = {};

	if (!values.token) {
		errors.token = "reCaptcha is required";
	}
	return errors;
};

export {
	validateStep1,
	validateStep2,
	validateStep3,
	validateQs,
	validateCaptcha,
	validateDob,
	validateUserName,
	validateFirstName,
	validateLastName,
};

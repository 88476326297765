import React from "react";
import { Card, CardContent, CardMedia, Grid, Button } from "@mui/material";
import {
	oneTimeCardValidation,
	oneTimeBankValidation,
} from "../Payments/validation";
import {
	AccountNumber,
	AccountType,
	TextInput,
	PaymentType,
} from "../../Forms/Inputs";
import AcceptedCards from "../Payments/AcceptedCards";
import Title from "../Dialogs/Title";

const classes: any = {
	ccInput: {
		width: { xs: "70px", sm: "100px" },
		margin: "5px",
		mr: { sm: "20px" },
	},
};
export default function OneTimeCardForm(props: any) {
	const [errors, setErrors] = React.useState<any>({});
	const { oneTimePaymentType, setOneTimePaymentType } = props;
	React.useEffect(() => {
		props.setOneTime(true);
	}, []);
	React.useEffect(() => {
		setErrors({ ...errors, firstName: "", lastName: "" });
	}, [props.paymentType]);
	const handleChange = (event: any) => {
		let prop: any = [event.target.name];
		if (errors[prop]) {
			setErrors({ ...errors, [event.target.name]: "" });
		}
		props.setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};
	let values: any = props.values;
	const handlePayment = () => {
		let valid: any;
		let config: any = {
			CardNumber: values.creditcard,
			NameOnCard: values.nameOnCard,
			ExpiryMonth: values.expiryMonth,
			ExpiryYear: values.expiryYear,
			SecurityCode: values.securityCode,
			firstName: values.firstName,
			lastName: values.lastName,
		};

		let achConfig: any = {
			AccountNumber: values.accountNumber,
			RoutingNumber: values.routing,
			BankAccountType: values.accountTypeFull,
			firstName: values.firstName,
			lastName: values.lastName,
			NameOnAccount: values.firstName + " " + values.lastName,
			BankName: values.bankName,
		};
		let eftConfig: any = {
			AccountNumber: values.accountNumber,
			TransitNumber: values.transitNumber,
			InstitutionNumber: values.institutionNumber,
			BankAccountType: values.accountTypeFull,
			NameOnAccount: values.firstName + " " + values.lastName,
			firstName: values.firstName,
			lastName: values.lastName,
		};
		if (oneTimePaymentType === "CreditCard") {
			valid = oneTimeCardValidation(
				config,
				setErrors,
				props.clubData?.validCCTypes
			);
			setOneTimePaymentType("CreditCard");
		} else if (props.clubData.paymentMethods?.includes("Ach")) {
			setOneTimePaymentType("Ach");
			valid = oneTimeBankValidation(achConfig, setErrors, "Ach");
		} else if (props.clubData.paymentMethods?.includes("Eft")) {
			setOneTimePaymentType("Eft");
			valid = oneTimeBankValidation(eftConfig, setErrors, "Eft");
		}
		if (valid) {
			props.setOneTime(true);
			props.setPaymentMethod("0");
			props.onClose();
		}
	};
	const handleAccountType = (event: any) => {
		let prop: any = [event.target.name];
		if (errors[prop]) {
			setErrors({ ...errors, [event.target.name]: "" });
		}
		props.setValues({
			...values,
			accountTypeFull: event.target.value as string,
		});
	};
	const handleClose = () => {
		props.setPaymentProfile();
		props.setOneTime(false);
		props.onClose();
	};
	const handlePaymentTypeChange = (event: any) => {
		setOneTimePaymentType(event.target.value as string);
	};
	return (
		<div>
			<form autoComplete="off" noValidate>
				<Card raised={true}>
					<Title title="One Time Payment" onClose={handleClose} />
					<CardMedia title="Account" key="1" />
					<CardContent>
						<Grid container justifyContent="center" sx={{ mb: 2 }}>
							<PaymentType
								value={oneTimePaymentType}
								onChange={handlePaymentTypeChange}
							/>
						</Grid>
						{oneTimePaymentType === "CreditCard" ? (
							<>
								<AcceptedCards acceptedCards={props.acceptedCards} />
								<Grid
									container
									justifyContent="center"
									spacing={3}
									sx={{ mt: 0.5, mb: 2 }}
								>
									<Grid item>
										<TextInput
											label={"First Name"}
											name="firstName"
											onChange={handleChange}
											value={values.firstName}
											error={errors.firstName ? true : false}
											helperText={errors.firstName}
										/>
									</Grid>
									<Grid item>
										<TextInput
											label={"Last Name"}
											name="lastName"
											onChange={handleChange}
											value={values.lastName}
											error={errors?.lastName ? true : false}
											helperText={errors?.lastName}
										/>
									</Grid>
									<Grid item>
										<TextInput
											label={"Name on Card"}
											name="nameOnCard"
											onChange={handleChange}
											value={values.nameOnCard}
											error={errors.nameOnCard ? true : false}
											helperText={errors.nameOnCard}
										/>
									</Grid>
									<Grid item>
										<TextInput
											label={"Card Number"}
											name="creditcard"
											onChange={handleChange}
											type="number"
											value={values?.creditcard}
											error={errors.creditcard ? true : false}
											helperText={errors.creditcard}
										/>
									</Grid>
								</Grid>
								<Grid container justifyContent="center" spacing={2}>
									<Grid item sx={classes.ccInput}>
										<TextInput
											label={"CVV"}
											name="securityCode"
											onChange={handleChange}
											type="number"
											value={values.securityCode}
											error={errors.securityCode ? true : false}
											helperText={errors.securityCode}
										/>
									</Grid>
									<Grid item sx={classes.ccInput}>
										<TextInput
											type="number"
											label={"Exp Mo."}
											name="expiryMonth"
											placeholder="MM"
											onChange={handleChange}
											value={values.expiryMonth}
											error={errors.expiryMonth ? true : false}
											helperText={errors.expiryMonth}
										/>
									</Grid>
									<Grid item sx={classes.ccInput}>
										{" "}
										<TextInput
											label={"Exp Year"}
											name="expiryYear"
											type="number"
											onChange={handleChange}
											placeholder="YYYY"
											value={values.expiryYear}
											error={errors.expiryYear ? true : false}
											helperText={errors.expiryYear}
										/>
									</Grid>
								</Grid>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Button
											fullWidth
											onClick={handleClose}
											variant="outlined"
											color="primary"
											style={{ marginTop: 20 }}
										>
											Cancel
										</Button>
									</Grid>
									<Grid item xs={6}>
										<Button
											fullWidth
											onClick={handlePayment}
											variant="contained"
											color="primary"
											style={{ marginTop: 20 }}
										>
											Continue
										</Button>
									</Grid>
								</Grid>
							</>
						) : (
							<>
								<Grid container justifyContent="center" spacing={3}>
									<Grid item>
										<TextInput
											onChange={handleChange}
											value={values.firstName}
											error={errors.firstName ? true : false}
											helperText={errors.firstName}
											label={"First Name"}
											name="firstName"
										/>
									</Grid>
									<Grid item>
										<TextInput
											label={"Last Name"}
											name="lastName"
											onChange={handleChange}
											value={values.lastName}
											error={errors?.lastName ? true : false}
											helperText={errors?.lastName}
										/>
									</Grid>

									<Grid item>
										<AccountNumber
											onChange={handleChange}
											value={values?.accountNumber}
											inputProps={{ maxLength: 17 }}
											error={errors.accountNumber ? true : false}
											helperText={errors.accountNumber}
										/>
									</Grid>
									{props.clubData.paymentMethods.includes("Ach") ? (
										<Grid item>
											<TextInput
												label={"Routing Number"}
												name="routing"
												value={values?.routing}
												inputProps={{ maxLength: 9 }}
												error={errors.routing ? true : false}
												helperText={errors.routing}
												onChange={handleChange}
											/>
										</Grid>
									) : null}
									{props.clubData.paymentMethods.includes("Eft") ? (
										<>
											<Grid item>
												<TextInput
													label={"Transit Number"}
													name="transitNumber"
													onChange={handleChange}
													inputProps={{ maxLength: 5 }}
													value={values?.transitNumber}
													error={errors.transitNumber ? true : false}
													helperText={errors.transitNumber}
												/>
											</Grid>
											<Grid item>
												<TextInput
													label={"Institution Number"}
													inputProps={{ maxLength: 3 }}
													name="institutionNumber"
													onChange={handleChange}
													value={values?.institutionNumber}
													error={errors.institutionNumber ? true : false}
													helperText={errors.institutionNumber}
												/>
											</Grid>
											<Grid item>
												<AccountType
													sx={{ width: 223 }}
													helperText={errors.accountTypeFull}
													value={values?.accountTypeFull}
													onChange={handleAccountType}
													error={errors.accountTypeFull ? true : false}
												/>
											</Grid>
										</>
									) : null}
								</Grid>
								<Grid
									container
									justifyContent="center"
									spacing={3}
									sx={{ mt: 0 }}
								>
									{props.clubData.paymentMethods.includes("Ach") ? (
										<>
											<Grid item>
												<TextInput
													label={"Bank Name"}
													name="bankName"
													onChange={handleChange}
													value={values.bankName}
													error={errors.bankName ? true : false}
													helperText={errors.bankName}
												/>
											</Grid>
											<Grid item>
												<AccountType
													sx={{ width: 223 }}
													helperText={errors.accountTypeFull}
													value={values?.accountTypeFull}
													onChange={handleAccountType}
													error={errors.accountTypeFull ? true : false}
												/>
											</Grid>
										</>
									) : null}
								</Grid>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Button
											fullWidth
											onClick={() => handleClose()}
											variant="outlined"
											color="primary"
											style={{ marginTop: 20 }}
										>
											Cancel
										</Button>
									</Grid>
									<Grid item xs={6}>
										<Button
											fullWidth
											onClick={handlePayment}
											variant="contained"
											color="primary"
											style={{ marginTop: 20 }}
										>
											Continue
										</Button>
									</Grid>
								</Grid>
							</>
						)}
					</CardContent>
				</Card>
			</form>
		</div>
	);
}

import {
	validateUserName,
	validateFirstName,
	validateLastName,
	isPhoneInValid,
	validateDob,
	getAge,
} from "../SignUp/Validation";

const profileInfoValidate = (values: any) => {
	let errors: any = {};
	errors.firstName = validateFirstName(values.firstName);
	errors.lastName = validateLastName(values.lastName);
	errors.cellPhone =
		values.cellPhone || values.homePhone || values.workPhone
			? ""
			: "At least one phone number is required";
	if (values.cellPhone) {
		errors.cellPhone = isPhoneInValid(values.cellPhone)
			? "Must be a valid phone number"
			: "";
	}
	if (values.homePhone) {
		errors.homePhone = isPhoneInValid(values.homePhone)
			? "Must be a valid phone number"
			: "";
	}
	if (values.workPhone) {
		errors.workPhone = isPhoneInValid(values.workPhone)
			? "Must be a valid phone number"
			: "";
	}
	if (values.dateOfBirth) {
		errors.dateOfBirth = validateDob(values.dateOfBirth);
	}
	if (
		values.zip ||
		values.city ||
		values.state ||
		values.country ||
		values.address
	) {
		errors.zip = !values.zip
			? "Field is required"
			: validateZipcode({ zip: values.zip, country: values.country });
		errors.city = !values.city ? "Field is required" : "";
		errors.state = !values.state ? "Field is required" : "";
		errors.country = !values.country ? "Field is required" : "";
		errors.address = !values.address ? "Field is required" : "";
	}

	return errors;
};

const validateZipcode = (values: any) => {
	let errors: any = {};
	var regex = /.*/;
	if (values.country === "US") {
		regex = /^\d{5}(?:[-\s]\d{4})?$/;
	} else if (values.country === "CA") {
		regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
	}
	if (!regex.test(values.zip)) {
		errors.zip = "Please enter correct value";
	} else {
		errors.zip = "";
	}
	return errors.zip;
};
const addUserValidate = (values: any) => {
	let errors: any = {};
	errors.firstName = validateFirstName(values.firstName);
	errors.lastName = validateLastName(values.lastName);
	if (values.userName && !values.email) {
		errors.email = "Email is required when username is entered";
	}
	if (values.userName && values.email) {
		errors.email = /\S+@\S+\.\S+/.test(values.email)
			? ""
			: "Email is not valid";
	}
	if (
		inFuture(values.dob) ||
		getAge(values.dob) > 100 ||
		values.dob == "Invalid Date"
	) {
		errors.dob = "Please enter a valid date";
	}
	if (
		values.dob === "0001-01-01T00:00:00Z" ||
		values.dob === null ||
		!values.dob
	) {
		errors.dob = "Date of birth is required";
	} else if (values.dob === "invalid") {
		errors.dob = "Please enter a valid date";
	}
	if (values.userName) {
		errors.userName = validateUserName(values.userName);
	}
	return errors;
};

const inFuture = (date: any) => {
	return new Date(date).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);
};
export { profileInfoValidate, addUserValidate };

import * as constants from "../constants/invoices.constants";

const initialState = {
	requesting: false,
	successful: false,
	getRequest: false,
	getSuccess: false,
	messages: null,
	errors: null,
	invoices: null,
	invoiceDetails: null,
	invoicePdf: null,
	pdfRequesting: null,
	pdfSuccess: null,
	pdfError: null,
};

function invoicesReducer(state = initialState, action: any) {
	switch (action.type) {
		case constants.GET_INVOICES_REQUEST:
			return {
				...state,
				getRequest: true,
				getSuccess: false,
			};
		case constants.GET_INVOICES_SUCCESS:
			return {
				...state,
				getRequest: false,
				getSuccess: true,
				errors: null,
				invoices: action.response.data,
			};
		case constants.INVOICE_DETAILS_REQUEST:
			return {
				...state,
				invoiceDetails: null,
			};
		case constants.INVOICE_IMAGE_REQUEST:
			return {
				...state,
				pdfRequesting: true,
				pdfSuccessful: false,
				pdfError: null,
			};
		case constants.INVOICE_IMAGE_SUCCESS:
			return {
				...state,
				pdfRequesting: false,
				pdfSuccessful: true,
				pdfError: null,
				invoicePdf: action.response.data[0].base64String,
			};
		case constants.PAY_INVOICES_REQUEST:
			return {
				...state,
				requesting: true,
				successful: false,
			};
		case constants.PAY_INVOICES_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages: "Invoice payment submitted",
			};
		case constants.EMAIL_INVOICE_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				messages: "Invoice emailed successfully",
			};
		case constants.INVOICE_DETAILS_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				invoiceDetails: action.response.data,
			};
		case constants.PAY_INVOICES_ERROR:
		case constants.INVOICE_DETAILS_ERROR:
		case constants.INVOICE_IMAGE_ERROR:
		case constants.EMAIL_INVOICE_ERROR:
		case constants.GET_INVOICES_ERROR:
			return {
				...state,
				requesting: false,
				successful: false,
				errors: action.error.response?.data,
			};
		case constants.CLEAR_INVOICES_ERROR:
			return {
				...state,
				errors: null,
				messages: null,
			};
		default:
			return state;
	}
}

export default invoicesReducer;

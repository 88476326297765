import React from "react";
import { Grow, IconButton, Snackbar, Alert } from "@mui/material";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

const classes: any = {
	alert: {
		alignItems: "center",
		justifyContent: "center",
		py: 1.6,
	},
	closeButton: {
		justifyContent: "flex-end",
		mt: -0.5,
	},
};
function Notifications(props: any) {
	const [open, setOpen] = React.useState(true);
	const handleClose = () => {
		clearAll();
		setOpen(false);
	};
	const clearAll = () => {
		props.clearErrors();
		props.clearMessage();
		props.clearBillingMessage();
		props.clearMembershipMessage();
		props.clearInvoiceMessage();
		props.clearEnrollMessage();
		props.clearScheduleMessage();
		props.clearMarketplace();
	};
	let severity: any;
	let notificationText: any;
	if (props.messages) {
		severity = "success";
		if (props.messages === "Payment unsuccessful") {
			severity = "error";
		}
		notificationText = props.messages;
	}
	if (props.errors) {
		severity = "error";
		notificationText = props.errors.message || props.errors;
	}
	let vertical: any = "top";
	let horizontal: any = "center";
	return (
		<Snackbar
			onClose={() => handleClose()}
			open={open}
			anchorOrigin={{ vertical, horizontal }}
			key={vertical + horizontal}
			sx={{
				ml: { md: 3.7 },
			}}
		>
			<Grow in={true} timeout={450}>
				<Alert
					action={
						!props.hide ? (
							<IconButton
								size="small"
								sx={classes.closeButton}
								onClick={() => {
									clearAll();
								}}
							>
								<CloseIcon />
							</IconButton>
						) : null
					}
					sx={classes.alert}
					severity={severity}
				>
					{notificationText}
				</Alert>
			</Grow>
		</Snackbar>
	);
}
const mapDispatchToProps = (dispatch: any) => {
	return {
		clearErrors: () => dispatch({ type: "CLEAR_MESSAGE" }),
		clearMessage: () => dispatch({ type: "CLEAR_ERRORS" }),
		clearBillingMessage: () => dispatch({ type: "CLEAR_BILLING_MESSAGE" }),
		clearMembershipMessage: () => dispatch({ type: "CLEAR_MEMBERSHIP_ERRORS" }),
		clearInvoiceMessage: () => dispatch({ type: "CLEAR_INVOICES_ERROR" }),
		clearEnrollMessage: () => dispatch({ type: "CLEAR_ENROLL_ERROR" }),
		clearScheduleMessage: () => dispatch({ type: "CLEAR_SCHEDULE_ERROR" }),
		clearMarketplace: () => dispatch({ type: "CLEAR_MARKETPLACE_ERRORS" }),
	};
};
export default connect(null, mapDispatchToProps)(Notifications);

import React from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Grow,
	Card,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SortTable from "./SortTable";
import Notifications from "../../AlertMessage/Notifications";
import Loader from "../../../utils/Loader";

const classes: any = {
	root: {
		width: "100%",
		marginBottom: "20px",
		marginTop: "20px",
		backgroundColor: "#FAFAFA",
	},
	header: {
		backgroundColor: "primary.main",
		color: "white",
		fontSize: 22,
		lineHeight: 1,
		margin: 0,
	},
};

export default function Memberships(props: any) {
	const {
		userConfig,
		getMemberships,
		paymentMessage,
		paymentError,
		memberships,
	} = props;

	let config = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		id: userConfig ? userConfig.clientId : null,
	};
	const [expand, setExpand] = React.useState(false);
	const toggleAccordion = () => {
		setExpand((prev) => !prev);
	};
	React.useEffect(() => {
		if (expand) {
			getMemberships(config);
		}
	}, [expand]);
	React.useEffect(() => {
		if (paymentMessage == "Payment submitted") {
			getMemberships(config);
		}
	}, [paymentMessage]);

	return (
		<div style={classes.root}>
			{paymentMessage || paymentError ? (
				<Notifications messages={paymentMessage} errors={paymentError} />
			) : null}
			<Grow in={true} timeout={500}>
				<Card raised>
					<Accordion expanded={expand} defaultExpanded={false}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
							aria-controls="panel1a-content"
							id="panel1a-header"
							sx={[classes.header]}
							onClick={toggleAccordion}
						>
							<Typography variant="h5">Manage Memberships</Typography>
						</AccordionSummary>
						<AccordionDetails sx={{ p: 0 }}>
							{memberships ? (
								<SortTable
									{...props}
									acceptedCards={props.clubData.validCCTypes}
								/>
							) : (
								<Loader />
							)}
						</AccordionDetails>
					</Accordion>
				</Card>
			</Grow>
		</div>
	);
}

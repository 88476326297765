import React from "react";

export const renderPaymentMethods = (
	billingProfiles: any,
	oneTimePaymentType?: any,
	values?: any,
	oneTime?: any
) => {
	let paymentMethods: any;
	if (billingProfiles && billingProfiles.length === 0 && !oneTime) {
		paymentMethods = (
			<option value={"No payment methods found"}>
				No payment methods found
			</option>
		);
	} else if (billingProfiles) {
		paymentMethods = billingProfiles.map((profile: any) => (
			<React.Fragment key={profile.id}>
				{profile.creditcard ? (
					<option key={profile.id} value={profile.id}>
						{profile.creditCardType} ends in{" "}
						{profile.creditcard?.substr(profile.creditcard.length - 4)}
						{profile.isDefaultProfile ? " (Default)" : null}
					</option>
				) : (
					<option key={profile.id} value={profile.id}>
						{profile.accountTypeFull} ends in{" "}
						{profile.account?.substr(profile.account.length - 4)}
						{profile.isDefaultProfile ? " (Default)" : null}
					</option>
				)}
			</React.Fragment>
		));
		if (oneTimePaymentType === "CreditCard" && oneTime) {
			paymentMethods.unshift(
				<option key={"0"} value={"0"}>
					One time card payment ends in{" "}
					{values.creditcard?.substr(values.creditcard.length - 4)}
				</option>
			);
		}
		if (
			(oneTimePaymentType === "Ach" && oneTime) ||
			(oneTimePaymentType === "Eft" && oneTime)
		) {
			paymentMethods.unshift(
				<option key={"0"} value={"0"}>
					One time bank account ends in{" "}
					{values.accountNumber?.substr(values.accountNumber.length - 4)}
				</option>
			);
		}
	}
	return paymentMethods;
};

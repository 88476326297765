import { Grid, FormControl, Select, MenuItem, Checkbox } from "@mui/material";
import { formatCurrency } from "../../../utils/FormatCurrency";

const ClassOptions = (props: any) => {
	const { product, selected, setSelected, id, quantity, setQuantity } = props;
	const handleSelect = () => {
		if (selected === id) {
			setSelected(0);
		} else {
			setSelected(id);
			setQuantity(1);
		}
	};
	const handleQuantity = (value: any) => {
		setQuantity(value);
	};
	const options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
	return (
		<>
			<Grid container>
				<Grid item xs={5} sm={6} sx={{ mt: { sm: 1.5 } }}>
					{product.name}
				</Grid>
				<Grid
					item
					xs={2}
					sx={{
						mt: 1.5,
						textAlign: "left",
					}}
				>
					{formatCurrency(Number(product.amount), props.clubData)}
				</Grid>
				<Grid item xs={2} sx={{ textAlign: { xs: "center" }, mb: 1 }}>
					<FormControl sx={{ m: 1, mt: { xs: 0, sm: 0.5 } }} size="small">
						<Select
							labelId="demo-select-small"
							id="demo-select-small"
							value={id !== selected ? 0 : quantity}
							onChange={(event: any) => handleQuantity(event.target.value)}
							disabled={id !== selected}
						>
							{options.map((option: any) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					item
					xs={2}
					sx={{
						p: 0,
						textAlign: "right",
						pt: { xs: 0, sm: 0.4 },
						ml: { xs: 2, sm: 0 },
					}}
				>
					<>
						<Checkbox
							onClick={handleSelect}
							checked={id === selected}
							color="primary"
							sx={{ p: 0.5, mt: 0.5 }}
						/>
					</>
				</Grid>
			</Grid>
		</>
	);
};

export default ClassOptions;

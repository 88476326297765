import * as constants from "../constants/users.constants";

const initialState = {
	requesting: false,
	successful: false,
	messages: null,
	errors: null,
	submitted: [],
	canReusePassword: false,
	validToken: true,
};

function userReducer(state = initialState, action: any) {
	switch (action.type) {
		case constants.FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages:
					"An email has been sent to the email address that we have on file to reset your password",
			};
		case constants.FORGOT_PASSWORD_FAILURE:
		case constants.FORGOT_USERNAME_FAILURE:
		case constants.RESET_PASSWORD_FAILURE:
		case constants.RESEND_EMAIL_FAILURE:
			return {
				...state,
				requesting: false,
				successful: false,
				errors:
					action.error.response?.data?.error ||
					action.error.data?.error?.message,
			};
		case constants.FORGOT_USERNAME_REQUEST:
		case constants.FORGOT_PASSWORD_REQUEST:
		case constants.CHANGE_PASSWORD_REQUEST:
		case constants.RESET_PASSWORD_REQUEST:
		case constants.RESEND_EMAIL_REQUEST:
			return {
				...state,
				requesting: true,
				errors: null,
			};
		case constants.FORGOT_USERNAME_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages: "An email has been sent with your username",
			};

		case constants.CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages:
					"Your password has successfully changed please try to sign in",
			};
		case constants.CHECK_TOKEN_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				validToken: action.response.data,
			};
		case constants.CHECK_TOKEN_FAILURE:
			return {
				...state,
				errors: "Failed to verify token, please check url and try again",
			};
		case constants.CHANGE_PASSWORD_FAILURE:
			let changePasswordErrors: any;

			if (action.error.response.data.errors) {
				changePasswordErrors = Object.values(
					action.error.response.data.errors
				).toString();
			} else {
				changePasswordErrors = action.error.response.data.error.message;
			}
			return {
				...state,
				requesting: false,
				successful: false,
				errors: changePasswordErrors,
			};
		case constants.RESET_PASSWORD_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages:
					"Your password has successfully changed please try to sign in",
			};
		case constants.SET_PASSWORD_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				messages: "Your password has successfully been set",
			};
		case constants.SET_PASSWORD_FAILURE:
			let setPasswordErrors: any;

			if (action.error.response.data.errors) {
				setPasswordErrors = Object.values(
					action.error.response.data.errors
				).toString();
			} else {
				setPasswordErrors = action.error.response.data.error.message;
			}
			return {
				...state,
				requesting: false,
				successful: true,
				errors: setPasswordErrors,
			};
		case constants.RESEND_EMAIL_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages:
					"Email has been resent, please confirm and try to sign in again",
			};
		case constants.VALID_PASSWORD_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				canReusePassword: action.response.data,
			};
		case constants.VALID_PASSWORD_FAILURE:
			let validPasswordErrors: any;

			if (action?.error?.data?.errors) {
				validPasswordErrors = Object.values(
					action.error.data.errors
				).toString();
			} else {
				validPasswordErrors = action.error.data.error.message;
			}
			return {
				...state,
				messages: null,
				requesting: false,
				successful: false,
				errors: validPasswordErrors,
			};
		case constants.CLEAR_MESSAGE:
			return {
				...state,
				requesting: false,
				errors: null,
				messages: null,
			};
		case constants.CLEAR_USER_MESSAGE:
			return {
				...state,
				requesting: false,
				errors: null,
				messages: null,
				canReusePassword: false,
			};
		default:
			return state;
	}
}

export default userReducer;

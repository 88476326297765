import React from "react";
import {
	Grid,
	Typography,
	TextField,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Checkbox,
	Button,
	FormControl,
	InputLabel,
	Select,
	Dialog,
	InputAdornment,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import PaymentMethods from "../../../containers/PaymentMethods";
import OneTimeCardForm from "../Dialogs/OneTimePayForm";
import ButtonLoader from "../../../utils/ButtonLoader";
import styles from "../../../styles";
import Title from "../Dialogs/Title";
import { renderPaymentMethods } from "../../Forms/PaymentMethods";
import { formatCurrency, validate } from "../../../utils/FormatCurrency";

const classes: any = {
	root: {
		backgroundColor: "#FAFAFA",
		width: "100%",
		margin: 0,
	},
	table: {
		width: "100%",
		padding: "20px",
	},
};

export default function PayInvoice(props: any) {
	const {
		userConfig,
		billingProfiles,
		invoices,
		credit,
		clubData,
		userInfo,
		credits,
		getCredits,
		creditsSuccess,
		requesting,
		paymentMessage,
		paymentSuccessful,
		onClose,
	} = props;
	const [checked, setChecked] = React.useState<boolean>(false);
	const [client, setClient] = React.useState(clubData?.clubType);
	const [paymentMethod, setPaymentMethod] = React.useState<string>("");
	const [open, setOpen] = React.useState<string>("0");
	const [oneTimePaymentType, setOneTimePaymentType] =
		React.useState<string>("CreditCard");
	const [oneTime, setOneTime] = React.useState<boolean>(false);
	const [values, setValues] = React.useState<any>({
		amountType: "all",
		credit: credit,
		totalDue: 0,
		amount: invoices?.reduce(function (tot: any, arr: any) {
			return tot + arr.amount - arr.amountPaid;
		}, 0),
		paymentMethodType: paymentMethod,
		billingProfileId: null,
		accountNumber: "",
		routing: "",
		accountTypeFull: "",
		transitNumber: "",
		institutionNumber: "",
		bankName: "",
		creditcard: null,
		nameOnCard: null,
		expiryMonth: null,
		expiryYear: null,
		securityCode: null,
		firstName: userInfo?.firstName,
		lastName: userInfo?.lastName,
	});
	React.useEffect(() => {
		setPaymentProfile();
	}, []);
	React.useEffect(() => {
		if (!creditsSuccess && !requesting) {
			getCredits(creditsConfig);
		}
	}, [creditsSuccess]);
	React.useEffect(() => {
		if (paymentMessage === "Invoice payment submitted" && paymentSuccessful) {
			onClose();
		}
	}, [paymentMessage]);
	React.useEffect(() => {
		setPaymentProfile();
	}, [billingProfiles]);

	const setPaymentProfile = () => {
		let isDefault: any = false;
		if (!oneTime) {
			sortedBillingProfiles?.map((profile: any) => {
				if (profile.isDefaultProfile) {
					setPaymentMethod(profile.id);
					isDefault = true;
				}
			});
			if (!isDefault) {
				if (sortedBillingProfiles.length > 0) {
					setPaymentMethod(sortedBillingProfiles[0].id);
				}
			}
		} else {
			setPaymentMethod(paymentMethod);
		}
	};
	const handlePaymentChange = (event: SelectChangeEvent) => {
		let value: any = event.target.value;
		setPaymentMethod(value as any);
	};
	let sortedBillingProfiles: any = billingProfiles.sort(function (
		a: any,
		b: any
	) {
		return b.isDefaultProfile - a.isDefaultProfile;
	});

	const openOneTimePayment = () => {
		setOpen("2");
	};
	const openAddPayment = () => {
		setOpen("1");
	};
	const handleClose = () => {
		setOpen("0");
	};
	const handleChange = (event: any) => {
		setValues({
			...values,
			[event.target.name]: validate(event.target.value),
		});
	};
	const payInvoices = () => {
		props.payInvoices(config);
	};
	const handleSetChecked = () => {
		setChecked(!checked);
	};
	let paymentMethods = renderPaymentMethods(
		billingProfiles,
		oneTimePaymentType,
		values,
		oneTime
	);
	let amount = React.useMemo(
		() =>
			props.invoices.reduce(function (tot: any, arr: any) {
				return tot + arr.amount - arr.amountPaid;
			}, 0),
		[]
	);
	let creditsConfig = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		invoiceId: invoices ? invoices.map((invoice: any) => invoice.id) : null,
		id: userConfig ? userConfig.clientId : null,
	};
	let config = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		invoiceIds: invoices ? invoices.map((invoice: any) => invoice.id) : null,
		clientId: userConfig?.clientId,
		billingProfileId:
			oneTime || (checked && values.amount < credits / 100)
				? "0"
				: paymentMethod,
		credits: checked ? credit : 0,
		amount: Number(values.amount),
		creditCard:
			oneTimePaymentType === "CreditCard" && oneTime
				? {
						cardNumber: values.creditcard,
						nameOnCard: values.nameOnCard,
						expiryMonth: values.expiryMonth,
						expiryYear: values.expiryYear,
						securityCode: values.securityCode,
				  }
				: null,
		eft:
			oneTimePaymentType === "Eft" && oneTime
				? {
						AccountNumber: values.accountNumber,
						TransitNumber: values.transitNumber,
						InstitutionNumber: values.institutionNumber,
						BankAccountType: values.accountTypeFull,
						NameOnAccount: values.firstName + " " + values.lastName,
				  }
				: null,
		ach:
			oneTimePaymentType === "Ach" && oneTime
				? {
						AccountNumber: values.accountNumber,
						RoutingNumber: values.routing,
						BankAccountType: values.accountTypeFull,
						NameOnAccount: values.firstName + " " + values.lastName,
						BankName: values.bankName,
				  }
				: null,
	};
	return (
		<div className={classes.root}>
			<Title title="Invoice Details" onClose={props.onClose} />
			<Grid container direction="row" justifyContent="flex-start">
				<Table sx={classes.table} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell
								sx={{ minWidth: 65, py: { xs: 0, md: 2 } }}
								align="left"
							>
								<strong>Invoice #</strong>
							</TableCell>
							<TableCell sx={{ py: { xs: 0, md: 2 } }} align="left">
								<strong>Description</strong>
							</TableCell>
							<TableCell sx={{ py: { xs: 0, md: 2 } }} align="right">
								<strong>Amount Due</strong>
							</TableCell>
						</TableRow>
					</TableHead>
					{invoices?.map((invoice: any) => (
						<>
							<TableBody style={{ margin: 20 }}>
								<TableRow key={invoice.id}>
									<TableCell align="left">{invoice.id}</TableCell>
									<TableCell align="left">{invoice.description}</TableCell>
									<TableCell align="right">
										{formatCurrency(
											invoice.amount - invoice.amountPaid,
											clubData
										)}
									</TableCell>
								</TableRow>
							</TableBody>
						</>
					))}
				</Table>
				<Grid container justifyContent="flex-start" sx={{ p: 2.5, pt: 0.5 }}>
					<Grid container justifyContent="space-between">
						<Typography variant="subtitle1">
							<strong>Total:</strong>
						</Typography>
						<Typography variant="subtitle1">
							{formatCurrency(amount, clubData)}
						</Typography>
					</Grid>
					{invoices.length < 2 ? (
						<Grid container justifyContent="space-between">
							<Grid item style={{ marginTop: 10 }}>
								<Typography>
									<strong>Pay Amount:</strong>
								</Typography>
							</Grid>
							<Grid item xs={5} sm={3}>
								<TextField
									placeholder="0"
									size="small"
									name="amount"
									onChange={handleChange}
									inputProps={{
										style: {
											textAlign: "right",
											marginRight: 0,
											paddingRight: 5,
										},
									}}
									variant="outlined"
									type="number"
									value={values.amount}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												{clubData.currencyPrefix}
											</InputAdornment>
										),
										endAdornment: clubData.currencyPostfix && (
											<InputAdornment position="end">
												{clubData.currencyPostfix}
											</InputAdornment>
										),
									}}
								/>
							</Grid>
						</Grid>
					) : null}

					{checked && values.amount > 0 && (
						<>
							<Grid container justifyContent="space-between">
								<Typography variant="subtitle1">Credits Applied:</Typography>
								<Typography variant="subtitle1">
									(
									{Number(props.credits) / 100 - values?.amount > 0
										? formatCurrency(Number(values.amount), clubData)
										: formatCurrency(Number(props.credits) / 100, clubData)}
									)
								</Typography>
							</Grid>
							{Number(props.credits) / 100 - values?.amount < 0 && (
								<Grid container justifyContent="space-between">
									<Typography variant="subtitle1">Amount Remaining:</Typography>
									<Typography variant="subtitle1">
										{formatCurrency(
											Number(values.amount) - Number(props.credits) / 100,
											clubData
										)}
									</Typography>
								</Grid>
							)}
						</>
					)}
					{values?.amount > amount ? (
						<Grid container justifyContent="center">
							<Typography
								variant="subtitle2"
								align="center"
								sx={{ color: "red", mt: 1 }}
							>
								Payment amount cannot exceed invoice total
							</Typography>
						</Grid>
					) : null}
					{credits ? (
						<Grid
							container
							alignItems="flex-end"
							justifyContent="flex-start"
							sx={{ p: 0 }}
						>
							<Grid item>
								<Checkbox
									sx={{ p: 0, pr: 0.5 }}
									checked={checked}
									onChange={handleSetChecked}
								/>
							</Grid>
							<Grid item>
								<Typography>
									Use Available Credit (
									{formatCurrency(credits / 100, clubData)})
								</Typography>
							</Grid>
						</Grid>
					) : null}
				</Grid>
				<Grid
					container
					sx={{ p: 2 }}
					direction="column"
					justifyContent="flex-end"
				>
					<FormControl margin="normal" variant="outlined">
						<InputLabel shrink={true} htmlFor="outlined-age-native-simple">
							Payment Method
						</InputLabel>
						<Select
							label="Payment Method"
							native
							value={paymentMethod}
							onChange={handlePaymentChange}
							disabled={checked && props.credits / 100 >= values.amount}
							inputProps={{
								name: "paymentMethod",
								id: "outlined-age-native-simple",
								padding: 5,
							}}
						>
							{paymentMethods}
						</Select>
					</FormControl>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Button
								fullWidth
								onClick={openAddPayment}
								variant="outlined"
								color="primary"
							>
								Add Payment Method
							</Button>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Button
								fullWidth
								onClick={openOneTimePayment}
								variant="outlined"
								color="primary"
								disableRipple
								disabled={client === "FSB ONLY"}
							>
								One Time Payment
							</Button>
						</Grid>
					</Grid>
					<Dialog
						PaperProps={{
							style: {
								backgroundColor: "transparent",
								boxShadow: "none",
							},
						}}
						sx={styles.modal}
						open={open === "2"}
						onClose={handleClose}
					>
						<OneTimeCardForm
							{...props}
							values={values}
							setValues={setValues}
							oneTime={oneTime}
							setOneTime={setOneTime}
							setPaymentMethod={setPaymentMethod}
							oneTimePaymentType={oneTimePaymentType}
							setOneTimePaymentType={setOneTimePaymentType}
							setPaymentProfile={setPaymentProfile}
							onClose={handleClose}
						/>
					</Dialog>
				</Grid>

				<Dialog open={open === "1"} onClose={handleClose} sx={styles.modal}>
					<PaymentMethods {...props} handleClose={handleClose} open={true} />
				</Dialog>
				<Grid container sx={{ width: "100%", pl: 2, pb: 2 }} spacing={2}>
					<Grid item xs={12} sm={6}>
						<Button
							onClick={props.onClose}
							fullWidth
							variant="outlined"
							color="primary"
						>
							Back
						</Button>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Button
							onClick={payInvoices}
							fullWidth
							variant="contained"
							color="primary"
							disabled={values.amount > amount || values.amount == 0}
						>
							{props.paymentRequesting ? <ButtonLoader /> : "Pay Invoice"}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}

import axios from "axios";
import * as URL from "../config";

export function requestGetInvoices(action: any) {
	const getInvoicesUrl: any = `${URL.MP_MEMBER}/clients/invoices?ClientId=${action.id}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}`;
	return axios
		.get(getInvoicesUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestGetDetails(action: any) {
	const getDetailsUrl: any = `${URL.MP_BILLING}/invoices?Ids=${action.Ids}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}`;
	return axios
		.get(getDetailsUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestInvoicePdf(action: any) {
	const getInvoicePdfUrl: any = `${URL.MP_BILLING}/invoices/pdf?Ids=${action.Ids}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}`;
	return axios
		.get(getInvoicePdfUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestEmailInvoice(action: any) {
	const emailInvoiceUrl: any = `${URL.MP_BILLING}/invoices/sendEmail`;
	return axios
		.post(emailInvoiceUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestPayInvoices(action: any) {
	const payInvoicesUrl: any = `${URL.MP_BILLING}/invoices/pay`;
	return axios
		.post(payInvoicesUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

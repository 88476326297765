export const formatCurrency = (amount: any, clubData: any) => {

	return (
		(clubData.currencyPrefix ? " " + clubData.currencyPrefix : "$") +
		Number(amount)?.toFixed(2) +
		(clubData.currencyPostfix ? " " + clubData.currencyPostfix : "")
	);
};
export const validate = (t: any) => {
	let newValue: any =
		t.indexOf(".") >= 0
			? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
			: t;
	return newValue;
};

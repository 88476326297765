import React from "react";
import {
	Checkbox,
	FormControl,
	List,
	ListItem,
	ListItemText,
	Popover,
	TextField,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const classes: any = {
	formControl: {
		display: "flex",
		cursor: "pointer",
	},
	Popover: {
		maxHeight: "350px",
	},
};

interface INode {
	value: string;
	label: string;
	children?: INode[];
}

interface IChangeEventArgs {
	target: { name?: string; value: any };
}

interface IProps {
	nodes: INode[];
	name: string;
	label: string;
	onChange: (e: IChangeEventArgs) => void;
	value: INode[];
	variant?: any;
	selected: any;
	id: string;
	error: boolean;
	helperText: string;
	currentUser: string;
}

export default function DropdownTreeSelect(props: IProps) {
	const nodes: INode[] = props.nodes;
	const [state, setState] = React.useState<string>(props.currentUser);
	const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);

		switch (true) {
			case props.selected.length >= 2:
				setState(`${props.selected.length} selected`);
				break;
			case props.selected.length === 1:
				let name: any =
					props.selected[0] == props.id
						? props.currentUser
						: props.nodes.find((v: any) => v.value == props.value)?.label;
				setState(name);
				break;
			default:
				setState("Select One");
		}
	};
	React.useEffect(() => {
		if (props.value.length < 1) {
			setState("");
		}
	}, [props.value]);
	const renderNode = (node: INode, index: number, isChild?: boolean) => {
		const style = isChild ? { paddingLeft: 35 } : {};

		return (
			<ListItem
				key={index}
				dense
				button
				style={style}
				onClick={handleToggle(node)}
			>
				<Checkbox checked={props.selected.includes(node.value)} />
				<ListItemText primary={node.label} />
			</ListItem>
		);
	};
	const renderChildren = (node: INode) =>
		node.children &&
		node.children.map((child, index) => renderNode(child, index, true));

	const handleToggle = (node: INode) => () => {
		const currentIndex = props.value.findIndex(
			(i, index) => i.label === node.label
		);
		let newChecked = [...props.value];

		if (currentIndex === -1) {
			newChecked.push(node, ...(node.children || []));
			newChecked.find(
				(i) =>
					i.children &&
					i.children.findIndex((c) => c.value === node.value) !== -1
			);

			props.nodes.forEach((parent, index) => {
				let parentOfChild =
					parent.children &&
					parent.children.every((child) => {
						return newChecked.findIndex((c) => c.value === child.value) !== -1;
					});

				parentOfChild && newChecked.push(parent);
			});
		} else {
			const exclude = [node, ...(node.children || [])];

			let excludeParent = newChecked.find(
				(i) =>
					i.children &&
					i.children.findIndex((c) => c.value === node.value) !== -1
			);

			excludeParent && exclude.push(excludeParent);

			newChecked = newChecked.filter(
				(item) => exclude.findIndex((i) => i.value === item.value) === -1
			);
		}

		props.onChange({
			target: { name: node.label, value: node.value },
		});
	};

	return (
		<FormControl sx={classes.formControl} size="small" fullWidth={true}>
			<TextField
				name={props.name}
				label={""}
				value={state}
				error={props.error}
				helperText={props.helperText}
				type="string"
				variant={"outlined"}
				inputProps={{ readOnly: true, cursor: "pointer" }}
				InputProps={{
					endAdornment: <ArrowDropDownIcon />,
				}}
				onClick={handleClick}
				size="small"
			/>
			<Popover
				sx={classes.Popover}
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				<List sx={{ minWidth: 272, maxHeight: 300, overflow: "auto" }}>
					{nodes?.map((node, index) => (
						<span key={index}>
							{renderNode(node, index)}
							{renderChildren(node)}
						</span>
					))}
				</List>
			</Popover>
		</FormControl>
	);
}

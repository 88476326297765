import React from "react";
import {
	Grid,
	Typography,
	TextField,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableContainer,
	Checkbox,
	Button,
	FormControl,
	InputLabel,
	Select,
	InputAdornment,
	Dialog,
	Divider,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import PaymentMethods from "../../../containers/PaymentMethods";
import OneTimeCardForm from "../Dialogs/OneTimePayForm";
import Invoice from "../Invoices/InvoiceView";
import ButtonLoader from "../../../utils/ButtonLoader";
import Title from "../Dialogs/Title";
import styles from "../../../styles";
import { renderPaymentMethods } from "../../Forms/PaymentMethods";
import { formatCurrency, validate } from "../../../utils/FormatCurrency";
import moment from "moment";

const classes: any = {
	root: {
		background: "#FAFAFA",
	},
	padding: {
		paddingLeft: 20,
		paddingRight: 20,
		width: "100%",
	},
	table: {
		width: "100%",
		padding: "20px",
		paddingTop: 0,
	},
	link: {
		textDecoration: "underline",
		cursor: "pointer",
	},
	red: {
		color: "red",
	},
};

export default function PayMembership(props: any) {
	const {
		userConfig,
		billingProfiles,
		credit,
		credits,
		userInfo,
		clubData,
		paymentMessage,
		onClose,
		getBillingProfile,
		getCredits,
		paymentSuccessful,
	} = props;
	const [checked, setChecked] = React.useState(false);
	const [client, setClient] = React.useState(clubData?.clubType);
	const [paymentMethod, setPaymentMethod] = React.useState("");
	const [open, setOpen] = React.useState("0");
	const [oneTimePaymentType, setOneTimePaymentType] =
		React.useState<string>("CreditCard");
	const [oneTime, setOneTime] = React.useState(false);
	const [values, setValues] = React.useState<any>({
		amountType: "all",
		credit: credit,
		amount: 0,
		paymentMethodType: paymentMethod,
		billingProfileId: null,
		accountNumber: "",
		routing: "",
		accountTypeFull: "",
		creditcard: null,
		nameOnCard: null,
		expiryMonth: null,
		expiryYear: null,
		securityCode: null,
		bankName: "",
		firstName: userInfo.firstName,
		lastName: userInfo.lastName,
	});
	React.useEffect(() => {
		setPaymentProfile();
		getCredits(config);
		if (billingProfiles?.length == 0) {
			getBillingProfile(config);
		}
	}, []);
	React.useEffect(() => {
		setPaymentProfile();
	}, [billingProfiles]);
	React.useEffect(() => {
		if (paymentMessage === "Payment submitted" && paymentSuccessful) {
			onClose();
		}
	}, [paymentMessage]);
	const handleSetChecked = () => {
		if (!checked) {
			setChecked(true);
		} else {
			setChecked(false);
		}
	};
	const setPaymentProfile = () => {
		let isDefault: any = false;
		if (!oneTime) {
			sortedBillingProfiles?.forEach((profile: any) => {
				if (profile.isDefaultProfile) {
					setPaymentMethod(profile.id);
					isDefault = true;
				}
			});
			if (!isDefault) {
				if (sortedBillingProfiles.length > 0) {
					setPaymentMethod(sortedBillingProfiles[0].id);
				}
			}
		} else {
			setPaymentMethod(paymentMethod);
		}
	};
	const handlePaymentChange = (event: SelectChangeEvent) => {
		setPaymentMethod(event.target.value as string);
	};
	let sortedBillingProfiles: any = billingProfiles.sort(function (
		a: any,
		b: any
	) {
		return b.isDefaultProfile - a.isDefaultProfile;
	});
	const openOneTimePayment = () => {
		setOpen("2");
	};
	const openAddPayment = () => {
		setOpen("1");
	};
	const handleClose = () => {
		setOpen("0");
	};
	let oneTimePaymentModal = (
		<Dialog open={open === "2"} onClose={handleClose} sx={styles.modal}>
			<OneTimeCardForm
				{...props}
				values={values}
				setValues={setValues}
				oneTimePaymentType={oneTimePaymentType}
				setOneTimePaymentType={setOneTimePaymentType}
				setPaymentMethod={setPaymentMethod}
				setPaymentProfile={setPaymentProfile}
				oneTime={oneTime}
				setOneTime={setOneTime}
				onClose={handleClose}
			/>
		</Dialog>
	);
	let membership: any = props.memberships;

	const handleChange = (event: any) => {
		setValues({
			...values,
			[event.target.name]: validate(event.target.value),
		});
	};

	const openInvoice = (id: any) => {
		let config = {
			headers: {
				Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
			},
			partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
			businessAlias: userConfig ? userConfig.businessAlias : null,
			Ids: [id],
			id: userConfig ? userConfig.clientId : null,
		};
		props.getInvoiceDetails(config);
		setTimeout(function () {
			setOpen("100");
			props.getInvoicePdf(config);
		}, 500);
	};
	let config = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		Ids: membership ? [membership.contractPayments[0]?.invoiceId] : null,
		id: userConfig ? userConfig.clientId : null,
	};

	let date = new Date();
	let todaysDate = date.getTime();
	let outstandingPayments: any =
		membership.contractPayments.length > 0
			? membership.contractPayments
					.map((payment: any) => {
						let payments: any = 0;
						if (payment.invoiceId !== "0") {
							payments +=
								payment.paidAmount < payment.amount
									? payment.amount - payment.paidAmount
									: 0;
						}
						return payments;
					})
					.reduce((a: any, b: any) => a + b)
			: null;

	let contractPayoff: any =
		membership.contractPayments.length > 0
			? membership.contractPayments
					.map((payment: any) => {
						let payments: any = 0;
						if (payment.paidAmount < payment.amount) {
							payments += payment.amount - payment.paidAmount;
						}
						return payments;
					})
					?.reduce((a: any, b: any) => a + b)
			: null;
	let creditAmount: any;
	if (checked && Number(values.amount) < props.credits / 100) {
		creditAmount = Number(values.amount * 100);
	} else {
		creditAmount = props.credits;
	}
	const payConfig: any = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		clientId: userConfig ? userConfig.clientId : null,
		token: userConfig.token,
		saleContractId: membership.id,
		billingProfileId:
			oneTime || (checked && values.amount < credits / 100)
				? "0"
				: paymentMethod,
		credits: checked ? creditAmount : 0,
		amount: Number(values.amount),
		creditCard:
			oneTimePaymentType === "CreditCard" && values.creditcard
				? {
						cardNumber: values.creditcard,
						nameOnCard: values.nameOnCard,
						expiryMonth: values.expiryMonth,
						expiryYear: values.expiryYear,
						securityCode: values.securityCode,
				  }
				: null,
		eft:
			oneTimePaymentType === "Eft" && oneTime
				? {
						AccountNumber: values.accountNumber,
						TransitNumber: values.transitNumber,
						InstitutionNumber: values.institutionNumber,
						BankAccountType: values.accountTypeFull,
						NameOnAccount: values.firstName + " " + values.lastName,
				  }
				: null,
		ach:
			oneTimePaymentType === "Ach" && oneTime
				? {
						AccountNumber: values.accountNumber,
						RoutingNumber: values.routing,
						BankAccountType: values.accountTypeFull,
						NameOnAccount: values.firstName + " " + values.lastName,
						BankName: values.bankName,
				  }
				: null,
	};
	const handlePayMembership = (event: any) => {
		event.preventDefault();
		props.payMembership(payConfig);
	};

	let paymentMethods: any = renderPaymentMethods(
		billingProfiles,
		oneTimePaymentType,
		values,
		oneTime
	);

	let fontStyle = {
		fontFamily: "Roboto Condensed, sans-serif",
		padding: 5,
		backgroundColor: "#FAFAFA",
	};
	return (
		<div className={classes.root}>
			<Title title="Pay Membership" onClose={props.onClose} />
			<Grid container direction="row" justifyContent="flex-start">
				<Grid key={membership.id} container justifyContent="center">
					<Typography
						sx={{ pt: 1, fontStyle, fontSize: 20 }}
						align="center"
						variant="subtitle1"
					>
						<strong>
							{membership.contractName} - Contract #{membership.id}
						</strong>
					</Typography>
					{membership.contractStatus === "CANCELLED" ||
					membership.contractStatus === "FROZEN" ? (
						<Typography
							style={{ color: "red", marginTop: 10, width: "100%" }}
							align="center"
						>
							This membership has been{" "}
							{membership.contractStatus?.toLowerCase()}
						</Typography>
					) : null}
				</Grid>
				<TableContainer style={{ maxHeight: 220, margin: 0, padding: 0 }}>
					<Table stickyHeader sx={classes.table} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell
									sx={{
										fontStyle,
										paddingLeft: 0,
									}}
									align="left"
								>
									<strong>Invoice #</strong>
								</TableCell>
								<TableCell sx={{ fontStyle }} align="center">
									<strong>Payment Date</strong>
								</TableCell>
								<TableCell sx={{ fontStyle }} align="center">
									<strong>Invoice Amount</strong>
								</TableCell>
								<TableCell sx={{ fontStyle }} align="center">
									<strong>Amount Paid</strong>
								</TableCell>
								<TableCell
									sx={{
										fontStyle,
										paddingRight: 0,
									}}
									align="right"
								>
									<strong>Balance Due</strong>
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody sx={{ bgcolor: "#FAFAFA" }}>
							{membership.contractPayments.map((payment: any) =>
								payment.amount !== payment.paidAmount ? (
									<TableRow key={payment.id}>
										<TableCell
											style={{ padding: 10, paddingLeft: 0 }}
											align="left"
										>
											<a
												className={classes.link}
												onClick={() => openInvoice(payment.invoiceId)}
											>
												{payment.invoiceId == 0 ? null : payment.invoiceId}
											</a>
										</TableCell>
										<TableCell
											className={
												payment.paidAmount < payment.amount &&
												todaysDate >
													new Date(payment.paymentDate.slice(0, 10)).getTime() +
														86400000
													? classes.red
													: ""
											}
											style={{ padding: 10 }}
											align="center"
										>
											{moment(payment.paymentDate.slice(0, 10)).format(
												"MM/DD/YYYY"
											)}
										</TableCell>
										<TableCell
											className={
												payment.paidAmount < payment.amount &&
												todaysDate >
													new Date(payment.paymentDate.slice(0, 10)).getTime() +
														86400000
													? classes.red
													: ""
											}
											style={{ padding: 10 }}
											align="center"
										>
											{formatCurrency(payment.amount, clubData)}
										</TableCell>
										<TableCell
											className={
												payment.paidAmount < payment.amount &&
												todaysDate >
													new Date(payment.paymentDate.slice(0, 10)).getTime() +
														86400000
													? classes.red
													: ""
											}
											style={{ padding: 10 }}
											align="center"
										>
											{formatCurrency(payment.paidAmount, clubData)}
										</TableCell>
										<TableCell
											className={
												payment.paidAmount < payment.amount &&
												todaysDate >
													new Date(payment.paymentDate.slice(0, 10)).getTime() +
														86400000
													? classes.red
													: ""
											}
											style={{ padding: 10, paddingRight: 0 }}
											align="right"
										>
											{formatCurrency(
												payment.amount - payment.paidAmount,
												clubData
											)}
										</TableCell>
										<Dialog
											maxWidth="md"
											open={open === "100"}
											sx={styles.modal}
										>
											<Title title="Membership Invoice" onClose={handleClose} />

											<Invoice
												{...props}
												type={"membership"}
												config={config}
												membership={membership}
											/>
										</Dialog>
									</TableRow>
								) : null
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<div style={classes.padding}>
					<Grid
						container
						justifyContent="flex-start"
						sx={{ borderTop: "solid lightgrey 1px" }}
					>
						<Divider />
						<Grid container justifyContent="space-between">
							<Typography style={{ color: "red" }} variant="subtitle1">
								Outstanding Payments:
							</Typography>
							<Typography
								style={{ marginRight: 15, color: "red" }}
								variant="subtitle1"
							>
								{outstandingPayments
									? formatCurrency(outstandingPayments, clubData)
									: formatCurrency(0, clubData)}
							</Typography>
						</Grid>
						<Grid container justifyContent="space-between">
							<Typography variant="subtitle1">Contract Payoff:</Typography>
							<Typography style={{ marginRight: 15 }} variant="subtitle1">
								{contractPayoff
									? formatCurrency(contractPayoff, clubData)
									: formatCurrency(0, clubData)}
							</Typography>
						</Grid>
					</Grid>

					<Grid container justifyContent="space-between">
						<Grid item style={{ marginTop: 10 }}>
							<Typography>Pay Amount:</Typography>
						</Grid>
						<Grid item xs={5} sm={3}>
							<TextField
								placeholder="0"
								size="small"
								name="amount"
								onChange={handleChange}
								inputProps={{ style: { textAlign: "right", marginRight: 0 } }}
								variant="outlined"
								type="number"
								value={values.amount}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											{clubData.currencyPrefix}
										</InputAdornment>
									),
									endAdornment: clubData.currencyPostfix && (
										<InputAdornment position="end">
											{clubData.currencyPostfix}
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>
				</div>
				{checked && values.amount > 0 && (
					<div className={classes.padding}>
						<Grid container justifyContent="space-between">
							<Typography variant="subtitle1">Credits Applied:</Typography>
							<Typography style={{ marginRight: 9 }} variant="subtitle1">
								({clubData.currencyPrefix}
								{Number(props.credits) / 100 - values.amount > 0
									? values.amount
									: Number(props.credits) / 100}
								{clubData.currencyPostfix ? " " + clubData.currencyPostfix : ""}
								)
							</Typography>
						</Grid>
						{Number(props.credits) / 100 - values.amount < 0 && (
							<Grid container justifyContent="space-between">
								<Typography variant="subtitle1">Amount Remaining:</Typography>
								<Typography style={{ marginRight: 15 }} variant="subtitle1">
									{clubData.currencyPrefix}

									{values.amount - Number(props.credits) / 100}
									{clubData.currencyPostfix
										? " " + clubData.currencyPostfix
										: ""}
								</Typography>
							</Grid>
						)}
						{contractPayoff < values.amount ? (
							<Typography
								variant="subtitle2"
								align="center"
								style={{ color: "red", marginTop: 10 }}
							>
								Payment amount cannot exceed contract payoff
							</Typography>
						) : null}
					</div>
				)}

				{props.credits ? (
					<Grid
						container
						style={{ marginLeft: 10 }}
						alignItems="center"
						justifyContent="flex-start"
					>
						<Grid item>
							<Checkbox checked={checked} onChange={handleSetChecked} />
						</Grid>
						<Grid item>
							<Typography>
								Use Available Credit (
								{formatCurrency(props.credits / 100, clubData)})
							</Typography>
						</Grid>
					</Grid>
				) : null}
				<Grid
					container
					style={{ padding: 20, paddingTop: 0 }}
					direction="column"
					justifyContent="flex-end"
				>
					<FormControl margin="normal" variant="outlined">
						<InputLabel shrink={true} htmlFor="outlined-age-native-simple">
							Payment Method
						</InputLabel>
						<Select
							label="Payment Method"
							native
							value={paymentMethod}
							onChange={handlePaymentChange}
							disabled={checked && props.credits / 100 >= values.amount}
							inputProps={{
								name: "paymentMethod",
								id: "outlined-age-native-simple",
								padding: 5,
							}}
						>
							{paymentMethods}
						</Select>
					</FormControl>

					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Button
								fullWidth
								onClick={openAddPayment}
								variant="outlined"
								color="primary"
							>
								Add Payment Method
							</Button>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Button
								fullWidth
								onClick={openOneTimePayment}
								variant="outlined"
								color="primary"
								disableRipple
								disabled={client === "FSB ONLY"}
							>
								One Time Payment
							</Button>
						</Grid>
					</Grid>

					{oneTimePaymentModal}
				</Grid>

				<Dialog
					PaperProps={{
						style: {
							backgroundColor: "transparent",
							boxShadow: "none",
						},
					}}
					sx={styles.modal}
					open={open === "1"}
					onClose={handleClose}
				>
					<PaymentMethods handleClose={handleClose} {...props} open={true} />
				</Dialog>
				<Grid container justifyContent="center" spacing={2} sx={{ p: 2 }}>
					<Grid item xs={12} sm={6}>
						<Button
							fullWidth
							variant="outlined"
							color="primary"
							onClick={props.onClose}
						>
							Back
						</Button>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Button
							disabled={
								contractPayoff < values.amount ||
								props.paymentRequesting ||
								!values.amount ||
								values.amount === "0"
							}
							fullWidth
							variant="contained"
							color="primary"
							onClick={handlePayMembership}
						>
							{props.paymentRequesting ? <ButtonLoader /> : "Pay Membership"}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}

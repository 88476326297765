import React from "react";
import {
	Grid,
	AppBar,
	Toolbar,
	Typography,
	Container,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	Grow,
} from "@mui/material";
import Alerts from "./Alerts/Alerts";
import UpcomingClasses from "./UpcomingClasses/UpcomingClasses";
import Renewals from "./UpcomingPayments/UpcomingPayments";
import Account from "./Remaining/Remaining";
import { SelectChangeEvent } from "@mui/material/Select";
import { MetroSpinner } from "react-spinners-kit";
import { blue } from "../../theme";
import moment from "moment";
import Notifications from "../../components/AlertMessage/Notifications";
import styles from "../../styles";
const classes: any = {
	paper: {
		pt: 2,
		mt: { xs: 5, md: 6 },
		width: "100%",
	},
	appBar: {
		justifyContent: "center",
		top: 55,
		bottom: "auto",
		height: "55px",
		zIndex: 1,
	},
	toolbar: {
		justifyContent: "center",
	},
	position: {
		pl: { md: 10 },
		mb: { xs: 6, sm: 0 },
		ml: "auto",
		mr: "auto",
		display: "flex",
	},
	dropDown: {
		color: "white",
		".MuiOutlinedInput-notchedOutline": {
			borderColor: "rgba(228, 219, 233, 0.25)",
		},
		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "rgba(228, 219, 233, 0.25)",
			color: "white",
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "rgba(228, 219, 233, 0.25)",
		},
		".MuiSvgIcon-root ": {
			fill: "white !important",
		},
		".MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
			color: "white !important",
		},
	},
	inputLabel: {
		color: "secondary",
		"&.Mui-focused": { color: "white" },
	},
};

export default function Dashboard(props: any) {
	const { userConfig } = props;
	const [user, setUser] = React.useState(userConfig?.clientId);
	const [endDate] = React.useState(moment().add(7, "days"));
	let config = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		id: userConfig ? userConfig.clientId : null,
		startDate: new Date().toISOString().slice(0, 10),
		endDate: endDate.toISOString().slice(0, 10),
	};
	const config2 = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		id: userConfig ? userConfig.clientId : null,
	};
	React.useEffect(() => {
		props.getRelatedUsers(config2);
		config.id = user;
		props.getDashboardData(config);
		props.getDashboardAlerts(config);
	}, [user]);

	const handleUserChange = (event: SelectChangeEvent) => {
		setUser(event.target.value as string);
	};

	return (
		<Grid direction="row" sx={classes.paper} container justifyContent="center">
			<AppBar sx={classes.appBar} position="fixed">
				<Toolbar sx={classes.toolbar}>
					<Grid container justifyContent="space-between" flexDirection="row">
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<Typography
								component="h3"
								variant="h5"
								align="center"
								sx={{ pl: { md: 7.5 }, color: "white", pt: "2px" }}
							>
								Dashboard
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Grid container justifyContent="flex-end">
								{props.purchaseForUsers && (
									<FormControl>
										<InputLabel sx={classes.inputLabel}>View As:</InputLabel>
										<Select
											size="small"
											sx={classes.dropDown}
											// disabled={props.requesting}
											label={"View As"}
											value={user}
											onChange={handleUserChange}
										>
											{props.purchaseForUsers?.map((user: any) => (
												<MenuItem key={user.id} value={user.id}>
													{user.fullName}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			{props.loadError && (
				<Typography sx={[styles.modal, { mt: 10 }]} align="center">
					There was an error loading dashboard
				</Typography>
			)}
			{!props.errors ? (
				<Container maxWidth="lg" sx={classes.position}>
					{props.requestingAlerts ? (
						<Grid container justifyContent="center" sx={{ mt: 20 }}>
							<MetroSpinner size={50} color={blue} />
						</Grid>
					) : (
						<Grid
							container
							justifyContent="center"
							spacing={2.5}
							sx={{ mt: { xs: 3.5, sm: 2.5 } }}
						>
							<Grow in={true} timeout={{ enter: 1000 }}>
								<Grid item xs={12} lg={10} md={12}>
									<Alerts {...props} />
								</Grid>
							</Grow>
							<Grow in={true} timeout={{ enter: 1500 }}>
								<Grid item lg={10} xs={12}>
									<UpcomingClasses {...props} />
								</Grid>
							</Grow>
							<Grow in={true} timeout={{ enter: 2000 }}>
								<Grid item xs={12} sm={12} md={6} lg={5}>
									<Account {...props} />
								</Grid>
							</Grow>
							<Grow in={true} timeout={{ enter: 2000 }}>
								<Grid item xs={12} sm={12} md={6} lg={5}>
									<Renewals {...props} />
								</Grid>
							</Grow>
						</Grid>
					)}
				</Container>
			) : (
				<Notifications
					messages={props.messages}
					errors={props.errors}
					hide={true}
				/>
			)}
		</Grid>
	);
}

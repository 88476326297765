import { Box, FormHelperText, Grid } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../config";

interface IProps {
	error: {
		token: string;
	};
	handleCaptcha: any;
}
const Captcha = (props: IProps) => {
	const { error, handleCaptcha } = props;
	const siteKey = RECAPTCHA_SITE_KEY;
	const captchaStyle = {
		border: "1px solid #D32F2F ",
		borderRadius: "4px",
	};
	let captcha: any;
	const handleExpired = () => {
		captcha.reset();
		handleCaptcha("");
	};

	return (
		<Grid
			container
			direction="column"
			alignItems={"center"}
			style={{ marginBottom: 10 }}
		>
			<Grid item>
				<Box sx={error.token ? captchaStyle : {}}>
					<ReCAPTCHA
						ref={(e: any) => (captcha = e)}
						sitekey={siteKey as string}
						onChange={handleCaptcha}
						onExpired={handleExpired}
						onErrored={handleExpired}
					/>
				</Box>
			</Grid>
			<Grid item>
				{error.token ? <FormHelperText children={error.token} error /> : null}
			</Grid>
		</Grid>
	);
};

export default Captcha;

import {
	Card,
	Grow,
	CardHeader,
	Divider,
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableContainer,
	TableCell,
	Typography,
} from "@mui/material";
import moment from "moment";
import ButtonLoader from "../../../utils/ButtonLoader";
import { classes } from "../styles";

export default function UpcomingClasses(props: any) {
	const { data } = props;
	let sortedClasses: any = data?.scheduleEvents?.sort(function (
		a: any,
		b: any
	) {
		return a.endDate.localeCompare(b.endDate);
	});
	return (
		<Grow in={true} timeout={0}>
			<Card raised={true}>
				<CardHeader sx={classes.cardHeader} title="Upcoming Classes" />
				<Divider light />
				<TableContainer sx={{ maxHeight: 198 }}>
					<Table
						sx={classes.table}
						style={{}}
						stickyHeader
						aria-label="customized table"
					>
						<TableHead>
							<TableCell
								padding="none"
								sx={[
									{
										pl: 2,
										py: 1,
									},
									classes.tableRow,
								]}
								align="left"
							>
								Class Name
							</TableCell>
							<TableCell padding="none" sx={classes.tableRow} align="right">
								Location
							</TableCell>
							<TableCell
								padding="none"
								sx={[
									classes.tableRow,
									{
										pr: { xs: 2 },
										py: 1,
									},
								]}
								align="right"
							>
								Date
							</TableCell>
						</TableHead>
						<TableBody>
							{data?.scheduleEvents.length > 0 &&
								props.successful &&
								sortedClasses.map((row: any, index: any) => (
									<TableRow
										key={index}
										sx={[
											{ "&:last-child td, &:last-child th": { border: 0 } },
											classes.table,
										]}
									>
										<TableCell
											sx={{
												py: 2,
												pl: 2,
												maxWidth: { xs: 70 },
											}}
											padding="none"
										>
											{row.eventName}
										</TableCell>
										<TableCell
											padding="none"
											align="right"
											sx={{ maxWidth: { xs: 50 } }}
										>
											{row.locationName || "N/A"}
										</TableCell>
										<TableCell
											padding="none"
											align="right"
											sx={{
												pr: { xs: 2 },
												maxWidth: { xs: 35 },
											}}
										>
											{moment(row.startDate).format("MMM D")} -{" "}
											{moment(row.startTime, "hh:mm A").format("h:mm A")}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>

					{data?.scheduleEvents.length == 0 && props.successful && (
						<Typography
							align="center"
							variant="subtitle2"
							sx={{
								p: 2,
								backgroundColor: "secondary.light",
							}}
						>
							There are no classes booked this week
						</Typography>
					)}
					{props.requesting && (
						<div
							style={{
								backgroundColor: "#E5F6FD",
								overflowY: "hidden",
								height: 50,
								paddingTop: 10,
								paddingBottom: 10,
							}}
						>
							<ButtonLoader />
						</div>
					)}
				</TableContainer>
			</Card>
		</Grow>
	);
}

import { createTheme } from "@mui/material/styles";

export const navy = "#0B2240";
export const blue = "#1976D2";
export const grey = "#818285";

const theme = createTheme({
	palette: {
		primary: {
			main: navy,
		},
		secondary: {
			main: blue,
		},
		background: {
			paper: "#FAFAFA",
		},
		text: {
			disabled: grey,
		},
	},
	components: {
		MuiTypography: {
			styleOverrides: {
				h5: {
					fontFamily: "Oswald, sans-serif",
					letterSpacing: 1,
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: blue,
				},
			},
		},

		MuiListItem: {
			styleOverrides: {
				root: {
					"&.Mui-selected": {
						backgroundColor: navy,
						color: blue,
					},
					"&.Mui-selected:hover": {
						backgroundColor: navy,
						color: blue,
					},
					"&:hover": {
						color: blue,
					},
				},
			},
		},
		MuiCheckbox: {
			defaultProps: {
				color: "secondary",
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: "inherit",
					"&.Mui-selected": {
						backgroundColor: navy,
						color: blue,
					},
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					zIndex: 100,
				},
			},
		},
		MuiBackdrop: {
			styleOverrides: {
				root: {
					backgroundColor: "rgba(0,0,0,0.1)",
					boxShadow: "none",
				},
			},
		},
	},
});

export default theme;

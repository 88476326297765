import * as constants from "../constants/schedules.constants";

const initialState = {
	requesting: true,
	successful: false,
	filtersRequesting: false,
	filtersSuccessful: false,
	messages: null,
	errors: null,
	schedule: null,
	mySchedule: null,
	clubDetails: null,
	scheduleNames: null,
	cancelSuccess: null,
	cancelReasons: null,
	booked: false,
	bookRequesting: false,
	bookSuccessful: false,
	recurringSchedule: null,
	relatedClasses: null,
	scheduleFilters: null,
	payBook: false,
	payBookingRequesting: false,
	payBookingSuccess: false,
	getRelatedRequesting: false,
};

function scheduleReducer(state = initialState, action: any) {
	switch (action.type) {
		case constants.GET_RELATED_CLASSES_REQUEST:
			return {
				...state,
				getRelatedRequesting: true,
			};
		case constants.GET_SCHEDULE_NAMES_REQUEST:
			return {
				...state,
				requesting: true,
			};
		case constants.GET_USER_SCHEDULE_REQUEST:
			return {
				...state,
				requesting: true,
				successful: false,
				schedule: null,
				scheduleNames: null,
				scheduleFilters: null,
			};
		case constants.PAY_BOOKING_REQUEST:
			return {
				...state,
				payBookingRequesting: true,
				payBookingSuccess: false,
			};
		case constants.GET_SCHEDULE_FILTERS_REQUEST:
			return {
				...state,
				errors: null,
				filtersRequesting: true,
				filtersSuccessful: false,
			};
		case constants.BOOK_CLASS_REQUEST:
			return {
				...state,
				bookRequesting: true,
				bookSuccessful: false,
			};
		case constants.PAY_BOOKING_SUCCESS:
			return {
				...state,
				messages: "Purchase submitted, class may now be booked",
				payBookingRequesting: false,
				payBookingSuccess: true,
			};

		case constants.PAY_BOOKING_ERROR:
			return {
				...state,
				payBookingRequesting: false,
				payBookingSuccess: false,
				errors:
					action.error.response.data.error ||
					"There was an error with the purchase",
			};
		case constants.GET_RELATED_CLASSES_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				relatedClasses: action.response.data.products,
				getRelatedRequesting: false,
			};
		case constants.GET_USER_SCHEDULE_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				mySchedule: action.response.data.scheduleAppointments,
			};
		case constants.GET_SCHEDULE_FILTERS_SUCCESS:
			return {
				...state,
				errors: null,
				filtersRequesting: false,
				filtersSuccessful: true,
				scheduleFilters: action.response.data,
			};
		case constants.GET_RECURRING_SCHEDULE_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				recurringSchedule: action.response.data.schedules,
			};
		case constants.CLOSE_PAY_BOOK:
			return {
				...state,
				payBook: false,
				relatedClasses: null,
				payBookingSuccess: false,
			};
		case constants.GET_CLUB_DETAILS_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				clubDetails: action.response.data,
			};
		case constants.GET_SCHEDULE_NAMES_SUCCESS:
			return {
				...state,
				requesting: false,
				errors: null,
				scheduleNames: action.response.data.schedules,
			};
		case constants.GET_CANCEL_REASONS_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				cancelReasons: action.response.data.cancellationReasons,
			};
		case constants.CANCEL_CLASS_SUCCESS:
			console.log("action", action.waitlist);
			let messages: any = "Class was successfully cancelled";
			if (action.waitlist === "yes") {
				messages = "Successfully removed from waitlist";
			}
			return {
				...state,
				requesting: false,
				successful: false,
				errors: null,
				cancelSuccess: action.response.data.success,
				messages: messages,
			};
		case constants.BOOK_CLASS_SUCCESS:
			let message = "Class was successfully booked";
			if (action.response.data.classFull) {
				message = "Successfully added to waitlist";
			}
			return {
				...state,
				requesting: false,
				successful: true,
				bookRequesting: false,
				bookSuccessful: true,
				errors: null,
				messages: message,
				bookedSuccess: true,
			};
		case constants.CLEAR_CANCEL_CLASS_SUCCESS:
			return {
				...state,
				cancelSuccess: false,
				bookedSuccess: false,
			};
		case constants.GET_GYM_SCHEDULE_REQUEST:
			return {
				...state,
				requesting: true,
				successful: false,
				cancelSuccess: false,
				bookedSuccess: false,
				bookSuccessful: false,
				bookRequesting: false,
				errors: null,
				schedule: null,
				payBook: false,
				relatedClasses: null,
			};
		case constants.GET_GYM_SCHEDULE_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				schedule: action.response?.data?.schedules,
			};
		case constants.GET_RELATED_CLASSES_ERROR:
			return {
				...state,
				getRelatedRequesting: false,
			};
		case constants.GET_GYM_SCHEDULE_ERROR:
			return {
				...state,
				requesting: false,
				successful: false,
				errors:
					action.error.response?.data?.exceptionMessage || action.error.message,
			};
		case constants.GET_SCHEDULE_FILTERS_ERROR:
			return {
				...state,
				filtersRequesting: false,
				filtersSuccessful: true,
				errors: action.error.response?.data?.exceptionMessage,
			};
		case constants.CANCEL_CLASS_ERROR:
		case constants.BOOK_CLASS_ERROR:
			let payBook = false;
			if (action.error.response.status === 417) {
				if (
					action.error?.response?.data?.error[0]?.errorCode ===
					"PurchaseRequired"
				) {
					payBook = true;
				}
			}
			let error: any =
				action.error?.response?.data?.message ||
				action.response?.data?.message ||
				action.error?.response?.data?.error[0]?.message;

			return {
				...state,
				requesting: false,
				successful: true,
				bookRequesting: false,
				bookSuccessful: false,
				errors: error,
				payBook: payBook,
				relatedClasses: null,
			};

		case constants.GET_RECURRING_SCHEDULE_ERROR:
		case constants.GET_SCHEDULE_NAMES_ERROR:
		case constants.GET_CANCEL_REASONS_ERROR:
		case constants.GET_CLUB_DETAILS_ERROR:
			return {
				...state,
				requesting: false,
				successful: false,
				errors:
					action.response?.data?.errors ||
					action.error?.response?.data?.message,
			};
		case constants.GET_USER_SCHEDULE_ERROR:
			return {
				...state,
				requesting: false,
				successful: false,
				errors:
					action.response?.data?.errors ||
					action.error?.response?.data?.message ||
					action.error.message,
			};
		case constants.CLEAR_SCHEDULE_ERROR:
			return {
				...state,
				errors: null,
				messages: null,
			};
		default:
			return state;
	}
}

export default scheduleReducer;

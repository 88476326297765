/**
 * Automatically adds the dashes required by the specified phone format and limits the input to ten characters
 */

export const normalizeCreditCard = (value: string) => {
	if (!value) {
		return value;
	}

	let onlyNums = value.replace(/[^\d]/g, "");
	onlyNums = parseCreditCard(onlyNums);

	if (onlyNums.length <= 4) {
		return onlyNums;
	}

	if (onlyNums.length <= 8) {
		return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4)}`;
	}

	if (onlyNums.length <= 12) {
		return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
			8
		)}`;
	}

	if (onlyNums.length <= 16) {
		return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
			8,
			12
		)} ${onlyNums.slice(12)}`;
	}

	// tslint:disable-next-line:max-line-length
	return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
		8,
		12
	)} ${onlyNums.slice(12, 16)} ${onlyNums.slice(16, 19)}`;
};

/**
 * Remove dashes added by the formatter. We want to store phones as plain values
 */
export const parseCreditCard = (value: string) =>
	value ? value.replace(/ /g, "") : "";

export const needAddPaymentMethod = (values: any) => {
	let newPaymentMethod;

	// Is it new PaymentMethod? Save it.
	switch (values.newPaymentMethod) {
		case "PaymentCard":
			const accountNumber = parseCreditCard(values.accountNumber); // remove mask
			const expiration = new Date(
				values.expirationYear,
				Number(values.expirationMonth) - 1
			); // 01 month it is Febrary

			newPaymentMethod = "";

			break;

		case "PaymentBankAccountACH":
			newPaymentMethod = "";
			break;

		case "PaymentBankAccountEFT":
			newPaymentMethod = "";

			break;

		default:
	}

	return newPaymentMethod;
};

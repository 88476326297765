import React from "react";
import placeholder from "../media/placeholder3.jpg";
const Image = (props: any) => {
	const { src, alt } = props;
	const [isLoaded, setIsLoaded] = React.useState(false);
	return (
		<React.Fragment>
			{!isLoaded && (
				<img
					src={placeholder}
					alt={"placeholder"}
					style={{
						height: "100%",
						maxWidth: "100%",
						padding: 0,
						display: isLoaded ? "flex" : "hidden",
						marginLeft: "auto",
						marginRight: "auto",
						backgroundSize: "cover",
						justifyContent: "center",
					}}
				/>
			)}
			<img
				src={src}
				alt={alt}
				onLoad={() => {
					setIsLoaded(true);
				}}
				style={{
					height: "100%",
					maxWidth: "100%",
					padding: 0,
					display: isLoaded ? "flex" : "hidden",
					marginLeft: "auto",
					marginRight: "auto",
					backgroundSize: "cover",
					justifyContent: "center",
					opacity: isLoaded ? 1 : 0,
					transition: "opacity 0ms ease 0ms",
				}}
			/>
		</React.Fragment>
	);
};
export default Image;

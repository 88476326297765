import React from "react";
import {
	TextField,
	FormControlLabel,
	Typography,
	Grid,
	Button,
	Dialog,
	Switch,
	InputAdornment,
	Paper,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@mui/material";
import { TextInput, AccountType } from "../../Forms/Inputs";
import {
	bankAccountValidation,
	bankAccountNumberValidation,
} from "./validation";
import { BillingAddressForm } from "./AddressForm";
import styles from "../../../styles";
import Title from "../Dialogs/Title";

export default function EftBankForm(props: any) {
	const { profileInfo, userConfig, userInfo, messages } = props;
	const [edit, setEdit] = React.useState<any>(false);
	const [state, setState] = React.useState({
		default: profileInfo?.isDefaultProfile ? true : false,
	});
	const [errors, setErrors] = React.useState<any>({});
	const [accountError, setAccountError] = React.useState<any>({});
	const [values, setValues] = React.useState({
		accountNumber: "",
		transitNumber: "",
		institutionNumber: "",
		accountTypeFull: "",
		address: "",
		city: "",
		zip: "",
		state: "",
		country: "",
		firstName: userInfo?.firstName,
		lastName: userInfo?.lastName,
		mask: "",
		newAccount: "",
	});

	React.useEffect(() => {
		if (props.type === "edit") {
			setValues({
				...profileInfo,
				transitNumber: profileInfo.transit,
			});
		}
	}, []);
	React.useEffect(() => {
		if (
			messages === "Payment method successfully updated" ||
			messages === "Payment method deleted"
		) {
			props.handleClose();
		}
	}, [messages]);
	React.useEffect(() => {
		if (props.type === "edit" && profileInfo.isDefaultProfile) {
			setState({ default: profileInfo?.isDefaultProfile });
		} else {
			setState({ default: false });
		}
	}, [profileInfo]);
	React.useEffect(() => {
		if (props.type === "edit" && values.accountNumber) {
			let maskNumber: any = props.maskNumber(values.accountNumber);
			setValues({ ...values, mask: maskNumber, newAccount: "" });
		}
	}, [values.accountNumber]);
	React.useEffect(() => {
		if (edit) {
			const timeout = setTimeout(() => {
				inputRef.current.focus();
			}, 100);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [edit]);
	const handleChange = (event: any) => {
		let prop: any = [event.target.name];
		if (errors[prop]) {
			setErrors({ ...errors, [event.target.name]: "" });
		}
		setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};
	const handleSubmit = () => {
		let config: any = {
			headers: { Authorization: `Bearer ${userConfig.token}` },
			BusinessAlias: userConfig.businessAlias,
			PartnerApiKey: userConfig.partnerApiKey,
			ClientId: userConfig.clientId,
			FirstName: values.firstName,
			LastName: values.lastName,
			Address: values.address,
			City: values.city,
			State: values.state,
			Country: values.country,
			Zip: values.zip,
			PaymentMethodType: "Eft",
			Eft: {
				AccountNumber: values.accountNumber,
				BankAccountType: values.accountTypeFull,
				TransitNumber: values.transitNumber,
				InstitutionNumber: values.institutionNumber,
				NameOnAccount: values.firstName + " " + values.lastName,
			},
		};
		let billingAddress: any = {
			firstName: values.firstName,
			lastName: values.lastName,
			address: values.address,
			city: values.city,
			zip: values.zip,
			state: values.state,
			country: values.country,
		};
		let valid: any;
		let type = "Eft";
		valid = bankAccountValidation(config.Eft, setErrors, billingAddress, type);
		if (valid) {
			let defaultConfig = {
				headers: { Authorization: `Bearer ${userConfig.token}` },
				BusinessAlias: userConfig.businessAlias,
				PartnerApiKey: userConfig.partnerApiKey,
				id: profileInfo?.id,
			};
			if (props.type === "edit") {
				config.id = props.profileInfo.id;
				if (state.default) {
					props.defaultBillingProfile(defaultConfig);
				}
				props.updateBillingProfile(config);
			} else {
				if (state.default) {
					props.setDefault(true);
				}
				props.createBillingProfile(config);
			}
		}
	};
	const handlePaymentChange = (event: any) => {
		let prop: any = [event.target.name];
		if (errors[prop]) {
			setErrors({ ...errors, [event.target.name]: "" });
		}
		setValues({
			...values,
			accountTypeFull: event.target.value as string,
		});
	};
	const handleDefaultChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};

	const handleNewNumber = () => {
		let newAccount = values.newAccount?.trim();
		let valid: any;
		valid = bankAccountNumberValidation(newAccount, setAccountError);
		if (valid) {
			setEdit(false);
			setValues({
				...values,
				accountNumber: newAccount,
				newAccount: "",
			});
		}
	};
	const handleEdit = () => {
		edit ? handleNewNumber() : setEdit(true);
	};

	const handleDeletePayment = (id: any) => {
		let config = {
			headers: { Authorization: `Bearer ${userConfig.token}` },
			partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
			businessAlias: userConfig ? userConfig.businessAlias : null,
			id: id,
		};
		props.deleteBillingProfile(config);
	};
	const inputRef: any = React.useRef();
	const gridItem = { xs: 12, md: 6 };

	return (
		<>
			<Title
				title={`${props.type === "edit" ? "" : "Add"} Bank Account
							${profileInfo?.isDefaultProfile ? " (Default)" : ""}`}
				onClose={props.handleClose}
			/>
			<DialogContent>
				<form>
					<DialogContentText>
						<Grid container justifyContent="center">
							<Grid container justifyContent="center">
								<FormControlLabel
									sx={{ mb: 1 }}
									control={
										<Switch
											checked={state.default}
											onChange={handleDefaultChange}
											color="primary"
											name="default"
											disabled={profileInfo?.isDefaultProfile ? true : false}
										/>
									}
									label="Make Default Payment"
								/>
							</Grid>

							{props.type === "edit" ? (
								<Grid item xs={4}>
									<Button
										fullWidth
										variant="outlined"
										onClick={() => props.delete()}
										disabled={profileInfo?.isAssociatedWithMembership}
										sx={{ mb: 2 }}
									>
										Delete
									</Button>
								</Grid>
							) : null}
							{profileInfo?.isAssociatedWithMembership && (
								<Grid container justifyContent="center">
									<Typography
										sx={{ mb: 2 }}
										align="center"
										color="black"
										variant="caption"
									>
										Profile is associated with renewing payments and cannot be
										deleted
									</Typography>
								</Grid>
							)}
						</Grid>
						<Grid container justifyContent="center" spacing={3}>
							<Grid item {...gridItem}>
								{props.type === "edit" ? (
									edit === true ? (
										<TextField
											fullWidth
											inputRef={inputRef}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											label={"Bank Account Number"}
											name="newAccount"
											onChange={handleChange}
											onBlur={handleEdit}
											color="secondary"
											inputProps={{ maxLength: 17 }}
											value={values?.newAccount}
											error={Boolean(
												errors.accountNumber || accountError.accountNumber
											)}
											helperText={
												errors.accountNumber || accountError.accountNumber
											}
										/>
									) : (
										<TextField
											fullWidth
											InputProps={{
												endAdornment: (
													<InputAdornment style={{ padding: 0 }} position="end">
														<Button style={{ padding: 5 }} onClick={handleEdit}>
															Edit
														</Button>
													</InputAdornment>
												),
											}}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											label={"Bank Account Number"}
											name="accountNumber"
											color="secondary"
											inputProps={{ maxLength: 17 }}
											value={values?.mask}
											error={errors.accountNumber ? true : false}
											helperText={errors.accountNumber}
										/>
									)
								) : (
									<TextInput
										fullWidth
										label={"Bank Account Number"}
										name="accountNumber"
										onChange={handleChange}
										inputProps={{ maxLength: 17 }}
										value={values.accountNumber}
										error={errors.accountNumber ? true : false}
										helperText={errors.accountNumber}
									/>
								)}
							</Grid>
							<Grid item {...gridItem}>
								<AccountType
									label="Account Type"
									name="accountTypeFull"
									fullWidth
									helperText={errors.accountTypeFull}
									value={values.accountTypeFull}
									onChange={handlePaymentChange}
									error={errors.accountTypeFull ? true : false}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextInput
									fullWidth
									label={"Institution Number"}
									name="institutionNumber"
									inputProps={{ maxLength: 3 }}
									onChange={handleChange}
									value={values.institutionNumber}
									error={errors.institutionNumber ? true : false}
									helperText={errors.institutionNumber}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextInput
									fullWidth
									label={"Transit Number"}
									name="transitNumber"
									onChange={handleChange}
									inputProps={{ maxLength: 5 }}
									value={values.transitNumber}
									error={errors.transitNumber ? true : false}
									helperText={errors.transitNumber}
								/>
							</Grid>
						</Grid>
						<BillingAddressForm
							{...props}
							values={values}
							errors={errors}
							userInfo={userInfo}
							setValues={setValues}
							setErrors={setErrors}
							handleChange={handleChange}
						/>
						<Grid
							container
							justifyContent="center"
							spacing={2}
							sx={{ padding: 1 }}
						>
							<Dialog open={props.open === "6"} sx={styles.modal}>
								<Paper sx={styles.paper}>
									<Grid container alignItems="center" justifyContent="center">
										<Grid item>
											<Typography paragraph align="center">
												Are you sure you want to delete this payment method?
											</Typography>
										</Grid>
										<Grid container spacing={2} justifyContent="center">
											<Grid item xs={4}>
												<Button
													fullWidth
													variant="outlined"
													onClick={() =>
														handleDeletePayment(props.profileInfo.id)
													}
												>
													Delete
												</Button>
											</Grid>
											<Grid item xs={4}>
												<Button
													fullWidth
													variant="outlined"
													onClick={() => {
														props.deleteCancel();
													}}
												>
													Cancel
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Paper>
							</Dialog>
						</Grid>
					</DialogContentText>
				</form>
			</DialogContent>
			<DialogActions sx={{ p: 2 }}>
				<Button
					fullWidth
					onClick={() => props.handleClose()}
					variant="outlined"
					color="primary"
					sx={{ mr: 1 }}
				>
					Cancel
				</Button>

				<Button
					fullWidth
					onClick={() => handleSubmit()}
					variant="contained"
					color="primary"
					sx={{ ml: 1 }}
					disabled={edit}
				>
					Save
				</Button>
			</DialogActions>
		</>
	);
}

import React from "react";
import {
	Grid,
	Typography,
	Button,
	Card,
	CardHeader,
	TextField,
} from "@mui/material";

const classes: any = {
	header: {
		width: "100%",
		margin: 0,
		padding: "20px",
		backgroundColor: "primary.main",
		borderTopLeftRadius: "3px",
		borderTopRightRadius: "3px",
		color: "white",
		justifyContent: "space-between",
	},
};

const CancelClass = (props: any) => {
	const [reason, setReason] = React.useState("select");
	const [notes, setNotes] = React.useState<string>("");
	const [error, setError] = React.useState("");
	React.useEffect(() => {
		props.getCancelReasons({ businessAlias: props.userConfig.businessAlias });
	}, []);
	const handleCancelClass = () => {
		const config: any = {
			businessAlias: props.userConfig.businessAlias,
			clientId: Number(props.user),
			tabId: props.otherInfo?.tabId,
			scheduleId: props.otherInfo?.scheduleId,
			notes: notes,
			reason: reason,
		};
		if (reason !== "select") {
			props.cancelClass(config);
		} else {
			setError("You must first select a reason");
		}
	};
	const handleChange = (event: any) => {
		setNotes(event.target.value);
	};
	return (
		<>
			{props.cancelReasons && (
				<Card>
					<CardHeader title="Confirm Cancellation" sx={classes.header} />
					<Grid container alignItems="center" justifyContent="center">
						<Grid
							container
							justifyContent="center"
							style={{ padding: 20, marginTop: 20 }}
						>
							<Grid item xs={8}>
								<TextField
									helperText={error}
									error={error ? true : false}
									label="Select Reason"
									fullWidth
									name="state"
									onChange={(event: any) => {
										setReason(event.target.value);
										if (event.target.value !== "select") {
											setError("");
										}
									}}
									select
									InputLabelProps={{ shrink: true }}
									SelectProps={{ native: true }}
									value={reason}
									variant="outlined"
									color="secondary"
								>
									<option key={1} value={"select"}>
										Select One
									</option>
									{props.cancelReasons?.map((reason: any) => (
										<option key={reason.reasonText} value={reason.reasonText}>
											{reason.reasonText}
										</option>
									))}
								</TextField>
							</Grid>
							<Grid item xs={8} style={{ paddingTop: 20 }}>
								<TextField
									fullWidth
									label="Addition Information"
									defaultValue={notes}
									variant="outlined"
									InputLabelProps={{ shrink: true }}
									color="secondary"
									onBlur={handleChange}
								/>
							</Grid>
							<Grid item>
								<Typography paragraph style={{ paddingTop: 40 }}>
									Do you wish to cancel this session from your schedule?
								</Typography>
							</Grid>
							<Grid container justifyContent="center" spacing={2}>
								<Grid item xs={6}>
									<Button
										fullWidth
										variant="outlined"
										onClick={handleCancelClass}
									>
										Yes
									</Button>
								</Grid>
								<Grid item xs={6}>
									<Button
										fullWidth
										variant="outlined"
										onClick={() => {
											props.setOpen(0);
										}}
									>
										No
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Card>
			)}
		</>
	);
};

export default CancelClass;

import React from "react";
import {
	Typography,
	Checkbox,
	FormControlLabel,
	CardHeader,
	Divider,
	Grid,
	Dialog,
	Hidden,
	Button,
} from "@mui/material";
import { CloseButton } from "../../../utils/Buttons";
import Pagination from "@mui/material/Pagination";
import PayInvoice from "./PayInvoice";
import Invoice from "./InvoiceView";
import { formatCurrency } from "../../../utils/FormatCurrency";
import styles from "../../../styles";
import moment from "moment";

const classes: any = {
	header: {
		backgroundColor: "text.disabled",
		color: "white",
		margin: 0,
	},
	selectedColor: {
		color: "secondary.main",
	},
	unselectedColor: {
		color: "black",
	},
	invoiceHeader: {
		backgroundColor: "primary.main",
		color: "white",
		padding: "20px",
	},
};
export default function MobileInvoiceTable(props: any) {
	let selectedInvoices: any = props.getSelectedInvoices();
	const {
		invoiceDetails,
		userConfig,
		rows,
		page,
		selected,
		rowsPerPage,
		clubData,
	} = props;
	const handleOpenInvoice = (id: any) => {
		props.handleOpenInvoice(id);
	};
	let config: any = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		id: userConfig ? userConfig.clientId : null,
		invoiceId: invoiceDetails?.invoiceId,
		Ids: invoiceDetails ? [invoiceDetails[0].invoiceId] : null,
	};
	let fontStyle = {
		fontFamily: "Roboto Condensed, sans-serif",
		fontSize: 15,
	};
	return (
		<>
			<Hidden smUp>
				<CardHeader
					title={
						<Typography
							variant="h6"
							align="left"
							sx={{ fontFamily: "Roboto Condensed, sans-serif" }}
						>
							Unpaid Invoices
						</Typography>
					}
					action={
						rows?.length > 0 ? (
							<FormControlLabel
								label={
									<Typography
										sx={{
											fontFamily: "Roboto Condensed, sans-serif",
										}}
									>
										All
									</Typography>
								}
								sx={{ fontFamily: "Roboto Condensed, sans-serif" }}
								control={
									<Checkbox
										onChange={props.handleSelectAllClick}
										edge="start"
										checked={
											selected.length > 0 && rows.length === selected.length
										}
										tabIndex={-1}
										disableRipple
										inputProps={{ "aria-labelledby": "all" }}
									/>
								}
							/>
						) : (
							<Typography />
						)
					}
					sx={classes.header}
				/>
				{rows?.length > 0 ? (
					rows
						.slice((page - 1) * rowsPerPage, page * rowsPerPage)
						.map((invoice: any, index: number) => {
							return (
								<React.Fragment key={invoice.id}>
									<Grid
										container
										direction="row"
										alignItems="center"
										sx={{
											backgroundColor: index % 2 == 0 ? "#DCDCDC" : "none",
										}}
									>
										<Grid item xs={9} sx={{ p: "10px" }}>
											<Grid
												container
												direction="column"
												onClick={(event: any) =>
													props.handleClick(event, invoice.id)
												}
												sx={
													props.selected.includes(invoice.id)
														? classes.selectedColor
														: classes.unselectedColor
												}
											>
												<Typography variant="subtitle2" sx={fontStyle}>
													<strong>Name:</strong>{" "}
													{invoice.description.length < 21
														? invoice.description
														: invoice.description.substr(0, 22) + "..."}
												</Typography>

												<Typography variant="subtitle2" sx={fontStyle}>
													<strong>Due:</strong>{" "}
													{moment(invoice.due.slice(0, 10)).format(
														"MM/DD/YYYY"
													)}
												</Typography>
												<Typography variant="subtitle2" sx={fontStyle}>
													<strong>Amount:</strong>{" "}
													{formatCurrency(invoice.amount, clubData)}
												</Typography>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Button
												variant="outlined"
												disableRipple
												onClick={() => handleOpenInvoice(invoice.id)}
												sx={{ bgcolor: "#FAFAFA" }}
											>
												View
											</Button>
										</Grid>
									</Grid>
									<Dialog
										fullScreen
										open={props.open === invoice.id}
										sx={styles.modal}
										onClose={props.handleCloseInvoice}
										style={{ width: "100%" }}
									>
										<Grid item>
											<CardHeader
												sx={classes.invoiceHeader}
												title="Marketplace Invoice"
												action={
													<CloseButton
														style={"open"}
														onClose={props.handleCloseInvoice}
													/>
												}
											/>
										</Grid>
										<Invoice {...props} config={config} membership={invoice} />
									</Dialog>

									<Divider />
									<Dialog
										open={props.open === "99"}
										onClose={props.handleCloseInvoice}
										sx={styles.modal}
									>
										<PayInvoice
											{...props}
											isDefault={props.isDefault}
											setIsDefault={props.setIsDefault}
											invoices={selectedInvoices}
											onClose={props.onClose}
										/>
									</Dialog>
								</React.Fragment>
							);
						})
				) : (
					<Typography align="center" style={{ padding: 20 }}>
						There are no unpaid invoices
					</Typography>
				)}
				{rows?.length > 0 ? (
					<Grid container justifyContent="center" sx={{ px: 2 }}>
						<Grid item>
							<Hidden mdUp>
								<Grid container direction="row" justifyContent="space-between">
									<Grid item xs={12}>
										{props.balanceDue === 0 ? (
											""
										) : (
											<Typography
												paragraph
												align="center"
												style={{ marginTop: 15, fontSize: 18 }}
												sx={fontStyle}
												variant="h1"
											>
												Balance Due:{" "}
												{formatCurrency(props.balanceDue, clubData)}
											</Typography>
										)}
									</Grid>
									<Grid item xs={12}>
										<Button
											fullWidth
											disabled={props.selected?.length === 0}
											onClick={() => props.setOpen("99")}
											variant="contained"
											color="primary"
											sx={{ mt: props.balanceDue === 0 ? 1 : 0, mb: 1 }}
										>
											Pay Selected Invoices
										</Button>
									</Grid>
								</Grid>
							</Hidden>
						</Grid>
						{props.noOfPages < 2 || rows.length === 0 ? null : (
							<Grid container direction="row" justifyContent="center">
								<Grid style={{ padding: 10 }} item>
									<Pagination
										page={props.page}
										onChange={props.handleChange}
										count={props.noOfPages}
										boundaryCount={0}
										siblingCount={1}
										color="secondary"
									/>
								</Grid>
							</Grid>
						)}
					</Grid>
				) : null}
			</Hidden>
			<Hidden smUp>
				<CardHeader
					title={
						<Typography
							variant="h6"
							align="left"
							sx={{ fontFamily: "Roboto Condensed, sans-serif" }}
						>
							Paid Invoices
						</Typography>
					}
					sx={classes.header}
				/>
				{props.paidRows?.length > 0 ? (
					props.paidRows
						.slice(
							(props.paidPage - 1) * rowsPerPage,
							props.paidPage * rowsPerPage
						)
						.map((invoice: any, index: number) => {
							const isItemSelected = props.isSelected(invoice.id);
							const labelId = `mobile-${invoice.id}`;
							return (
								<React.Fragment key={labelId}>
									<Grid
										container
										direction="row"
										alignItems="center"
										sx={{
											backgroundColor: index % 2 == 0 ? "#DCDCDC" : "none",
										}}
									>
										<Grid item xs={9} sx={{ p: "10px" }}>
											<Grid
												sx={classes.unselectedColor}
												container
												direction="column"
											>
												<Typography variant="subtitle2" sx={fontStyle}>
													<strong>Name:</strong>{" "}
													{invoice.description.length < 21
														? invoice.description
														: invoice.description.substr(0, 22) + "..."}
												</Typography>

												<Typography variant="subtitle2" sx={fontStyle}>
													<strong>Due:</strong>{" "}
													{moment(invoice.due.slice(0, 10)).format(
														"MM/DD/YYYY"
													)}
												</Typography>
												<Typography variant="subtitle2" sx={fontStyle}>
													<strong>Amount:</strong>{" "}
													{formatCurrency(invoice.amount, clubData)}
												</Typography>
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Button
												variant="outlined"
												disableRipple
												onClick={() => handleOpenInvoice(invoice.id)}
												sx={{ bgcolor: "#FAFAFA" }}
											>
												View
											</Button>
										</Grid>
									</Grid>
									<Dialog
										fullScreen
										sx={styles.modal}
										open={props.open === invoice.id}
										onClose={props.handleCloseInvoice}
										maxWidth="md"
									>
										<Grid item>
											<CardHeader
												sx={classes.invoiceHeader}
												title="Marketplace Invoice"
												action={
													<CloseButton
														style={"open"}
														onClose={props.handleCloseInvoice}
													/>
												}
											/>
										</Grid>

										<Invoice {...props} config={config} membership={invoice} />
									</Dialog>

									<Divider />
									<Dialog
										open={props.open === "99"}
										onClose={props.handleCloseInvoice}
										sx={styles.modal}
									>
										<PayInvoice
											{...props}
											invoices={selectedInvoices}
											onClose={props.onClose}
										/>
									</Dialog>
								</React.Fragment>
							);
						})
				) : (
					<Typography align="center">There are no invoices</Typography>
				)}
			</Hidden>
		</>
	);
}

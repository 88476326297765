import axios from "axios";
import * as URL from "../config";

export function requestForgotPassword(action: any) {
	const forgotPasswordUrl = `${URL.MP_USER}/user/password/forgot`;
	return axios
		.post(forgotPasswordUrl, action.value)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestForgotUsername(action: any) {
	const forgotUsernameUrl = `${URL.MP_USER}/user/ForgotUsername`;
	return axios
		.post(forgotUsernameUrl, action.value)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestResetPassword(action: any) {
	const resetPasswordUrl = `${URL.MP_USER}/user/password/reset`;
	return axios
		.post(resetPasswordUrl, action.value)
		.then((response) => {
			return response;
		})
		.catch((error: any) => {
			console.log("error", error?.response);
			throw error.response;
		});
}
export function requestCheckToken(action: any) {
	const checkTokenUrl = `${URL.MP_USER}/user/password/verifyresetpasswordtoken`;
	return axios
		.post(checkTokenUrl, action.value)
		.then((response) => {
			return response;
		})
		.catch((error: any) => {
			console.log("error", error?.response);
			throw error.response;
		});
}
export function requestChangePassword(action: any) {
	const changePasswordUrl = `${URL.MP_USER}/user/password/change`;
	return axios
		.post(changePasswordUrl, action.value)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestSetPassword(action: any) {
	const setPasswordUrl = `${URL.MP_USER}/user/RegisterDependent/setPassword`;
	return axios
		.post(setPasswordUrl, action.value)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestEmail(action: any) {
	const resendEmailUrl = `${URL.MP_USER}/user/register/resendEmail`;
	return axios
		.post(resendEmailUrl, action.value)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestValidPassword(action: any) {
	const validPasswordUrl = `${URL.MP_USER}/user/password/canReuse`;
	return axios
		.post(validPasswordUrl, action.value, {
			headers: { "Content-Type": "application/json" },
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error.response;
		});
}

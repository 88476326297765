export const GET_ENROLL_MEMBERSHIPS_REQUEST = "GET_ENROLL_MEMBERSHIPS_REQUEST";
export const GET_ENROLL_MEMBERSHIPS_SUCCESS = "GET_ENROLL_MEMBERSHIPS_SUCCESS";
export const GET_ENROLL_MEMBERSHIPS_ERROR = "GET_ENROLL_MEMBERSHIPS_ERROR";

export const GET_ENROLL_MEMBERSHIP_DETAILS_REQUEST =
	"GET_ENROLL_MEMBERSHIP_DETAILS_REQUEST";
export const GET_ENROLL_MEMBERSHIP_DETAILS_SUCCESS =
	"GET_ENROLL_MEMBERSHIP_DETAILS_SUCCESS";
export const GET_ENROLL_MEMBERSHIP_DETAILS_ERROR =
	"GET_ENROLL_MEMBERSHIP_DETAILS_ERROR";

export const GET_ENROLL_MEMBERSHIP_TERMS_REQUEST =
	"GET_ENROLL_MEMBERSHIP_TERMS_REQUEST";
export const GET_ENROLL_MEMBERSHIP_TERMS_SUCCESS =
	"GET_ENROLL_MEMBERSHIP_TERMS_SUCCESS";
export const GET_ENROLL_MEMBERSHIP_TERMS_ERROR =
	"GET_ENROLL_MEMBERSHIP_TERMS_ERROR";
export const ENROLL_MEMBERSHIP_REQUEST = "ENROLL_MEMBERSHIP_REQUEST";
export const ENROLL_MEMBERSHIP_SUCCESS = "ENROLL_MEMBERSHIP_SUCCESS";
export const ENROLL_MEMBERSHIP_ERROR = "ENROLL_MEMBERSHIP_ERROR";

export const CLEAR_ENROLL_ERROR = "CLEAR_ENROLL_ERROR";

export const PROFILE_INFO_REQUEST = "PROFILE_INFO_REQUEST";
export const PROFILE_INFO_SUCCESS = "PROFILE_INFO_SUCCESS";
export const PROFILE_INFO_ERROR = "PROFILE_INFO_ERROR";

export const PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_ERROR = "PROFILE_UPDATE_ERROR";

export const GET_QUESTIONNAIRE_REQUEST = "GET_QUESTIONNAIRE_REQUEST";
export const GET_QUESTIONNAIRE_SUCCESS = "GET_QUESTIONNAIRE_SUCCESS";
export const GET_QUESTIONNAIRE_ERROR = "GET_QUESTIONNAIRE_ERROR";

export const GET_CREDITS_REQUEST = "GET_CREDITS_REQUEST";
export const GET_CREDITS_SUCCESS = "GET_CREDITS_SUCCESS";
export const GET_CREDITS_ERROR = "GET_CREDITS_ERROR";

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const UPDATE_NOTIFICATIONS_REQUEST = "UPDATE_NOTIFICATIONS_REQUEST";
export const UPDATE_NOTIFICATIONS_SUCCESS = "UPDATE_NOTIFICATIONS_SUCCESS";
export const UPDATE_NOTIFICATIONS_ERROR = "UPDATE_NOTIFICATIONS_ERROR";

export const GET_PROFILE_IMAGE_REQUEST = "GET_PROFILE_IMAGE_REQUEST";
export const GET_PROFILE_IMAGE_SUCCESS = "GET_PROFILE_IMAGE_SUCCESS";
export const GET_PROFILE_IMAGE_ERROR = "GET_PROFILE_IMAGE_ERROR";

export const GET_SIGNATURE_IMAGE_REQUEST = "GET_SIGNATURE_IMAGE_REQUEST";
export const GET_SIGNATURE_IMAGE_SUCCESS = "GET_SIGNATURE_IMAGE_SUCCESS";
export const GET_SIGNATURE_IMAGE_ERROR = "GET_SIGNATURE_IMAGE_ERROR";

export const GET_RELATED_USERS_REQUEST = "GET_RELATED_USERS_REQUEST";
export const GET_RELATED_USERS_SUCCESS = "GET_RELATED_USERS_SUCCESS";
export const GET_RELATED_USERS_ERROR = "GET_RELATED_USERS_ERROR";

export const DELETE_PROFILE_IMAGE_REQUEST = "DELETE_PROFILE_IMAGE_REQUEST";
export const DELETE_PROFILE_IMAGE_SUCCESS = "DELETE_PROFILE_IMAGE_SUCCESS";
export const DELETE_PROFILE_IMAGE_ERROR = "DELETE_PROFILE_IMAGE_ERROR";

export const HEALTHINFO_UPDATE_REQUEST = "HEALTHINFO_UPDATE_REQUEST";
export const HEALTHINFO_UPDATE_SUCCESS = "HEALTHINFO_UPDATE_SUCCESS";
export const HEALTHINFO_UPDATE_ERROR = "HEALTHINFO_UPDATE_ERROR";

export const BELT_RANK_REQUEST = "BELT_RANK_REQUEST";
export const BELT_RANK_SUCCESS = "BELT_RANK_SUCCESS";
export const BELT_RANK_ERROR = "BELT_RANK_ERROR";

export const PROFILE_IMAGE_UPLOAD_REQUEST = "PROFILE_IMAGE_UPLOAD_REQUEST";
export const PROFILE_IMAGE_UPLOAD_SUCCESS = "PROFILE_IMAGE_UPLOAD_SUCCESS";
export const PROFILE_IMAGE_UPLOAD_ERROR = "PROFILE_IMAGE_UPLOAD_ERROR";

export const UPDATE_RECURRING_REQUEST = "UPDATE_RECURRING_REQUEST";
export const UPDATE_RECURRING_SUCCESS = "UPDATE_RECURRING_SUCCESS";
export const UPDATE_RECURRING_ERROR = "UPDATE_RECURRING_ERROR";

export const SUBMIT_PROFILE_QUESTIONNAIRE_REQUEST =
	"SUBMIT_PROFILE_QUESTIONNAIRE_REQUEST";
export const SUBMIT_PROFILE_QUESTIONNAIRE_SUCCESS =
	"SUBMIT_PROFILE_QUESTIONNAIRE_SUCCESS";
export const SUBMIT_PROFILE_QUESTIONNAIRE_ERROR =
	"SUBMIT_PROFILE_QUESTIONNAIRE_ERROR";

export const UPDATE_PROFILE_QUESTIONNAIRE_REQUEST =
	"UPDATE_PROFILE_QUESTIONNAIRE_REQUEST";
export const UPDATE_PROFILE_QUESTIONNAIRE_SUCCESS =
	"UPDATE_PROFILE_QUESTIONNAIRE_SUCCESS";
export const UPDATE_PROFILE_QUESTIONNAIRE_ERROR =
	"UPDATE_PROFILE_QUESTIONNAIRE_ERROR";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

import React from "react";
import {
	Grid,
	Typography,
	Button,
	Dialog,
	Box,
	Grow,
	Snackbar,
} from "@mui/material";
import { CloseButton } from "../../utils/Buttons";
import PayEnroll from "./PayEnroll";
import Contract from "./Contract";
import Alert from "@mui/material/Alert";
import styles from "../../styles";
import Loader from "../../utils/Loader";

const classes: any = {
	root: {
		backgroundColor: "#FAFAFA",
		margin: 0,
		borderRadius: "5px 5px 5px 5px",
		paddingBottom: "20px",
		maxWidth: "900px",
	},
	form: {
		backgroundColor: "#FAFAFA",
		height: "90%",
		width: "100%",
		borderBottomLeftRadius: 5,
	},
	padding: {
		paddingLeft: "20px",
		paddingRight: "20px",
		marginLeft: "auto",
		marginRight: "auto",
	},
	scroll: {
		maxHeight: { xs: "80%", sm: "90%" },
		overflowY: "auto",
		paddingBottom: 0,
	},
};

export default function ReviewContract(props: any) {
	const [open, setOpen] = React.useState(0);
	const [errors, setErrors] = React.useState({});
	const sigCanvas: any = React.useRef({});
	const sigCanvas2: any = React.useRef({});
	const sigCanvas3: any = React.useRef({});
	const {
		billingProfileId,
		clubData,
		productId,
		getEnrollMembershipTerms,
		membershipTerms,
		onClose,
		selected,
		onCloseAll,
		matches,
		details
	} = props;
	React.useEffect(() => {
		let config = props.config;
		config.billingProfileId = billingProfileId;
		config.purchasedFor = selected;
		config.productId = productId;
		getEnrollMembershipTerms(config);
		setErrors({});
	}, []);
	const handleClose = () => {
		setOpen(0);
	};
	const handleCheckout = () => {
		let temp: any = {};
		if (sigCanvas.current.isEmpty()) {
			temp.sig1 = "Signature is required";
		}
		if (sigCanvas2.current.isEmpty()) {
			temp.sig2 = "Signature is required";
		}
		if (sigCanvas3.current.isEmpty()) {
			temp.sig3 = "Signature is required";
		}
		setErrors({ ...temp });
		if (Object.keys(temp).length === 0) {
			setOpen(1);
		}
	};
	let vertical: any = "top";
	let horizontal: any = "center";
	return (
		<>
			<div style={{ ...classes.root, display: open === 0 ? "block" : "none" }}>
				<Box displayPrint="none">
					{Object.keys(errors).length !== 0 ? (
						<Snackbar
							onClose={() => handleClose()}
							open={Object.keys(errors).length !== 0}
							anchorOrigin={{ vertical, horizontal }}
							key={vertical + horizontal}
						>
							<Grow in={true} timeout={450}>
								<Alert severity={"error"}>All signatures are required</Alert>
							</Grow>
						</Snackbar>
					) : null}
					<Grid container direction="row" justifyContent="flex-start">
						<Grid
							container
							sx={styles.header}
							justifyContent="space-between"
							alignItems="center"
						>
							<Grid />
							<Grid item sx={{ ml: 5 }}>
								<Typography variant="h5">Review Contract</Typography>
							</Grid>
							<Grid item>
								<CloseButton style="open" onClose={onCloseAll} />
							</Grid>
						</Grid>
					</Grid>
					{membershipTerms ? (
						<>
							<form style={classes.form}>
								<div style={classes.padding}>
									<Grid
										container
										spacing={2}
										style={{ paddingTop: 10, paddingBottom: 0 }}
									>
										<Grid item sx={classes.scroll}>
											<Typography variant="subtitle2" align="center">
												Review and Sign contract below:
											</Typography>
											<Contract
												refs={{
													sigCanvas: sigCanvas,
													sigCanvas2: sigCanvas2,
													sigCanvas3: sigCanvas3,
												}}
												errors={errors}
												clubData={clubData}
												membershipTerms={membershipTerms}
											/>
											<Grid container spacing={2} sx={{ pb: 1 }}>
												<Grid item xs={6}>
													<Button
														onClick={onClose}
														fullWidth
														variant="outlined"
														color="primary"
													>
														Back
													</Button>
												</Grid>
												<Grid item xs={6}>
													<Button
														onClick={() => handleCheckout()}
														fullWidth
														variant="contained"
														color="primary"
													>
														Checkout
													</Button>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</div>
							</form>
						</>
					) : (
						<Loader />
					)}
				</Box>
			</div>
			<Dialog
				sx={styles.modal}
				maxWidth="sm"
				open={open === 1}
				onClose={handleClose}
				fullScreen={matches ? true : false}
				fullWidth={!matches ? true : false}
			>
				<PayEnroll
					{...props}
					sig1={sigCanvas}
					sig2={sigCanvas2}
					sig3={sigCanvas3}
					details={details}
					paymentMethod={billingProfileId}
					onClose={handleClose}
					onCloseAll={onCloseAll}
				/>
			</Dialog>
		</>
	);
}

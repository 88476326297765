export const classes: any = {
	cardHeader: {
		backgroundColor: "primary.light",
		color: "white",
		alignItems: "center",
		textAlign: "center",
	},
	tableHeader: {
		fontFamily: "Roboto Condensed, sans-serif",
		padding: 1,
		color: "white",
		backgroundColor: "text.disabled",
	},
	table: {
		"&:nth-of-type(even)": {
			backgroundColor: "#DCDCDC",
		},
		"&:nth-of-type(odd)": {
			backgroundColor: "secondary.light",
		},
	},
	tableRow: {
		fontFamily: "Roboto Condensed, sans-serif",
		backgroundColor: "text.disabled",
		color: "white",
		zIndex: 0,
	},
};

import axios from "axios";
import * as URL from "../config";

export function requestGetMarketplace(action: any) {
	const getMarketplaceUrl: any = `${URL.MP_MEMBER}/clients/marketplaceProducts?ClientId=${action.id}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}`;
	return axios
		.get(getMarketplaceUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestCompletePurchase(action: any) {
	const completePurchaseUrl: any = `${URL.MP_BILLING}/marketplace/checkoutCart?ClientId=${action.clientId}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}`;
	return axios
		.post(completePurchaseUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestSaveCart(action: any) {
	const saveCartUrl: any = `${URL.MP_BILLING}/marketplace/saveCart?ClientId=${action.clientId}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}&CartItems:${action.cartItems}`;
	return axios
		.post(saveCartUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestAddItem(action: any) {
	const addItemUrl: any = `${URL.MP_BILLING}/marketplace/saveCart?ClientId=${action.clientId}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}&CartItems:${action.cartItems}`;
	return axios
		.post(addItemUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestRemoveItem(action: any) {
	const removeItemUrl: any = `${URL.MP_BILLING}/marketplace/saveCart?ClientId=${action.clientId}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}&CartItems:${action.cartItems}`;
	return axios
		.post(removeItemUrl, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestGetCart(action: any) {
	const getCartUrl: any = `${URL.MP_MEMBER}/clients/marketplaceCart?ClientId=${action.id}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}&PromoCode=${action.promoCode}`;
	return axios
		.get(getCartUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

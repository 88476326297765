import { call, put, takeEvery } from "redux-saga/effects";
import * as constants from "../constants/invoices.constants";
import {
	requestGetInvoices,
	requestPayInvoices,
	requestEmailInvoice,
	requestGetDetails,
	requestInvoicePdf,
} from "../services/invoices.service";
import { ResponseGenerator } from "../types";

export function* getInvoices(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestGetInvoices, action.value);
		if (response) {
			yield put({ type: constants.GET_INVOICES_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.GET_INVOICES_ERROR, error });
	}
}
export function* getInvoiceDetails(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestGetDetails, action.value);
		if (response) {
			yield put({ type: constants.INVOICE_DETAILS_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.INVOICE_DETAILS_ERROR, error });
	}
}
export function* getInvoicePdf(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestInvoicePdf, action.value);
		if (response) {
			yield put({ type: constants.INVOICE_IMAGE_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.INVOICE_IMAGE_ERROR, error });
	}
}
export function* emailInvoice(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestEmailInvoice, action.value);
		if (response) {
			yield put({ type: constants.EMAIL_INVOICE_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.EMAIL_INVOICE_ERROR, error });
	}
}
export function* payInvoices(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestPayInvoices, action.value);
		if (response) {
			yield put({ type: constants.PAY_INVOICES_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.PAY_INVOICES_ERROR, error });
	}
}
export function* watchInvoicesRequest() {
	yield takeEvery(constants.GET_INVOICES_REQUEST, getInvoices);
	yield takeEvery(constants.PAY_INVOICES_REQUEST, payInvoices);
	yield takeEvery(constants.EMAIL_INVOICE_REQUEST, emailInvoice);
	yield takeEvery(constants.INVOICE_DETAILS_REQUEST, getInvoiceDetails);
	yield takeEvery(constants.INVOICE_IMAGE_REQUEST, getInvoicePdf);
}

import {
	Grid,
	FormControl,
	MenuItem,
	InputLabel,
	Select,
	InputBase,
	InputAdornment,
	IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import PriceSlider from "./PriceSlider";

const classes = {
	categories: {
		maxWidth: 250,
		display: "flex",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: { xs: 1.5 },
	},
	actionBar: {
		mt: { xs: 0, md: 1 },
		mb: { xs: 2, md: 6 },
	},
	searchBar: {
		margin: 1.5,
		marginLeft: 2,
		width: "100%",
		maxWidth: { xs: 260, md: 400 },
		backgroundColor: "#FAFAFA",
		borderRadius: 5,
		height: 40,
		display: "flex",
		boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
		transition: "width .3s",
		"&:hover": {
			borderBottom: "none",
		},
	},
};

export default function Categories(props: any) {
	const {
		clubData,
		category,
		handleCategoryChange,
		search,
		setSearch,
		handleSearch,
		price,
		setPrice,
	} = props;
	let categories = clubData?.membershipCategories;
	const renderCategories = (
		<FormControl
			sx={classes.categories}
			margin="dense"
			variant="outlined"
			size="small"
		>
			<InputLabel shrink={true} htmlFor="outlined-age-native-simple">
				Categories
			</InputLabel>
			<Select
				label="Categories"
				value={category}
				onChange={handleCategoryChange}
				inputProps={{
					name: "categories",
					padding: 5,
				}}
			>
				<MenuItem key={"All"} value={"All"}>
					All
				</MenuItem>
				{categories
					? categories
							.sort((a: any, b: any) => {
								return a === b ? 0 : a < b ? -1 : 1;
							})
							.map((category: string, index: any) => (
								<MenuItem key={index} value={category}>
									{category}
								</MenuItem>
							))
					: null}
			</Select>
		</FormControl>
	);
	return (
		<Grid container justifyContent="space-between" sx={classes.actionBar}>
			<Grid item xs={12} md={4}>
				{renderCategories}
			</Grid>
			<Grid item xs={12} md={4}>
				<Grid container justifyContent="center" alignItems="center">
					<InputBase
						sx={classes.searchBar}
						placeholder="Search..."
						startAdornment={
							<InputAdornment position="start" sx={{ pl: 1.5 }}>
								<SearchIcon sx={{ fill: "grey" }} />
							</InputAdornment>
						}
						endAdornment={
							<InputAdornment position="start">
								{search && (
									<IconButton
										onClick={() => {
											setSearch("");
										}}
										size="large"
									>
										<CloseIcon
											sx={{
												fill: "grey",
											}}
										/>
									</IconButton>
								)}
							</InputAdornment>
						}
						value={search}
						onChange={(e: any) => handleSearch(e)}
						inputProps={{ pl: 1.5 }}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12} md={4}>
				<div
					style={{
						marginLeft: "auto",
						marginRight: "auto",
						maxWidth: 280,
					}}
				>
					<PriceSlider clubData={clubData} setPrice={setPrice} price={price} />
				</div>
			</Grid>
		</Grid>
	);
}

import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
const ErrorPage = (props: any) => {
	return (
		<React.Fragment>
			<div
				style={{
					width: "100vw",
					height: "90vh",
					display: "flex",
				}}
			>
				<Grid container justifyContent="center" sx={{ margin: "auto auto" }}>
					<Paper elevation={12} sx={{ padding: 5 }}>
						<Grid item container justifyContent="center" alignItems="center">
							<Grid item>
								<ReportProblemRoundedIcon
									color="primary"
									sx={{
										height: 100,
										width: 100,
									}}
								/>
							</Grid>
						</Grid>
						<Grid item sx={{ textAlign: "center" }}>
							<Typography
								variant="h5"
								gutterBottom
								sx={{ fontWeight: 900 }}
								color="secondary"
							>
								{props.message ? props.message : "404 Page Not Found"}
							</Typography>
							<Typography
								variant="body1"
								gutterBottom
								color="primary"
								sx={{ fontFamily: "Roboto Condensed, sans-serif" }}
							>
								<div style={{ fontSize: 18, paddingBottom: 10 }}>
									{/* {props.invalid
										? "Please double check the name in the url."
										: "You've reached a url that does not exist."} */}
									Please verify the url and try again.
								</div>
								Contact Member Solutions Support Team at 1-888-277-4408 <br />{" "}
								or{" "}
								<a href="mailto:cs@membersolutions.com">
									cs@membersolutions.com
								</a>{" "}
								for assistance.
							</Typography>
						</Grid>
					</Paper>
				</Grid>
			</div>
		</React.Fragment>
	);
};

export default ErrorPage;

import React from "react";
import {
	Card,
	CardContent,
	CssBaseline,
	Grid,
	Typography,
	Container,
	AppBar,
	Toolbar,
	Dialog,
	IconButton,
	Badge,
	SwipeableDrawer,
	useTheme,
	useMediaQuery,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import ItemInfo from "./ItemInfo";
import Notifications from "../AlertMessage/Notifications";
import placeholder from "../../media/placeholder3.jpg";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Cart from "./Cart";
import { formatCurrency } from "../../utils/FormatCurrency";
import styles from "../../styles";
import Categories from "./Categories";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Image from "../../utils/Image";
import { MetroSpinner } from "react-spinners-kit";
import { blue } from "../../theme";

const classes: any = {
	"@global": {
		ul: {
			margin: 0,
			padding: 0,
			listStyle: "none",
		},
	},
	appBar: {
		justifyContent: "space-between",
		display: "flex",
		top: 55,
		bottom: "auto",
		height: "55px",
		zIndex: 50,
	},
	storeContainer: {
		mb: 2,
		height: "100%",
		pt: { xs: 8, md: 4 },
		mt: { xs: 8, md: 10 },
		pb: { xs: 4, md: 0 },
		pl: { xs: 1, md: 8.5 },
		maxWidth: { xs: 370, sm: 557, md: 885, lg: 1150 },
	},
	margin: {
		color: "white",
		marginLeft: { xs: 0, md: 5.5 },
	},

	mobileHidden: {
		justifyContent: "center",
		paddingLeft: { xs: 5.5, sm: 0 },
		display: { xs: "none", sm: "flex" },
	},
	desktopHidden: {
		display: { xs: "flex", sm: "none" },
		justifyContent: "center",
		marginTop: "7px",
		paddingLeft: 2,
	},
	card: {
		height: 220,
		maxWidth: { lg: 245 },
		color: "primary.main",
	},
	searchGrid: {
		pb: 3,
		ml: 2.5,
	},
	drawer: {
		marginTop: 110,
		zIndex: 1,
	},
	backdrop: {
		cursor: "pointer",
	},
};

export default function MarketPlace(props: any) {
	const {
		userConfig,
		purchaseSuccess,
		getCart,
		marketplace,
		cart,
		clubData,
		getMarketplace,
	} = props;
	const [error, setError] = React.useState<any>("");
	const [category, setCategory] = React.useState("All");
	const [open, setOpen] = React.useState(0);
	const [search, setSearch] = React.useState("");
	const [results, setResults] = React.useState<any>();
	const [filterDisplay, setFilterDisplay] = React.useState<any>([]);
	const [itemsPerPage] = React.useState(12);
	const [noOfPages, setNoOfPages] = React.useState(0);
	const [page, setPage] = React.useState(1);
	const [openCart, setOpenCart] = React.useState(false);
	const [cartItems, setCartItems] = React.useState([]);
	const [price, setPrice] = React.useState<number[]>([0, 1000]);
	const theme = useTheme();
	const toggleDrawer = (open: any) => {
		setOpenCart(open);
	};
	let items: any = cart;
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	let config: any = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		id: userConfig ? userConfig.clientId : null,
	};
	React.useEffect(() => {
		if (props.marketplaceErrors) {
			setError("There was an error loading the store");
		}
	}, [props.marketplaceErrors]);
	React.useEffect(() => {
		if (results) {
			setNoOfPages(Math.ceil(results.length / itemsPerPage));
		}
	}, [results]);
	React.useEffect(() => {
		if (userConfig) {
			getMarketplace(config);
			config.clientId = userConfig?.clientId;
			getCart(config);
		}
	}, [userConfig]);
	React.useEffect(() => {
		let filteredResults: any = marketplace?.filter(filterByCategory);
		if (price[1].toString() !== "1000") {
			filteredResults = filteredResults?.filter(
				(result: any) =>
					result.amount >= price[0] && result.amount <= price[1] && result
			);
		} else if (price[1].toString() === "1000") {
			filteredResults = filteredResults?.filter(
				(result: any) => result.amount >= price[0] && result
			);
		} else {
			setResults(filteredResults);
		}
		if (search?.length > 0) {
			let sResults: any = searchResults();
			if (price[1].toString() !== "1000") {
				sResults = sResults.filter(
					(result: any) =>
						result.amount >= price[0] && result.amount <= price[1] && result
				);
				setResults(sResults);
			} else if (price[1].toString() === "1000") {
				sResults = sResults.filter(
					(result: any) => result.amount >= price[0] && result
				);
				setResults(sResults);
			}
		} else {
			setResults(filteredResults);
		}
	}, [search, category, price]);
	React.useEffect(() => {
		let sortedMarketplace: any = marketplace?.sort((a: any, b: any) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		});
		setResults(sortedMarketplace);
	}, [marketplace, purchaseSuccess]);
	React.useEffect(() => {
		let indexedCart: any = [];
		if (cart?.length > 1) {
			indexedCart = cart?.map((cartItem: any, index: any) => {
				if (cartItem) {
					cartItem.index = index;
					return cartItem;
				}
			});
		}
		setCartItems(indexedCart);
	}, [cart]);
	React.useEffect(() => {
		cartCount = cartQuantity();
	}, [cart]);
	const handlePageChange = (event: any, value: any) => {
		setPage(value);
	};
	const handleCategoryChange = (event: SelectChangeEvent) => {
		setCategory(event.target.value as string);
	};
	const handleSearch = (e: any) => {
		setSearch(e.target.value);
		let oldList: any = props.marketplace?.map((item: any) => {
			return item.name.toLowerCase();
		});
		if (e.target.value !== "") {
			let newList: any = [];
			newList = oldList.filter((item: any) => {
				return item.includes(e.target.value.toLowerCase());
			});
			setFilterDisplay(newList);
		} else {
			setFilterDisplay(items);
		}
	};
	const handleClick = (id: any) => {
		setOpen(id);
	};
	const handleClose = () => {
		setOpen(0);
	};
	const filterByCategory = (item: any) => {
		if (category === "All") {
			return item;
		} else {
			if (item.categoryName === category) {
				return item;
			}
		}
	};
	let searchResults = () => {
		let results: any = [];
		let filteredResults: any = marketplace?.filter(filterByCategory);
		filteredResults?.map(
			(item: any) =>
				filterDisplay?.includes(item?.name?.toLowerCase()) && results.push(item)
		);
		return results;
	};
	const cartQuantity = () => {
		let quantity: any = 0;
		if (cart?.length > 0) {
			cart?.map((item: any) => {
				if (item && item?.quantity > 0) {
					quantity = quantity + item.quantity;
				}
			});
		}
		return quantity;
	};
	let cartCount = cartQuantity();
	const defaultImage: any = placeholder;
	return (
		<div>
			<CssBaseline />
			{props.paymentMessage ||
			props.paymentError ||
			props.errors ||
			props.purchaseMessage ||
			props.messages ? (
				<Notifications
					messages={
						props.paymentMessage || props.messages || props.purchaseMessage
					}
					errors={props.paymentError || props.errors}
					hide={false}
				/>
			) : null}
			{props.marketplaceMessages || props.marketplaceErrors ? (
				<Notifications
					messages={props.marketplaceMessages}
					errors={props.marketplaceErrors}
					hide={false}
				/>
			) : null}
			<AppBar sx={classes.appBar} position="fixed">
				<Toolbar
					disableGutters={true}
					sx={{ ...classes.margin, minHeight: 55 }}
				>
					<Grid
						container
						justifyContent="space-between"
						alignContent="center"
						sx={{ height: 55 }}
					>
						<Grid item xs={4}>
							<Typography
								sx={classes.desktopHidden}
								component="h3"
								variant="h5"
								align="center"
							>
								Marketplace
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography
								sx={classes.mobileHidden}
								component="h3"
								variant="h5"
								align="center"
							>
								Marketplace
							</Typography>
						</Grid>
						<Grid item xs={4}>
							{cartItems ? (
								<div style={{ marginRight: 10, float: "right" }}>
									<IconButton
										onClick={() => toggleDrawer(true)}
										size="large"
										sx={{ mt: { xs: "2px", md: "-8px" } }}
									>
										<Badge badgeContent={cartCount} color="error">
											<ShoppingCartIcon
												sx={{ color: "white", cursor: "pointer" }}
											/>
										</Badge>
									</IconButton>
								</div>
							) : null}
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			{props.userMessages || props.userErrors ? (
				<Notifications
					messages={props.userMessages}
					errors={props.userErrors}
					hide={false}
				/>
			) : null}

			<React.Fragment>
				<SwipeableDrawer
					ModalProps={{
						BackdropProps: {
							sx: {
								root: classes.backdrop,
							},
						},
					}}
					PaperProps={{
						sx: { maxWidth: 445 },
					}}
					sx={{ paper: classes.drawer, maxWidth: 445 }}
					anchor={"right"}
					open={openCart}
					onClose={() => toggleDrawer(false)}
					onOpen={() => toggleDrawer(true)}
					children={
						<Cart
							{...props}
							cartItems={cartItems}
							setCartItems={setCartItems}
							defaultImage={defaultImage}
							config={config}
							handleClose={toggleDrawer}
						/>
					}
				/>
			</React.Fragment>
			<Container sx={classes.storeContainer} component="main">
				{matches ? (
					<Accordion
						sx={{
							boxShadow: "none",
							ml: 1.2,
							mb: 6,
							minHeight: 50,
							"&.Mui-expanded": {
								ml: 1.2,
								mb: 6,
							},
						}}
					>
						<AccordionSummary
							sx={{
								backgroundColor: "darkGrey",
								"&.Mui-expanded": {
									minHeight: 50,
									maxHeight: 50,
									borderRadius: 0,
								},
							}}
							expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
						>
							<Typography sx={{ color: "white" }}>Filters</Typography>
						</AccordionSummary>
						<AccordionDetails sx={{ p: 0 }}>
							<Categories
								category={category}
								handleCategoryChange={handleCategoryChange}
								handleSearch={handleSearch}
								price={price}
								setPrice={setPrice}
								clubData={clubData}
								search={search}
								setSearch={setSearch}
							/>
						</AccordionDetails>
					</Accordion>
				) : (
					<div style={{ marginBottom: 50 }}>
						<Categories
							category={category}
							handleCategoryChange={handleCategoryChange}
							handleSearch={handleSearch}
							price={price}
							setPrice={setPrice}
							clubData={clubData}
							search={search}
							setSearch={setSearch}
						/>
					</div>
				)}

				<Grid
					container
					justifyContent="center"
					spacing={4}
					sx={{ height: "100%" }}
				>
					<Grid
						container
						spacing={3}
						alignItems="flex-start"
						justifyContent="center"
						sx={classes.searchGrid}
					>
						{results?.length > 0 &&
							props.getMarketplaceSuccessful &&
							results
								?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
								?.map((item: any, index: any) => (
									<React.Fragment key={index}>
										<Grid
											onClick={() => handleClick(item.productId)}
											item
											key={item.productId}
											xs={9}
											sm={6}
											md={4}
											lg={3}
										>
											<Card raised sx={classes.card}>
												<CardContent
													sx={{
														cursor: "pointer",
														position: "relative",
														padding: 0,
														height: 184,
														width: 245,
														margin: 0,
														backgroundColor: item.imageUrl
															? "white"
															: "#E9EEF1",
													}}
												>
													<Image
														src={item.imageUrl ? item.imageUrl : defaultImage}
														alt={item.name}
													/>
													<div
														color="secondary"
														style={{
															fontWeight: 600,
															fontSize: 16,
															position: "absolute",
															top: "0px",
															right: "0px",
															color: theme.palette.secondary.main,
														}}
													>
														{item.price > item.amount ? (
															<div
																style={{
																	textAlign: "right",
																	marginRight: 7,
																}}
															>
																<div
																	style={{
																		textDecoration: "line-through",
																		color: "red",
																		marginRight: 5,
																	}}
																>
																	{formatCurrency(item.price, clubData)}
																</div>

																<span
																	style={{
																		backgroundColor: "white",
																		padding: 5,
																		borderRadius: 5,
																	}}
																>
																	{formatCurrency(item.amount, clubData)}
																</span>
															</div>
														) : (
															<Typography
																color="secondary"
																sx={{
																	padding: 1,
																	pt: 1.2,
																	fontWeight: 600,
																	fontSize: 16,
																	textAlign: "right",
																}}
															>
																<span
																	style={{
																		backgroundColor: "white",
																		padding: 5,
																		borderRadius: 5,
																	}}
																>
																	{formatCurrency(item.amount, clubData)}
																</span>
															</Typography>
														)}
													</div>
													<div
														style={{
															position: "absolute",
															top: 80,
															left: 0,
															right: 0,
														}}
													>
														{item.quantity === 0 && (
															<Typography
																align="center"
																variant="subtitle2"
																sx={{
																	mt: 0,
																	pt: 0.3,
																	color: "darkgray",
																	backgroundColor: "white",
																}}
															>
																OUT OF STOCK
															</Typography>
														)}
													</div>
												</CardContent>
												<Typography
													sx={{
														padding: 0.5,
													}}
													variant="subtitle1"
													align="center"
												>
													{item.name}
												</Typography>
											</Card>
											{results.length < 9 ? <Grid item /> : null}
										</Grid>
										<Dialog
											open={open === item.productId}
											onClose={handleClose}
											fullScreen={matches ? true : false}
											fullWidth={!matches ? true : false}
											maxWidth="sm"
											sx={styles.modal}
											PaperProps={{
												sx: {
													backgroundColor: "transparent",
													justifyContent: "center",
												},
											}}
										>
											<ItemInfo
												{...props}
												config={config}
												cartItems={cartItems}
												setCartItems={setCartItems}
												item={item}
												defaultImage={defaultImage}
												additionalItems={item.additionalItems}
												acceptedCards={
													clubData.validCCTypes || props.acceptedCards
												}
												onClose={handleClose}
											/>
										</Dialog>
									</React.Fragment>
								))}
						{props.getMarketplaceSuccessful && results?.length === 0 && (
							<Grid container justifyContent="center">
								<Typography style={{ marginTop: 50 }} align="center">
									There are no results
								</Typography>
							</Grid>
						)}
						{!props.getMarketplaceSuccessful &&
							props.getMarketplaceRequesting && (
								<Grid container justifyContent="center" sx={{ m: 10, pl: 0 }}>
									<MetroSpinner size={50} color={blue} />
								</Grid>
							)}
						{error && (
							<Grid container justifyContent="center">
								<Typography sx={{ mt: 6 }} align="center">
									{error}
								</Typography>
							</Grid>
						)}
					</Grid>
					{noOfPages < 2 ||
					results?.length === 0 ||
					props.getMarketplaceRequesting ? null : (
						<div
							style={{
								padding: 1,
								paddingLeft: 45,
								paddingBottom: 20,
								paddingTop: 0,
								marginLeft: "auto",
								marginRight: "auto",
							}}
						>
							<Pagination
								showFirstButton
								showLastButton
								page={page}
								onChange={handlePageChange}
								count={noOfPages}
								color="secondary"
							/>
						</div>
					)}
				</Grid>
			</Container>
		</div>
	);
}

import React from "react";
import {
	DialogContent,
	DialogContentText,
	Dialog,
	DialogActions,
	Typography,
	Grid,
	Button,
	FormControl,
	InputLabel,
	Select,
	Divider,
	Checkbox,
} from "@mui/material";
import Title from "../../Profile/Dialogs/Title";
import PaymentMethods from "../../../containers/PaymentMethods";
import OneTimeCardForm from "../../Profile/Dialogs/OneTimePayForm";
import styles from "../../../styles";
import { SelectChangeEvent } from "@mui/material/Select";
import { formatCurrency } from "../../../utils/FormatCurrency";
import { renderPaymentMethods } from "../../Forms/PaymentMethods";
import ButtonLoader from "../../../utils/ButtonLoader";
import BookOptions from "./SelectedProducts";

const PayBooking = (props: any) => {
	const {
		billingProfiles,
		clubData,
		userInfo,
		credits,
		userConfig,
		getBillingProfile,
		defaultBillingProfile,
		defaultId,
		billingMessages,
		payBookingRequesting,
		payBookingSuccess,
		selectedClass,
		quantity,
	} = props;
	const [open, setOpen] = React.useState<number>(0);
	const [checked, setChecked] = React.useState<boolean>(false);
	const [oneTimePaymentType, setOneTimePaymentType] =
		React.useState<string>("CreditCard");
	const [client] = React.useState<string>(clubData?.clubType);
	const [paymentMethod, setPaymentMethod] = React.useState<string>("");
	const [paymentType, setPaymentType] = React.useState<string>("");
	const [oneTime, setOneTime] = React.useState<boolean>(false);
	const [bookFor, setBookFor] = React.useState(props.bookFor);
	const [isDefault, setIsDefault] = React.useState<boolean>(false);
	const [values, setValues] = React.useState<any>({
		amountType: "all",
		credit: credits,
		totalDue: 0,
		amount: null,
		paymentMethodType: paymentMethod,
		billingProfileId: null,
		accountNumber: "",
		routing: "",
		accountTypeFull: "",
		creditcard: null,
		nameOnCard: null,
		expiryMonth: null,
		expiryYear: null,
		securityCode: null,
		bankName: "",
		firstName: userInfo?.firstName,
		lastName: userInfo?.lastName,
	});
	React.useEffect(() => {
		if (payBookingSuccess) {
			handleClose();
			props.closePayBook();
		}
	}, [payBookingSuccess]);
	React.useEffect(() => {
		props.getRelatedUsers(config);
		props.getCredits(config);
	}, []);
	React.useEffect(() => {
		if (isDefault && defaultId) {
			let defaultConfig: any = {
				headers: { Authorization: `Bearer ${userConfig.token}` },
				BusinessAlias: userConfig.businessAlias,
				PartnerApiKey: userConfig.partnerApiKey,
				id: defaultId,
			};
			defaultBillingProfile(defaultConfig);
			setIsDefault(false);
		}
	}, [defaultId]);
	React.useEffect(() => {
		setPaymentProfile();
	}, [billingProfiles]);
	React.useEffect(() => {
		getBillingProfile(config);
	}, [billingMessages]);
	const handleClose = () => {
		setOpen(0);
		props.closePayBook();
	};
	const handlePaymentType = (event: SelectChangeEvent) => {
		setPaymentType(event.target.value as string);
	};
	const handleSetChecked = (event: any) => {
		setChecked(event.target.checked);
	};
	const setPaymentProfile = () => {
		let isDefault: any = false;
		sortedBillingProfiles?.forEach((profile: any) => {
			if (profile.isDefaultProfile && !oneTime) {
				setPaymentMethod(profile.id);
				isDefault = true;
			} else {
				if (sortedBillingProfiles.length > 0) {
					setPaymentMethod(sortedBillingProfiles[0].id);
				}
			}
		});
	};
	const handlePaymentChange = (event: SelectChangeEvent) => {
		let value: any = event.target.value;
		setPaymentMethod(value as any);
		const type: any = billingProfiles.find(
			(x: any) => x.id === event.target.value
		);
		if (!oneTime) {
			if (type.profileType === "CreditCard") {
				setPaymentType("CreditCard");
			}
			if (type.profileType === "Eft") {
				clubData.country === "CA"
					? setPaymentType("Eft")
					: setPaymentType("Ach");
			}
		}
	};
	const payBooking = () => {
		props.payBooking(payConfig);
	};
	let sortedBillingProfiles: any = billingProfiles.sort(function (
		a: any,
		b: any
	) {
		return b.isDefaultProfile - a.isDefaultProfile;
	});
	let config: any = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		id: userConfig ? userConfig.clientId : null,
	};
	let paymentMethods: any = renderPaymentMethods(
		sortedBillingProfiles,
		oneTimePaymentType,
		values,
		oneTime
	);
	let creditAmount: any;
	let total: any = (selectedClass?.tax + selectedClass?.price) * quantity;
	if (checked && total < props.credits / 100) {
		creditAmount = total * 100;
	} else {
		creditAmount = credits;
	}
	const payConfig: any = {
		BusinessAlias: userConfig?.businessAlias,
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		PartnerApiKey: userConfig?.partnerApiKey,
		ClientId: userConfig?.clientId,
		BillingProfileId: paymentMethod,
		Credits: checked ? creditAmount : 0,
		Amount: total,
		creditCard:
			oneTimePaymentType === "CreditCard" && oneTime
				? {
						cardNumber: values.creditcard,
						nameOnCard: values.nameOnCard,
						expiryMonth: values.expiryMonth,
						expiryYear: values.expiryYear,
						securityCode: values.securityCode,
				  }
				: null,
		eft:
			oneTimePaymentType === "Eft" && oneTime
				? {
						AccountNumber: values.accountNumber,
						TransitNumber: values.transitNumber,
						InstitutionNumber: values.institutionNumber,
						BankAccountType: values.accountTypeFull,
						NameOnAccount: values.firstName + " " + values.lastName,
				  }
				: null,
		ach:
			oneTimePaymentType === "Ach" && oneTime
				? {
						AccountNumber: values.accountNumber,
						RoutingNumber: values.routing,
						BankAccountType: values.accountTypeFull,
						NameOnAccount: values.firstName + " " + values.lastName,
				  }
				: null,
		CartItems: [
			{
				productId: selectedClass?.id,
				quantity: quantity,
				purchaseForId: bookFor,
				amountPerItem: selectedClass?.amount,
				Discount: 0,
				tax: selectedClass?.tax,
				totalAmount: selectedClass?.amount * quantity,
			},
		],
	};
	return (
		<>
			<Title title="Complete Purchase" onClose={props.handleClose} />
			<DialogContent sx={{ p: 2 }}>
				<DialogContentText>
					<Grid container sx={{ p: 0.5 }}>
						<Grid item xs={4}>
							Class Name
						</Grid>
						<Grid item xs={4}>
							Purchase For
						</Grid>
						<Grid item xs={2} sx={{ textAlign: "left" }}>
							Price
						</Grid>
						<Grid item xs={2} sx={{ textAlign: "right" }}>
							Quantity
						</Grid>
					</Grid>
					<Divider sx={{ mb: 1 }} />
					<BookOptions
						{...props}
						bookFor={bookFor}
						setBookFor={setBookFor}
						selectedClass={selectedClass}
						quantity={quantity}
						setOpen={setOpen}
					/>
					<Divider sx={{ mt: 1.5, mb: 0.5 }} />
					<Grid container justifyContent="flex-end">
						<Grid
							container
							justifyContent="space-between"
							style={{ maxWidth: 250, width: "100%", paddingTop: 10 }}
						>
							<Grid item xs={6}>
								<Typography>Subtotal:</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography align="right">
									{formatCurrency(selectedClass.price * quantity, clubData)}
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography>Tax:</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography align="right">
									{formatCurrency(selectedClass.tax * quantity, clubData)}
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Divider />
								<Typography gutterBottom>Total:</Typography>
							</Grid>
							<Grid item xs={6}>
								<Divider />
								<Typography align="right">
									{formatCurrency(
										(selectedClass.amount + selectedClass.tax) * quantity,
										clubData
									)}
								</Typography>
							</Grid>
							<Grid container justifyContent="center">
								{Number(total?.toFixed(2)) < 0 && (
									<Typography
										align="right"
										sx={{ color: "red" }}
										variant="caption"
									>
										Total cannot be less than zero
									</Typography>
								)}
							</Grid>
							{checked && (
								<>
									<Grid container justifyContent="space-between">
										<Typography>Credits Applied:</Typography>
										<Typography sx={{ mr: -0.7 }}>
											(
											{Number(credits) / 100 - total > 0
												? formatCurrency(total, clubData)
												: formatCurrency(Number(credits) / 100, clubData)}
											)
										</Typography>
									</Grid>
									{Number(props.credits) / 100 - total < 0 && (
										<Grid container justifyContent="space-between">
											<Typography>Amount Remaining:</Typography>
											<Typography>
												{formatCurrency(
													Number(total - Number(credits) / 100),
													clubData
												)}
											</Typography>
										</Grid>
									)}
								</>
							)}
						</Grid>
						{credits ? (
							<Grid
								container
								alignItems="center"
								justifyContent="flex-start"
								sx={{ p: 0, pb: 1 }}
							>
								<Grid item>
									<Checkbox
										checked={checked}
										sx={{ p: 0, pr: 0.5 }}
										onChange={(event: any) => handleSetChecked(event)}
										disabled={client === "FSB ONLY" && credits / 100 < total}
										// disabled={credits / 100 < total}
									/>
								</Grid>
								<Grid item>
									<Typography>
										Available Credit (
										{formatCurrency(props.credits / 100, props.clubData)})
										{credits / 100 < total && client === "FSB ONLY" ? (
											<Typography variant="caption">
												{" "}
												*Not enough credits
											</Typography>
										) : null}
									</Typography>
								</Grid>
							</Grid>
						) : null}
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<FormControl fullWidth margin="normal" variant="outlined">
								<InputLabel shrink={true} htmlFor="outlined-age-native-simple">
									Payment Method
								</InputLabel>
								<Select
									label="Payment Method"
									native
									fullWidth
									value={paymentMethod}
									onChange={handlePaymentChange}
									inputProps={{
										name: "paymentMethod",
										id: "outlined-age-native-simple",
										padding: 5,
									}}
								>
									{paymentMethods}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Button
								fullWidth
								color="primary"
								variant="outlined"
								onClick={() => setOpen(1)}
							>
								Add Payment Method
							</Button>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Button
								fullWidth
								onClick={() => setOpen(2)}
								variant="outlined"
								color="primary"
								disabled={selectedClass.defaultRenewal || client === "FSB ONLY"}
							>
								One Time Payment
							</Button>
						</Grid>
					</Grid>
				</DialogContentText>
				<Dialog open={open === 1} onClose={() => setOpen(0)} sx={styles.modal}>
					<PaymentMethods
						{...props}
						open={true}
						requesting={props.billingRequesting}
						successful={props.billingSuccessful}
						handleClose={() => setOpen(0)}
						setIsDefault={setIsDefault}
					/>
				</Dialog>
				<Dialog open={open === 2} onClose={() => setOpen(0)} sx={styles.modal}>
					<OneTimeCardForm
						{...props}
						values={values}
						setValues={setValues}
						acceptedCards={props.clubData.validCCTypes}
						setPaymentMethod={setPaymentMethod}
						oneTimePaymentType={oneTimePaymentType}
						setPaymentProfile={setPaymentProfile}
						setOneTimePaymentType={setOneTimePaymentType}
						handlePaymentType={handlePaymentType}
						oneTime={oneTime}
						setOneTime={setOneTime}
						onClose={() => setOpen(0)}
					/>
				</Dialog>
			</DialogContent>
			<DialogActions sx={{ p: 2 }}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<Button
							fullWidth
							color="primary"
							variant="outlined"
							onClick={props.handleClose}
						>
							Back
						</Button>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Button
							fullWidth
							onClick={payBooking}
							variant="contained"
							color="primary"
							disabled={
								payBookingRequesting ||
								(props.billingProfiles.length == 0 && !oneTime)
							}
						>
							{payBookingRequesting ? <ButtonLoader /> : "Complete Purchase"}
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</>
	);
};

export default PayBooking;

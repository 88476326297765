import React from "react";
import {
	TextField,
	Typography,
	Grid,
	Button,
	FormGroup,
	FormControlLabel,
	Switch,
	InputAdornment,
	Dialog,
	Paper,
	DialogActions,
	DialogContent,
	DialogContentText,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { editCardValidation, creditCardNumberValidation } from "./validation";
import { BillingAddressForm } from "./AddressForm";
import AcceptedCards from "./AcceptedCards";
import { TextInput } from "../../Forms/Inputs";
import styles from "../../../styles";
import Title from "../Dialogs/Title";

export default function CreditCardForm(props: any) {
	const { profileInfo, userConfig, messages } = props;
	const [errors, setErrors] = React.useState<any>({});
	const [accountError, setAccountError] = React.useState<any>({});
	const [edit, setEdit] = React.useState<any>(false);
	const [state, setState] = React.useState({
		default: profileInfo?.isDefaultProfile ? true : false,
	});
	const [values, setValues] = React.useState<any>({
		creditcard: "",
		expiryMonth: "",
		expiryYear: "",
		securityCode: "",
		address: "",
		city: "",
		zip: "",
		state: "",
		country: "",
		firstName: "",
		lastName: "",
		nameOnCard: "",
		mask: "",
		newcard: "",
	});
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("sm"));
	React.useEffect(() => {
		setValues(profileInfo);
	}, []);
	React.useEffect(() => {
		if (
			messages === "Payment method successfully updated" ||
			messages === "Payment method deleted"
		) {
			props.handleClose();
		}
	}, [messages]);
	React.useEffect(() => {
		if (profileInfo.isDefaultProfile) {
			setState({ default: profileInfo?.isDefaultProfile });
		} else {
			setState({ default: false });
		}
	}, [profileInfo]);
	React.useEffect(() => {
		if (values.creditcard) {
			let cardNumber: any = props.maskNumber(values.creditcard);
			setValues({ ...values, mask: cardNumber, newcard: "" });
		}
	}, [values.creditcard]);
	React.useEffect(() => {
		if (edit) {
			const timeout = setTimeout(() => {
				inputRef.current.focus();
			}, 100);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [edit]);
	const handleChange = (event: any) => {
		let prop: any = [event.target.name];
		if (event.target.name === "newcard") {
			setErrors({ ...errors, creditcard: "" });
		}
		if (errors[prop]) {
			setErrors({ ...errors, [event.target.name]: "" });
		}
		setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};
	const handleDefaultChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};
	const handleDeletePayment = (id: any) => {
		let config = {
			headers: { Authorization: `Bearer ${userConfig.token}` },
			partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
			businessAlias: userConfig ? userConfig.businessAlias : null,
			id: id,
		};
		props.deleteBillingProfile(config);
	};
	let billingAddress: any = {
		firstName: values.firstName,
		lastName: values.lastName,
		address: values.address,
		city: values.city,
		zip: values.zip,
		state: values.state,
		country: values.country,
	};
	const handleSubmitPayment = () => {
		let config: any = {
			headers: { Authorization: `Bearer ${userConfig.token}` },
			BusinessAlias: userConfig.businessAlias,
			PartnerApiKey: userConfig.partnerApiKey,
			ClientId: userConfig.clientId,
			FirstName: values.firstName,
			LastName: values.lastName,
			Address: values.address,
			City: values.city,
			State: values.state,
			Country: values.country,
			Zip: values.zip,
			PaymentMethodType: "CreditCard",
			CreditCard: {
				CardNumber: values.creditcard,
				NameOnCard: values.nameOnCard,
				ExpiryMonth: values.expiryMonth,
				ExpiryYear: values.expiryYear,
				SecurityCode: values.securityCode,
			},
		};

		let valid: any;
		valid = editCardValidation(
			config.CreditCard,
			setErrors,
			billingAddress,
			props.acceptedCards,
			profileInfo.creditcard
		);

		if (valid) {
			let defaultConfig = {
				headers: { Authorization: `Bearer ${userConfig.token}` },
				BusinessAlias: userConfig.businessAlias,
				PartnerApiKey: userConfig.partnerApiKey,
				id: profileInfo?.id,
			};

			config.id = profileInfo.id;
			if (state.default) {
				props.defaultBillingProfile(defaultConfig);
			}
			props.updateBillingProfile(config);
		}
	};
	const handleNewNumber = () => {
		let newcard = values.newcard?.trim();
		let valid: any;
		valid = creditCardNumberValidation(
			{ CardNumber: newcard, SecurityCode: values.securityCode },
			props.acceptedCards,
			setAccountError
		);
		if (valid) {
			setEdit(false);
			setValues({ ...values, creditcard: newcard, newcard: "" });
		}
	};
	const handleEdit = () => {
		edit ? handleNewNumber() : setEdit(true);
	};
	const inputRef: any = React.useRef();

	return (
		<>
			<Title
				title={`Credit/Debit Card
						${profileInfo?.isDefaultProfile ? " (Default)" : ""}`}
				onClose={props.handleClose}
				hide={matches ? false : true}
			/>
			<DialogContent sx={{ pb: 0 }}>
				<DialogContentText tabIndex={-1}>
					<AcceptedCards acceptedCards={props.acceptedCards} />
					<Grid container justifyContent="center">
						<Grid container justifyContent="center">
							<FormGroup row>
								<FormControlLabel
									control={
										<Switch
											checked={state.default}
											onChange={handleDefaultChange}
											color="primary"
											name="default"
											disabled={profileInfo?.isDefaultProfile ? true : false}
										/>
									}
									label="Make Default Payment"
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={4}>
							<Button
								fullWidth
								variant="outlined"
								onClick={() => props.delete()}
								disabled={profileInfo?.isAssociatedWithMembership}
								sx={{ mb: 2 }}
							>
								Delete
							</Button>
						</Grid>
						{profileInfo?.isAssociatedWithMembership && (
							<Grid container justifyContent="center">
								<Typography
									sx={{ mb: 2 }}
									align="center"
									color="black"
									variant="caption"
								>
									Profile is associated with renewing payments and cannot be
									deleted
								</Typography>
							</Grid>
						)}
					</Grid>

					<Grid container justifyContent="center" spacing={3}>
						<Grid item xs={12} md={6}>
							<TextInput
								fullWidth
								InputLabelProps={{ shrink: true }}
								variant="outlined"
								label={"Name on Card"}
								name="nameOnCard"
								onChange={handleChange}
								color="secondary"
								value={values.nameOnCard}
								error={errors.nameOnCard ? true : false}
								helperText={errors.nameOnCard}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							{edit === true ? (
								<TextField
									inputRef={inputRef}
									InputLabelProps={{ shrink: true }}
									onBlur={() => handleEdit()}
									fullWidth
									variant="outlined"
									label={"Card Number"}
									name="newcard"
									onChange={handleChange}
									color="secondary"
									value={values?.newcard?.trim()}
									error={
										errors.creditcard ||
										errors.cardNumber ||
										accountError.creditcard
											? true
											: false
									}
									helperText={
										errors.creditcard ||
										errors.cardNumber ||
										accountError.creditcard
									}
								/>
							) : (
								<TextField
									InputProps={{
										endAdornment: (
											<InputAdornment style={{ padding: 0 }} position="end">
												<Button
													style={{ padding: 5 }}
													onClick={() => handleEdit()}
												>
													Edit
												</Button>
											</InputAdornment>
										),
									}}
									InputLabelProps={{ shrink: true }}
									fullWidth
									variant="outlined"
									label={"Card Number"}
									name="creditcard"
									color="secondary"
									value={values?.mask}
									error={errors.creditcard || errors.cardNumber ? true : false}
									helperText={errors.creditcard || errors.cardNumber}
								/>
							)}
						</Grid>
						<Grid item xs={4} sm={3}>
							<TextInput
								variant="outlined"
								label={"CVV"}
								name="securityCode"
								onChange={handleChange}
								color="secondary"
								type="number"
								InputLabelProps={{ shrink: true }}
								value={values.securityCode}
								error={errors.securityCode ? true : false}
								helperText={errors.securityCode}
							/>
						</Grid>
						<Grid item xs={4} sm={3}>
							<TextInput
								variant="outlined"
								type="number"
								InputLabelProps={{ shrink: true }}
								label={"Exp Mo."}
								name="expiryMonth"
								onChange={handleChange}
								color="secondary"
								value={values.expiryMonth}
								error={errors.expiryMonth ? true : false}
								helperText={errors.expiryMonth}
							/>
						</Grid>
						<Grid item xs={4} sm={3}>
							{" "}
							<TextInput
								variant="outlined"
								label={"Exp Year"}
								name="expiryYear"
								type="number"
								InputLabelProps={{ shrink: true }}
								onChange={handleChange}
								color="secondary"
								value={values.expiryYear}
								error={errors.expiryYear ? true : false}
								helperText={errors.expiryYear}
							/>
						</Grid>
					</Grid>
					<BillingAddressForm
						{...props}
						values={values}
						errors={errors}
						userInfo={props.userInfo}
						setValues={setValues}
						setErrors={setErrors}
						handleChange={handleChange}
					/>

					<Grid
						container
						justifyContent="center"
						spacing={2}
						sx={{ padding: 1 }}
					>
						<Dialog open={props.open === "6"} sx={styles.modal}>
							<Paper sx={styles.paper}>
								<Grid container alignItems="center" justifyContent="center">
									<Grid item>
										<Typography paragraph>
											Are you sure you want to delete this payment method?
										</Typography>
									</Grid>
									<Grid container spacing={2} justifyContent="center">
										<Grid item xs={4}>
											<Button
												fullWidth
												variant="outlined"
												onClick={() =>
													handleDeletePayment(props.profileInfo.id)
												}
											>
												Delete
											</Button>
										</Grid>
										<Grid item xs={4}>
											<Button
												fullWidth
												variant="outlined"
												onClick={() => {
													props.deleteCancel();
												}}
											>
												Cancel
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</Dialog>
					</Grid>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ py: 2, pr: 2, pl: 2 }}>
				<Button
					fullWidth
					sx={{ mr: 1 }}
					onClick={() => props.handleClose()}
					variant="outlined"
					color="primary"
				>
					Cancel
				</Button>
				<Button
					fullWidth
					sx={{ ml: 1 }}
					onClick={handleSubmitPayment}
					variant="contained"
					color="primary"
					disabled={edit}
				>
					Save
				</Button>
			</DialogActions>
		</>
	);
}

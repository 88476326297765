import { useState, useEffect } from "react";
import {
	Button,
	Card,
	CardContent,
	Typography,
	Grid,
	Collapse,
	Divider,
	Hidden,
	FormHelperText,
	Tooltip,
	DialogContent,
	DialogContentText,
	DialogActions,
	DialogTitle,
	Dialog,
	useMediaQuery,
} from "@mui/material";
import CompletePurchase from "./CompletePurchase";
import PurchaseFor from "./PurchaseFor";
import { formatCurrency } from "../../utils/FormatCurrency";
import styles from "../../styles";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useTheme } from "@mui/material/styles";
import { AddressValidation } from "../Enroll/AddressPrompt";

const classes: any = {
	quantity: {
		width: 20,
		minWidth: 0,
		backgroundColor: "white",
		color: "secondary.main",
		fontWeight: 900,
		boxShadow: "none",
		borderRadius: 0,
	},
	overlay: {
		position: "absolute",
		top: 5,
		left: 5,
		color: "#0000008A",
		cursor: "pointer",
	},
};

export default function Cart(props: any) {
	const {
		cart,
		marketplace,
		userInfo,
		userConfig,
		purchaseForUsers,
		removeItem,
		setCartItems,
		clearMessage,
		saveCart,
		clubData,
	} = props;
	const [collapse, setCollapse] = useState<boolean>(false);
	const [open, setOpen] = useState<number>(0);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("sm"));
	useEffect(() => {
		setCollapse(true);
	}, [collapse]);
	useEffect(() => {
		if (
			props.userMessages === "User successfully created" ||
			props.userMessages === "User successfully updated"
		) {
			props.getRelatedUsers(props.config);
		}
	}, [props.userMessages]);

	useEffect(() => {
		if (!userInfo) {
			props.getProfileInfo(props.config);
		}
	}, [userInfo]);
	const handleClose = () => {
		setOpen(0);
	};
	const getCartItems = () => {
		let cartItems: any = [];
		cart?.map((cartItem: any) => {
			marketplace.map((item: any) => {
				if (cartItem && cartItem.productId === item.productId) {
					cartItems.push(item);
				}
			});
		});
		return cartItems;
	};

	let cartItems = getCartItems();
	let cartItemsQ = () => {
		return cartItems.map((cartItem: any, index: any) => {
			const obj = Object.assign({}, cartItem);
			obj["cartQuantity"] = cart[index]?.quantity;
			obj["index"] = cart[index]?.index;
			obj["tax"] = cart[index]?.tax;
			obj["totalAmount"] = cart[index]?.totalAmount;
			obj["discount"] = cart[index]?.discount;
			let purchaseForName = "";
			if (cart[index]?.purchaseForId === userConfig.clientId) {
				purchaseForName = userInfo?.fullName;
			}
			purchaseForUsers?.filter((user: any) => {
				if (user.id === cart[index]?.purchaseForId) {
					purchaseForName = user.fullName;
				}
			});
			obj["purchaseForName"] = purchaseForName;
			return obj;
		});
	};
	const clearCart = () => {
		let cartConfig: any = props.config;
		cartConfig.cartItems = [];
		cartConfig.clientId = props.config.id;
		saveCart(cartConfig);
		setOpen(0);
		setTimeout(() => {
			clearMessage();
		}, 2000);
		setCartItems([]);
	};
	const addQuantity = (id: any) => {
		let config = props.config;
		let items: any = cart;
		items[id].quantity = items[id].quantity + 1;
		config.cartItems = items;
		config.clientId = userConfig.clientId;
		saveCart(config);
		setTimeout(() => {
			clearMessage();
		}, 2000);
		setCartItems(items);
	};
	const subtractQuantity = (index: any) => {
		let config = props.config;
		let items: any = cart;
		if (items[index].quantity > 1) {
			items[index].quantity = items[index].quantity - 1;
			config.cartItems = items;
			config.clientId = userConfig.clientId;
			setCartItems(items);
		}
		removeItem(config);
		setTimeout(() => {
			clearMessage();
		}, 2000);
	};
	const deleteItem = (id: any) => {
		let itemsInCart = cart;
		let config = props.config;
		itemsInCart = itemsInCart.filter((item: any) => {
			return item.index !== id;
		});
		config.cartItems = itemsInCart;
		config.clientId = userConfig.clientId;
		setCartItems(itemsInCart);
		removeItem(config);
		setTimeout(() => {
			clearMessage();
		}, 2000);
	};
	const getSubtotal = () => {
		let subtotal: any = 0;

		cartItemsQ()?.map((item: any) => {
			subtotal = subtotal + item.cartQuantity * item.amount;
		});
		return subtotal;
	};
	let subtotal = getSubtotal();
	const validateQuantity = () => {
		let temp: any = {};
		let validate = () => {
			cartItemsQ()?.map((item: any) => {
				if (item.cartQuantity > item.quantity) {
					temp[
						item.index
					] = `Quantity exceeds amount available (${item.quantity})`;
				} else {
					temp[item.index] = "";
				}
			});
			return Object.values(temp).every((x) => x === "");
		};
		if (validate()) {
			if (clubData?.clubType === "FSB ONLY") {
				if (
					!userInfo.address ||
					!userInfo.city ||
					!userInfo.country ||
					!userInfo.state ||
					!userInfo.zip ||
					!userInfo.email ||
					(userInfo?.country && userInfo.country !== "US") ||
					(userInfo?.country && userInfo.country !== "CA") ||
					(!userInfo.cellPhone && !userInfo.homePhone && !userInfo.workPhone)
				) {
					setOpen(3);
				} else {
					setOpen(1);
				}
			} else {
				setOpen(1);
			}
		}
	};
	return (
		<>
			<DialogTitle sx={{ p: 0 }}>
				<Grid container justifyContent="space-between">
					<Hidden mdDown>
						<Grid item xs={3} />
					</Hidden>
					<Grid item xs={5}>
						<Typography
							sx={{ mb: 1, pl: 2, mt: 1 }}
							variant="h5"
							align="center"
						>
							Shopping Cart
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography align="right" sx={{ mb: 1, mr: 1, mt: 1 }}>
							{cart?.length > 0 ? (
								<Button variant="outlined" onClick={() => setOpen(2)}>
									Clear
								</Button>
							) : null}
						</Typography>
					</Grid>
				</Grid>
			</DialogTitle>
			<Divider />
			<DialogContent sx={{ p: 1, pt: 0 }}>
				<DialogContentText>
					<Grid container justifyContent="space-between" direction="column">
						<Grid item>
							<Collapse in={collapse} timeout={1000}>
								{cart?.length > 0 ? (
									cartItemsQ()?.map((item: any, index: any) => (
										<div key={index}>
											<Card
												raised
												sx={{
													m: 2,
													borderRadius: 3,
													position: "relative",
												}}
											>
												<CardContent style={{ padding: 0 }}>
													<Grid
														container
														justifyContent="space-between"
														style={{ backgroundColor: "rgba(129,130,133,.05)" }}
													>
														<Grid
															item
															sx={{ backgroundColor: "white", minWidth: 130 }}
														>
															<img
																src={
																	item.imageUrl
																		? item.imageUrl
																		: props.defaultImage
																}
																alt={item.name}
																style={{
																	height: 103,
																	maxWidth: 130,
																	padding: 0,
																	display: "flex",
																	marginLeft: "auto",
																	marginRight: "auto",
																	backgroundSize: "cover",
																	justifyContent: "center",
																}}
															/>
															<Typography sx={classes.overlay}>
																<DeleteOutlinedIcon
																	onClick={() => deleteItem(item.index)}
																	className="material-icons"
																	color="secondary"
																/>
															</Typography>
														</Grid>
														<Grid item sx={{ maxWidth: 250, paddingTop: 0.5 }}>
															<Typography
																variant="subtitle1"
																sx={{
																	lineHeight: 1.2,
																	minWidth: 150,
																	maxWidth: 160,
																}}
															>
																{item.name?.length < 21 ? (
																	item.name
																) : (
																	<Tooltip
																		arrow
																		placement="top"
																		title={item.name}
																		style={{ cursor: "pointer" }}
																	>
																		<Typography>
																			{item.name.slice(0, 16) + "..."}
																		</Typography>
																	</Tooltip>
																)}
															</Typography>
															<Typography
																sx={{
																	alignItems: "left",
																	minWidth: 150,
																	color: "black",
																}}
																variant="subtitle2"
															>
																<span style={{ fontSize: 18, fontWeight: 500 }}>
																	{formatCurrency(item?.amount, clubData)}
																</span>
															</Typography>
															{item.isPackage || item.isService ? (
																<div style={{ paddingTop: 10 }}>
																	<PurchaseFor
																		{...props}
																		item={item}
																		id={cart[index]?.purchaseForId}
																		index={index}
																		setOpen={setOpen}
																		open={open}
																	/>
																</div>
															) : null}
														</Grid>
														<Grid item>
															<Grid item>
																<div
																	style={{
																		paddingBottom: 3,
																		justifyContent: "flex-end",
																		display: "flex",
																	}}
																>
																	<Button
																		sx={classes.quantity}
																		variant="contained"
																		onClick={() => addQuantity(index)}
																		disabled={
																			item.cartQuantity === item.quantity
																		}
																	>
																		+
																	</Button>
																</div>
															</Grid>
															<Grid item>
																<Typography align="center" color="primary">
																	<strong>
																		{item?.cartQuantity !== 2147483647
																			? item?.cartQuantity
																			: null}
																	</strong>
																</Typography>
															</Grid>
															<Grid item>
																<div
																	style={{
																		marginTop: 3,
																		justifyContent: "flex-end",
																		display: "flex",
																	}}
																>
																	<Button
																		sx={classes.quantity}
																		variant="contained"
																		onClick={() => subtractQuantity(index)}
																		disabled={item?.cartQuantity === 1}
																	>
																		-
																	</Button>
																</div>
															</Grid>
														</Grid>
													</Grid>
													{item.cartQuantity > item.quantity ? (
														<FormHelperText sx={{ pl: 4, color: "red" }}>
															Quantity exceeds amount available ({item.quantity}
															)
														</FormHelperText>
													) : null}
												</CardContent>
											</Card>
										</div>
									))
								) : (
									<Typography align="center" sx={{ padding: 2 }}>
										Your cart is empty
									</Typography>
								)}
							</Collapse>
						</Grid>
						<Grid item>
							<div
								style={{ marginLeft: 20, marginRight: 20, marginBottom: 20 }}
							>
								<Dialog
									sx={styles.modal}
									open={open === 2}
									onClose={handleClose}
								>
									<Grid container justifyContent="center" sx={{ padding: 2 }}>
										<Grid item xs={12}>
											<Typography paragraph align="center">
												Are you sure you want to clear your cart?
											</Typography>
										</Grid>
										<Grid style={{ margin: 5 }} item xs={5}>
											<Button
												onClick={() => setOpen(0)}
												fullWidth
												variant="outlined"
											>
												NO
											</Button>
										</Grid>
										<Grid sx={{ margin: 0.5 }} item xs={5}>
											<Button onClick={clearCart} fullWidth variant="outlined">
												YES
											</Button>
										</Grid>
									</Grid>
								</Dialog>

								<Dialog
									open={open === 1}
									sx={styles.modal}
									maxWidth="sm"
									fullScreen={matches ? true : false}
									fullWidth={!matches ? true : false}
								>
									<CompletePurchase
										{...props}
										items={cartItemsQ()}
										config={props.config}
										onClose={handleClose}
										closeCart={() => props.handleClose(false)}
									/>
								</Dialog>
								<Dialog
									sx={styles.modal}
									style={{ minHeight: "100%" }}
									maxWidth="sm"
									open={open == 3}
									onClose={handleClose}
								>
									<AddressValidation
										{...props}
										config={props.config}
										handleOpen={() => setOpen(1)}
										onClose={handleClose}
									/>
								</Dialog>
							</div>
						</Grid>
					</Grid>
				</DialogContentText>
				<Typography sx={{ pb: 1, width: "100%" }} align="center" variant="h5">
					Subtotal: {formatCurrency(subtotal, clubData)}
				</Typography>
			</DialogContent>
			<DialogActions sx={{ p: 2, pt: 0.5 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Button
							onClick={() => props.handleClose(false)}
							color="primary"
							variant="outlined"
							fullWidth
						>
							Back to Store
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button
							color="primary"
							variant="contained"
							fullWidth
							onClick={validateQuantity}
							disabled={cart?.length < 1}
						>
							Complete Purchase
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</>
	);
}

import { Grid, Button, Grow, IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const classes: any = {
	root: {
		"& > * + *": {
			marginTop: 2,
		},
	},
	alert: {
		alignItems: "center",
		justifyContent: "center",
		padding: 10,
	},
	closeButton: {
		justifyContent: "flex-end",
		padding: 0,
	},
};
function Alerts(props: any) {
	const params: any = window.location.pathname.split("/")[1];
	let alertMessage =
		props.errors || props.messages || props.userTaken || props.userMessages;
	let userMessage = props.userMessages;
	let messages = props.messages;
	if (userMessage === undefined) {
		userMessage = null;
	}
	let severity: any;
	let error: any;

	if (props.errors) {
		severity = "error";
		if (props.errors.code) {
			error = props.errors.code;
		} else if (props.errors.message) {
			error = props.errors.message;
		} else {
			error = props.errors;
		}
	} else {
		severity = "success";
	}
	if (userMessage === undefined) {
		userMessage = null;
	}
	if (
		alertMessage === "Signing in..." ||
		alertMessage === "Loading..." ||
		alertMessage === "Signing up..."
	) {
		alertMessage = null;
	}
	let config: any;
	if (props.config) {
		config = {
			Username: props.config.userName,
			BusinessAlias: props.config.businessAlias,
			PartnerApiKey: props.config.partnerApiKey,
		};
	}

	const handleClick = (e: any) => {
		e.preventDefault();
		clearAll();
		props.resendEmail(config);
	};
	const clearAll = () => {
		props.clearErrors();
		props.clearMessage();
		props.clearBillingMessage();
	};
	const resendButton = (
		<Grow in={true} timeout={650}>
			<Button
				onClick={handleClick}
				type="submit"
				variant="contained"
				color="primary"
			>
				Resend Email
			</Button>
		</Grow>
	);

	const renderMessage = () => {
		switch (error) {
			case "0000":
				return "Your account is now locked. Please use the Forgot Password option or contact a member of our Support Team.";
			case "0001":
				return "Email address has not been verified.  Please check your inbox for the verification email or click Resend Email to send again.";
			case "0002":
				return "Username is already taken";
			case "0003":
				return "Oops! Something went wrong";
			case "0004":
				return "Invalid Password";
			case "0005":
				return "Invalid username or password, please try again";
			case "0006":
				return "User registration token is invalid";
			case "0007":
				return "Your password is no longer valid, please use forgot password link to reset it";
			case "0008":
				return "Your password has expired";
			case "0009":
				return "User is disabled, Please contact your system adminstrator";
			case "0010":
				return "User cannot be found";
			case "0011":
				return "Username is already taken";
			case "PasswordMismatch":
				return "Password doesnt match the one we have on file";
			case "invalid_username_or_password":
				return "Unable to update information. Password is incorrect.";
			case "Network Error":
				return "No response from server, check your connection and try again";
			default:
				return error;
		}
	};
	return (
		<div className={classes.root}>
			{alertMessage ? (
				<>
					<Grow in={true} timeout={450}>
						<Alert
							action={
								props.close === true ? (
									<IconButton
										className={classes.closeButton}
										onClick={() => {
											clearAll();
										}}
										size="large"
									>
										<CloseIcon />
									</IconButton>
								) : null
							}
							className={classes.alert}
							severity={severity}
						>
							{userMessage ? userMessage : ""}
							{error ? renderMessage() : ""}
							{messages ? messages : ""}
						</Alert>
					</Grow>
					{error === "0001" ? (
						<Grid container justifyContent="center">
							{props.config ? resendButton : null}
						</Grid>
					) : null}
					{error === "0008" ? (
						<Grid container justifyContent="center">
							<Link
								to={`/${params}/changepassword`}
								style={{ textDecoration: "none" }}
							>
								<Button variant="contained" color="primary">
									Change Password
								</Button>
							</Link>{" "}
						</Grid>
					) : null}
				</>
			) : null}
		</div>
	);
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		resendEmail: (config: any) =>
			dispatch({ type: "RESEND_EMAIL_REQUEST", value: config }),
		clearErrors: () => dispatch({ type: "CLEAR_MESSAGE" }),
		clearMessage: () => dispatch({ type: "CLEAR_ERRORS" }),
		clearBillingMessage: () => dispatch({ type: "CLEAR_BILLING_MESSAGE" }),
	};
};
export default connect(null, mapDispatchToProps)(Alerts);

import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";

const OpenRoute = (props: any) => {
	const { user } = props;
	if (user) {
		props.onSignOut();
		return (
			<React.Fragment>
				<Route {...props} />
			</React.Fragment>
		);
	}
	return (
		<React.Fragment>
			<Route {...props} />
		</React.Fragment>
	);
};

const mapStateToProps = (state: any) => {
	return {
		user: state.auth.currentUser,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onSignOut: () => dispatch({ type: "LOGOUT" }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(OpenRoute);

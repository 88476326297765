import React from "react";
import {
	Button,
	Card,
	CardContent,
	CardActions,
	Typography,
	Grid,
	Divider,
	TextField,
	FormHelperText,
} from "@mui/material";
import Notifications from "../AlertMessage/Notifications";
import { formatCurrency } from "../../utils/FormatCurrency";
import getLegends from "../../helpers/getLegends";
import ButtonLoader from "../../utils/ButtonLoader";

const classes: any = {
	root: {
		minWidth: { xs: 320, sm: 600 },
		overflowY: "auto",
	},
	quantity: {
		width: "10px",
		marginTop: "6px",
		minWidth: 0,
		border: `solid .5px lightgrey`,
		fontFamily: "Oswald, sans-serif",
		backgroundColor: "#FAFAFA",
		color: "primary.main",
		fontWeight: 900,
		height: "25px",
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "primary.main",
			border: `solid .5px primary.main`,
			color: "white",
		},
	},
	amount: {
		padding: 0,
		marginTop: "6px",
		height: "22px",
		fontSize: 20,
	},
};

export default function ItemInfo(props: any) {
	const { userConfig } = props;
	const [quantity, setQuantity] = React.useState(1);
	const [newItem, setNewItem] = React.useState(false);
	let config: any = {
		headers: {
			Authorization: `Bearer ${userConfig.token}`,
		},
		partnerApiKey: userConfig.partnerApiKey,
		businessAlias: userConfig.businessAlias,
		clientId: userConfig.clientId,
	};
	React.useEffect(() => {
		props.clearMessage();
	}, []);
	React.useEffect(() => {
		if (newItem) {
			config.cartItems = props.cartItems;
			props.addItem(config);
			setNewItem(false);
		}
	}, [newItem]);
	React.useEffect(() => {
		if (props.marketplaceSuccess) {
			props.setMarketplace();
			props.onClose();
			setTimeout(() => {
				props.clearMessage();
			}, 2000);
		}
	}, [props.marketplaceSuccess]);
	const addToCart = () => {
		let cart: any = props.cart;
		const newItem = {
			productId: props.item.productId,
			quantity: quantity,
			purchaseForId: userConfig.clientId,
		};
		if (cart?.length === 0) {
			//if cart is empty add item to cart
			props.setCartItems([newItem]);
			setNewItem(true);
		} else {
			//if cart isnt empty, check productIds
			let productIds = props.cart?.map((item: any) => {
				if (item) {
					return item.productId;
				}
			});
			if (productIds?.includes(props.item?.productId)) {
				//if item exists in cart
				cart.map((cartItem: any, index: any) => {
					if (props.item?.productId === cartItem?.productId) {
						if (props.item?.isPackage || props.item?.isService) {
							//and is package or service, create new item
							props.setCartItems([...props.cart, newItem]);
							setNewItem(true);
						} else {
							//else add to quantity of item
							cartItem.quantity = Number(cartItem.quantity) + quantity;
							cart[index] = cartItem;
							props.setCartItems(cart);
							setNewItem(true);
						}
					}
				});
			} else {
				//product Id does not exist in cart, add new item
				props.setCartItems([...props.cart, newItem]);
				setNewItem(true);
			}
		}
	};
	const changeQuantity = (change: string) => {
		if (change === "add") {
			setQuantity(quantity + 1);
		}
		if (change === "remove" && quantity !== 1) {
			setQuantity(quantity - 1);
		}
	};
	const handleQuantity = (event: any) => {
		setQuantity(Number(event.target.value));
	};
	return (
		<div style={classes.root}>
			{props.marketplaceMessages || props.marketplaceErrors ? (
				<Notifications
					messages={props.marketplaceMessages}
					errors={props.marketplaceErrors}
					hide="false"
				/>
			) : null}
			<Card>
				<CardContent style={{ padding: 0 }}>
					<img
						src={props.item.imageUrl ? props.item.imageUrl : props.defaultImage}
						alt={props.item.name}
						width={"100%"}
						style={{
							aspectRatio: "3/2",
							objectFit: "contain",
							background: !props.item.imageUrl ? "#E9EEF1" : "white",
						}}
					/>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							padding: 20,
						}}
					>
						<Typography color="primary" variant="h5">
							{props.item.name}
						</Typography>
						{props.item.price > props.item.amount ? (
							<>
								<Typography
									color="primary"
									align="right"
									variant="h5"
									style={{
										alignSelf: "center",
										zIndex: 1,
									}}
								>
									<span
										style={{
											textDecoration: "line-through",
											color: "red",
											marginRight: 5,
										}}
									>
										{formatCurrency(props.item.price, props.clubData)}
									</span>
									{formatCurrency(props.item.amount, props.clubData)}{" "}
									{getLegends(props.item.productLegend)}
								</Typography>
							</>
						) : (
							<Typography color="primary" align="right" variant="h5">
								{formatCurrency(props.item.amount, props.clubData)}{" "}
								{getLegends(props.item.productLegend)}
							</Typography>
						)}
					</div>
					<Divider light />
					<div style={{ padding: 20, paddingBottom: 0, paddingTop: 0 }}>
						{props.item.quantity !== 2147483647 ? (
							<Typography
								color="primary"
								variant="subtitle1"
								sx={{
									fontSize: 20,
									paddingTop: 1,
								}}
							>
								<span style={{ fontFamily: "Oswald, sans-serif" }}>
									Available:
								</span>
								<span style={{ paddingLeft: 10 }}>{props.item.quantity}</span>
							</Typography>
						) : null}
						<Grid item>
							<div style={{ paddingBottom: 2 }}>
								<Grid container>
									<Typography
										style={{
											paddingLeft: 0,
											fontSize: 20,
											marginRight: 10,
											fontFamily: "Oswald, sans-serif",
										}}
										color="primary"
									>
										Quantity:
									</Typography>
									{props.item?.quantity > 0 ? (
										<>
											<Grid item>
												<div style={{ alignSelf: "flex-end" }}>
													<Button
														sx={classes.quantity}
														variant="contained"
														onClick={() => changeQuantity("remove")}
													>
														-
													</Button>
												</div>
											</Grid>
											<Grid item>
												<TextField
													sx={classes.amount}
													inputProps={{
														style: {
															padding: "1px 10px",
															textAlign: "center",
															width: quantity.toString().length + "ch",
														},
													}}
													margin="none"
													variant="outlined"
													value={quantity}
													onChange={handleQuantity}
												/>
											</Grid>
											<Grid item>
												<Button
													sx={classes.quantity}
													variant="contained"
													onClick={() => changeQuantity("add")}
													disabled={props.item.quantity === quantity}
												>
													+
												</Button>
											</Grid>
											{quantity > props.item.quantity && (
												<Grid item xs={12}>
													<FormHelperText style={{ color: "red" }}>
														Quantity exceeds amount available (
														{props.item.quantity})
													</FormHelperText>
												</Grid>
											)}
										</>
									) : (
										<Typography
											variant="subtitle1"
											sx={{ mt: 0.5, pb: 0, mb: 0 }}
										>
											This item is out of stock
										</Typography>
									)}
								</Grid>
							</div>
						</Grid>
						<div
							style={{
								maxHeight: 170,
								height: "100%",
								overflowY: "auto",
								maxWidth: "100%",
								wordWrap: "break-word",
								wordBreak: "break-word",
								whiteSpace: "pre-line",
								marginBottom: 0,
							}}
						>
							<Typography
								component="span"
								align="center"
								color="primary"
								variant="subtitle1"
								sx={{ paddingBottom: 0, marginBottom: 0, mt: 0 }}
							>
								<span
									style={{
										fontFamily: "Oswald, sans-serif",
										fontSize: 20,
									}}
								>
									Description:{" "}
								</span>
								{props.item.description
									? props.item.description
									: "There is no description for this item"}
							</Typography>
						</div>
					</div>
				</CardContent>
				<CardActions style={{ padding: 20 }}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Button
								onClick={props.onClose}
								fullWidth
								color="primary"
								variant="outlined"
							>
								Back
							</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								onClick={() => addToCart()}
								fullWidth
								color="primary"
								variant="contained"
								disabled={
									props.item.quantity === 0 ||
									quantity > props.item.quantity ||
									quantity <= 0 ||
									props.purchaseRequesting
								}
							>
								{props.purchaseRequesting ? <ButtonLoader /> : "Add to Cart"}
							</Button>
						</Grid>
					</Grid>
				</CardActions>
			</Card>
		</div>
	);
}

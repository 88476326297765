import React from "react";
import {
	Grid,
	Typography,
	Button,
	FormControl,
	InputLabel,
	Select,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	Checkbox,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import PaymentMethods from "../../containers/PaymentMethods";
import OneTimeCardForm from "../Profile/Dialogs/OneTimePayForm";
import ButtonLoader from "../../utils/ButtonLoader";
import { formatCurrency } from "../../utils/FormatCurrency";
import styles from "../../styles";
import { renderPaymentMethods } from "../Forms/PaymentMethods";
import Title from "../Profile/Dialogs/Title";

const classes: any = {
	form: {
		backgroundColor: "#FAFAFA",
		width: "100%",
		borderBottomLeftRadius: 5,
		borderBottomRightRadius: 5,
		padding: 20,
		paddingBottom: 0,
		scrollY: "auto",
	},
	paddingList: {
		paddingLeft: { xs: 0, sm: "75px" },
		paddingRight: { xs: 0, sm: "75px" },
	},
};

export default function PayMembership(props: any) {
	const {
		onCloseAll,
		onClose,
		paymentMessage,
		billingProfiles,
		clubData,
		selected,
		productId,
		userConfig,
		credit,
		membershipDetails,
		sig1,
		sig2,
		sig3,
		paymentRequesting,
		enrollMembership,
		membership,
		credits,
	} = props;
	const [paymentMethod, setPaymentMethod] = React.useState(props.paymentMethod);
	const [oneTimePaymentType, setOneTimePaymentType] =
		React.useState<string>("CreditCard");
	const [contractPaymentMethod] = React.useState(props.paymentMethod);
	const [client] = React.useState(clubData?.clubType);
	const [checked, setChecked] = React.useState<boolean>(false);
	const [open, setOpen] = React.useState("0");
	const [oneTime, setOneTime] = React.useState(false);

	const [values, setValues] = React.useState<any>({
		amountType: "all",
		credit: credit,
		totalDue: 0,
		amount: null,
		paymentMethodType: paymentMethod,
		billingProfileId: null,
		accountNumber: "",
		routing: "",
		accountTypeFull: "",
		creditcard: null,
		nameOnCard: null,
		expiryMonth: null,
		expiryYear: null,
		securityCode: null,
		bankName: "",
		firstName: userConfig.firstName,
		lastName: userConfig.lastName,
	});
	let creditsConfig = {
		headers: {
			Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
		},
		partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
		businessAlias: userConfig ? userConfig.businessAlias : null,
		id: userConfig ? userConfig.clientId : null,
	};
	React.useEffect(() => {
		props.getCredits(creditsConfig);
		if (billingProfiles?.length == 0) {
			props.getBillingProfile(creditsConfig);
		}
	}, []);
	React.useEffect(() => {
		if (paymentMessage === "Enrolled successfully") {
			onCloseAll();
		}
	}, [paymentMessage]);
	const handleSetChecked = () => {
		setChecked(!checked);
	};
	const setPaymentProfile = () => {
		setPaymentMethod(paymentMethod);
	};
	const handlePaymentChange = (event: SelectChangeEvent) => {
		let value: any = event.target.value;
		setPaymentMethod(value as any);
	};
	const openOneTimePayment = () => {
		setOpen("2");
	};
	const openAddPayment = () => {
		setOpen("1");
	};
	const handleClose = () => {
		setOpen("0");
	};
	let oneTimePaymentModal = (
		<Dialog open={open === "2"} onClose={handleClose} sx={styles.modal}>
			<OneTimeCardForm
				{...props}
				values={values}
				setValues={setValues}
				oneTimePaymentType={oneTimePaymentType}
				setOneTimePaymentType={setOneTimePaymentType}
				setPaymentProfile={setPaymentProfile}
				setPaymentMethod={setPaymentMethod}
				oneTime={oneTime}
				setOneTime={setOneTime}
				onClose={handleClose}
			/>
		</Dialog>
	);

	let dueToday: number = membershipDetails?.tieredPricingPaymentAmounts?.length > 0 ? 
		Number(membershipDetails?.tieredPricingPaymentAmounts[selected.length - 1]?.dueTodayPrincipal 
		+ membershipDetails?.tieredPricingPaymentAmounts[selected.length - 1]?.dueTodayTax ) :
		Number(((membershipDetails?.dueTodayPrincipal + membershipDetails?.dueTodayTax) * selected.length).toFixed(2));
		
	let creditAmount: number;
	if (checked && dueToday < props.credits / 100) {
		creditAmount = Number(dueToday * 100);
	} else {
		creditAmount = Number(credits);
	}

	const signature1 = sig1?.current
		.getTrimmedCanvas()
		.toDataURL("image/png")
		.substring(22);

	const signature2 = sig2?.current
		.getTrimmedCanvas()
		.toDataURL("image/png")
		.substring(22);

	const signature3 = sig3?.current
		.getTrimmedCanvas()
		.toDataURL("image/png")
		.substring(22);

	const handlePayMembership = (e: any) => {
		e.preventDefault();
		const payConfig: any = {
			headers: {
				Authorization: `Bearer ${userConfig ? userConfig.token : null}`,
			},
			partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
			businessAlias: userConfig ? userConfig.businessAlias : null,
			clientId: userConfig ? userConfig.clientId : null,
			token: userConfig.token,
			productId: productId,
			billingProfileId:
				oneTime || (checked && dueToday < credits / 100) ? "0" : paymentMethod,
			contractBillingProfileId: contractPaymentMethod,
			credits: checked ? Math.round(creditAmount) : 0,
			amount: dueToday,
			purchasedFor: selected,
			agreementSignatures: {
				Base64PaymentConfirmSignature: signature1,
				Base64CancelConfirmSignature: signature2,
				Base64LegalConfirmSignature: signature3,

				SignaturesExtension: "png",
			},
			creditCard:
				oneTimePaymentType === "CreditCard" && oneTime
					? {
							cardNumber: values.creditcard,
							nameOnCard: values.nameOnCard,
							expiryMonth: values.expiryMonth,
							expiryYear: values.expiryYear,
							securityCode: values.securityCode,
					  }
					: null,
			eft:
				oneTimePaymentType === "Eft" && oneTime
					? {
							AccountNumber: values.accountNumber,
							TransitNumber: values.transitNumber,
							InstitutionNumber: values.institutionNumber,
							BankAccountType: values.accountTypeFull,
							NameOnAccount: values.firstName + " " + values.lastName,
					  }
					: null,
			ach:
				oneTimePaymentType === "Ach" && oneTime
					? {
							AccountNumber: values.accountNumber,
							RoutingNumber: values.routing,
							BankAccountType: values.accountTypeFull,
							NameOnAccount: values.firstName + " " + values.lastName,
					  }
					: null,
		};

		enrollMembership(payConfig);
	};
	const getPurchaseForUsers = () => {
		let i: any;
		let users: any = [];
		if (props.purchaseForUsers.length > 0) {
			for (i = 0; i < props.purchaseForUsers?.length; i++) {
				selected.forEach((user: any) => {
					if (user == props.purchaseForUsers[i].id) {
						users.push(" " + props.purchaseForUsers[i].fullName);
					}
				});
			}
		}
		return users;
	};
	let renderPayments: any = renderPaymentMethods(
		billingProfiles,
		oneTimePaymentType,
		values,
		oneTime
	);

	return (
		<>
			<Title title="Complete Purchase" onClose={onCloseAll} />
			<DialogContent>
				<DialogContentText>
					<Grid container direction="row" justifyContent="flex-start">
						<Grid container justifyContent="center">
							<Typography
								style={{ paddingTop: 10 }}
								align="center"
								variant="subtitle1"
							></Typography>
							<form className={classes.form}>
								<Typography paragraph variant="h5" align="center">
									{membership.name}
								</Typography>
								<Grid
									container
									justifyContent="flex-start"
									sx={classes.paddingList}
								>
									<Typography variant="subtitle1" align="left">
										<b>Membership Terms</b>
									</Typography>
									<Grid container justifyContent="space-between">
										<Typography variant="subtitle1">Purchase For:</Typography>
										<Typography variant="subtitle1">
											{getPurchaseForUsers().toString()}
										</Typography>
									</Grid>
									<Grid container justifyContent="space-between">
										<Typography variant="subtitle1">
											Number of Payments:
										</Typography>
										<Typography variant="subtitle1">
											{membershipDetails?.noPayments}
										</Typography>
									</Grid>
									<Grid container justifyContent="space-between">
										<Typography variant="subtitle1">Payment Amount:</Typography>
										<Typography variant="subtitle1">
											{membershipDetails?.tieredPricingPaymentAmounts?.length > 0 ? 
											formatCurrency(
												membershipDetails.tieredPricingPaymentAmounts[selected.length - 1]?.paymentAmount,
												 clubData
												 )
											:
											formatCurrency(
												membershipDetails?.paymentAmount * selected.length,
												clubData
											)} + tax
										</Typography>
									</Grid>
									<Grid container justifyContent="space-between">
										<Typography variant="subtitle1">
											Payment Frequency:
										</Typography>
										<Typography variant="subtitle1">
											{membershipDetails?.paymentFrequency}
										</Typography>
									</Grid>
									<Grid container justifyContent="space-between">
										<Typography variant="subtitle1">
											First Payment Date:
										</Typography>
										<Typography variant="subtitle1">
											{new Date(
												membershipDetails?.firstPaymentDate
											).toLocaleDateString("en-US")}
										</Typography>
									</Grid>
									<Grid container justifyContent="space-between">
										<Typography variant="subtitle1">Initiation Fee:</Typography>
										<Typography variant="subtitle1">
											{formatCurrency(
												Number(
													membershipDetails?.initiationFee * selected.length
												),
												clubData
											)}
										</Typography>
									</Grid>
								</Grid>
								<Grid
									container
									justifyContent="flex-start"
									sx={classes.paddingList}
								>
									<Typography variant="subtitle1" align="left">
										<b>Payment Details</b>
									</Typography>
									<Grid container justifyContent="space-between">
										<Typography variant="subtitle1">Subtotal:</Typography>
										<Typography variant="subtitle1">
											{membershipDetails?.tieredPricingPaymentAmounts?.length > 0 ? 
											formatCurrency(
												membershipDetails.tieredPricingPaymentAmounts[selected.length - 1]?.dueTodayPrincipal,
												 clubData
												 )
											: formatCurrency(
												Number(
													membershipDetails?.dueTodayPrincipal * selected.length
												),
												clubData
											)}
										</Typography>
									</Grid>
									<Grid container justifyContent="space-between">
										<Typography variant="subtitle1">Tax:</Typography>
										<Typography variant="subtitle1">
											{membershipDetails?.tieredPricingPaymentAmounts?.length > 0 ? 
											formatCurrency(
												membershipDetails.tieredPricingPaymentAmounts[selected.length - 1]?.dueTodayTax,
												 clubData
												 )
											:formatCurrency(
												Number(
													membershipDetails?.dueTodayTax * selected.length
												),
												clubData
											)}
										</Typography>
									</Grid>
									<Grid
										container
										justifyContent="space-between"
										style={{ borderTop: "1px solid black" }}
									>
										<Typography variant="subtitle1">
											<strong>Due Today:</strong>
										</Typography>
										<Typography variant="subtitle1">
											{formatCurrency(dueToday, clubData)}
										</Typography>
									</Grid>
									{checked && (
										<>
											<Grid container justifyContent="space-between">
												<Typography variant="subtitle1">
													Credits Applied:
												</Typography>

												<Typography variant="subtitle1" sx={{ mr: -0.6 }}>
													(
													{Number(credits) / 100 - dueToday > 0
														? formatCurrency(dueToday, clubData)
														: formatCurrency(Number(credits) / 100, clubData)}
													)
												</Typography>
											</Grid>
											{Number(props.credits) / 100 - dueToday < 0 && (
												<Grid container justifyContent="space-between">
													<Typography variant="subtitle1">
														Amount Remaining:
													</Typography>
													<Typography variant="subtitle1">
														{formatCurrency(
															dueToday - Number(credits) / 100,
															clubData
														)}
													</Typography>
												</Grid>
											)}
										</>
									)}
								</Grid>
							</form>
							{credits ? (
								<Grid
									container
									alignItems="flex-end"
									justifyContent="flex-start"
									sx={{ p: 0, pt: 1 }}
								>
									<Grid item>
										<Checkbox
											sx={{ p: 0, pr: 0.5 }}
											checked={checked}
											onChange={handleSetChecked}
											disabled={client !== "SSB" && credits / 100 < dueToday}
										/>
									</Grid>
									<Grid item>
										<Typography>
											Use Available Credit (
											{formatCurrency(credits / 100, clubData)})
											{credits / 100 < dueToday && client !== "SSB" ? (
												<Typography variant="caption">
													{" "}
													*Not enough credits
												</Typography>
											) : null}
										</Typography>
									</Grid>
								</Grid>
							) : null}
							<Grid
								container
								style={{ paddingTop: 5 }}
								direction="column"
								justifyContent="flex-end"
							>
								<FormControl margin="normal" variant="outlined">
									<InputLabel
										shrink={true}
										htmlFor="outlined-age-native-simple"
									>
										Payment Method
									</InputLabel>
									<Select
										label="Payment Method"
										native
										value={paymentMethod}
										disabled={
											(checked && (client === "FSB" || client === "FSBO")) ||
											(checked && client === "SSB" && credits / 100 > dueToday)
										}
										onChange={handlePaymentChange}
										inputProps={{
											name: "paymentMethod",
											id: "outlined-age-native-simple",
											padding: 5,
										}}
									>
										{renderPayments}
									</Select>
								</FormControl>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6}>
										<Button
											fullWidth
											onClick={openAddPayment}
											variant="outlined"
											color="primary"
										>
											Add Payment Method
										</Button>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Button
											fullWidth
											onClick={openOneTimePayment}
											variant="outlined"
											color="primary"
											disableRipple
											disabled={client === "FSB ONLY"}
										>
											One Time Payment
										</Button>
									</Grid>
								</Grid>
								{oneTimePaymentModal}
							</Grid>
							<Dialog
								PaperProps={{
									style: {
										backgroundColor: "transparent",
									},
								}}
								sx={styles.modal}
								open={open === "1"}
								onClose={handleClose}
							>
								<PaymentMethods
									handleClose={handleClose}
									{...props}
									open={true}
								/>
							</Dialog>
						</Grid>
					</Grid>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Grid
					container
					spacing={2}
					sx={{ p: 2, pt: 0 }}
					justifyContent="center"
				>
					<Grid item xs={12} sm={6}>
						<Button
							fullWidth
							variant="outlined"
							color="primary"
							onClick={onClose}
						>
							Back
						</Button>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							disabled={paymentRequesting}
							onClick={(event: any) => handlePayMembership(event)}
						>
							{paymentRequesting ? <ButtonLoader /> : "Complete Sale"}
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</>
	);
}

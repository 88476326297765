export const GET_MARKETPLACE_REQUEST = "GET_MARKETPLACE_REQUEST";
export const GET_MARKETPLACE_SUCCESS = "GET_MARKETPLACE_SUCCESS";
export const GET_MARKETPLACE_ERROR = "GET_MARKETPLACE_ERROR";

export const SAVE_CART_REQUEST = "SAVE_CART_REQUEST";
export const SAVE_CART_SUCCESS = "SAVE_CART_SUCCESS";
export const SAVE_CART_ERROR = "SAVE_CART_ERROR";

export const COMPLETE_PURCHASE_REQUEST = "COMPLETE_PURCHASE_REQUEST";
export const COMPLETE_PURCHASE_SUCCESS = "COMPLETE_PURCHASE_SUCCESS";
export const COMPLETE_PURCHASE_ERROR = "COMPLETE_PURCHASE_ERROR";

export const APPLY_PROMO_REQUEST = "APPLY_PROMO_REQUEST";
export const APPLY_PROMO_SUCCESS = "APPLY_PROMO_SUCCESS";
export const APPLY_PROMO_ERROR = "APPLY_PROMO_ERROR";

export const REMOVE_PROMO_SUCCESS = "REMOVE_PROMO_SUCCESS";

export const ADD_ITEM_REQUEST = "ADD_ITEM_REQUEST";
export const ADD_ITEM_SUCCESS = "ADD_ITEM_SUCCESS";
export const ADD_ITEM_ERROR = "ADD_ITEM_ERROR";

export const REMOVE_ITEM_REQUEST = "REMOVE_ITEM_REQUEST";
export const REMOVE_ITEM_SUCCESS = "REMOVE_ITEM_SUCCESS";
export const REMOVE_ITEM_ERROR = "REMOVE_ITEM_ERROR";

export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_ERROR = "GET_CART_ERROR";

export const SET_MARKETPLACE_SUCCESS = "SET_MARKETPLACE_SUCCESS";
export const CLEAR_MARKETPLACE_ERRORS = "CLEAR_MARKETPLACE_ERRORS";

import axios from "axios";
import * as URL from "../config";

export function requestGetDashboardAlerts(action: any) {
	const getDashboardAlertsUrl = `${URL.MP_MEMBER}/clients/alerts?Id=${action.id}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}`;
	return axios
		.get(getDashboardAlertsUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestGetDashboardData(action: any) {
	const getDashboardDataUrl = `${URL.MP_MEMBER}/clients/dashboard?Id=${action.id}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}&scheduleStartDate=${action.startDate}&scheduleEndDate=${action.endDate}`;
	return axios
		.get(getDashboardDataUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

import * as React from "react";
import { Fragment } from "react";
import { PasswordMeter, IResult } from "password-meter";
import { Typography, Grid, Grow } from "@mui/material";

const barStyles = {
	height: "100%",
	margin: "0 1px",
	padding: "0 3px",
	borderRadius: "2px",
};

const meter = new PasswordMeter(
	{},
	{
		"80": "Weak", // 001 <= x <  80
		"160": "Medium", // 80  <= x <  120
		_: "Strong", //        x >= 120
	}
);

interface PasswordMeterProps {
	value: string;
}
const classes: any = {
	bar_wrapper: {
		backgroundColor: "#CED3D9",
		padding: "4px",
		height: "24px",
		width: "160px",
		marginLeft: "8px",
		borderRadius: "2px",
	},
	bar: {
		...barStyles,
	},
	text: {
		color: "primary.contrastText",
		fontWeight: "normal",
	},
	label: {
		fontSize: ".75rem",
	},
};
const emptyBlock = (classes: any, color?: string) => (
	<Grid item xs sx={{ ...barStyles, backgroundColor: color }}>
		<Typography> </Typography>
	</Grid>
);
const currentBlock = (
	classes: any,
	color: string,
	status: string,
	labelStyle: any
) => {
	return (
		<Grow in={true} timeout={500}>
			<Grid item xs sx={{ ...barStyles, backgroundColor: color }}>
				<Typography align="center" sx={classes.label} style={labelStyle}>
					{status}
				</Typography>
			</Grid>
		</Grow>
	);
};
const renderBars = (status: string, classes: any) => {
	let color = "";
	if (status === "Weak") {
		color = "red";
	}
	if (status === "Medium") {
		color = "yellow";
	}
	if (status === "Strong") {
		color = "green";
	}
	const labelStyle = { color: status === "Medium" ? "black" : "white" };

	switch (status) {
		case "Weak":
			return (
				<Fragment>
					{currentBlock(classes, color, status, labelStyle)}
					{emptyBlock(classes)}
					{emptyBlock(classes)}
				</Fragment>
			);

		case "Medium":
			return (
				<Fragment>
					{emptyBlock(classes, color)}
					{currentBlock(classes, color, status, labelStyle)}
					{emptyBlock(classes)}
				</Fragment>
			);

		case "Strong":
			return (
				<Fragment>
					{emptyBlock(classes, color)}
					{emptyBlock(classes, color)}
					{currentBlock(classes, color, status, labelStyle)}
				</Fragment>
			);

		default:
			return null;
	}
};
const PasswordMeterComponent: React.FunctionComponent<PasswordMeterProps> = (
	props
) => {
	const result: IResult = meter.getResult(props.value);
	return (
		<Fragment>
			<Grid container>
				<Grid item xs>
					<Typography align="center" variant="body2">
						Password Strength:
					</Typography>
				</Grid>
				<Grid item xs>
					<Grid container sx={classes.bar_wrapper} alignItems="stretch">
						{result.status !== "Empty"
							? renderBars(result.status, classes)
							: null}
					</Grid>
				</Grid>
			</Grid>
		</Fragment>
	);
};
export default PasswordMeterComponent;

import * as constants from "../constants/marketplace.constants";

const initialState = {
	requesting: false,
	successful: false,
	getMarketplaceSuccessful: false,
	getMarketplaceRequesting: false,
	messages: null,
	errors: null,
	marketplace: null,
	cart: null,
	promo: null,
	purchaseSuccess: false,
	purchaseMessage: null,
};

function marketplaceReducer(state = initialState, action: any) {
	switch (action.type) {
		case constants.COMPLETE_PURCHASE_REQUEST:
			return {
				...state,
				purchaseMessage: null,
				purchaseSuccess: false,
				requesting: true,
			};
		case constants.GET_MARKETPLACE_REQUEST:
			return {
				...state,
				getMarketplaceRequesting: true,
				getMarketplaceSuccessful: false,
			};
		case constants.SET_MARKETPLACE_SUCCESS:
			return {
				...state,
				successful: false,
			};
		case constants.APPLY_PROMO_REQUEST:
			return {
				...state,
				promo: null,
			};
		case constants.GET_MARKETPLACE_SUCCESS:
			return {
				...state,
				getMarketplaceRequesting: false,
				getMarketplaceSuccessful: true,
				errors: null,
				marketplace: action.response.data,
			};
		case constants.SAVE_CART_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: false,
				cart: action.response?.data?.cartItems,
			};
		case constants.ADD_ITEM_REQUEST:
			return {
				...state,
				requesting: true,
				successful: false,
			};
		case constants.ADD_ITEM_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				messages: "Item(s) added to cart",
				cart: action.response?.data?.cartItems,
			};
		case constants.REMOVE_PROMO_SUCCESS:
			return {
				...state,
				promo: null,
			};
		case constants.REMOVE_ITEM_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: false,
				cart: action.response.data.cartItems,
			};
		case constants.GET_CART_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: false,
				errors: null,
				cart: action.response.data.cartItems,
				promo: null,
			};
		case constants.APPLY_PROMO_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: false,
				errors: null,
				cart: action.response.data.cartItems,
				promo: action.response.data.promotionInfo.promoValidationResult,
			};
		case constants.COMPLETE_PURCHASE_SUCCESS:
			return {
				...state,
				purchaseMessage: "Your purchase was successfully made",
				purchaseSuccess: action.response.data.isApproved,
				requesting: false,
				promo: null,
			};
		case constants.COMPLETE_PURCHASE_ERROR:
		case constants.ADD_ITEM_ERROR:
		case constants.SAVE_CART_ERROR:
		case constants.APPLY_PROMO_ERROR:
		case constants.GET_CART_ERROR:
			return {
				...state,
				requesting: false,
				successful: false,
				errors:
					action.error?.response?.data?.error ||
					"There was an unexpected error",
			};
		case constants.GET_MARKETPLACE_ERROR:
			return {
				...state,
				getMarketplaceRequesting: false,
				getMarketplaceSuccessful: false,
				errors:
					action.error?.response?.data?.error ||
					"There was an unexpected error",
			};
		case constants.CLEAR_MARKETPLACE_ERRORS:
			return {
				...state,
				errors: null,
				messages: null,
				purchaseMessage: null,
			};
		default:
			return state;
	}
}
export default marketplaceReducer;

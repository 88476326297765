import * as constants from "../constants/billingprofiles.constants";

const initialState = {
	requesting: false,
	successful: false,
	messages: null,
	errors: null,
	billingProfiles: [],
	clubData: "",
	defaultId: null,
};

function billingProfileReducer(state = initialState, action: any) {
	switch (action.type) {
		case constants.ACCEPTED_CARDS_REQUEST:
		case constants.BILLING_PROFILE_REQUEST:
			return {
				...state,
				requesting: true,
				successful: false,
			};
		case constants.BILLING_PROFILE_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				billingProfiles: action.response.data,
			};
		case constants.CREATE_BILLING_PROFILE_REQUEST:
			return {
				...state,
				requesting: true,
				successful: false,
				defaultId: null,
			};
		case constants.CREATE_BILLING_PROFILE_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages: "Payment method successfully added",
				defaultId: action.response.data.id,
			};
		case constants.ACCEPTED_CARDS_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				clubData: action.response.data,
			};

		case constants.DEFAULT_BILLING_PROFILE_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
			};
		case constants.UPDATE_BILLING_PROFILE_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages: "Payment method successfully updated",
			};
		case constants.DELETE_BILLING_PROFILE_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages: "Payment method deleted",
			};
		case constants.CLEAR_BILLING_MESSAGE:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages: null,
			};

		case constants.ACCEPTED_CARDS_ERROR:
		case constants.DEFAULT_BILLING_PROFILE_ERROR:
		case constants.DELETE_BILLING_PROFILE_ERROR:
		case constants.UPDATE_BILLING_PROFILE_ERROR:
		case constants.CREATE_BILLING_PROFILE_ERROR:
			console.log("error");
			let error = action.error.response?.data.title
				? action.error.response?.data.title
				: action.error.response?.data.error;
			return {
				...state,
				requesting: false,
				successful: false,
				errors: error,
			};
		default:
			return state;
	}
}

export interface IState {
	requesting: boolean;
	successful: boolean;
	messages: string | null;
	errors: string | null;
	billingProfiles: any | null;
}
export default billingProfileReducer;

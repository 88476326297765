import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = (props: any) => {
	const alias: any = window.location.pathname.split("/")[1];
	const params: any = window.location.pathname.split("/")[3];
	const schedule: any = window.location.pathname.split("/")[2];
	let user = useSelector((state: any) => state.auth.currentUser);
	if (params && schedule === "schedule") {
		localStorage.setItem("path", params);
	}
	return user ? <Route {...props} /> : <Redirect to={`/${alias}/signin`} />;
};

export default PrivateRoute;

import { Route, Switch, withRouter } from "react-router-dom";
import SignIn from "./components/SignIn/SignIn";
import SignUp from "./containers/SignUp";
import Dashboard from "./containers/Dashboard";
import ForgotPassword from "./components/SignIn/ForgotPassword";
import ForgotUsername from "./components/SignIn/ForgotUsername";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import SetPassword from "./components/ResetPassword/SetPassword";
import MarketPlace from "./containers/Marketplace";
import Complete from "./components/MarketPlace/CompletePurchase";
import Schedule from "./containers/Schedules";
import Profile from "./containers/Profile";
import ChangePassword from "./components/ResetPassword/ChangePassword";
import Enroll from "./containers/Enroll";
import ErrorPage from "./components/AlertMessage/ErrorPage";
import PrivateRoute from "./hoc/PrivateRoute";
import OpenRoute from "./hoc/OpenRoute";

const Routes = () => {
	const pathList: any = [
		"dashboard",
		"schedule",
		"marketplace",
		"profile",
		"signin",
		"signup",
		"forgot",
		"password",
	];
	return (
		<div>
			<Switch>
				<Route exact path="/:id/" component={SignIn} />
				<Route exact path="/:id/signin" component={SignIn} />
				<OpenRoute exact path="/:id/signup" component={SignUp} />
				<OpenRoute
					exact
					path="/:id/forgot/password"
					component={ForgotPassword}
				/>
				<OpenRoute
					exact
					path="/:id/forgot/username"
					component={ForgotUsername}
				/>
				<OpenRoute exact path="/:id/resetpassword" component={ResetPassword} />
				<OpenRoute
					exact
					path="/:id/changepassword"
					component={ChangePassword}
				/>
				<OpenRoute exact path="/:id/setpassword" component={SetPassword} />
				<PrivateRoute exact path="/:id/dashboard" component={Dashboard} />
				<PrivateRoute path="/:id/schedule" component={Schedule} />
				<PrivateRoute exact path="/:id/marketplace" component={MarketPlace} />
				<PrivateRoute
					exact
					path="/:id/marketplace/cart/complete"
					component={Complete}
				/>
				<PrivateRoute exact path="/:id/enroll" component={Enroll} />
				<PrivateRoute path="/:id/profile" component={Profile} />
				<Route
					path="/:id/:path"
					component={(props: any) => {
						let path: any = props.match.params.path;
						if (pathList?.includes(path)) {
							return null;
						}
						return <ErrorPage />;
					}}
				/>
				<Route exact path="/">
					<ErrorPage />
				</Route>
			</Switch>
		</div>
	);
};

export default withRouter(Routes);

export const GET_USER_SCHEDULE_REQUEST = "GET_USER_SCHEDULE_REQUEST";
export const GET_USER_SCHEDULE_SUCCESS = "GET_USER_SCHEDULE_SUCCESS";
export const GET_USER_SCHEDULE_ERROR = "GET_USER_SCHEDULE_ERROR";

export const GET_CANCEL_REASONS_REQUEST = "GET_CANCEL_REASONS_REQUEST";
export const GET_CANCEL_REASONS_SUCCESS = "GET_CANCEL_REASONS_SUCCESS";
export const GET_CANCEL_REASONS_ERROR = "GET_CANCEL_REASONS_ERROR";

export const GET_GYM_SCHEDULE_REQUEST = "GET_GYM_SCHEDULE_REQUEST";
export const GET_GYM_SCHEDULE_SUCCESS = "GET_GYM_SCHEDULE_SUCCESS";
export const GET_GYM_SCHEDULE_ERROR = "GET_GYM_SCHEDULE_ERROR";

export const GET_SCHEDULE_NAMES_REQUEST = "GET_SCHEDULE_NAMES_REQUEST";
export const GET_SCHEDULE_NAMES_SUCCESS = "GET_SCHEDULE_NAMES_SUCCESS";
export const GET_SCHEDULE_NAMES_ERROR = "GET_SCHEDULE_NAMES_ERROR";

export const GET_RECURRING_SCHEDULE_REQUEST = "GET_RECURRING_SCHEDULE_REQUEST";
export const GET_RECURRING_SCHEDULE_SUCCESS = "GET_RECURRING_SCHEDULE_SUCCESS";
export const GET_RECURRING_SCHEDULE_ERROR = "GET_RECURRING_SCHEDULE_ERROR";

export const GET_CLUB_DETAILS_REQUEST = "GET_CLUB_DETAILS_REQUEST";
export const GET_CLUB_DETAILS_SUCCESS = "GET_CLUB_DETAILS_SUCCESS";
export const GET_CLUB_DETAILS_ERROR = "GET_CLUB_DETAILS_ERROR";

export const GET_RELATED_CLASSES_REQUEST = "GET_RELATED_CLASSES_REQUEST";
export const GET_RELATED_CLASSES_SUCCESS = "GET_RELATED_CLASSES_SUCCESS";
export const GET_RELATED_CLASSES_ERROR = "GET_RELATED_CLASSES_ERROR";

export const CLOSE_PAY_BOOK = "CLOSE_PAY_BOOK";

export const GET_SCHEDULE_FILTERS_REQUEST = "GET_SCHEDULE_FILTERS_REQUEST";
export const GET_SCHEDULE_FILTERS_SUCCESS = "GET_SCHEDULE_FILTERS_SUCCESS";
export const GET_SCHEDULE_FILTERS_ERROR = "GET_SCHEDULE_FILTERS_ERROR";

export const BOOK_CLASS_REQUEST = "BOOK_CLASS_REQUEST";
export const BOOK_CLASS_SUCCESS = "BOOK_CLASS_SUCCESS";
export const BOOK_CLASS_ERROR = "BOOK_CLASS_ERROR";

export const CLEAR_CANCEL_CLASS_SUCCESS = "CLEAR_CANCEL_CLASS_SUCCESS";

export const CANCEL_CLASS_REQUEST = "CANCEL_CLASS_REQUEST";
export const CANCEL_CLASS_SUCCESS = "CANCEL_CLASS_SUCCESS";
export const CANCEL_CLASS_ERROR = "CANCEL_CLASS_ERROR";

export const CLEAR_SCHEDULE_ERROR = "CLEAR_SCHEDULE_ERROR";

export const PAY_BOOKING_REQUEST = "PAY_BOOKING_REQUEST";
export const PAY_BOOKING_SUCCESS = "PAY_BOOKING_SUCCESS";
export const PAY_BOOKING_ERROR = "PAY_BOOKING_ERROR";

import React from "react";
import {
	Button,
	Card,
	CardContent,
	CardActions,
	Typography,
	Dialog,
	useMediaQuery,
	Tooltip
} from "@mui/material";
import Summary from "./Summary";
import { AddressValidation } from "./AddressPrompt";
import { formatCurrency } from "../../utils/FormatCurrency";
import styles from "../../styles";
import { useTheme } from "@mui/material/styles";

export default function TransitionsModal(props: any) {
	let {
		config,
		onClose,
		membership,
		clubData,
		acceptedCards,
		defaultImage,
		userInfo,
	} = props;
	const [open, setOpen] = React.useState(0);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	const handleClose = () => {
		setOpen(0);
	};
	React.useEffect(() => {
		if (!userInfo) {
			props.getProfileInfo(props.config);
		}
	}, [userInfo]);

	const handleOpen = () => {
		if (membership?.isFsbContract) {
			if (
				!userInfo?.address ||
				!userInfo?.city ||
				!userInfo?.country ||
				(userInfo?.country && userInfo.country !== "US") ||
				(userInfo?.country && userInfo.country !== "CA") ||
				!userInfo?.state ||
				!userInfo?.zip ||
				!userInfo?.email ||
				(!userInfo?.cellPhone && !userInfo?.homePhone && !userInfo?.workPhone)
			) {
				setOpen(1);
			} else {
				setOpen(2);
			}
		} else {
			setOpen(2);
		}
	};
	return (
		<div>
			{open === 0 && (
				<Card>
					<CardContent style={{ padding: 0 }}>
						<img
							src={membership.imageUrl ? membership.imageUrl : defaultImage}
							alt={membership.name}
							width={"100%"}
						/>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								padding: 10,
							}}
						>
							<Typography color="primary" variant="h5">
								{membership.name}
							</Typography>
							<Typography color="primary" variant="h5">
							<div style={{display: "inline-block"}}>
							{membership.proratedAmount > 0 ?
								<>
									<span style={{textDecoration: "line-through", color: "red"}}>{formatCurrency(membership.amount, clubData)}</span>
									<span>{formatCurrency(membership.proratedAmount, clubData)}</span>
								</>
								: formatCurrency(membership.amount, clubData)}
								{membership.isTieredPricing && <Tooltip arrow placement="top" title="Tiered Pricing Contract">
								<Button style={{ padding: 2, height: 2, minWidth: 2 }}>T</Button>
								</Tooltip>}
								{membership.proratedAmount > 0 && <Tooltip arrow placement="top" title="Prorated Contract">
								<Button style={{ padding: 2, maxHeight: 15, minWidth: 2 }}>P</Button>
								</Tooltip>}
								</div>
							</Typography>	
						</div>
						<div style={{ padding: 20 }}>
							<Typography
								component="span"
								align="center"
								color="primary"
								variant="subtitle1"
							>
								{membership.description
									? membership.description
									: "There is no description for this membership"}
							</Typography>
						</div>
					</CardContent>
					<CardActions>
						<Button
							onClick={() => props.onClose()}
							fullWidth
							color="primary"
							variant="outlined"
						>
							Back
						</Button>
						<Button
							onClick={handleOpen}
							fullWidth
							color="primary"
							variant="contained"
						>
							Join Now
						</Button>
					</CardActions>
				</Card>
			)}
			<Dialog
				sx={styles.modal}
				open={open === 2}
				onClose={handleClose}
				fullScreen={matches ? true : false}
				fullWidth={!matches ? true : false}
			>
				<Summary
					{...props}
					config={config}
					acceptedCards={acceptedCards}
					id={membership.productId}
					onClose={handleClose}
					onCloseAll={onClose}
					matches={matches}
				/>
			</Dialog>
			<Dialog
				sx={styles.modal}
				style={{ minHeight: "100%" }}
				maxWidth="sm"
				open={open == 1}
				onClose={handleClose}
			>
				<AddressValidation
					{...props}
					config={props.config}
					handleOpen={() => setOpen(2)}
					onClose={handleClose}
				/>
			</Dialog>
		</div>
	);
}

import React from "react";
import {
	Grid,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	Button,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import moment from "moment";

const classes: any = {
	root: { width: "100%" },
	schedule: {
		color: "white",
		borderRadius: "5px",
		justifyContent: "flex-end",
	},
	select: {
		"& .MuiOutlinedInput-input": {
			backgroundColor: "white",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
			backgroundColor: "white",
			borderRadius: "5px",
		},
	},
};

const FilterTypes = (props: any) => {
	const {
		userConfig,
		scheduleFilters,
		scheduleId,
		scheduleNames,
		setAppointmentId,
		setAppointmentType,
		setStaffId,
	} = props;
	const [appointment, setAppointment] = React.useState("0");
	const [location, setLocation] = React.useState("0");
	const [staff, setStaff] = React.useState("0");
	const [scheduleType, setScheduleType] = React.useState<any>();
	const [endDate] = React.useState(moment().add(30, "days"));
	const path: any = window.location.pathname.split("/")[3];
	const config: any = {
		businessAlias: userConfig.businessAlias,
		clientId: userConfig.clientId,
		startDate: new Date().toISOString().slice(0, 10),
		endDate: endDate.toISOString().slice(0, 10),
	};
	React.useEffect(() => {
		if (path) {
			const tabId = path.substring(path.indexOf("=") + 1);
			setScheduleType(tabId);
		} else {
			let selected = scheduleNames?.find(
				(schedule: any) => schedule.id === scheduleId
			);
			setScheduleType(selected?.type);
		}
	}, [scheduleId]);
	React.useEffect(() => {
		if (scheduleType && scheduleType != 4) {
			config.tabId = scheduleId;
			props.getGymSchedule(config);
		}
	}, [scheduleType]);
	React.useEffect(() => {
		if (props.messages == "Class was successfully booked") {
			if (scheduleType == 4) {
				config.trainer = Number(staff);
				config.appointmentType = appointment;
				config.tabId = scheduleId;
				props.getGymSchedule(config);
			}
		}
	}, [props.messages]);
	React.useEffect(() => {
		if (scheduleType && scheduleType == 4) {
			if (appointment != "0") {
				config.trainer = Number(staff);
				config.appointmentType = appointment;
				config.tabId = scheduleId;
				props.getGymSchedule(config);
			}
		}
	}, [scheduleType]);
	React.useEffect(() => {
		let appointmentTypeName = scheduleFilters?.appointmentTypes?.find(
			(appointments: any) => {
				return appointments.appointmentTypeID === appointment;
			}
		);
		if (appointmentTypeName) {
			setAppointmentType(appointmentTypeName?.name);
		}
	}, [appointment]);
	React.useEffect(() => {
		if (scheduleFilters?.appointmentTypes) {
			setAppointment(scheduleFilters?.appointmentTypes[0]?.appointmentTypeID);
			setAppointmentId(scheduleFilters?.appointmentTypes[0]?.appointmentTypeID);
		}
	}, [scheduleFilters?.appointmentTypes]);
	React.useEffect(() => {
		if (scheduleFilters?.trainers) {
			setStaff(scheduleFilters?.trainers[0]?.id);
			setStaffId(Number(scheduleFilters?.trainers[0]?.id));
		}
	}, [scheduleFilters?.trainers]);
	const handleAppointmentChange = (event: SelectChangeEvent) => {
		setAppointment(event.target.value);
		setAppointmentId(event.target.value);
	};
	const handleStaffChange = (event: SelectChangeEvent) => {
		setStaff(event.target.value);
		setStaffId(Number(event.target.value));
	};
	const handleLocationChange = (event: SelectChangeEvent) => {
		setLocation(event.target.value);
	};
	const handleSearch = () => {
		if (staff != "0") {
			config.trainer = staff;
		}
		if (location != "0") {
			config.location = location;
		}
		if (appointment != "0") {
			config.appointmentType = appointment;
		}
		config.tabId = scheduleId;
		props.getGymSchedule(config);
	};
	return (
		<div style={classes.root}>
			<Grid
				container
				justifyContent="space-between"
				alignContent="center"
				direction="row"
				sx={{ px: 1 }}
				columnSpacing={1}
			>
				<Grid item xs={6} sm={3}>
					<FormControl
						sx={classes.schedule}
						margin="dense"
						variant="outlined"
						color="primary"
						fullWidth
					>
						<InputLabel>Staff</InputLabel>
						<Select
							sx={classes.select}
							label="staff"
							size="small"
							inputProps={{
								name: "schedule",
								id: "outlined-age-native-simple",
								padding: 5,
								background: "white",
							}}
							value={staff}
							onChange={handleStaffChange}
						>
							{scheduleFilters?.trainers?.map((trainer: any) => (
								<MenuItem key={trainer.id} value={trainer.id}>
									{trainer.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={6} sm={3}>
					<FormControl
						sx={classes.schedule}
						fullWidth
						margin="dense"
						variant="outlined"
						color="primary"
					>
						<InputLabel>Appointment Type </InputLabel>
						<Select
							sx={classes.select}
							label={"appointment Type"}
							size="small"
							inputProps={{
								name: "schedule",
								id: "outlined-age-native-simple",
								padding: 5,
								background: "white",
							}}
							value={appointment}
							onChange={handleAppointmentChange}
						>
							{scheduleFilters?.appointmentTypes?.map(
								(appointmentType: any) => (
									<MenuItem
										key={appointmentType.appointmentTypeID}
										value={appointmentType.appointmentTypeID}
									>
										{appointmentType.name}
									</MenuItem>
								)
							)}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={6} sm={3}>
					<FormControl
						sx={classes.schedule}
						fullWidth
						margin="dense"
						variant="outlined"
						color="primary"
					>
						<InputLabel>Location</InputLabel>
						<Select
							sx={classes.select}
							label={"location"}
							size="small"
							inputProps={{
								name: "schedule",
								id: "outlined-age-native-simple",
								padding: 5,
								background: "white",
							}}
							value={location}
							onChange={handleLocationChange}
						>
							{scheduleFilters?.locations?.map((location: any) => (
								<MenuItem key={location.id} value={location.id}>
									{location.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={6} sm={2}>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={handleSearch}
						sx={{
							mt: 1,
							mb: 1,
						}}
					>
						Search
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};
export default FilterTypes;

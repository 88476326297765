import { call, put, takeEvery } from "redux-saga/effects";
import * as constants from "../constants/billingprofiles.constants";
import {
	requestBillingProfile,
	requestCreateBillingProfile,
	requestUpdateBillingProfile,
	requestDeleteBillingProfile,
	requestDefaultBillingProfile,
	requestGetAcceptedCards,
} from "../services/billingprofiles.service";
import { ResponseGenerator } from "../types";

export function* getBillingProfile(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestBillingProfile, action.value);
		if (response) {
			yield put({ type: constants.BILLING_PROFILE_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.BILLING_PROFILE_ERROR, error });
	}
}

export function* createBillingProfile(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestCreateBillingProfile, action.value);
		if (response) {
			yield put({ type: constants.CREATE_BILLING_PROFILE_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.CREATE_BILLING_PROFILE_ERROR, error });
	}
}

export function* updateBillingProfile(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestUpdateBillingProfile, action.value);
		if (response) {
			yield put({ type: constants.UPDATE_BILLING_PROFILE_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.UPDATE_BILLING_PROFILE_ERROR, error });
	}
}

export function* deleteBillingProfile(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestDeleteBillingProfile, action.value);
		if (response) {
			yield put({ type: constants.DELETE_BILLING_PROFILE_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.DELETE_BILLING_PROFILE_ERROR, error });
	}
}

export function* defaultBillingProfile(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestDefaultBillingProfile, action.value);
		if (response) {
			yield put({ type: constants.DEFAULT_BILLING_PROFILE_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.DEFAULT_BILLING_PROFILE_ERROR, error });
	}
}

export function* getAcceptedCards(action: any) {
	let response: ResponseGenerator;
	try {
		response = yield call(requestGetAcceptedCards, action.value);
		if (response) {
			yield put({ type: constants.ACCEPTED_CARDS_SUCCESS, response });
		}
	} catch (error) {
		yield put({ type: constants.ACCEPTED_CARDS_ERROR, error });
	}
}
export function* watchBillingProfileRequest() {
	yield takeEvery(constants.BILLING_PROFILE_REQUEST, getBillingProfile);
	yield takeEvery(
		constants.CREATE_BILLING_PROFILE_REQUEST,
		createBillingProfile
	);
	yield takeEvery(
		constants.UPDATE_BILLING_PROFILE_REQUEST,
		updateBillingProfile
	);
	yield takeEvery(
		constants.DELETE_BILLING_PROFILE_REQUEST,
		deleteBillingProfile
	);
	yield takeEvery(
		constants.DEFAULT_BILLING_PROFILE_REQUEST,
		defaultBillingProfile
	);
	yield takeEvery(constants.ACCEPTED_CARDS_REQUEST, getAcceptedCards);
}

import * as React from "react";
import {
	Typography,
	Divider,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterTypes from "./FilterTypes";

const MobileFilterTypes = (props: any) => {
	const { scheduleName } = props;
	const [expanded, setExpanded] = React.useState<boolean>(false);
	const handleChange = () => {
		setExpanded(!expanded);
	};
	React.useEffect(() => {
		if (scheduleName == 1) {
			setExpanded(true);
		}
	}, [scheduleName]);
	return (
		<div style={{ margin: 0 }}>
			<Accordion
				square={true}
				defaultExpanded={false}
				onChange={handleChange}
				expanded={expanded}
				sx={{
					minHeight: 40,
					borderRadius: 0,
					boxShadow: "none",
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
					sx={{
						"&.Mui-expanded": {
							minHeight: 40,
							maxHeight: 40,
							borderRadius: 0,
						},
					}}
				>
					<Typography variant="subtitle2">Search Filters</Typography>
					<Divider />
				</AccordionSummary>
				<AccordionDetails sx={{ p: 0, pb: 1 }}>
					<FilterTypes {...props} />
				</AccordionDetails>
			</Accordion>
		</div>
	);
};
export default MobileFilterTypes;

export const MP_VERSION = "qa0.0.0";
export const MP_VERSION_API = "/api/v1";
const params = process.env;
const env = window.location.origin;
export const MP_ENV = env === "https://mp.membersolutions.com" ? "PROD" : "DEV";

const AUTH_URL =
	MP_ENV === "PROD"
		? "https://mp-user.membersolutions.com"
		: "https://mp-qa-user.membersolutions.com";
const MEMBER_URL =
	MP_ENV === "PROD"
		? "https://mp-member.membersolutions.com"
		: "https://mp-qa-member.membersolutions.com";
const CLIENT_URL =
	MP_ENV === "PROD"
		? "https://mp-mmclient.membersolutions.com"
		: "https://mp-qa-mmclient.membersolutions.com";
const BILLING_URL =
	MP_ENV === "PROD"
		? "https://mp-billing.membersolutions.com"
		: "https://mp-qa-billing.membersolutions.com";
const SCHEDULE_URL =
	MP_ENV === "PROD"
		? "https://api.myvolo.com/api/service"
		: "https://qa-voloapi.membersolutions.com/api/service";
const API_KEY =
	MP_ENV === "PROD"
		? params.REACT_APP_SCHEDULE_KEY_PROD
		: params.REACT_APP_SCHEDULE_KEY_QA;
export const MP_AUTH = `${AUTH_URL}${MP_VERSION_API}`;
export const MP_USER = `${AUTH_URL}${MP_VERSION_API}`;
export const MP_MEMBER = `${MEMBER_URL}${MP_VERSION_API}`;
export const MP_CLIENT = `${CLIENT_URL}${MP_VERSION_API}`;
export const MP_BILLING = `${BILLING_URL}${MP_VERSION_API}`;
export const MP_SCHEDULE = SCHEDULE_URL;
export const apiKey = API_KEY;
export const RECAPTCHA_SITE_KEY = params.REACT_APP_RECAPTCHA;
export const ANALYTICS_ID: any = params.REACT_APP_ANALYTICS_ID;

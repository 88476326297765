import React, { useEffect } from "react";
import {
	FormControlLabel,
	Checkbox,
	Grid,
	Paper,
	Link,
	FormHelperText,
	Typography,
} from "@mui/material";
import Questionnaire from "./Questionnaires";
import Loader from "../../utils/Loader";
import { validateCaptcha } from "./Validation";
import Captcha from "./Captcha";

export default function Step3(props: any) {
	const [checked, setChecked] = React.useState(props.values.privacyPolicy);
	const [errorsCaptcha, setErrorsCaptcha] = React.useState<any>({});
	let questions: any = [];
	let data = props.data;
	useEffect(() => {
		const config = {
			businessAlias: window.location.pathname.split("/")[1],
			partnerApiKey: "mm",
		};
		props.requestQs(config);
	}, []);
	const onCheck = () => {
		props.handleBoolean("privacyPolicy", !checked);
		checked ? setChecked(false) : setChecked(true);
	};
	const setQuestions = () => {
		let i: any;
		if (props.data) {
			for (i = 0; data.length > i; i++) {
				questions[i] = data[i].questions.map((question: any) => question);
			}
		}
	};
	const renderQuestionnaires = () => {
		let i: any = 0;
		let questionnaires = [];
		for (i = 0; i < questions.length; i++) {
			let questionnaire = (
				<React.Fragment key={props.data[i].id}>
					<Questionnaire
						submitQs={props.submitQs}
						submitted={props.submitted}
						questions={questions[i]}
						data={props.data}
						name={props.data[i].name}
						id={props.data[i].id}
						handleQuestionnaire={props.handleQuestionnaire}
						markQs={props.markQs}
						setErrors={props.setErrors}
					/>
					<Grid container justifyContent="center">
						<FormHelperText style={{ width: "100%", textAlign: "center" }}>
							{props.data[i] && props.submitted.includes(props.data[i].id)
								? ""
								: "Questionnaire must be submitted"}
						</FormHelperText>
					</Grid>
				</React.Fragment>
			);
			questionnaires.push(questionnaire);
		}
		return questionnaires;
	};

	function handleCaptcha(token: any) {
		props.handleBoolean("captcha", token);
		const isValid = validateCaptcha({
			token: token,
		});
		setErrorsCaptcha({ ...isValid });
	}
	setQuestions();
	return (
		<Grid>
			{props.requesting ? (
				<Loader messages={props.messages} />
			) : (
				<>
					{props.data?.length > 0 ? (
						<Paper
							style={{
								maxHeight: 250,
								overflow: "auto",
								padding: 20,
								margin: 20,
							}}
						>
							<Typography align="center">
								Please fill out the following health questionnaires:
							</Typography>
							{renderQuestionnaires()}
							<Grid container justifyContent="center">
								<FormHelperText
									error={props.errors.questionnaires ? true : false}
								>
									{props.errors.questionnaires
										? props.errors.questionnaires
										: ""}
								</FormHelperText>
							</Grid>
						</Paper>
					) : (
						""
					)}
					<Paper
						style={{
							maxHeight: 200,
							overflow: "auto",
							padding: 20,
							margin: 20,
						}}
					>
						<FormControlLabel
							control={
								<Checkbox
									onChange={onCheck}
									defaultChecked={props.values.privacyPolicy}
									value={props.values.privacyPolicy}
									color="secondary"
								/>
							}
							label={
								<Typography variant="subtitle2">
									I have read and agree to the Member Solutions
									<Link
										target="_blank"
										href="https://membersolutions.com/privacy-policy/"
										color="secondary"
									>
										Privacy Policy
									</Link>
								</Typography>
							}
							color="secondary"
						/>
						<Grid container justifyContent="center">
							<FormHelperText error={props.errors.privacyPolicy ? true : false}>
								{checked ? "" : props.errors.privacyPolicy}
							</FormHelperText>
						</Grid>
					</Paper>

					<Captcha error={errorsCaptcha} handleCaptcha={handleCaptcha} />
				</>
			)}
		</Grid>
	);
}

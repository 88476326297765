import React from "react";
import {
	TextField,
	FormControlLabel,
	Typography,
	Grid,
	Button,
	Switch,
	InputAdornment,
	Paper,
	DialogContent,
	DialogContentText,
	DialogActions,
	Dialog,
} from "@mui/material";
import { TextInput, AccountType } from "../../Forms/Inputs";
import {
	bankAccountValidation,
	bankAccountNumberValidation,
} from "./validation";
import { BillingAddressForm } from "./AddressForm";
import styles from "../../../styles";
import Title from "../Dialogs/Title";

export default function BankForm(props: any) {
	const { userConfig, profileInfo, userInfo, messages } = props;
	const [edit, setEdit] = React.useState<any>(false);
	const [state, setState] = React.useState({
		default: profileInfo?.isDefaultProfile ? true : false,
	});
	const [accountError, setAccountError] = React.useState<any>({});
	const [errors, setErrors] = React.useState<any>({});
	const [values, setValues] = React.useState({
		accountNumber: "",
		routing: "",
		accountTypeFull: "",
		address: "",
		city: "",
		zip: "",
		state: "",
		country: "",
		firstName: userInfo.firstName,
		lastName: userInfo.lastName,
		mask: "",
		newAccount: "",
		bankName: "",
	});
	React.useEffect(() => {
		if (props.type === "edit") {
			setValues(profileInfo);
		}
	}, []);
	React.useEffect(() => {
		if (
			messages === "Payment method successfully updated" ||
			messages === "Payment method deleted"
		) {
			props.handleClose();
		}
	}, [messages]);
	React.useEffect(() => {
		if (props.type === "edit" && profileInfo.isDefaultProfile) {
			setState({ default: profileInfo?.isDefaultProfile });
		} else {
			setState({ default: false });
		}
	}, [profileInfo]);
	React.useEffect(() => {
		if (props.type === "edit" && values.accountNumber) {
			let maskNumber: any = props.maskNumber(values.accountNumber);
			setValues({ ...values, mask: maskNumber, newAccount: "" });
		}
	}, [values.accountNumber]);
	React.useEffect(() => {
		if (edit) {
			const timeout = setTimeout(() => {
				inputRef.current.focus();
			}, 100);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [edit]);
	const handleChange = (event: any) => {
		let prop: any = [event.target.name];
		if (errors[prop]) {
			setErrors({ ...errors, [event.target.name]: "" });
		}
		setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};
	const handleSubmit = (event: any) => {
		event.preventDefault();
		let config: any = {
			headers: { Authorization: `Bearer ${userConfig.token}` },
			BusinessAlias: userConfig.businessAlias,
			PartnerApiKey: userConfig.partnerApiKey,
			ClientId: userConfig.clientId,
			BankName: values.bankName,
			FirstName: values.firstName,
			LastName: values.lastName,
			Address: values.address,
			City: values.city,
			State: values.state,
			Country: values.country,
			Zip: values.zip,
			PaymentMethodType: "Ach",
			Ach: {
				AccountNumber: values.accountNumber,
				BankAccountType: values.accountTypeFull,
				RoutingNumber: values.routing,
				BankName: values.bankName,
				NameOnAccount: values.firstName + " " + values.lastName,
			},
		};
		let billingAddress: any = {
			firstName: values.firstName,
			lastName: values.lastName,
			address: values.address,
			city: values.city,
			zip: values.zip,
			state: values.state,
			country: values.country,
		};
		let valid: any;
		let type = "ach";
		valid = bankAccountValidation(config.Ach, setErrors, billingAddress, type);
		if (valid) {
			let defaultConfig = {
				headers: { Authorization: `Bearer ${userConfig.token}` },
				BusinessAlias: userConfig.businessAlias,
				PartnerApiKey: userConfig.partnerApiKey,
				id: profileInfo?.id,
			};
			if (props.type === "edit") {
				config.id = profileInfo.id;
				if (state.default) {
					props.defaultBillingProfile(defaultConfig);
				}
				props.updateBillingProfile(config);
			} else {
				if (state.default) {
					props.setDefault(true);
				}
				props.createBillingProfile(config);
			}
		}
	};
	const handlePaymentChange = (event: any) => {
		let prop: any = [event.target.name];
		if (errors[prop]) {
			setErrors({ ...errors, [event.target.name]: "" });
		}
		setValues({
			...values,
			accountTypeFull: event.target.value as string,
		});
	};
	const handleDefaultChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};
	const handleNewNumber = () => {
		let newAccount = values.newAccount?.trim();
		let valid: any;
		valid = bankAccountNumberValidation(newAccount, setAccountError);
		if (valid) {
			setEdit(false);
			setValues({
				...values,
				accountNumber: newAccount,
				newAccount: "",
			});
		}
	};
	const handleEdit = () => {
		edit ? handleNewNumber() : setEdit(true);
	};
	const handleDeletePayment = (id: any) => {
		let config = {
			headers: { Authorization: `Bearer ${userConfig.token}` },
			partnerApiKey: userConfig ? userConfig.partnerApiKey : null,
			businessAlias: userConfig ? userConfig.businessAlias : null,
			id: id,
		};
		props.deleteBillingProfile(config);
	};
	const gridItem = { xs: 12, md: 6 };
	const inputRef: any = React.useRef();

	return (
		<>
			<Title
				title={`${props.type === "edit" ? "" : "Add"} Bank Account ${
					profileInfo?.isDefaultProfile ? "(Default)" : ""
				}`}
				onClose={props.handleClose}
			/>
			<DialogContent>
				<DialogContentText>
					<form autoComplete="off" noValidate>
						<Grid
							style={{ overflowY: "auto" }}
							container
							justifyContent="center"
						>
							<Grid container justifyContent="center" sx={{ mb: 1 }}>
								<FormControlLabel
									control={
										<Switch
											checked={state.default}
											onChange={handleDefaultChange}
											color="primary"
											name="default"
											disabled={profileInfo?.isDefaultProfile ? true : false}
										/>
									}
									label="Make Default Payment"
								/>
							</Grid>
							{props.type === "edit" && (
								<Grid item xs={4}>
									<Button
										sx={{ mb: 2 }}
										fullWidth
										variant="outlined"
										onClick={props.delete}
										disabled={profileInfo?.isAssociatedWithMembership}
									>
										Delete
									</Button>
								</Grid>
							)}
							{profileInfo?.isAssociatedWithMembership && (
								<Grid container justifyContent="center">
									<Typography
										paragraph
										sx={{ mt: 1 }}
										align="center"
										color="black"
										variant="caption"
									>
										Profile is associated with renewing payments and cannot be
										deleted
									</Typography>
								</Grid>
							)}
						</Grid>
						<Grid container justifyContent="center" spacing={3}>
							<Grid item {...gridItem}>
								{props.type === "edit" ? (
									edit === true ? (
										<TextField
											fullWidth
											inputRef={inputRef}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											label={"Bank Account Number"}
											name="newAccount"
											onChange={handleChange}
											onBlur={handleEdit}
											color="secondary"
											inputProps={{ maxLength: 17 }}
											value={values?.newAccount}
											error={Boolean(
												errors.accountNumber || accountError.accountNumber
											)}
											helperText={
												errors.accountNumber || accountError.accountNumber
											}
										/>
									) : (
										<TextField
											fullWidth
											InputProps={{
												endAdornment: (
													<InputAdornment style={{ padding: 0 }} position="end">
														<Button style={{ padding: 5 }} onClick={handleEdit}>
															Edit
														</Button>
													</InputAdornment>
												),
											}}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											label={"Bank Account Number"}
											name="accountNumber"
											color="secondary"
											inputProps={{ maxLength: 17 }}
											value={values?.mask}
											error={errors.accountNumber ? true : false}
											helperText={errors.accountNumber}
										/>
									)
								) : (
									<TextInput
										fullWidth
										label={"Bank Account Number"}
										name="accountNumber"
										onChange={handleChange}
										inputProps={{ maxLength: 17 }}
										value={values.accountNumber}
										error={errors.accountNumber ? true : false}
										helperText={errors.accountNumber}
									/>
								)}
							</Grid>
							<Grid item {...gridItem}>
								<TextInput
									fullWidth
									label={"Bank Name"}
									name="bankName"
									onChange={handleChange}
									value={values.bankName}
									error={errors.bankName ? true : false}
									helperText={errors.bankName}
								/>
							</Grid>
							<Grid item {...gridItem}>
								<TextInput
									fullWidth
									label={"Routing Number"}
									name="routing"
									inputProps={{ maxLength: 9 }}
									onChange={handleChange}
									value={values.routing}
									error={errors.routing ? true : false}
									helperText={errors.routing}
								/>
							</Grid>
							<Grid item {...gridItem}>
								<AccountType
									fullWidth
									label="Account Type"
									name="accountTypeFull"
									helperText={errors.accountTypeFull}
									value={values.accountTypeFull}
									onChange={handlePaymentChange}
									error={errors.accountTypeFull ? true : false}
								/>
							</Grid>
						</Grid>
						<BillingAddressForm
							{...props}
							values={values}
							errors={errors}
							userInfo={userInfo}
							setValues={setValues}
							setErrors={setErrors}
							handleChange={handleChange}
						/>

						<Grid
							container
							justifyContent="center"
							spacing={2}
							sx={{ padding: 1 }}
						>
							<Dialog open={props.open === "6"} sx={styles.modal}>
								<Paper sx={styles.paper}>
									<Grid container alignItems="center" justifyContent="center">
										<Grid item>
											<Typography paragraph align="center">
												Are you sure you want to delete this payment method?
											</Typography>
										</Grid>
										<Grid container spacing={2} justifyContent="center">
											<Grid item xs={4}>
												<Button
													fullWidth
													variant="outlined"
													onClick={() =>
														handleDeletePayment(props.profileInfo.id)
													}
												>
													Delete
												</Button>
											</Grid>
											<Grid item xs={4}>
												<Button
													fullWidth
													variant="outlined"
													onClick={() => {
														props.deleteCancel();
													}}
												>
													Cancel
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Paper>
							</Dialog>
						</Grid>
					</form>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ p: 2 }}>
				<Button
					fullWidth
					onClick={props.handleClose}
					variant="outlined"
					color="primary"
					sx={{ mr: 1 }}
				>
					Cancel
				</Button>

				<Button
					fullWidth
					onClick={handleSubmit}
					variant="contained"
					color="primary"
					sx={{ ml: 1 }}
					disabled={edit}
				>
					Save
				</Button>
			</DialogActions>
		</>
	);
}

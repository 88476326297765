import React from "react";
import { Checkbox, FormControlLabel, Typography, Grid } from "@mui/material";
import {
	SelectState,
	SelectCountry,
	SelectProvince,
	TextInput,
} from "../../Forms/Inputs";

export const BillingAddressForm = (props: any) => {
	const { errors, values, userInfo, setValues, setErrors } = props;
	const [checked, setChecked] = React.useState<boolean>(false);
	const handleCheck = () => {
		setChecked(!checked);
		handleSameAddress();
	};
	const handleSameAddress = () => {
		if (!checked) {
			setValues({
				...values,
				address: userInfo.address ? userInfo.address : "",
				city: userInfo.city ? userInfo.city : "",
				zip: userInfo.zip ? userInfo.zip : "",
				state: userInfo.state ? userInfo.state : "",
				country: userInfo.country ? userInfo.country : "",
			});
			setErrors({
				...errors,
				address: "",
				city: "",
				zip: "",
				state: "",
				country: "",
			});
		} else {
			setValues({
				...values,
				address: "",
				city: "",
				zip: "",
				state: "",
				country: "",
			});
			setErrors({
				...errors,
				address: "",
				city: "",
				zip: "",
				state: "",
				country: "",
			});
		}
	};
	const gridItem = { xs: 12, md: 6 };
	const handleCountryChange = (event: any) => {
		setValues({
			...values,
			country: event.target.value,
			state: "",
		});
	};
	return (
		<>
			<Typography sx={{ mt: 2 }} align="center">
				Billing Address
			</Typography>
			<Grid container justifyContent="center" sx={{ mb: 1 }}>
				<FormControlLabel
					control={
						<Checkbox
							checked={checked}
							onChange={handleCheck}
							name="Same as address on account"
						/>
					}
					label="Same as address on account"
				/>
			</Grid>
			<Grid container justifyContent="center" sx={{ mb: 3 }}>
				<Grid item {...gridItem}>
					<SelectCountry
						fullWidth
						onChange={handleCountryChange}
						value={values?.country}
						error={errors?.country ? true : false}
						helperText={errors?.country}
					/>
				</Grid>
			</Grid>
			<Grid container justifyContent="center" direction="row" spacing={3}>
				<Grid item {...gridItem}>
					<TextInput
						label={"First Name"}
						name="firstName"
						fullWidth
						onChange={props.handleChange}
						value={values?.firstName}
						error={errors.firstName ? true : false}
						helperText={errors.firstName}
					/>
				</Grid>
				<Grid item {...gridItem}>
					<TextInput
						label={"Last Name"}
						name="lastName"
						fullWidth
						onChange={props.handleChange}
						value={values?.lastName}
						error={errors?.lastName ? true : false}
						helperText={errors?.lastName}
					/>
				</Grid>
				<Grid item {...gridItem}>
					<TextInput
						label={"Street Address"}
						name="address"
						fullWidth
						onChange={props.handleChange}
						value={values?.address}
						error={errors?.address ? true : false}
						helperText={errors?.address}
					/>
				</Grid>
				<Grid item {...gridItem}>
					<TextInput
						label={"City"}
						name="city"
						fullWidth
						onChange={props.handleChange}
						value={values?.city}
						error={errors?.city ? true : false}
						helperText={errors?.city}
					/>
				</Grid>
				<Grid item {...gridItem}>
					<TextInput
						label={"Zip/Postal Code"}
						name="zip"
						fullWidth
						onChange={props.handleChange}
						value={values?.zip}
						error={errors?.zip ? true : false}
						helperText={errors?.zip}
					/>
				</Grid>
				<Grid item {...gridItem}>
					{values.country !== "US" && values.country !== "CA" ? (
						<TextInput
							label={"State/Province/Region"}
							name="state"
							fullWidth
							onChange={props.handleChange}
							disabled={!values.country}
							value={values.state}
							error={errors?.state ? true : false}
							helperText={errors?.state}
						/>
					) : null}
					{values.country === "US" ? (
						<SelectState
							fullWidth
							onChange={props.handleChange}
							value={values.state}
							error={errors?.state ? true : false}
							helperText={errors?.state}
						/>
					) : null}
					{values.country === "CA" ? (
						<SelectProvince
							fullWidth
							onChange={props.handleChange}
							value={values.state}
							error={errors?.state ? true : false}
							helperText={errors?.state}
						/>
					) : null}
				</Grid>
			</Grid>
		</>
	);
};

import { connect } from "react-redux";
import ManageUsers from "../components/Profile/Dialogs/ManageUsers";

const mapStateToProps = (state: any) => {
	return {
		userConfig: state.auth.userConfig,
		users: state.client.users,
		requesting: state.client.requesting,
		successful: state.client.successful,
		clientErrors: state.client.errors,
		userInfo: state.client.user,
		messages: state.client.messages,
		userTaken: state.auth.userTaken,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getUsers: (config: any) =>
			dispatch({ type: "GET_USERS_REQUEST", value: config }),
		getProfileInfo: (config: any) =>
			dispatch({ type: "PROFILE_INFO_REQUEST", value: config }),
		createUser: (config: any) =>
			dispatch({ type: "CREATE_USER_REQUEST", value: config }),
		updateUser: (config: any) =>
			dispatch({ type: "UPDATE_USER_REQUEST", value: config }),
		clearErrors: () => dispatch({ type: "CLEAR_ERRORS" }),
		checkUserName: (action: any) =>
			dispatch({ type: "CHECK_USERNAME", value: action }),
		clearUserNameError: () => dispatch({ type: "CLEAR_USERNAME_ERROR" }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);

import React from "react";
import {
	TextField,
	IconButton,
	Input,
	InputLabel,
	InputAdornment,
	FormControl,
	Typography,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { validateStep2 } from "./Validation";

const classes: any = {
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: "8px",
		marginBottom: 0,
		paddingBottom: 0,
	},
};
export default function Step2(props: any) {
	const [showPassword, setShowPassword] = React.useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] =
		React.useState<boolean>(false);
	const { errors, setErrors, values } = props;
	const [checked, setChecked] = React.useState({
		emailAlerts: values.emailAlerts,
		emailAccountAlerts: values.emailAccountAlerts,
	});
	const { emailAlerts, emailAccountAlerts } = checked;
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked({ ...checked, [event.target.name]: event.target.checked });
		props.handleBoolean(event.target.name, event.target.checked);
	};
	const config = {
		username: values.username,
		partnerApiKey: "mm",
		businessAlias: window.location.pathname.split("/")[1],
	};
	return (
		<form noValidate>
			<TextField
				color="secondary"
				required
				autoFocus
				margin="normal"
				fullWidth
				InputLabelProps={{
					shrink: true,
				}}
				variant="standard"
				label={"Email Address"}
				name="email"
				value={values.email}
				onChange={props.handleChange("email")}
				onBlur={(event: any) => {
					const isValid: any = validateStep2({
						email: event.target.value,
					});
					setErrors({
						...errors,
						email: isValid.email,
					});
				}}
				error={errors.email ? true : false}
				helperText={errors.email}
				inputProps={{ maxLength: 244 }}
			/>
			<TextField
				color="secondary"
				margin="normal"
				required
				InputLabelProps={{
					shrink: true,
				}}
				style={{ marginTop: 8 }}
				fullWidth
				variant="standard"
				label={"Username"}
				name="username"
				value={values.username}
				onChange={props.handleChange("username")}
				onBlur={(event: any) => {
					const isValid: any = validateStep2({
						username: event.target.value,
					});
					setErrors({
						...errors,
						username: isValid.username,
					});
					props.checkUserName(config);
				}}
				onFocus={() => props.clearUserNameError()}
				error={errors.username ? true : false}
				helperText={errors.username}
				inputProps={{
					maxLength: 244,
					autoComplete: "new-password",
					form: {
						autoComplete: "off",
					},
				}}
			/>
			<FormControl sx={classes.form}>
				<InputLabel
					style={{
						marginLeft: -13,
						marginTop: 5,
					}}
					shrink={true}
					error={Boolean(errors.password)}
					color="secondary"
					htmlFor="standard-adornment-password"
				>
					Password *
				</InputLabel>
				<Input
					required
					color="secondary"
					type={showPassword ? "text" : "password"}
					value={values.password}
					onChange={props.handleChange("password")}
					inputProps={{
						maxLength: 244,
						autoComplete: "new-password",
						form: {
							autoComplete: "off",
						},
					}}
					onBlur={(event: any) => {
						const isValid: any = validateStep2({
							password: event.target.value,
							confirmPassword: values.confirmPassword,
						});
						setErrors({
							...errors,
							password: isValid.password,
							confirmPassword: isValid.confirmPassword,
						});
					}}
					error={errors.password ? true : false}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => {
									setShowPassword(!showPassword);
								}}
								size="large"
							>
								{showPassword ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>
					}
				/>
				<Typography color="error" variant="caption">
					{errors.password}
				</Typography>
			</FormControl>
			<FormControl sx={classes.form}>
				<span style={classes.form}>
					<InputLabel
						shrink={true}
						style={{
							marginLeft: -13,
							marginTop: 10,
						}}
						error={Boolean(errors.confirmPassword)}
					>
						Confirm Password *
					</InputLabel>
					<Input
						color="secondary"
						name="noAutoFill2"
						fullWidth
						required
						type={showConfirmPassword ? "text" : "password"}
						value={values.confirmPassword}
						inputProps={{
							maxLength: 244,
							autoComplete: "new-password",
							form: {
								autoComplete: "off",
							},
						}}
						onChange={props.handleChange("confirmPassword")}
						onBlur={(event: any) => {
							const isValid: any = validateStep2({
								confirmPassword: event.target.value,
								password: values.password,
							});
							setErrors({
								...errors,
								password: isValid.password,
								confirmPassword: isValid.confirmPassword,
							});
						}}
						error={errors.confirmPassword ? true : false}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => setShowConfirmPassword(!showConfirmPassword)}
									size="large"
								>
									{showConfirmPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						}
					/>
				</span>
				<Typography color="error" variant="caption">
					{errors.confirmPassword}
				</Typography>
				<Typography sx={{ mt: 1 }} variant="caption" align="center">
					* Password must be at least 7 characters and contain one number and
					letter
				</Typography>
				<FormControlLabel
					control={
						<Checkbox
							onChange={handleChange}
							value={emailAccountAlerts}
							defaultChecked={
								values.emailAccountAlerts ? values.emailAccountAlerts : false
							}
							name="emailAccountAlerts"
							color="secondary"
						/>
					}
					label={
						<Typography variant="subtitle2">
							I want to receive account alerts via email
						</Typography>
					}
					color="secondary"
				/>
				<FormControlLabel
					control={
						<Checkbox
							onChange={handleChange}
							name="emailAlerts"
							defaultChecked={values.emailAlerts ? values.emailAlerts : false}
							value={emailAlerts}
							color="secondary"
						/>
					}
					label={
						<Typography variant="subtitle2">
							I want to receive inspiration, marketing promotions and updates
							via email
						</Typography>
					}
					color="secondary"
				/>
			</FormControl>
		</form>
	);
}

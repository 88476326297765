import { connect } from "react-redux";
import Schedules from "../components/Schedule/index";

const mapStateToProps = (state: any) => {
	return {
		schedule: state.schedule.schedule,
		billingRequesting: state.billingProfile.requesting,
		billingSuccessful: state.billingProfile.successful,
		requesting: state.schedule.requesting,
		successful: state.schedule.successful,
		bookRequesting: state.schedule.bookRequesting,
		bookSuccessful: state.schedule.bookSuccessful,
		filtersRequesting: state.schedule.filtersRequesting,
		filtersSuccessful: state.schedule.filtersSuccessful,
		clubDetails: state.schedule.clubDetails,
		userConfig: state.auth.userConfig,
		users: state.client.users,
		mySchedule: state.schedule.mySchedule,
		billingProfiles: state.billingProfile.billingProfiles,
		clubData: state.billingProfile.clubData,
		credits: state.client.credits,
		scheduleNames: state.schedule.scheduleNames,
		cancelSuccess: state.schedule.cancelSuccess,
		bookedSuccess: state.schedule.bookedSuccess,
		cancelReasons: state.schedule.cancelReasons,
		scheduleFilters: state.schedule.scheduleFilters,
		recurringSchedule: state.schedule.recurringSchedule,
		messages: state.schedule.messages,
		profileMessages: state.client.messages,
		billingMessages: state.billingProfile.messages,
		errors: state.schedule.errors,
		userInfo: state.client.user,
		defaultId: state.billingProfile.defaultId,
		purchaseForUsers: state.client.purchaseForUsers,
		payBook: state.schedule.payBook,
		relatedClasses: state.schedule.relatedClasses,
		payBookingRequesting: state.schedule.payBookingRequesting,
		payBookingSuccess: state.schedule.payBookingSuccess,
		getRelatedRequesting: state.schedule.getRelatedRequesting,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getGymSchedule: (config: any) =>
			dispatch({ type: "GET_GYM_SCHEDULE_REQUEST", value: config }),
		getScheduleFilters: (config: any) =>
			dispatch({ type: "GET_SCHEDULE_FILTERS_REQUEST", value: config }),
		getScheduleNames: (config: any) =>
			dispatch({ type: "GET_SCHEDULE_NAMES_REQUEST", value: config }),
		getRecurringSchedule: (config: any) =>
			dispatch({ type: "GET_RECURRING_SCHEDULE_REQUEST", value: config }),
		getCancelReasons: (config: any) =>
			dispatch({ type: "GET_CANCEL_REASONS_REQUEST", value: config }),
		getUsers: (config: any) =>
			dispatch({ type: "GET_USERS_REQUEST", value: config }),
		getClubDetails: (config: any) =>
			dispatch({ type: "GET_CLUB_DETAILS_REQUEST", value: config }),
		bookClass: (config: any) =>
			dispatch({ type: "BOOK_CLASS_REQUEST", value: config }),
		cancelClass: (config: any) =>
			dispatch({ type: "CANCEL_CLASS_REQUEST", value: config }),
		getUserSchedule: (config: any) =>
			dispatch({ type: "GET_USER_SCHEDULE_REQUEST", value: config }),
		getBillingProfile: (config: any) =>
			dispatch({ type: "BILLING_PROFILE_REQUEST", value: config }),
		clearCancelSuccess: () => dispatch({ type: "CLEAR_CANCEL_CLASS_SUCCESS" }),
		getRelatedUsers: (config: any) =>
			dispatch({
				type: "GET_RELATED_USERS_REQUEST",
				value: config,
			}),
		getRelatedClasses: (config: any) =>
			dispatch({
				type: "GET_RELATED_CLASSES_REQUEST",
				value: config,
			}),
		getCredits: (config: any) =>
			dispatch({ type: "GET_CREDITS_REQUEST", value: config }),
		closePayBook: () =>
			dispatch({
				type: "CLOSE_PAY_BOOK",
			}),
		payBooking: (config: any) =>
			dispatch({ type: "PAY_BOOKING_REQUEST", value: config }),
		updateProfileInfo: (config: any) =>
			dispatch({ type: "PROFILE_UPDATE_REQUEST", value: config }),
		getProfileInfo: (config: any) =>
			dispatch({ type: "PROFILE_INFO_REQUEST", value: config }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Schedules);

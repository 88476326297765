import { connect } from "react-redux";
import Questionnaires from "../components/Profile/Questionnaires/ManageQuestionnaires";

const mapStateToProps = (state: any) => {
	return {
		data: state.client.data,
		userConfig: state.auth.userConfig,
		users: state.client.users,
		successful: state.client.successful,
		errors: state.auth.errors,
		userInfo: state.client.user,
		signatureImage: state.client.signatureImage,
		messages: state.client.messages,
		requesting: state.client.requesting,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		getProfileInfo: (config: any) =>
			dispatch({ type: "PROFILE_INFO_REQUEST", value: config }),
		updateQuestionnaires: (config: any) =>
			dispatch({ type: "UPDATE_PROFILE_QUESTIONNAIRE_REQUEST", value: config }),
		submitQuestionnaires: (config: any) =>
			dispatch({ type: "SUBMIT_PROFILE_QUESTIONNAIRE_REQUEST", value: config }),
		getQuestionnaires: (config: any) =>
			dispatch({ type: "GET_QUESTIONNAIRE_REQUEST", value: config }),
		getSignatureImage: (config: any) =>
			dispatch({ type: "GET_SIGNATURE_IMAGE_REQUEST", value: config }),
		clearErrors: () => dispatch({ type: "CLEAR_ERRORS" }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Questionnaires);

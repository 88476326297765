import * as constants from "../constants/enroll.constants";

const initialState = {
	requesting: false,
	successful: false,
	enrollRequesting: false,
	enrollSuccessful: false,
	messages: null,
	errors: null,
	loadError: false,
	memberships: null,
	membershipDetails: null,
	membershipTerms: null,
	membershipResponse: null,
};

function enrollMembershipsReducer(state = initialState, action: any) {
	switch (action.type) {
		case constants.GET_ENROLL_MEMBERSHIP_DETAILS_REQUEST:
			return {
				...state,
				membershipDetails: null,
				requesting: true,
				successful: false,
			};
		case constants.GET_ENROLL_MEMBERSHIP_TERMS_REQUEST:
			return {
				...state,
				membershipTerms: null,
				requesting: true,
				successful: false,
			};
		case constants.GET_ENROLL_MEMBERSHIPS_SUCCESS:
			return {
				...state,
				enrollRequesting: false,
				enrollSuccessful: true,
				errors: null,
				memberships: action.response.data,
				loadError: false,
			};
		case constants.GET_ENROLL_MEMBERSHIPS_REQUEST:
			return {
				...state,
				enrollRequesting: true,
				enrollSuccessful: false,
				loadError: false,
			};
		case constants.ENROLL_MEMBERSHIP_REQUEST:
			return {
				...state,
				requesting: true,
				successful: false,
			};
		case constants.ENROLL_MEMBERSHIP_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: true,
				errors: null,
				messages: "Enrolled successfully",
				membershipResponse: action.response.data,
			};
		case constants.GET_ENROLL_MEMBERSHIP_DETAILS_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: false,
				errors: null,
				membershipDetails: action.response.data,
			};
		case constants.GET_ENROLL_MEMBERSHIP_TERMS_SUCCESS:
			return {
				...state,
				requesting: false,
				successful: false,
				errors: null,
				membershipTerms: action.response.data,
			};

		case constants.ENROLL_MEMBERSHIP_ERROR:
		case constants.GET_ENROLL_MEMBERSHIPS_ERROR:
		case constants.GET_ENROLL_MEMBERSHIP_TERMS_ERROR:
		case constants.GET_ENROLL_MEMBERSHIP_DETAILS_ERROR:
			return {
				...state,
				requesting: false,
				successful: false,
				enrollRequesting: false,
				errors: action.error?.response?.data?.error || action.error?.message,
				loadError: true,
			};
		case constants.CLEAR_ENROLL_ERROR:
			return {
				...state,
				errors: null,
				messages: null,
			};
		default:
			return state;
	}
}

export default enrollMembershipsReducer;

import { Button, Tooltip } from "@mui/material";

const getLegends = (legends: any) => {
	let icons: any = [];
	if (legends.includes("Monthly Prorated")) {
		icons.push(
			<Tooltip arrow placement="top" title="Item price is prorated">
				<Button style={{ padding: 2, minHeight: 2, minWidth: 2 }}>P</Button>
			</Tooltip>
		);
	}
	if (legends.includes("Special Rate")) {
		icons.push(
			<Tooltip arrow placement="top" title="Special rate applied">
				<Button style={{ padding: 2, minHeight: 2, minWidth: 2 }}>S</Button>
			</Tooltip>
		);
	}
	if (legends.includes("Automatic Renewal")) {
		icons.push(
			<Tooltip arrow placement="top" title="Auto Renewal">
				<Button style={{ padding: 2, minHeight: 2, minWidth: 2 }}>R</Button>
			</Tooltip>
		);
	}
	return icons;
};
export default getLegends;

import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Toolbar,
	Typography,
	Checkbox,
	Paper,
	FormControlLabel,
	CardHeader,
	CircularProgress,
	Divider,
	Grid,
	Dialog,
	Hidden,
	Button,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import PayInvoice from "./PayInvoice";
import MobileInvoiceMenu from "./MobileInvoices";
import Invoice from "./InvoiceView";
import { formatCurrency } from "../../../utils/FormatCurrency";
import styles from "../../../styles";
import Title from "../Dialogs/Title";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";

interface Data {
	description: string;
	due: string;
	quantity: number;
	purchasedFor: string;
	amount: any;
	id: number;
	contractStatus: string;
	paidAmount: string;
	actions: any;
}

function createData(
	description: string,
	due: string,
	quantity: number,
	purchasedFor: string,
	amount: any,
	paidAmount: string,
	id: number,
	contractStatus: string,
	actions: any
): Data {
	return {
		description,
		due,
		quantity,
		purchasedFor,
		amount,
		paidAmount,
		id,
		contractStatus,
		actions,
	};
}
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

type Order = "asc" | "desc";
function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key
): (
	a: { [key in Key]: number | string },
	b: { [key in Key]: number | string }
) => number {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis?.map((el) => el[0]);
}

interface HeadCell {
	disablePadding: boolean;
	id: keyof Data;
	label: string;
	align: any;
}

const headCells: HeadCell[] = [
	{
		id: "id",
		align: "left",
		disablePadding: true,
		label: "Invoice #",
	},
	{
		id: "description",
		align: "left",
		disablePadding: true,
		label: "Description",
	},
	{
		id: "due",
		align: "left",
		disablePadding: true,
		label: "Invoice Due",
	},
	{ id: "amount", align: "left", disablePadding: true, label: "Amount" },
	{ id: "actions", align: "right", disablePadding: false, label: "" },
];

interface EnhancedTableProps {
	classes: any;
	numSelected: number;
	onRequestSort: (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => void;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
	type: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const {
		classes,
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort,
		type,
	} = props;
	const createSortHandler =
		(property: keyof Data) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

	return (
		<TableHead sx={classes.tableHead}>
			<TableRow>
				{props.type === "paid" ? null : (
					<TableCell padding="checkbox" sx={{ maxWidth: 80 }}>
						<FormControlLabel
							sx={classes.color}
							value="start"
							style={{ marginLeft: 0 }}
							control={
								<Checkbox
									className={classes.color}
									sx={{ pr: 0.5 }}
									onChange={onSelectAllClick}
									checked={rowCount > 0 && numSelected === rowCount}
									color="secondary"
								/>
							}
							label={
								<Typography
									variant="subtitle2"
									sx={{
										mt: 0,
										fontFamily: "Roboto Condensed, sans-serif",
										fontSize: 16,
									}}
								>
									All
								</Typography>
							}
							labelPlacement="end"
						/>
					</TableCell>
				)}
				{headCells.map((headCell) => (
					<TableCell
						sx={classes.tableHead}
						// style={{ paddingLeft: props.type === "paid" ? 20 : 0 }}
						key={headCell.id}
						align={headCell.align}
						padding={props.type === "paid" ? "normal" : "none"}
						style={{
							maxWidth: headCell.id == "id" ? 95 : "none",
						}}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							style={{
								color: "white",
								fontFamily: "Roboto Condensed, sans-serif",
								fontSize: 14,
								minWidth: props.type === "paid" ? 120 : 0,
							}}
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

const useToolbarStyles: any = {
	header: {
		backgroundColor: "text.disabled",
		color: "white",
		margin: 0,
		paddingLeft: 2,
		paddingRight: 1,
		paddingBottom: 0,
	},
};

const EnhancedTableToolbar = () => {
	const classes = useToolbarStyles;
	return (
		<Toolbar sx={classes.header}>
			<Typography
				variant="h6"
				id="tableTitle"
				component="div"
				sx={{
					fontFamily: "Roboto Condensed, sans-serif",
					borderRadius: "0px !important",
				}}
			>
				Unpaid Invoices
			</Typography>
		</Toolbar>
	);
};

const classes: any = {
	root: {
		width: "100%",
	},
	paper: {
		width: "100%",
	},
	table: {
		minWidth: 500,
	},
	tableRowRoot: {
		"&:nth-of-type(odd)": {
			backgroundColor: "#DCDCDC",
		},
	},
	tableHead: {
		backgroundColor: "text.disabled",
		color: "white",
	},
	color: {
		color: "white",
	},
	header: {
		backgroundColor: "text.disabled",
		color: "white",
		margin: 0,
		width: "100%",
	},
	paidHeader: {
		backgroundColor: "text.disabled",
		color: "white",
		margin: 0,
		borderBottom: "none",
		textAlign: "left",
		maxWidth: "100%",
	},
	list: {
		width: "100%",
		justifyContent: "space-between",
		alignItems: "center",
		borderBottom: `solid 1px #d7d7d7`,
		"&:nth-of-type(odd)": {
			backgroundColor: "#DCDCDC",
		},
	},
};
export default function InvoiceTable(props: any) {
	const { billingProfiles, filteredInvoices, clubData, config } = props;
	const [order, setOrder] = React.useState<Order>("asc");
	const [orderPaid, setOrderPaid] = React.useState<Order>("desc");
	const [orderBy, setOrderBy] = React.useState<keyof Data>("due");
	const [orderPaidBy, setPaidOrderBy] = React.useState<keyof Data>("due");
	const [invoiceId, setInvoiceId] = React.useState(null);
	const [selected, setSelected] = React.useState<any>([]);
	const [page, setPage] = React.useState(1);
	const [paidPage, setPaidPage] = React.useState(1);
	const [open, setOpen] = React.useState("0");
	const [rowsPerPage] = React.useState(5);
	const [noOfPages, setNoOfPages] = React.useState(0);
	const [noOfPaidPages, setNoOfPaidPages] = React.useState(0);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("sm"));
	React.useEffect(() => {
		if (filteredInvoices) {
			setNoOfPages(Math.ceil(invoices.length / rowsPerPage));
			setNoOfPaidPages(Math.ceil(paidInvoices.length / rowsPerPage));
		}
	}, [filteredInvoices]);

	let sortedBillingProfiles: any = [];
	if (billingProfiles) {
		sortedBillingProfiles = billingProfiles;
	}
	sortedBillingProfiles = sortedBillingProfiles.sort(function (a: any, b: any) {
		return a.id - b.id || a.name.localeCompare(b.name);
	});

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handlePaidRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => {
		const isAsc = orderPaidBy === property && orderPaid === "asc";
		setOrderPaid(isAsc ? "desc" : "asc");
		setPaidOrderBy(property);
	};
	const invoices: any = filteredInvoices?.filter(
		(invoice: any) => invoice.amountPaid < invoice.amount
	);
	const paidInvoices: any = filteredInvoices?.filter(
		(invoice: any) =>
			invoice.amountPaid === invoice.amount ||
			invoice.amountPaid > invoice.amount
	);
	let rows: any = invoices?.map((row: any) =>
		createData(
			row.description,
			row.dueDate,
			row.quantity,
			row.purchasedFor,
			row.amount,
			row.amountPaid,
			Number(row.id),
			row.contractStatus,
			row.actions
		)
	);
	let paidRows: any = paidInvoices?.map((row: any) =>
		createData(
			row.description,
			row.dueDate,
			row.quantity,
			row.purchasedFor,
			row.amount,
			row.amountPaid,
			Number(row.id),
			row.contractStatus,
			row.actions
		)
	);
	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = rows.map((n: any) => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};
	const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};
	const handleCloseInvoice = () => {
		setOpen("0");
	};
	const handleOpenInvoice = (number: any) => {
		setInvoiceId(number);
		config.Ids = number;
		props.getInvoiceDetails(config);
		setTimeout(function () {
			setOpen(number);
			props.getInvoicePdf(config);
		}, 500);
	};
	const handleChange = (event: any, value: any) => {
		setPage(value);
	};
	const handlePaidChange = (event: any, value: any) => {
		setPaidPage(value);
	};
	const getSelectedInvoices = () => {
		let selectedInvoices: any = [];
		invoices?.map((invoice: any) =>
			selected.includes(Number(invoice.id))
				? selectedInvoices.push(invoice)
				: null
		);
		return selectedInvoices;
	};
	const getBalanceDue = () => {
		let balance: number = 0;
		invoices?.map((invoice: any) =>
			selected.includes(Number(invoice.id))
				? (balance = balance + invoice.amount - invoice.amountPaid)
				: null
		);
		return balance;
	};
	let balanceDue = getBalanceDue();
	const isSelected = (name: string) => selected.indexOf(name) !== -1;
	let selectedInvoices: any = getSelectedInvoices();

	React.useEffect(() => {
		config.Ids = invoiceId;
	}, [invoiceId]);

	return (
		<div className={classes.root}>
			<Paper sx={classes.paper}>
				<Hidden smDown>
					<EnhancedTableToolbar />
					<Divider sx={{ color: "primary.main" }} />
					<TableContainer>
						{invoices?.length > 0 ? (
							<Table
								sx={classes.table}
								aria-labelledby="tableTitle"
								size={"small"}
								aria-label="enhanced table"
							>
								<>
									<EnhancedTableHead
										type=""
										classes={classes}
										numSelected={selected.length}
										order={order}
										orderBy={orderBy}
										onSelectAllClick={handleSelectAllClick}
										onRequestSort={handleRequestSort}
										rowCount={rows?.length}
									/>
									<TableBody>
										{stableSort(rows, getComparator(order, orderBy))
											?.slice((page - 1) * rowsPerPage, page * rowsPerPage)
											?.map((row: any, index: any) => {
												const isItemSelected = isSelected(row.id);
												const labelId = `enhanced-table-checkbox-${index}`;
												return (
													<TableRow
														sx={classes.tableRowRoot}
														tabIndex={-1}
														key={index}
													>
														<TableCell align="left">
															<Checkbox
																onClick={(event: any) =>
																	handleClick(event, row.id)
																}
																checked={isItemSelected}
																inputProps={{ "aria-labelledby": labelId }}
															/>
														</TableCell>
														<TableCell size="small" padding="none" align="left">
															{row.id}
														</TableCell>
														<TableCell
															padding="none"
															align="left"
															id={labelId}
															style={{ paddingRight: 10 }}
														>
															{row.description}

															{/* {row.description.length < 20
																? row.description
																: row.description.substr(0, 21) + "..."} */}
														</TableCell>
														<TableCell padding="none" align="left">
															{moment(row.due.slice(0, 10)).format("MM/DD/YY")}
														</TableCell>
														<TableCell padding="none" align="left">
															{formatCurrency(
																row.amount - row.paidAmount,
																clubData
															)}
														</TableCell>
														<TableCell align="right">
															<Button
																onClick={() => handleOpenInvoice(row.id)}
																fullWidth
																variant="outlined"
																sx={{ bgcolor: "#FAFAFA" }}
															>
																View
															</Button>
														</TableCell>
														<Dialog
															open={open === row.id}
															onClose={handleCloseInvoice}
															sx={styles.modal}
															maxWidth="md"
															fullWidth
														>
															<Title
																title="Marketplace Invoice"
																onClose={handleCloseInvoice}
															/>
															<Invoice
																{...props}
																config={config}
																membership={row}
															/>
														</Dialog>
														<Dialog
															sx={styles.modal}
															open={open === "99"}
															onClose={handleCloseInvoice}
														>
															<PayInvoice
																{...props}
																onClose={handleCloseInvoice}
																invoices={selectedInvoices}
																setSelected={setSelected}
															/>
														</Dialog>
													</TableRow>
												);
											})}
									</TableBody>
								</>
							</Table>
						) : (
							<Typography align="center" style={{ padding: 20 }}>
								There are no unpaid invoices
							</Typography>
						)}

						{noOfPages < 2 || rows.length === 0 ? null : (
							<Grid
								container
								sx={{ width: "100%", borderBottom: "1px #DCDCDC solid" }}
								direction="row"
								justifyContent="center"
							>
								<Grid style={{ padding: 10 }} item>
									<Pagination
										page={page}
										onChange={handleChange}
										count={noOfPages}
										color="secondary"
									/>
								</Grid>
							</Grid>
						)}
					</TableContainer>
					{invoices?.length > 0 ? (
						<Grid
							container
							direction="row"
							justifyContent="space-between"
							sx={{ p: 1 }}
						>
							<Grid item>
								{balanceDue === 0 ? null : (
									<Typography sx={{ pt: 1 }}>
										Balance Due: {formatCurrency(balanceDue, clubData)}
									</Typography>
								)}
							</Grid>
							<Grid item>
								<Button
									onClick={() => setOpen("99")}
									variant="contained"
									fullWidth
									color="primary"
									disabled={selected?.length === 0 || props.getRequest}
								>
									{!props.getRequest ? (
										"Pay Selected Invoices"
									) : (
										<CircularProgress size={25} color="secondary" />
									)}
								</Button>
							</Grid>
						</Grid>
					) : null}
				</Hidden>
				<>
					<MobileInvoiceMenu
						{...props}
						rows={rows}
						rowsPerPage={rowsPerPage}
						paidRows={paidRows}
						page={page}
						paidPage={paidPage}
						open={open}
						noOfPages={noOfPages}
						setOpen={setOpen}
						handleChange={handleChange}
						handleOpenInvoice={handleOpenInvoice}
						handleCloseInvoice={handleCloseInvoice}
						getSelectedInvoices={getSelectedInvoices}
						onClose={handleCloseInvoice}
						handleClick={handleClick}
						selected={selected}
						selectedInvoices={selectedInvoices}
						handleSelectAllClick={handleSelectAllClick}
						isSelected={isSelected}
						invoiceDetails={props.invoiceDetails}
						paidInvoices={paidInvoices}
						balanceDue={balanceDue}
					/>
					<Hidden smDown>
						<CardHeader
							title={
								<Typography
									variant="h6"
									sx={{ fontFamily: "Roboto Condensed, sans-serif" }}
								>
									Paid Invoices
								</Typography>
							}
							sx={classes.paidHeader}
						/>
						<Divider sx={{ color: "primary.main" }} />
						{paidInvoices?.length > 0 ? (
							<TableContainer>
								<Table sx={classes.table} size="small">
									<EnhancedTableHead
										type="paid"
										classes={classes}
										numSelected={selected.length}
										order={orderPaid}
										orderBy={orderPaidBy}
										onSelectAllClick={handleSelectAllClick}
										onRequestSort={handlePaidRequestSort}
										rowCount={paidRows?.length}
									/>

									<TableBody sx={{ width: "100%" }}>
										{stableSort(paidRows, getComparator(orderPaid, orderPaidBy))
											?.slice(
												(paidPage - 1) * rowsPerPage,
												paidPage * rowsPerPage
											)
											?.map((value: any, index: any) => {
												const labelId = `checkbox-${value}`;
												return (
													<React.Fragment key={index}>
														<TableRow
															tabIndex={-1}
															sx={classes.list}
															key={index}
														>
															<TableCell
																style={{ borderBottom: "none" }}
																align="left"
																size="small"
															>
																{value.id}
															</TableCell>
															<TableCell
																style={{ borderBottom: "none" }}
																padding="normal"
																align="left"
																id={labelId}
															>
																{value.description}
																{/* {value.description.length < 20
																	? value.description
																	: value.description.substr(0, 21) + "..."} */}
															</TableCell>
															<TableCell
																style={{ borderBottom: "none" }}
																padding="normal"
																align="left"
																id={labelId}
															>
																{moment(value.due.slice(0, 10)).format(
																	"MM/DD/YY"
																)}
															</TableCell>
															<TableCell
																style={{ borderBottom: "none" }}
																align="left"
															>
																{formatCurrency(value.amount, clubData)}
															</TableCell>
															<TableCell
																align="right"
																style={{ borderBottom: "none" }}
															>
																<Button
																	onClick={() => handleOpenInvoice(value.id)}
																	variant="outlined"
																	fullWidth
																	sx={{ bgcolor: "#FAFAFA" }}
																>
																	View
																</Button>
															</TableCell>
														</TableRow>
														<Dialog
															open={open === value.id}
															onClose={handleCloseInvoice}
															sx={styles.modal}
															maxWidth="md"
															fullWidth
														>
															<Title
																title="Membership Invoices"
																onClose={handleCloseInvoice}
															/>
															<Invoice
																{...props}
																config={config}
																membership={value}
															/>
														</Dialog>
													</React.Fragment>
												);
											})}
									</TableBody>
								</Table>
							</TableContainer>
						) : (
							<Typography align="center" style={{ padding: 20 }}>
								There are no paid invoices
							</Typography>
						)}
					</Hidden>
					{noOfPaidPages < 2 || paidInvoices.length === 0 ? null : (
						<Grid container justifyContent="center">
							<Grid style={{ padding: 10 }} item>
								<Pagination
									page={paidPage}
									onChange={handlePaidChange}
									count={noOfPaidPages}
									boundaryCount={matches ? 0 : 1}
									siblingCount={1}
									color="secondary"
								/>
							</Grid>
						</Grid>
					)}
				</>
			</Paper>
		</div>
	);
}

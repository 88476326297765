import { connect } from "react-redux";
import SignUp from "../components/SignUp/SignUp";
const mapStateToProps = (state: any) => {
	return {
		errors: state.auth.errors,
		messages: state.auth.messages,
		userTaken: state.auth.userTaken,
		submitted: state.auth.submitted,
		data: state.auth.data,
		clientId: state.auth.clientId,
		registerErrors: state.auth.errors,
		questions: state.auth.questions,
		requesting: state.auth.requesting,
		user: state.auth.currentUser,
		clubData: state.billingProfile.clubData,
		clubDataSuccess: state.billingProfile.successful,
		clubDataRequesting: state.billingProfile.requesting,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onRegister: (config: any) =>
			dispatch({ type: "REGISTER_REQUEST", value: config }),
		checkUserName: (action: any) =>
			dispatch({ type: "CHECK_USERNAME", value: action }),
		onSignOut: () => dispatch({ type: "LOGOUT" }),
		clearUserNameError: () => dispatch({ type: "CLEAR_USERNAME_ERROR" }),
		clearMessage: () => dispatch({ type: "CLEAR_MESSAGE" }),
		clearError: () => dispatch({ type: "CLEAR_ERROR" }),
		getClubDetails: (config: any) =>
			dispatch({ type: "ACCEPTED_CARDS_REQUEST", value: config }),
		submitQs: (config: any) =>
			dispatch({ type: "SUBMIT_QUESTIONNAIRES", value: config }),
		requestQs: (config: any) =>
			dispatch({ type: "REQUEST_QUESTIONNAIRES", value: config }),
		markQs: (config: any) =>
			dispatch({ type: "MARK_QUESTIONNAIRE", value: config }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

import axios from "axios";
import * as URL from "../config";
import { apiKey } from "../config";

export function requestGetScheduleByDate(action: any) {
	const getScheduleUrl: any = `${URL.MP_SCHEDULE}/ScheduleByDate?alias=${action.value.businessAlias}&startDate=${action.value.startDate}&endDate=${action.value.endDate}&scheduleType=${action.value.scheduleType}`;
	return axios
		.get(getScheduleUrl, {
			headers: { Authorization: apiKey },
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestGetClubDetails(action: any) {
	const getClubDetailsUrl: any = `${URL.MP_SCHEDULE}/ClubDetails?alias=${action.value.businessAlias}`;
	return axios
		.get(getClubDetailsUrl, {
			headers: { Authorization: apiKey },
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestScheduleFilters(action: any) {
	const getClubDetailsUrl: any = `${URL.MP_SCHEDULE}/GetScheduleFilters?alias=${action.value.businessAlias}&tabId=${action.value.tabId}`;
	return axios
		.get(getClubDetailsUrl, {
			headers: { Authorization: apiKey },
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestBookClass(action: any) {
	const bookClassUrl: any = `${URL.MP_SCHEDULE}/MPBookIt?alias=${action.value.businessAlias}&scheduleId=${action.value.scheduleId}&clientId=${action.value.clientId}&waitList=${action.value.waitlist}&tabId=${action.value.tabId}&startTime=${action.value.startTime}&endTime=${action.value.endTime}&trainerId=${action.value.trainerId}&appointmentTypeId=${action.value.appointmentTypeId}&startDate=${action.value.startDate}`;

	return axios
		.get(bookClassUrl, {
			headers: { Authorization: apiKey },
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestGetUserSchedule(action: any) {
	const getMemberClassesUrl: any = `${URL.MP_SCHEDULE}/MPMemberBooking?alias=${action.value.businessAlias}&clientId=${action.value.clientId}&startDate=${action.value.startDate}&endDate=${action.value.endDate}`;
	return axios
		.get(getMemberClassesUrl, {
			headers: { Authorization: apiKey },
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestGetGymSchedule(action: any) {
	let getMemberClassesUrl: any = `${URL.MP_SCHEDULE}/getSchedules?alias=${action.value.businessAlias}&clientId=${action.value.clientId}&startDate=${action.value.startDate}&endDate=${action.value.endDate}&tabId=${action.value.tabId}`;

	if (action.value.trainer) {
		getMemberClassesUrl =
			getMemberClassesUrl + `&trainerId=${action.value.trainer}`;
	}
	if (action.value.appointmentType) {
		getMemberClassesUrl =
			getMemberClassesUrl +
			`&appointmentTypeId=${action.value.appointmentType}`;
	}
	if (action.value.location) {
		getMemberClassesUrl =
			getMemberClassesUrl + `&locationId=${action.value.location}`;
	}
	return axios
		.get(getMemberClassesUrl, {
			headers: { Authorization: apiKey },
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error || error?.message;
		});
}
export function requestCancelClass(action: any) {
	const cancelClassUrl: any = `${URL.MP_SCHEDULE}/MPCancelIt?alias=${action.value.businessAlias}`;
	return axios
		.post(cancelClassUrl, action.value, {
			headers: { Authorization: apiKey, "Content-Type": "application/json" },
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestGetScheduleNames(action: any) {
	const scheduleNamesUrl: any = `${URL.MP_SCHEDULE}/GetScheduleNames?alias=${action.value.businessAlias}`;
	return axios
		.get(scheduleNamesUrl, {
			headers: { Authorization: apiKey },
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestPayBooking(action: any) {
	console.log("action", action.value);
	const payBookingUrl: any = `${URL.MP_BILLING}/schedule/checkoutBooking`;
	return axios
		.post(payBookingUrl, action.value, {
			headers: action.value.headers,
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}

export function requestGetCancelReasons(action: any) {
	const cancelReasonsUrl: any = `${URL.MP_SCHEDULE}/GetCancelReasons?alias=${action.value.businessAlias}`;
	return axios
		.get(cancelReasonsUrl, {
			headers: { Authorization: apiKey },
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

export function requestGetRelatedClasses(action: any) {
	const relatedClasses: any = `${URL.MP_SCHEDULE}/GetProducts?alias=${action.value.businessAlias}&clientId=${action.value.clientId}&appointmentTypeId=${action.value.appointmentTypeId}`;
	return axios
		.get(relatedClasses, {
			headers: { Authorization: apiKey },
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}

export function requestGetRecurringSchedule(action: any) {
	const recurringScheduleUrl: any = `${URL.MP_SCHEDULE}/GetRecurringSchedules?alias=${action.value.businessAlias}&scheduleId=${action.value.scheduleId}&clientId=${action.value.clientId}`;
	return axios
		.get(recurringScheduleUrl, {
			headers: { Authorization: apiKey },
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

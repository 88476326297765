import axios from "axios";
import * as URL from "../config";

export function requestGetEnrollMemberships(action: any) {
	const getEnrollMembershipsUrl: any = `${URL.MP_MEMBER}/clients/storeMemberships?ClientId=${action.id}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}`;
	return axios
		.get(getEnrollMembershipsUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestGetMembershipDetails(action: any) {
	const getMembershipDetailsUrl: any = `${URL.MP_MEMBER}/clients/storeMembershipDetails?ClientId=${action.id}&BusinessAlias=${action.businessAlias}&PartnerApiKey=${action.partnerApiKey}&ProductId=${action.productId}`;
	return axios
		.get(getMembershipDetailsUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestGetMembershipTerms(action: any) {
	let users: any = action.purchasedFor.map((user: any) => {
		return `&PurchasedFor=${user}`;
	});

	users = users.toString().replace(/,/g, "");
	const getMembershipTermsUrl: any = `${URL.MP_MEMBER}/clients/storeMembershipTerms?ClientId=${action.id}&BusinessAlias=${action.businessAlias}&ProductId=${action.productId}&PartnerApiKey=${action.partnerApiKey}&BillingProfileId=${action.billingProfileId}${users}`;
	return axios
		.get(getMembershipTermsUrl, action)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}
export function requestEnrollMembership(action: any) {
	const enrollMembership: any = `${URL.MP_BILLING}/memberships/checkout`;
	return axios
		.post(enrollMembership, action, { headers: action.headers })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log("error", error?.response);
			throw error;
		});
}

import Layout from "./hoc/Layout";
import { useDispatch } from "react-redux";
import axios from "axios";
import { history } from "./utils/history";
import ReactGA from "react-ga4";
import { ANALYTICS_ID, MP_ENV } from "./config";

function App(props: any) {
	/* eslint eqeqeq: 0 */
	if (MP_ENV === "PROD") {
		console.log("GA intialized");
		ReactGA.initialize(ANALYTICS_ID);
	}
	let alias: any = window.location.pathname.split("/")[1];
	const dispatch = useDispatch();
	axios.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			if (401 === error?.response?.status) {
				console.log("SESSION EXPIRED SIGNOUT");
				dispatch({ type: "LOGOUT" });
				return history.push(`/${alias}/signin`);
			} else {
				return Promise.reject(error);
			}
		}
	);
	return (
		<div>
			<Layout {...props} />
		</div>
	);
}

export default App;
